
import { log } from 'util'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const hasCondition = (state, formId, needIndex = false) => {
  const index = state.processConditions.findIndex(d => d.formId === formId)
  return needIndex ? index : index > -1
}
export default new Vuex.Store({
  state: {
    recordingUrl:null,
    recordingUrl1:null,
    iscollapse: false,
    tablist: [],
    isphone: false,
    processConditions: [], // processConditions 用于传递流程图需要的条件
    formItemList: [], // 流程节点表单权限控制——组件列表
    type: 4,
    // 菜单
    menu: [],
    // 权限
    permissions: {},
    // 路由
    routers: [],
    page: 1,
    activeNum: 2,
    personRiskId: null,
    isSaveForm: false,             //是否保存表单草稿
    currentRouting: '',
    isBack: false,                   //是否是返回按钮
    formModel:null,
  },
  getters: {
  },
  mutations: {
    changeRecordingUrl1(state,data){
      state.recordingUrl1 = data
    },
    changeIsPhone(state,data){
      state.isphone = data
    },
    changeFormModel(state, data) {
      state.formModel = data;
    },
    changeIsBack(state, data) {
      state.isBack = data;
    },
    changeCurrentRoute(state, data) {
      state.currentRouting = data;
      console.log(state.currentRouting);
    },
    changeIsSaveForm(state, data) {
      state.isSaveForm = data;
      console.log(state.isSaveForm);
    },
    changePersonRiskId(state, data) {
      state.personRiskId = data;
      console.log(data);
    },
    changePage(state, data) {
      state.page = data;
      console.log(data);
    },
    changeActiveNum(state, data) {
      state.activeNum = data;
    },
    changecollapse() {
      this.state.iscollapse = !this.state.iscollapse
      // console.log(iscollapse);
    },
    collapsefalse() {
      this.state.iscollapse = true
    },
    // 所有mutations中的方法的第一个参数一定是state变量，用来进行对state中的状态的操作
    // 第二个参数是可选参数，用于调用该 mutations 方法的时候传参
    initPConditions(state, data) {
      state.processConditions = data
    },
    addPCondition(state, data) {
      if (data.formId === null || data.formId === undefined) return
      if (!hasCondition(state, data.formId)) {
        state.processConditions.unshift(data)
      }
    },
    delPCondition(state, formId) {
      if (formId === null || formId === undefined) return
      const index = hasCondition(state, formId, true)
      const cons = state.processConditions
      index > -1 && cons.splice(index, 1)
    },
    //  * 清除所有的条件
    clearPCondition(state) {
      state.processConditions = []
    },
    updateFormItemList(state, list) {
      state.formItemList = list
    },
    type_amend(state, data) {
      state.type = data
    },
    menumutations(state, data) {
      state.menu = data
    },
    permissionsmutations(state, data) {
      state.permissions = data
    },
    routersmutations(state, data) {
      state.routers = data
    }

  },
  actions: {
  },
  modules: {

  }
})
