<template>
    <div>
        <div>
            <SettingForm ref="SettingAddProcurementOrders" :refs="'purchasingRef1'" :inline="true" :isFormBtn="false"
                :readonly="readonly" :settingForm="settingForm" @onAppliedForm="onAppliedForm">
                <div slot="shortcut_button" style="margin-bottom:10px;">
                    <el-button type="primary" size="mini" @click="onProcurement('带出1')">带出1</el-button>
                    <el-button type="primary" size="mini" @click="onProcurement('带出2')">带出2</el-button>
                    <el-button type="primary" size="mini" @click="onProcurement('带出3')">带出3</el-button>
                    <el-button type="primary" size="mini" @click="onProcurement('带出4')">带出4</el-button>
                </div>
            </SettingForm>
        </div>
    </div>
</template>
<script>
import { EventBus } from '@/components/ProcessFlow/CRM/Forms/components/eventBus';
import SettingForm from "@/components/ProcessFlow/CRM/Forms/components/SettingForm.vue";
import Decimal from 'decimal.js';
export default {
    components: {
        SettingForm
    },
    props: ["isPurchasingRequisition", "addPurchasingRequisitionForm", 'formTitle', "readonly", "dialogCustomerForm"],
    data() {
        return {
            purchasingForm: {
            },
            settingForm: {
                form_root: '采购订单',
                classification: '采购订单',
                control_setting: [{
                    default_value: null,
                    options: [
                        {
                            label: null,
                            value: null,
                        },
                    ],
                    type: '文本输入框',
                    model_class: null,
                    url: null,
                    model_class_field: null,
                    model_class_value: null,
                    is_numeric: false,
                    particulars: [{
                        type: null,
                        url: null,
                        field: null,
                        acquire: null,
                        is_edit: true,
                        is_show: true,
                        label: null,
                        placeholder: null,
                    }],
                    permissions: {
                        edit: true,
                        show: true,
                        update: true,
                        require: false
                    },
                    visible_rule: {
                        role_limit: []
                    },
                    basic_settings: {
                        show_name: "field",
                        field: "field",
                        fields: "field",
                        placeholder: "field"
                    },
                    numeric_settings: {
                        max_digits: null,
                        decimal_places: null
                    },
                }]
            },
        }
    },
    methods: {
        onProcurement(val) {
            console.log(val);
        },
        onAppliedForm(val) {
            this.purchasingForm = val
        },
        async getSettingForm() {
            const res = await this.$http({
                method: "GET",
                url: "rbac/form_control_setting/",
                params: {
                    form_root: '采购订单',
                    classification: '采购订单',
                }
            })
            if (res.data[0] != null) {
                this.settingForm = JSON.parse(JSON.stringify(res.data[0]))
                // console.log(this.settingForm.control_setting);
                let today = new Date();
                let date = today.getFullYear() + "-" + (today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1) + "-" + (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
                // let purchase = {
                //     label: "阿里巴巴",
                //     value: 353
                // }
                this.settingForm.control_setting.forEach((item, i) => {
                    if (item.basic_settings.field == 'filing_date') {
                        item.default_value = date
                    }
                    if (item.basic_settings.field == 'is_tax') {
                        item.default_value = true
                    }
                    // if (item.basic_settings.field == 'purchase') {
                    //     item.default_value = purchase
                    // }
                })
                // console.log(date, purchase, this.dialogCustomerForm);
            }
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                if (column.property === "total_actual_amount") {
                    const sum = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + value;
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] = sum.toString().replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
                } else {
                    sums[index] = '';
                }
            });
            return sums;
        },
    },
    watch: {
        settingForm: {
            handler(val) {
                EventBus.$emit('onIsEditForm', true);
            }
        },
        "purchasingForm.deliver_method": {
            handler(val) {
                EventBus.$emit('onIsEditForm', false);
                // console.log(val, '6666666666')
                this.settingForm.control_setting.forEach(item => {
                    if (item.basic_settings.field == 'deliver_addr' && val === '需方自提') {
                        item.permissions.show = false
                    } else if (item.basic_settings.field == 'deliver_addr' && val != '需方自提') {
                        item.permissions.show = true
                    }
                });
            },
            deep: true,
            immediate: true
        },
        "purchasingForm.details": {
            handler(val) {
                if (this.$refs.SettingAddProcurementOrders.$refs.table_ref == undefined) {
                    return
                }
                const data = this.$refs.SettingAddProcurementOrders.$refs.table_ref[0].data;
                const columns = this.$refs.SettingAddProcurementOrders.$refs.table_ref[0].columns;
                console.log(data);
                data.forEach(row => {
                    if (row.unit_price !== null && row.quantity !== null && row.unit_price !== '' && row.quantity !== '') {
                        let s1 = row.unit_price.toString();
                        let s2 = row.quantity.toString();
                        let decimal1 = new Decimal(s1)
                        let decimal2 = new Decimal(s2)
                        let decimal3 = decimal1.times(decimal2)
                        row.total_actual_amount = decimal3 * 1
                    }
                });
                let arr = this.getSummaries({
                    columns: columns,
                    data: data
                })
                const footerWrapper = this.$refs.SettingAddProcurementOrders.$refs.table_ref[0].$refs.footerWrapper.querySelector('.has-gutter');
                const cells = footerWrapper.querySelectorAll('.cell');
                cells.forEach((item, i) => {
                    item.innerText = arr[i]
                })
            },
            deep: true,
        },
        addPurchasingRequisitionForm: {
            handler(val) {
                if (this.formTitle == "采购订单") {
                    if (val !== undefined) {
                        for (const key in val) {
                            this.purchasingForm[key] = val[key]
                        }
                    }
                }
            },
            deep: true,
            immediate: true,
        },
        purchasingForm: {
            handler(val) {
                if (this.isPurchasingRequisition == false) {
                    this.$emit("onAddOutboundOrder", this.purchasingForm);
                }
            },
            deep: true,
        },
    },
    mounted() {

        // let obj = {
        //             order_form_type: "5",
        //             date: "2024-09-11",
        //             purchase: {
        //                 label: '阿里巴巴',
        //                 value: 353
        //             },
        //             creator_arr: null,
        //             warehouse: {
        //                 label: null,
        //                 value: null
        //             },
        //             currency: null,
        //             is_tax: null,
        //             required_date: null,
        //             payment_method: null,
        //             deliver_method: null,
        //             deliver_addr: null,
        //             charge_code: {
        //                 label: null,
        //                 value: null
        //             },
        //             packing_requirement: null,
        //             project: null,
        //             remark: null,
        //             details: [
        //                 {
        //                     product_code: null,
        //                     product_name: null,
        //                     product: null,
        //                     specifications: null,
        //                     quantity: null,
        //                     unit_price: null,
        //                     unit: null,
        //                     total_actual_amount: null,
        //                     abstract: null
        //                 }
        //             ],
        //         }
    },
    created() {
        this.getSettingForm()

    },
}
</script>
<style lang="less" scoped></style>