<template>
    <div>
        <!-- 表格 -->
        <el-dialog v-if="isVisible" :modal="false" v-dialogDrags :close-on-click-modal="false" :show-close="false"
            :visible.sync="isVisible" width="1000px" :fullscreen="isFullscreen">
            <div slot="title" class="dialog_slot_title">
                <div class="title">{{ title }}</div>
                <div>
                    <i v-if="isFullscreen" class="iconfont icon-zuidahua "
                        style="font-size: 16px; cursor: pointer;margin-right: 10px;"
                        @click.stop="onFullscreen('isFullscreen')"></i>
                    <i v-else class="iconfont icon-chuangkouzuidahua"
                        style="font-size: 16px; cursor: pointer;margin-right: 10px;"
                        @click.stop="onFullscreen('isFullscreen')"></i>
                    <i class="el-icon-close" style="font-size: 16px; cursor: pointer;"
                        @click.stop="onCancel('isVisible')"></i>
                </div>
            </div>
            <div>
                <div style="margin-bottom: 10px;">
                    <el-button type="primary" size="mini" @click="isAdd = true">添加</el-button>
                    <el-button type="danger" v-if="this.settingForm.id != undefined" size="mini"
                        @click="onDelete(settingForm.id)">删除</el-button>
                </div>
                <div class="control_table">
                    <div class="control_table_header">
                        <div class="control_table_column1">序号</div>
                        <div class="control_table_column">名称</div>
                        <div class="control_table_column">字段名</div>
                    </div>
                    <draggable v-model="settingForm.control_setting" @end="onDragEnd" animation="0">
                        <div class="control_table_body movetag" v-for="(items, j) in settingForm.control_setting" :key="j"
                            @click="rowClick(items, j)">
                            <div class="control_table_column1">
                                {{ j + 1 }}
                            </div>
                            <div class="control_table_column">
                                {{ items.type == '表格' ? '表格' : items.basic_settings.show_name }}
                            </div>
                            <div class="control_table_column">
                                {{ items.basic_settings.field }}
                            </div>
                        </div>
                    </draggable>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="onCancel('isVisible')">取 消</el-button>
                <el-button size="mini" type="primary" @click="onApplied">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 添加控件 -->
        <el-dialog v-if="isAdd" :modal="false" v-dialogDrags :close-on-click-modal="false" :show-close="false"
            :visible.sync="isVisible" width="1000px" :fullscreen="isAddFullscreen">
            <div slot="title" class="dialog_slot_title">
                <div class="title">添加表单</div>
                <div>
                    <i v-if="isAddFullscreen" class="iconfont icon-zuidahua "
                        style="font-size: 16px; cursor: pointer;margin-right: 10px;"
                        @click.stop="onFullscreen('isAddFullscreen')"></i>
                    <i v-else class="iconfont icon-chuangkouzuidahua"
                        style="font-size: 16px; cursor: pointer;margin-right: 10px;"
                        @click.stop="onFullscreen('isAddFullscreen')"></i>
                    <i class="el-icon-close" style="font-size: 16px; cursor: pointer;" @click.stop="onCancel('isAdd')"></i>
                </div>
            </div>
            <div>
                <el-form ref="form" :model="addForm" size="mini">
                    <el-form-item v-if="addForm.type != '表格'" label="显示名称">
                        <el-input v-model="addForm.basic_settings.show_name" placeholder="请输入内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="字段名(前端)">
                        <el-input v-model="addForm.basic_settings.field" placeholder="请输入内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="字段名(后端)"
                        v-if="addForm.type == '对象(多选)' || addForm.type == '对象(单选)' || addForm.type == '组织架构(人员单选)' || addForm.type == '组织架构(人员多选)' || addForm.type == '组织架构(部门单选)' || addForm.type == '组织架构(部门多选)'">
                        <el-input v-model="addForm.basic_settings.fields" placeholder="请输入内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="提示内容" v-if="addForm.type != '表格'">
                        <el-input v-model="addForm.basic_settings.placeholder" placeholder="请输入内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="默认值" v-if="addForm.type != '表格'">
                        <el-input v-model="addForm.default_value" placeholder="请输入内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="类型">
                        <template>
                            <el-select style="width: 100%;" v-model="addForm.type" placeholder="请选择">
                                <el-option v-for="item in typeOption" :label="item.label" :value="item.label"
                                    :key="item.label">
                                </el-option>
                            </el-select>
                        </template>
                    </el-form-item>
                    <el-form-item v-if="addForm.type == '下拉选择框(多选)' || addForm.type == '下拉选择框(单选)'" label="控件参数">
                        <template v-for="(items, i) in addForm.options">
                            <div :key="i"
                                style="width: 100%; display: flex;justify-content: space-between;align-items: center;cursor: pointer;margin-bottom: 5px;">
                                <el-input style="width: 80%;" v-model="items.label" placeholder="请输入内容" clearable
                                    @change="onChangeOptions('addForm', i, items.label)"></el-input>
                                <i v-if="i == 0" class="el-icon-circle-plus-outline"
                                    @click="onAddChangeOptions('addForm')"></i>
                                <i v-else class="el-icon-remove-outline" @click="onDeleteChangeOptions('addForm', i)"></i>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item label="模型类" v-if="addForm.type == '对象(多选)' || addForm.type == '对象(单选)'">
                        <el-select style="width: 100%;" v-model="addForm.model_class" filterable placeholder="请选择"
                            @change="onModelClassChange(addForm.model_class)">
                            <el-option v-for="item in modelClassOption" :label="item.label" :value="item.value"
                                :key="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="URL"
                        v-if="addForm.type == '对象(多选)' || addForm.type == '对象(单选)' || addForm.type == '下拉选择框(多选)' || addForm.type == '下拉选择框(单选)'">
                        <template>
                            <el-input v-model="addForm.url" placeholder="请输入内容" clearable></el-input>
                        </template>
                    </el-form-item>
                    <el-form-item label="显示名称" v-if="addForm.type == '对象(多选)' || addForm.type == '对象(单选)'">
                        <el-select style="width: 100%;" v-model="addForm.model_class_field" filterable placeholder="请选择">
                            <el-option v-for="item in modelClassFieldOption" :label="item.field" :value="item.field"
                                :key="item.field">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="下拉选择框label" v-if="addForm.type == '下拉选择框(多选)' || addForm.type == '下拉选择框(单选)'">
                        <el-input v-model="addForm.model_class_field" placeholder="请输入内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="下拉选择框value" v-if="addForm.type == '下拉选择框(多选)' || addForm.type == '下拉选择框(单选)'">
                        <el-input v-model="addForm.model_class_value" placeholder="请输入内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item v-if="addForm.type != '表格'" label="是否可编辑">
                        <el-switch v-model="addForm.permissions.edit" active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                    </el-form-item>
                    <el-form-item v-if="addForm.type != '表格'" label="是否显示">
                        <el-switch v-model="addForm.permissions.show" active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                    </el-form-item>
                    <el-form-item v-if="addForm.type != '表格'" label="是否可修改">
                        <el-switch v-model="addForm.permissions.update" active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                    </el-form-item>
                    <el-form-item v-if="addForm.type != '表格'" label="是否必填">
                        <el-switch v-model="addForm.permissions.require" active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                    </el-form-item>
                    <el-form-item v-if="addForm.type != '表格'" label="是否数字">
                        <el-switch v-model="addForm.is_numeric" active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                    </el-form-item>
                    <el-form-item v-if="addForm.is_numeric" label="最大位数">
                        <el-input v-model="addForm.numeric_settings.max_digits">
                        </el-input>
                    </el-form-item>
                    <el-form-item v-if="addForm.is_numeric" label="最小数位数">
                        <el-input v-model="addForm.numeric_settings.decimal_places">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="角色可见称" prop="title">
                        <el-select v-model="addForm.visible_rule.role_limit" multiple collapse-tags style="width: 100%;"
                            placeholder="请选择">
                            <el-option v-for="item in roleOptions" :key="item.id" :label="item.role" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" v-if="addForm.type == '表格'">
                        <div>
                            <div style="display: flex;justify-content: flex-end;">
                                <i class="el-icon-circle-plus-outline add_particulars"
                                    @click="onAddParticulars('addForm')"></i>
                            </div>
                            <div style="border: 1px dashed #8fc31f; padding: 10px; border-radius: 12px;">
                                <template v-for="(items, j) in addForm.particulars">
                                    <div :key="j"
                                        style="border: 1px dashed #dcdfe6; padding: 10px;margin-bottom: 10px; border-radius: 12px;">
                                        <div style="display: flex;justify-content: flex-end;">
                                            <i class="el-icon-close close_btn"
                                                @click="onDeleteParticulars('addForm', j)"></i>
                                        </div>
                                        <el-form :model="items" size="mini">
                                            <el-form-item label="名称" prop="label">
                                                <el-input v-model="items.label" placeholder="请输入内容" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="前端字段名" prop="field">
                                                <el-input v-model="items.field" placeholder="请输入内容" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="后端字段名" prop="field">
                                                <el-input v-model="items.acquire" placeholder="请输入内容" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="提示内容" prop="placeholder">
                                                <el-input v-model="items.placeholder" placeholder="请输入内容"
                                                    clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="是否可编辑" prop="is_edit">
                                                <el-switch v-model="items.is_edit" active-color="#13ce66"
                                                    inactive-color="#ff4949">
                                                </el-switch>
                                            </el-form-item>
                                            <el-form-item label="是否显示" prop="is_show">
                                                <el-switch v-model="items.is_show" active-color="#13ce66"
                                                    inactive-color="#ff4949">
                                                </el-switch>
                                            </el-form-item>
                                            <el-form-item label="类型" prop="type">
                                                <template>
                                                    <el-select v-model="items.type" placeholder="请选择">
                                                        <el-option v-for="tags in typeParticularsOption" :label="tags.label"
                                                            :value="tags.value" :key="tags.value">
                                                        </el-option>
                                                    </el-select>
                                                </template>
                                            </el-form-item>
                                            <el-form-item label="URL" prop="url">
                                                <el-input v-model="items.url" placeholder="请输入内容" clearable></el-input>
                                            </el-form-item>
                                        </el-form>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="onCancel('isAdd')">取 消</el-button>
                <el-button size="mini" type="primary" @click="onAdd">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 修改控件 -->
        <el-dialog :visible.sync="isVisibleForm" v-if="isVisibleForm" :modal="false" v-dialogDrags
            :close-on-click-modal="false" :show-close="false" width="1000px" :fullscreen="isVisibleFormFullscreen">
            <div slot="title" class="dialog_slot_title">
                <div class="title"></div>
                <div>
                    <i v-if="isVisibleFormFullscreen" class="iconfont icon-zuidahua "
                        style="font-size: 16px; cursor: pointer;margin-right: 10px;"
                        @click.stop="onFullscreen('isVisibleFormFullscreen')"></i>
                    <i v-else class="iconfont icon-chuangkouzuidahua"
                        style="font-size: 16px; cursor: pointer;margin-right: 10px;"
                        @click.stop="onFullscreen('isVisibleFormFullscreen')"></i>
                    <i class="el-icon-close" style="font-size: 16px; cursor: pointer;"
                        @click.stop="onCancel('isVisibleForm')"></i>
                </div>
            </div>
            <div>
                <div>
                    <el-button type="primary" size="mini" @click="onReset">重置</el-button>
                </div>
                <el-form ref="form" :model="dialogForm" size="mini">
                    <el-form-item label="显示名称" v-if="dialogForm.type != '表格'">
                        <el-input v-model="dialogForm.basic_settings.show_name" placeholder="请输入内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="字段名(前端)">
                        <el-input v-model="dialogForm.basic_settings.field" placeholder="请输入内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="字段名(后端)"
                        v-if="dialogForm.type == '对象(多选)' || dialogForm.type == '对象(单选)' || dialogForm.type == '组织架构(人员单选)' || dialogForm.type == '组织架构(人员多选)' || dialogForm.type == '组织架构(部门单选)' || dialogForm.type == '组织架构(部门多选)'">
                        <el-input v-model="dialogForm.basic_settings.fields" placeholder="请输入内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="提示内容" v-if="dialogForm.type != '表格'">
                        <el-input v-model="dialogForm.basic_settings.placeholder" placeholder="请输入内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="默认值" v-if="dialogForm.type != '表格'">
                        <el-input v-model="dialogForm.default_value" placeholder="请输入内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="类型">
                        <template>
                            <el-select style="width: 100%;" v-model="dialogForm.type" placeholder="请选择">
                                <el-option v-for="item in typeOption" :label="item.label" :value="item.label"
                                    :key="item.label">
                                </el-option>
                            </el-select>
                        </template>
                    </el-form-item>
                    <el-form-item v-if="dialogForm.type == '下拉选择框(多选)' || dialogForm.type == '下拉选择框(单选)'" label="控件参数">
                        <template v-for="(items, i) in dialogForm.options">
                            <div :key="i"
                                style="width: 100%; display: flex;justify-content: space-between;align-items: center;cursor: pointer;margin-bottom: 5px;">
                                <el-input style="width: 80%;" v-model="items.label" placeholder="请输入内容" clearable
                                    @change="onChangeOptions('dialogForm', i, items.label)"></el-input>
                                <i v-if="i == 0" class="el-icon-circle-plus-outline"
                                    @click="onAddChangeOptions('dialogForm')"></i>
                                <i v-else class="el-icon-remove-outline"
                                    @click="onDeleteChangeOptions('dialogForm', i)"></i>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item label="模型类" v-if="dialogForm.type == '对象(多选)' || dialogForm.type == '对象(单选)'">
                        <el-select style="width: 100%;" v-model="dialogForm.model_class" filterable placeholder="请选择"
                            @change="onModelClassChange(dialogForm.model_class)">
                            <el-option v-for="item in modelClassOption" :label="item.label" :value="item.value"
                                :key="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="URL"
                        v-if="dialogForm.type == '对象(多选)' || dialogForm.type == '对象(单选)' || dialogForm.type == '下拉选择框(多选)' || dialogForm.type == '下拉选择框(单选)'">
                        <template>
                            <el-input v-model="dialogForm.url" placeholder="请输入内容" clearable></el-input>
                        </template>
                    </el-form-item>
                    <el-form-item label="显示名称" v-if="dialogForm.type == '对象(多选)' || dialogForm.type == '对象(单选)'">
                        <el-select style="width: 100%;" v-model="dialogForm.model_class_field" filterable placeholder="请选择">
                            <el-option v-for="item in modelClassFieldOption" :label="item.field" :value="item.field"
                                :key="item.field">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="下拉选择框label"
                        v-if="dialogForm.type == '下拉选择框(多选)' || dialogForm.type == '下拉选择框(单选)'">
                        <el-input v-model="dialogForm.model_class_field" placeholder="请输入内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="下拉选择框value"
                        v-if="dialogForm.type == '下拉选择框(多选)' || dialogForm.type == '下拉选择框(单选)'">
                        <el-input v-model="dialogForm.model_class_value" placeholder="请输入内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="是否可编辑" v-if="dialogForm.type != '表格'">
                        <el-switch v-model="dialogForm.permissions.edit" active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                    </el-form-item>
                    <el-form-item label="是否显示" v-if="dialogForm.type != '表格'">
                        <el-switch v-model="dialogForm.permissions.show" active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                    </el-form-item>
                    <el-form-item label="是否可修改" v-if="dialogForm.type != '表格'">
                        <el-switch v-model="dialogForm.permissions.update" active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                    </el-form-item>
                    <el-form-item label="是否必填" v-if="dialogForm.type != '表格'">
                        <el-switch v-model="dialogForm.permissions.require" active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                    </el-form-item>
                    <el-form-item label="是否数字" v-if="dialogForm.type != '表格'">
                        <el-switch v-model="dialogForm.is_numeric" active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                    </el-form-item>
                    <el-form-item v-if="dialogForm.is_numeric" label="最大位数">
                        <el-input v-model="dialogForm.numeric_settings.max_digits">
                        </el-input>
                    </el-form-item>
                    <el-form-item v-if="dialogForm.is_numeric" label="最小数位数">
                        <el-input v-model="dialogForm.numeric_settings.decimal_places">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="角色可见" prop="title">
                        <el-select v-model="dialogForm.visible_rule.role_limit" multiple collapse-tags style="width: 100%;"
                            placeholder="请选择">
                            <el-option v-for="item in roleOptions" :key="item.id" :label="item.role" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" v-if="dialogForm.type == '表格'">
                        <div>
                            <div style="display: flex;justify-content: flex-end;">
                                <i class="el-icon-circle-plus-outline add_particulars"
                                    @click="onAddParticulars('dialogForm')"></i>
                            </div>
                            <div style="border: 1px dashed #8fc31f; padding: 10px; border-radius: 12px;">
                                <template v-for="(items, j) in dialogForm.particulars">
                                    <div :key="j"
                                        style="border: 1px dashed #dcdfe6; padding: 10px;margin-bottom: 10px; border-radius: 12px;">
                                        <div style="display: flex;justify-content: flex-end;">
                                            <i class="el-icon-close close_btn"
                                                @click="onDeleteParticulars('dialogForm', j)"></i>
                                        </div>
                                        <el-form :model="items" size="mini">
                                            <el-form-item label="名称" prop="label">
                                                <el-input v-model="items.label" placeholder="请输入内容" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="前端字段名" prop="field">
                                                <el-input v-model="items.field" placeholder="请输入内容" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="后端字段名" prop="field">
                                                <el-input v-model="items.acquire" placeholder="请输入内容" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="提示内容" prop="placeholder">
                                                <el-input v-model="items.placeholder" placeholder="请输入内容"
                                                    clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="是否可编辑" prop="is_edit">
                                                <el-switch v-model="items.is_edit" active-color="#13ce66"
                                                    inactive-color="#ff4949">
                                                </el-switch>
                                            </el-form-item>
                                            <el-form-item label="是否显示" prop="is_show">
                                                <el-switch v-model="items.is_show" active-color="#13ce66"
                                                    inactive-color="#ff4949">
                                                </el-switch>
                                            </el-form-item>
                                            <el-form-item label="类型" prop="type">
                                                <template>
                                                    <el-select v-model="items.type" placeholder="请选择">
                                                        <el-option v-for="tags in typeParticularsOption" :label="tags.label"
                                                            :value="tags.value" :key="tags.value">
                                                        </el-option>
                                                    </el-select>
                                                </template>
                                            </el-form-item>
                                            <el-form-item label="URL" prop="url">
                                                <el-input v-model="items.url" placeholder="请输入内容" clearable></el-input>
                                            </el-form-item>
                                        </el-form>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="isVisibleForm = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="onApplieds">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
    props: {
        title: {
            type: String,
            default: '测试'
        },
        isSetting: {
            type: Boolean,
            default: false,
        },
        settingForm: {
            type: Object,
            default: {},
        },
    },
    components: {
        draggable
    },
    data() {
        return {
            isVisible: false,
            isFullscreen: false,
            isVisibleForm: false,
            isVisibleFormFullscreen: false,
            isAdd: false,
            isAddFullscreen: false,
            dialogForm: null,
            dialogFormIndex: null,
            dialogForm_value: null,
            roleOptions: [],
            addForm: {
                default_value: null,
                options: [
                    {
                        label: null,
                        value: null,
                    },
                ],
                type: '文本输入框',
                model_class: null,
                url: null,
                model_class_field: null,
                model_class_value: null,
                is_numeric: false,
                particulars: [
                    {
                        type: null,
                        url: null,
                        field: null,
                        acquire: null,
                        is_edit: true,
                        is_show: true,
                        label: null,
                        placeholder: null,
                    }
                ],
                permissions: {
                    edit: true,
                    show: true,
                    update: true,
                    require: false
                },
                visible_rule: {
                    role_limit: []
                },
                basic_settings: {
                    show_name: null,
                    field: null,
                    fields: null,
                    placeholder: null,
                },
                numeric_settings: {
                    max_digits: null,
                    decimal_places: null
                },
            },
            typeOption: [
                {
                    label: "文本输入框",
                    value: "文本输入框",
                },
                {
                    label: "多文本输入框",
                    value: "多文本输入框",
                },
                {
                    label: "下拉选择框(单选)",
                    value: "下拉选择框(单选)",
                },
                {
                    label: "下拉选择框(多选)",
                    value: "下拉选择框(多选)",
                },
                {
                    label: "数字输入框",
                    value: "数字输入框",
                },
                {
                    label: "对象(单选)",
                    value: "对象(单选)",
                },
                {
                    label: "对象(多选)",
                    value: "对象(多选)",
                },
                {
                    label: "开关",
                    value: "开关",
                },
                {
                    label: "日期选择",
                    value: "日期选择",
                },
                {
                    label: "日期选择(范围)",
                    value: "日期选择(范围)",
                },
                {
                    label: "日期选择(时分秒)",
                    value: "日期选择(时分秒)",
                },
                {
                    label: "日期选择(范围-时分秒)",
                    value: "日期选择(范围-时分秒)",
                },
                {
                    label: "组织架构(人员单选)",
                    value: "组织架构(人员单选)",
                },
                {
                    label: "组织架构(人员多选)",
                    value: "组织架构(人员多选)",
                },
                {
                    label: "组织架构(部门单选)",
                    value: "组织架构(部门单选)",
                },
                {
                    label: "组织架构(部门多选)",
                    value: "组织架构(部门多选)",
                },
                {
                    label: "表格",
                    value: "表格"
                }
            ],
            modelClassOption: null,
            modelClassFieldOption: null,
            typeParticularsOption: [
                {
                    label: '数组',
                    value: '数组',
                },
                {
                    label: '数字',
                    value: '数字',
                },
                {
                    label: '文本',
                    value: '文本',
                },
            ]
        }
    },
    methods: {
        onReset() {
            this.dialogForm = {
                default_value: null,
                options: [
                    {
                        label: null,
                        value: null,
                    },
                ],
                type: '文本输入框',
                model_class: null,
                url: null,
                model_class_field: null,
                model_class_value: null,
                is_numeric: false,
                particulars: [
                    {
                        type: null,
                        url: null,
                        field: null,
                        acquire: null,
                        is_edit: true,
                        is_show: true,
                        label: null,
                        placeholder: null,
                    }
                ],
                permissions: {
                    edit: true,
                    show: true,
                    update: true,
                    require: false
                },
                visible_rule: {
                    role_limit: []
                },
                basic_settings: {
                    show_name: null,
                    field: null,
                    fields: null,
                    placeholder: null,
                },
                numeric_settings: {
                    max_digits: null,
                    decimal_places: null
                },
            }
        },
        onDeleteParticulars(val, index) {
            this[val].particulars.splice(index, 1)
        },
        onAddParticulars(val) {
            let obj = {
                type: null,
                url: null,
                field: null,
                acquire: null,
                is_edit: true,
                is_show: true,
                label: null,
                placeholder: null,
            }
            this[val].particulars.push(obj)
        },
        onDragEnd() {
            console.log('onDragEnd', this.settingForm.control_setting);
        },
        onModelClassChange(val) {
            let obj = {
                model_class: val
            }
            console.log(obj);
            this.getModelClassFieldOption(obj)
        },
        async getModelClassFieldOption(val) {
            const res = await this.$http({
                method: "GET",
                url: "rbac/sys_table_config/",
                params: val
            })
            if (res.status == 200) {
                let arr = [...res.data.filter_field, ...res.data.back_data[0].add_field]
                this.modelClassFieldOption = arr
            } else {
                return this.$message.error(res.data)
            }

        },
        async getSysTableConfig() {
            const res = await this.$http({
                method: "GET",
                url: 'rbac/sys_table_config/',
            })
            if (res.status == 200) {
                this.modelClassOption = res.data.down_list
            } else {
                return this.$message.error(res.data)
            }
        },
        onAddChangeOptions(str) {
            this[str].options.push({
                label: null,
                value: null,
            })
        },
        onDeleteChangeOptions(str, index) {
            this[str].options.splice(index, 1)
        },
        onChangeOptions(str, index, label) {
            this[str].options.splice(index, 1, {
                label: label,
                value: label
            })
        },
        async onDelete(id) {
            const res = await this.$http({
                method: "DELETE",
                url: `rbac/form_control_setting/${id}/`,
            })
            if (res.status == 204) {
                delete this.settingForm.id
            } else {
                this.$message.error(res.data)
            }
            this.onCancel('isVisible')
        },
        async onAdd() {
            console.log(this.addForm);
            this.settingForm.control_setting.push(this.addForm)
            if (this.settingForm.id == undefined) {
                const res = await this.$http({
                    method: "POST",
                    url: "rbac/form_control_setting/",
                    data: this.settingForm
                })
                this.settingForm = res.data
            } else {
                const res = await this.$http({
                    method: "PUT",
                    url: `rbac/form_control_setting/${this.settingForm.id}/`,
                    data: this.settingForm
                })
                this.settingForm = res.data
            }
            this.isAdd = false
        },

        rowClick(row, index) {
            console.log(row, index);
            this.dialogForm = JSON.parse(JSON.stringify(row))
            this.dialogFormIndex = index
            this.isVisibleForm = true
        },
        async onApplied() {
            if (this.settingForm.id == undefined) {
                const res = await this.$http({
                    method: "POST",
                    url: "rbac/form_control_setting/",
                    data: this.settingForm
                })
                this.settingForm = res.data
            } else {
                const res = await this.$http({
                    method: "PUT",
                    url: `rbac/form_control_setting/${this.settingForm.id}/`,
                    data: this.settingForm
                })
                this.settingForm = res.data
            }
            this.$emit('onIsVisible', false)
        },
        async onApplieds() {
            this.settingForm.control_setting.splice(this.dialogFormIndex, 1, this.dialogForm)
            if (this.settingForm.id == undefined) {
                const res = await this.$http({
                    method: "POST",
                    url: "rbac/form_control_setting/",
                    data: this.settingForm
                })
                this.settingForm = res.data
            } else {
                const res = await this.$http({
                    method: "PUT",
                    url: `rbac/form_control_setting/${this.settingForm.id}/`,
                    data: this.settingForm
                })
                this.settingForm = res.data
            }
            this.isVisibleForm = false
        },
        onCancel(val) {
            this[val] = false
            if (val == 'isVisible') {
                this.$emit('onIsVisible', false)
            }
        },
        onFullscreen(val) {
            this[val] = !this[val]
        },
        async getRoleOptions() {
            const res = await this.$http({
                method: "GET",
                url: "rbac/role/",
            })
            console.log(res.data);
            this.roleOptions = res.data.role_data
        },
    },
    watch: {
        isSetting: {
            handler(val) {
                this.isVisible = val
                if (val) {
                    this.getRoleOptions()
                }
            },
            deep: true,
            immediate: true,
        },
        isAdd: {
            handler(val) {
                if (!val) {
                    this.addForm = {
                        default_value: null,
                        options: [
                            {
                                label: null,
                                value: null,
                            },
                        ],
                        type: '文本输入框',
                        model_class: null,
                        url: null,
                        model_class_field: null,
                        model_class_value: null,
                        is_numeric: false,
                        particulars: [
                            {
                                type: null,
                                url: null,
                                field: null,
                                acquire: null,
                                is_edit: true,
                                is_show: true,
                                label: null,
                                placeholder: null,
                            }
                        ],
                        permissions: {
                            edit: true,
                            show: true,
                            update: true,
                            require: false
                        },
                        visible_rule: {
                            role_limit: []
                        },
                        basic_settings: {
                            show_name: null,
                            field: null,
                            fields: null,
                            placeholder: null,
                        },
                        numeric_settings: {
                            max_digits: null,
                            decimal_places: null
                        },
                    }
                }
            }
        },
        isVisible: {
            handler(val) {
                if (val) {
                    this.getSysTableConfig()
                }
            }
        },
        "dialogForm.type": {
            handler(val) {
                if (val === '对象(单选)' || val === '对象(多选)') {
                    this.getRoleOptions()
                }
            }
        },
        "addForm.type": {
            handler(val) {
                if (val === '对象(单选)' || val === '对象(多选)') {
                    this.getRoleOptions()
                }
            }
        }
    },
    mounted() {

    },
    created() {

    },
}
</script>
<style lang="less" scoped>
/deep/.el-dialog {
    border-radius: 12px;
    overflow: hidden;
}

.dialog_slot_title {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
        padding-left: 10px;
    }
}

/deep/.el-dialog__header {
    background-color: rgb(89, 87, 87);
    color: #fff;
    padding: 0 !important;

    .icon-zuidahua {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            color: #898989;
        }
    }

    .icon-chuangkouzuidahua {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            background-color: #898989;
        }
    }

    .el-icon-close {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            background-color: #898989;
        }
    }
}

table {
    text-align: center;

    tr {
        td {
            line-height: 30px;
        }
    }
}

.control_table {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #dcdfe6;
    border-bottom: 0px;
    font-size: 14px;

    .control_table_header {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        border-bottom: 1px solid #dcdfe6;
        color: #97a1a3;
        background-color: #f4f6fa;

        .control_table_column1 {
            box-sizing: border-box;
            width: 50px;
            text-align: center;
            padding: 9px 0;
        }

        .control_table_column {
            box-sizing: border-box;
            flex: 1;
            text-align: center;
            border-left: 1px solid #dcdfe6;
            padding: 9px 0;
        }
    }

    .control_table_body {
        color: #5f5d5d;
        font-size: 14px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        border-bottom: 1px solid #dcdfe6;
        cursor: pointer;

        .control_table_column1 {
            box-sizing: border-box;
            width: 50px;
            text-align: center;
            padding: 9px 0;
        }

        .control_table_column {
            box-sizing: border-box;
            flex: 1;
            text-align: center;
            padding: 9px 0;
            border-left: 1px solid #dcdfe6;
        }
    }
}

.close_btn {
    position: relative;
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 3px 5px;
    display: inline-block;
    border-radius: 6px;
    transition: all .2s;
    border: none;
    font-family: inherit;
    font-weight: 500;
    color: black;
    background-color: white;

    &:hover {
        color: red;
    }
}

.close_btn:hover {
    transform: translateY(-1.5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.close_btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.close_btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
}

.close_btn::after {
    background-color: #fff;
}

.close_btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}

.add_particulars {
    font-size: 16px;
    padding: 3px 5px;
    border-radius: 6px;
    font-weight: 500;
    color: black;
    transition: all .4s;

    &:hover {
        border-radius: 5px;
        transform: translateY(-1.5px);
        box-shadow: rgba(0, 0, 0, 0.425) 0px 2px 4px;

        ::after {
            transform: scaleX(1.4) scaleY(1.6);
        }
    }
}
</style>