<template>
  <div id="app">
    <!-- <nav> -->
    <!-- <router-link to="/">H</router-link> | -->
    <!-- <router-link to="/about">A</router-link> -->
    <!-- </nav> -->
    <keep-alive include="skyEyeCheck">
      <router-view />
    </keep-alive>

  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  mounted() {
    if (document.documentElement.clientWidth < 1000) {
      this.$store.commit('changeIsPhone', true)
    } else {
      this.$store.commit('changeIsPhone', false)
    }
    console.log(this.$store.state.isphone);
  }
}
</script>
<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
  height: 100%;
  width: 100%;
  /* background-color: rgb(143,195, 31); */
}

/* 解决element-ui下拉框在ios上点击两次才能选中 */
.el-scrollbar__bar {
  opacity: 1 !important;
}
</style>
