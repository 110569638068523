<template>
  <div>
    <h1 v-if="isAssignmentRequest">分配申请</h1>
    <div class="assignment_request" v-if="list != null">
      <el-form ref="assignmentRequestFormRef" :disabled="is_Readonly" :model="assignmentRequestForm"
        :rules="assignmentRequestFormRules" size="mini" label-width="auto">
        <el-form-item label="客户" prop="customerValue">
          <!-- <el-input v-model="assignmentRequestForm.customer" placeholder="请输入内容"></el-input> -->
          <el-input size="mini" v-model="assignmentRequestForm.customerValue" placeholder="客户名称"
            @keyup.enter.native="isCustomerValue = true" @blur.stop="isCustomerValue = true" clearable>
            <el-button slot="prepend" icon="el-icon-search" @click="isCustomerValue = true"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="领取人" prop="salesman">
          <!-- <el-input v-model="assignmentRequestForm.salesman" placeholder="请输入内容"></el-input> -->
          <el-select v-model="assignmentRequestForm.salesman" placeholder="请选择" clearable>
            <el-option v-for="item in salesmanOption" :label="item.label" :value="item" :key="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户联系人" prop="contacts">
          <el-select v-model="assignmentRequestForm.contacts" placeholder="请选择" clearable>
            <el-option v-for="item in contactsOption" :label="item.label" :value="item" :key="item.value">
            </el-option>
            <div v-if="contactsOption.length == 0" slot="empty" style="padding: 10px;"><el-button type="primary"
                size="mini" @click="addContact">添加客户联系人</el-button></div>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="电话" prop="phone">
                    <el-input v-model="assignmentRequestForm.phone" placeholder="请输入内容" clearable></el-input>
                </el-form-item> -->
        <el-form-item label="职务" prop="office">
          <!-- <el-input v-model="assignmentRequestForm.office" placeholder="请输入内容"></el-input> -->
          <el-select v-model="assignmentRequestForm.office" placeholder="请选择" clearable>
            <el-option v-for="item in nameDutyOption" :label="item.label" :value="item" :key="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公海" prop="open_sea">
          <el-select v-model="assignmentRequestForm.open_sea" placeholder="请选择" clearable>
            <el-option v-for="item in openSeaOption" :label="item.label" :value="item" :key="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否新客户" prop="is_new_customer">
          <!-- <el-input v-model="assignmentRequestForm.office" placeholder="请输入内容"></el-input> -->
          <el-switch v-model="assignmentRequestForm.is_new_customer">
          </el-switch>
        </el-form-item>
        <el-form-item label="行业类型" prop="industry_infor">
          <div>
            <div>
              <div class="revise_type_title">行业一级:</div>
              <div>
                <template v-for="(item, key, i) in list.industry_type">
                  <el-radio @change="
                    changeRank1(assignmentRequestForm.industry_infor.rank1)
                    " :key="i" v-model="assignmentRequestForm.industry_infor.rank1" :label="key">{{ key }}</el-radio>
                </template>
                <el-radio v-model="assignmentRequestForm.industry_infor.rank1" label="">无</el-radio>
              </div>
            </div>
            <div v-if="assignmentRequestForm.industry_infor.rank1 != '' &&
              assignmentRequestForm.industry_infor.rank1 != '1688'
              ">
              <div class="revise_type_title">行业二级:</div>
              <el-checkbox-group v-if="assignmentRequestForm.industry_infor.rank1 != '渠道'" v-model="assignmentRequestForm.industry_infor.rank2[
                assignmentRequestForm.industry_infor.rank1
              ]
                ">
                <template v-for="(item, key, i) in list.industry_type[
                  assignmentRequestForm.industry_infor.rank1
                ]">
                  <el-checkbox :key="i" :label="key"></el-checkbox>
                </template>
              </el-checkbox-group>
              <div v-else>
                <template v-for="(item, key, i) in list.industry_type[
                  assignmentRequestForm.industry_infor.rank1
                ]">
                  <el-radio :key="i" v-model="assignmentRequestForm.industry_infor.rank2[
                    assignmentRequestForm.industry_infor.rank1
                  ]
                    " :label="key">{{ key }}</el-radio>
                </template>
              </div>
            </div>
            <div v-if="industry_infor_arr.length != 0 &&
              assignmentRequestForm.industry_infor.rank1 != ''
              ">
              <div class="revise_type_title">行业三级:</div>
              <div>
                <template v-for="(item, key, i) in industry_infor_arr">
                  <div :key="i">
                    <div class="revise_type_title">{{ item }}:</div>
                    <el-checkbox-group v-model="assignmentRequestForm.industry_infor.rank3[item]">
                      <template v-for="(items, keys) in list.industry_type[
                        assignmentRequestForm.industry_infor.rank1
                      ][item]">
                        <el-checkbox :key="keys" :label="items"></el-checkbox>
                      </template>
                    </el-checkbox-group>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </el-form-item>

        <!-- <el-form-item label="跟进度" prop="">
                    <el-select v-model="assignmentRequestForm.follow_progress" filterable clearable placeholder="请选择"
                        disabled size="mini">
                        <el-option v-for="item in followProgressArr" :key="item.label" :label="item.label"
                            :value="item.label">
                        </el-option>
                    </el-select>
                </el-form-item> -->
        <el-form-item label="对策度" prop="updateMeasures">
          <el-radio-group v-model="assignmentRequestForm.updateMeasures">
            <el-radio v-for="(item, index) in updateMeasuresArr" :label="item.label" :value="item.value" :key="index">{{
              item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="需求度" prop="updateImportance">
          <el-select v-model="assignmentRequestForm.updateImportance" filterable clearable placeholder="请选择" size="mini">
            <el-option v-for="item in list.importance" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关注度" prop="updateAttitude">
          <el-select v-model="assignmentRequestForm.updateAttitude" filterable clearable placeholder="请选择" size="mini">
            <el-option v-for="item in attitudeArr" :key="item.label" :label="item.label" :value="item.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品度" prop="updateDemandInfor">
          <el-cascader size="mini" placeholder="试试搜索" v-model="assignmentRequestForm.updateDemandInfor"
            :options="demandInforMethod" :props="{
              multiple: true,
              children: 'children',
              value: 'label',
              emitPath: false,
            }" :show-all-levels="false" filterable clearable>
          </el-cascader>
        </el-form-item>

        <el-form-item label="说明" prop="illustrate">
          <el-input v-model="assignmentRequestForm.illustrate" type="textarea" clearables placeholder="请输入内容"></el-input>
        </el-form-item>
      </el-form>
      <inquireDialog v-if="isCustomerValue" :isVisible="isCustomerValue" :obj="customerDialogObj" :title="'客户'"
        :isChoose="true" :searchValue="assignmentRequestForm.customerValue" @onIsVisible="customerVisible"
        @onApplied="customerApplied">
      </inquireDialog>
      <el-dialog :modal="false" :append-to-body="false" :close-on-click-modal="false" :fullscreen="isFullscreen"
        :show-close="false" :visible.sync="isAddAddress" v-dialogDrags width="60%" style="overflow: auto">
        <div v-if="dialogFromObj != null" slot="title"
          style="display: flex;justify-content: space-between; align-items: center;;">
          <div class="title">
            <span style="padding: 10px;">{{ dialogFromObj.label }}</span>
          </div>
          <div>
            <i v-if="isFullscreen" class="iconfont icon-zuidahua " style=""
              @click.stop="onFullscreen('isFullscreen')"></i>
            <i v-else class="iconfont icon-chuangkouzuidahua" @click.stop="onFullscreen('isFullscreen')"></i>
            <i class="el-icon-close" @click="isAddAddress = false"></i>
          </div>
        </div>

        <span v-if="dialogFromObj != null">
          <Preview @getlist="getFormList" :dialogForm="dialogForm" :formal="true" :id="dialogFromObj.value"
            :specialDialog="specialDialog">
          </Preview>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Preview from "@/components/OA/components/jsxPreview.vue";
import inquireDialog from "@/components/ProcessFlow/CRM/Forms/components/Dialog.vue";
import { log } from 'util';
import { tree } from '@/components/OA/components/FormControls/OrgTransfer/tree';
export default {
  props: ["isAssignmentRequest", "AddAssignmentRequestForm", 'formTitle', "readonly", "dialogCustomerForm"],
  data() {
    return {
      specialDialog: "客户",
      isAddAddress: false,
      isFullscreen: false,
      customer: null,
      dialogFromObj: null,
      contactsOption: [],
      is_Readonly: this.readonly == undefined ? false : this.readonly,
      // 公海
      openSeaOption: null,
      list: null,
      demandInforMethod: null,
      // 领取人
      salesmanOption: null,
      industry_infor_arr: [],
      //   customerValue: null,
      isCustomerValue: false,
      // 客户Dialog参数
      customerDialogObj: {
        searchField: "search",
        url: "crm/customer_bulk/open_sea_list/",
        params: {
          per_page: 10,
          page: 1,
          query_type: 1,
          open_sea_id: 6,
          // user_id: localStorage.getItem("user_id"),
          search: "",
        },
      },
      assignmentRequestForm: {
        is_new_customer: false,
        customerValue: null,
        customer: null,
        salesman: null,
        contacts: null,
        open_sea: null,
        office: {
          "value": 14,
          "label": "法人代表"
        },
        industry_infor: {
          rank1: "",
          rank2: {
            医疗: [],
            渠道: "",
            自动化: [],
          },
          rank3: {
            制氧: [],
            半导体: [],
            仪器仪表: [],
            纺织: [],
            新能源: [],
            机床: [],
            军工: [],
            气动: [],
            振动盘: [],
            线束: [],
            包装: [],
            汽车: [],
            白色家电: [],
            电子烟: [],
            SMT: [],
            锂电: [],
            安防: [],
            检测: [],
            非标: [],
            木工: [],
            "3C": [],
            其他: [],
          },
        }, //行业信息
        // follow_progress: "测试",
        updateImportance: null,
        updateAttitude: null,
        updateDemandInfor: null,
        updateMeasures: null,
        // 说明
        illustrate: null,
      },
      assignmentRequestFormRules: {
        is_new_customer: [
          { required: true, message: "请选择客户", trigger: "change" },
        ],
        customerValue: [
          { required: true, message: "请选择客户", trigger: "change" },
        ],
        salesman: [
          { required: true, message: "请选择领取人", trigger: "change" },
        ],
        contacts: [
          { required: true, message: "请选择联络人", trigger: "change" },
        ],
        open_sea: [
          { required: true, message: "请选择公海", trigger: "change" },
        ],
        office: [{ required: true, message: "请选择职务", trigger: "change" }],
        // updateImportance: [
        //   { required: true, message: "请选择重要度", trigger: "change" },
        // ],
        'industry_infor': [
          { required: true, message: "请选择行业类型", trigger: "change" },
        ],
        // updateAttitude: [
        //   { required: true, message: "请选择态度", trigger: "change" },
        // ],
        // updateDemandInfor: [
        //   { required: true, message: "请选择需求度", trigger: "change" },
        // ],
        updateMeasures: [
          { required: true, message: "请选择对策度", trigger: "change" },
        ],
        illustrate: [
          { required: true, message: "请选择说明", trigger: "blur" },
        ],
      },
      // 跟进度
      followProgressArr: [
        { value: 0, label: "新客户" },
        { value: 1, label: "送样客户" },
        { value: 2, label: "拜访客户" },
        { value: 3, label: "送样拜访客户" },
        { value: 4, label: "成交客户" },
        { value: 5, label: "活跃客户" },
        { value: 6, label: "呆滞客户" },
      ],
      // 态度
      attitudeArr: [
        { value: 0, label: "积极跟进" },
        { value: 1, label: "正常跟进" },
        { value: 2, label: "缓慢跟进" },
        { value: 3, label: "停止跟进" },
      ],
      // 对策度
      updateMeasuresArr: [
        { value: 0, label: "搜索联系人" },
        { value: 1, label: "搜索关键人" },
        { value: 2, label: "搜索客户资料" },
        { value: 3, label: "解决拜访" },
        { value: 4, label: "解决技术" },
        { value: 5, label: "解决送样" },
        { value: 6, label: "解决客需" },
        { value: 7, label: "解决产品" },
        { value: 8, label: "解决客诉" },
        { value: 9, label: "跟进试样" },
        { value: 10, label: "跟进认证" },
        { value: 11, label: "跟进设计" },
        { value: 12, label: "跟进需求" },
        { value: 13, label: "突破价格" },
        { value: 14, label: "突破返点" },
        { value: 15, label: "突破拜访" },
        { value: 16, label: "突破协议" },
        { value: 17, label: "维护订单" },
        { value: 18, label: "维护信息" },
        { value: 19, label: "维护售后" },
        { value: 20, label: "维护价格" },
        { value: 21, label: "维护技术" },
        { value: 22, label: "维护需求" },
      ],
    };
  },
  components: {
    inquireDialog, Preview,
  },
  watch: {
    dialogCustomerForm: {
      handler(val) {
        if (val != undefined) {
          console.log(val);
          this.assignmentRequestForm.customerValue = val.customer.company_name
          this.assignmentRequestForm.customer = val.customer
          this.getContacts(this.assignmentRequestForm.customer);
          this.$nextTick(() => {
            if (val.salesman != undefined) {
              this.assignmentRequestForm.salesman = val.salesman
            }
            if (val.contacts != undefined) {
              this.assignmentRequestForm.contacts = val.contacts
            }
          })

        }
      },
      deep: true,
      immediate: true
    },
    readonly: {
      handler(val) {
        if (val == undefined) {
          this.is_Readonly = false
        } else {
          this.is_Readonly = this.readonly
        }
      },

    },
    assignmentRequestForm: {
      handler(val) {
        // console.log(val);
        this.industry_infor_arr = [];
        if (this.assignmentRequestForm.customer != null) {
          this.assignmentRequestForm.customerValue =
            this.assignmentRequestForm.customer.company_name;
        }
        let arrRank2 = this.assignmentRequestForm.industry_infor.rank2["医疗"];
        for (let i = 0; i < arrRank2.length; i++) {
          if (
            this.assignmentRequestForm.industry_infor.rank3[arrRank2[i]] !=
            undefined
          ) {
            this.industry_infor_arr.push(arrRank2[i]);
          }
        }
        let arrRank3 =
          this.assignmentRequestForm.industry_infor.rank2["自动化"];
        for (let i = 0; i < arrRank3.length; i++) {
          if (
            this.assignmentRequestForm.industry_infor.rank3[arrRank3[i]] !=
            undefined
          ) {
            this.industry_infor_arr.push(arrRank3[i]);
          }
        }
        if (!this.isAssignmentRequest) {
          this.$emit("onAssignmentRequest", this.assignmentRequestForm);
        }
      },
      deep: true,
    },
    "assignmentRequestForm.customerValue": {
      handler(val) {
        if (val != null) {
          //   console.log(this.assignmentRequestForm.customer);
          this.getContacts(this.assignmentRequestForm.customer);
        }
      },
    },
    AddAssignmentRequestForm: {
      handler(val) {
        if (this.formTitle == "客户申请") {
          if (val !== undefined) {
            this.assignmentRequestForm.is_new_customer = val.is_new_customer
            this.assignmentRequestForm.customerValue = val.customerValue
            this.assignmentRequestForm.customer = val.customer
            this.assignmentRequestForm.salesman = val.salesman
            this.assignmentRequestForm.contacts = val.contacts
            this.assignmentRequestForm.open_sea = val.open_sea
            this.assignmentRequestForm.office = val.office
            this.assignmentRequestForm.updateImportance = val.updateImportance
            this.assignmentRequestForm.updateAttitude = val.updateAttitude
            this.assignmentRequestForm.updateDemandInfor = val.updateDemandInfor
            this.assignmentRequestForm.industry_infor = val.industry_infor
            this.assignmentRequestForm.updateMeasures = val.updateMeasures
            this.assignmentRequestForm.illustrate = val.illustrate
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() { },
  mounted() {
    // salesman
    this.getopenSea();
    this.getSalesman();
    this.getNameDuty();
    this.getDemandInforMethod();
    this.getList();
    // this.$nextTick(() => {
    //   if (this.AddAssignmentRequestForm != null) {

    //   }
    // });
  },
  methods: {
    addContact() {
      let obj = {
        label: "联系人",
        value: 92,
      };
      this.onPushOA(obj);
    },
    onPushOA(val) {
      this.dialogForm = {
        customer: {
          company_name: this.assignmentRequestForm.customerValue,
          id: this.assignmentRequestForm.customer.id,
        },
      };
      this.dialogFromObj = val;
      this.isAddAddress = true;
      // this.getformiconfig(val.value);
    },
    getFormList(val) {
      if (val.arr.length == 0) {
        console.log(val);
        if (val.data.status == 200) {
          this.$message.success("提交成功，审批流程已发起");
        } else {
          this.$message.error(val.data.data);
        }
      } else {
        if (val.arr[0].status == 200) {
          // this.deliverMethodChange();
          this.$message.success("提交成功，审批流程已发起");
        } else {
          this.$message.error(val.arr[0].data);
        }
      }
      this.isAddAddress = false;
      if (this.assignmentRequestForm.customer != null && this.assignmentRequestForm.customer != '') {
        this.getContacts(this.assignmentRequestForm.customer);
      }
      this.dialogFromObj = null;
    },
    changeRank1() {
      this.assignmentRequestForm.industry_infor.rank2 = {
        医疗: [],
        渠道: "",
        自动化: [],
      };
      this.industry_infor_arr = [];
    },
    customerVisible(val) {
      this.isCustomerValue = val;
    },
    customerApplied(val) {
      console.log(val);
      this.assignmentRequestForm.customer = val;
      this.assignmentRequestForm.customerValue = val.company_name;
    },
    async getList() {
      const res = await this.$http({
        method: "GET",
        url: "crm/customer/detail/1/?current_page=customer",

      });
      this.list = res.data;
    },
    async getDemandInforMethod() {
      const res = await this.$http({
        method: "GET",
        url: "crm/cascade_demand_infor_method/",
      });
      this.demandInforMethod = res.data;
    },
    async getSalesman() {
      const res = await this.$http({
        method: "GET",
        url: "crm/data_source/?source=salesman ",
      });
      // console.log(res.data);
      this.salesmanOption = res.data;
    },
    async getNameDuty() {
      const res = await this.$http({
        method: "GET",
        url: "crm/contact_name_duty/",
      });
      this.nameDutyOption = res.data.contact_name_duty_map;
    },
    async getContacts(val) {
      const res = await this.$http({
        method: "GET",
        url: `crm/data_source/?source=contact&customer=${val.id}`,
      });
      this.contactsOption = [...res.data];
      console.log(res.data);
      console.log(this.contactsOption);
    },
    async getopenSea() {
      const res = await this.$http({
        method: "GET",
        url: `crm/data_source/?source=open_sea`,
      });
      this.openSeaOption = res.data;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-dialog__header {
  background-color: rgb(89, 87, 87);
  color: #fff;
  padding: 0 !important;

  .icon-zuidahua {
    font-size: 16px;
    cursor: pointer;
    padding: 10px;

    &:hover {
      color: #898989;
    }
  }

  .icon-chuangkouzuidahua {
    font-size: 16px;
    cursor: pointer;
    padding: 10px;

    &:hover {
      background-color: #898989;
    }
  }

  .el-icon-close {
    font-size: 16px;
    cursor: pointer;
    padding: 10px;

    &:hover {
      background-color: #898989;
    }
  }
}

.assignment_request {
  .el-form {
    width: 100%;

    /deep/ .el-form-item__label-wrap {
      margin-left: 0 !important;
    }

    .el-select {
      width: 100%;
    }

    /deep/.el-radio__input.is-disabled.is-checked .el-radio__inner {
      border-color: rgb(143, 195, 31);
      background: rgb(143, 195, 31);
    }

    /deep/.el-radio__input.is-disabled+span.el-radio__label {
      color: #606266;
      cursor: not-allowed;
    }

    /deep/.el-radio__input.is-disabled .el-radio__inner::after {
      // cursor: not-allowed;
      background-color: #FFFFFF;
    }

    /deep/.el-radio__input.is-disabled.is-checked .el-radio__inner::after {
      background-color: #FFFFFF;
    }

    .el-radio {
      margin-top: 10px;
    }
  }
}
</style>