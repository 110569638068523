<template>
    <div>
        <h1 v-if="isAssignmentRequest">供应商分配申请</h1>
        <div class="assignment_request" v-if="list != null">
            <el-form ref="assignmentRequestFormRef" :disabled="is_Readonly" :model="assignmentRequestForm"
                :rules="assignmentRequestFormRules" size="mini" label-width="auto">
                <el-form-item label="客户" prop="customerValue">
                    <el-input size="mini" v-model="assignmentRequestForm.customerValue" placeholder="客户名称"
                        @keyup.enter.native="isCustomerValue = true" @blur.stop="isCustomerValue = true" clearable>
                        <el-button slot="prepend" icon="el-icon-search" @click="isCustomerValue = true"></el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="领取人" prop="salesman">
                    <el-select v-model="assignmentRequestForm.salesman" placeholder="请选择" clearable>
                        <el-option v-for="item in salesmanOption" :label="item.label" :value="item" :key="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="供应商联系人" prop="contacts">
                    <el-select v-model="assignmentRequestForm.contacts" placeholder="请选择" clearable
                        @change="getContactBulk(assignmentRequestForm.contacts.value)">
                        <el-option v-for="item in contactsOption" :label="item.label" :value="item" :key="item.value">
                        </el-option>
                        <div v-if="contactsOption.length == 0" slot="empty" style="padding: 10px;"><el-button type="primary"
                                size="mini" @click="addContact">添加客户联系人</el-button></div>
                    </el-select>
                </el-form-item>
                <el-form-item label="职务" prop="office">
                    <el-select v-model="assignmentRequestForm.office" placeholder="请选择" clearable>
                        <el-option v-for="item in nameDutyOption" :label="item.label" :value="item" :key="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="公海" prop="open_sea">
                    <el-select v-model="assignmentRequestForm.open_sea" placeholder="请选择" clearable>
                        <el-option v-for="item in openSeaOption" :label="item.label" :value="item" :key="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="需求度" prop="importance">
                    <el-select v-model="assignmentRequestForm.importance" filterable clearable placeholder="请选择"
                        size="mini">
                        <el-option v-for="item in list.importance" :key="item.value" :label="item.label"
                            :value="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="产品度" prop="demand_infor">
                    <el-cascader size="mini" placeholder="试试搜索" v-model="assignmentRequestForm.demand_infor"
                        :options="demandInforMethod" :props="{
                            multiple: true,
                            children: 'children',
                            value: 'label',
                            emitPath: false,
                        }" :show-all-levels="false" filterable clearable>
                    </el-cascader>
                </el-form-item>
                <el-form-item label="说明" prop="illustrate">
                    <el-input v-model="assignmentRequestForm.illustrate" type="textarea" clearables
                        placeholder="请输入内容"></el-input>
                </el-form-item>
            </el-form>
            <inquireDialog v-if="isCustomerValue" :isVisible="isCustomerValue" :obj="customerDialogObj" :title="'客户'"
                :isChoose="true" :searchValue="assignmentRequestForm.customerValue" @onIsVisible="customerVisible"
                @onApplied="customerApplied">
            </inquireDialog>
            <el-dialog :modal="false" :append-to-body="false" :close-on-click-modal="false" :fullscreen="isFullscreen"
                :show-close="false" :visible.sync="isAddAddress" v-dialogDrags width="60%" style="overflow: auto">
                <div v-if="dialogFromObj != null" slot="title"
                    style="display: flex;justify-content: space-between; align-items: center;;">
                    <div class="title">
                        <span style="padding: 10px;">{{ dialogFromObj.label }}</span>
                    </div>
                    <div>
                        <i v-if="isFullscreen" class="iconfont icon-zuidahua " style=""
                            @click.stop="onFullscreen('isFullscreen')"></i>
                        <i v-else class="iconfont icon-chuangkouzuidahua" @click.stop="onFullscreen('isFullscreen')"></i>
                        <i class="el-icon-close" @click="isAddAddress = false"></i>
                    </div>
                </div>
                <span v-if="dialogFromObj != null">
                    <Preview @getlist="getFormList" :dialogForm="dialogForm" :formal="true" :id="dialogFromObj.value"
                        :specialDialog="specialDialog">
                    </Preview>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import Preview from "@/components/OA/components/jsxPreview.vue";
import inquireDialog from "@/components/ProcessFlow/CRM/Forms/components/Dialog.vue";
export default {
    props: ["isAssignmentRequest", "AddAssignmentRequestForm", 'formTitle', "readonly", "dialogCustomerForm"],
    data() {
        return {
            specialDialog: "客户",
            isAddAddress: false,
            isFullscreen: false,
            customer: null,
            dialogFromObj: null,
            contactsOption: [],
            is_Readonly: this.readonly == undefined ? false : this.readonly,
            // 公海
            openSeaOption: null,
            list: null,
            demandInforMethod: null,
            // 领取人
            salesmanOption: null,
            isCustomerValue: false,
            // 客户Dialog参数
            customerDialogObj: {
                searchField: "search",
                url: "crm/customer_bulk/open_sea_list/",
                params: {
                    per_page: 10,
                    page: 1,
                    filter_supplier: 1,
                    query_type: 1,
                    open_sea_id: 6,
                    search: "",
                },
            },
            assignmentRequestForm: {
                customerValue: null,
                customer: null,
                salesman: null,
                contacts: null,
                open_sea: null,
                office: {
                    "value": 14,
                    "label": "法人代表"
                },
                importance: null,
                demand_infor: null,
                // 说明
                illustrate: null,
            },
            assignmentRequestFormRules: {
                customerValue: [
                    { required: true, message: "请选择客户", trigger: "change" },
                ],
                salesman: [
                    { required: true, message: "请选择领取人", trigger: "change" },
                ],
                contacts: [
                    { required: true, message: "请选择联络人", trigger: "change" },
                ],
                open_sea: [
                    { required: true, message: "请选择公海", trigger: "change" },
                ],
                office: [{ required: true, message: "请选择职务", trigger: "change" }],
                illustrate: [
                    { required: true, message: "请选择说明", trigger: "blur" },
                ],
            },
        };
    },
    components: {
        inquireDialog, Preview,
    },
    watch: {
        dialogCustomerForm: {
            handler(val) {
                if (val != undefined) {
                    console.log(val);
                    this.assignmentRequestForm.customerValue = val.customer.company_name
                    this.assignmentRequestForm.customer = val.customer
                    this.getContacts(this.assignmentRequestForm.customer);
                    this.$nextTick(() => {
                        if (val.salesman != undefined) {
                            this.assignmentRequestForm.salesman = val.salesman
                        }
                        if (val.contacts != undefined) {
                            this.assignmentRequestForm.contacts = val.contacts
                        }
                    })
                }
            },
            deep: true,
            immediate: true
        },
        readonly: {
            handler(val) {
                if (val == undefined) {
                    this.is_Readonly = false
                } else {
                    this.is_Readonly = this.readonly
                }
            },

        },
        assignmentRequestForm: {
            handler(val) {
                if (this.assignmentRequestForm.customer != null) {
                    this.assignmentRequestForm.customerValue =
                        this.assignmentRequestForm.customer.company_name;
                }

                if (!this.isAssignmentRequest) {
                    this.$emit("onAssignmentRequest", this.assignmentRequestForm);
                }
            },
            deep: true,
        },
        "assignmentRequestForm.customerValue": {
            handler(val) {
                if (val != null) {
                    this.getContacts(this.assignmentRequestForm.customer);
                }
            },
        },
        AddAssignmentRequestForm: {
            handler(val) {
                if (this.formTitle == "供应商分配申请") {
                    if (val !== undefined) {
                        this.assignmentRequestForm.customerValue = val.customerValue
                        this.assignmentRequestForm.customer = val.customer
                        this.assignmentRequestForm.salesman = val.salesman
                        this.assignmentRequestForm.contacts = val.contacts
                        this.assignmentRequestForm.open_sea = val.open_sea
                        this.assignmentRequestForm.office = val.office
                        this.assignmentRequestForm.importance = val.importance
                        this.assignmentRequestForm.demand_infor = val.demand_infor
                        this.assignmentRequestForm.illustrate = val.illustrate
                    }
                }
            },
            deep: true,
            immediate: true
        }
    },
    created() { },
    mounted() {
        this.getopenSea();
        this.getSalesman();
        this.getNameDuty();
        this.getDemandInforMethod();
        this.getList();
    },
    methods: {
        async getContactBulk(val) {
            const res = await this.$http({
                method: "GET",
                url: `crm/contact_bulk/${val}/`
            })
            // console.log(res.data.duty);
            let obj = null
            this.nameDutyOption.forEach(item => {
                if (item.label = res.data.duty) {
                    obj = item
                }
            });
            this.assignmentRequestForm.office = obj

        },
        addContact() {
            let obj = {
                label: "联系人",
                value: 92,
            };
            this.onPushOA(obj);
        },
        onPushOA(val) {
            this.dialogForm = {
                customer: {
                    company_name: this.assignmentRequestForm.customerValue,
                    id: this.assignmentRequestForm.customer,
                },
            };
            this.dialogFromObj = val;
            this.isAddAddress = true;
        },
        getFormList(val) {
            if (val.arr.length == 0) {
                console.log(val);
                if (val.data.status == 200) {
                    this.$message.success("提交成功，审批流程已发起");
                } else {
                    this.$message.error(val.data.data);
                }
            } else {
                if (val.arr[0].status == 200) {
                    this.$message.success("提交成功，审批流程已发起");
                } else {
                    this.$message.error(val.arr[0].data);
                }
            }
            this.isAddAddress = false;
            if (this.assignmentRequestForm.customer != null && this.assignmentRequestForm.customer != '') {
                this.getContacts(this.assignmentRequestForm.customer);
            }
            this.dialogFromObj = null;
        },
        customerVisible(val) {
            this.isCustomerValue = val;
        },
        customerApplied(val) {
            console.log(val);
            this.assignmentRequestForm.customer = val;
            this.assignmentRequestForm.customerValue = val.company_name;
        },
        async getList() {
            const res = await this.$http({
                method: "GET",
                url: "crm/customer/detail/1/",
            });
            this.list = res.data;
        },
        async getDemandInforMethod() {
            const res = await this.$http({
                method: "GET",
                url: "crm/cascade_demand_infor_method/",
            });
            this.demandInforMethod = res.data;
        },
        async getSalesman() {
            const res = await this.$http({
                method: "GET",
                url: "crm/data_source/?source=purchaser ",
            });
            this.salesmanOption = res.data;
        },
        async getNameDuty() {
            const res = await this.$http({
                method: "GET",
                url: "crm/contact_name_duty/",
            });
            this.nameDutyOption = res.data.contact_name_duty_map;
        },
        async getContacts(val) {
            const res = await this.$http({
                method: "GET",
                url: `crm/data_source/?source=contact&customer=${val.id}`,
            });
            this.contactsOption = [...res.data];
            console.log(res.data);
            console.log(this.contactsOption);
        },
        async getopenSea() {
            const res = await this.$http({
                method: "GET",
                url: `crm/data_source/?source=open_sea`,
            });
            this.openSeaOption = res.data;
        },
    },
};
</script>
<style lang="less" scoped>
/deep/.el-dialog__header {
    background-color: rgb(89, 87, 87);
    color: #fff;
    padding: 0 !important;

    .icon-zuidahua {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            color: #898989;
        }
    }

    .icon-chuangkouzuidahua {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            background-color: #898989;
        }
    }

    .el-icon-close {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            background-color: #898989;
        }
    }
}

.assignment_request {
    .el-form {
        width: 100%;

        /deep/ .el-form-item__label-wrap {
            margin-left: 0 !important;
        }

        .el-select {
            width: 100%;
        }

        /deep/.el-radio__input.is-disabled.is-checked .el-radio__inner {
            border-color: rgb(143, 195, 31);
            background: rgb(143, 195, 31);
        }

        /deep/.el-radio__input.is-disabled+span.el-radio__label {
            color: #606266;
            cursor: not-allowed;
        }

        /deep/.el-radio__input.is-disabled .el-radio__inner::after {
            background-color: #FFFFFF;
        }

        /deep/.el-radio__input.is-disabled.is-checked .el-radio__inner::after {
            background-color: #FFFFFF;
        }

        .el-radio {
            margin-top: 10px;
        }
    }
}
</style>