<template>
    <!-- 手写签名组件 -->
    <!-- <div class="page sign-page" >
        <div style="width: 90%; margin: 0 auto; margin-top: 10px; display: flex;justify-content: space-between;">
            <el-button type="danger" size="mini" @click="clearArea()">清除签名</el-button>
            <el-button type="danger" size="mini" @click="Cancel()">取消签名</el-button>
            <el-button type="primary" size="mini" @click="saveSign()">确认签名</el-button>
        </div>
        <div class="content_sign">
            <div class="sign-wrap" id="signWrap">
                <canvas class="sign-center" id="signatureId" width="100%" height="100%"></canvas>
            </div>
        </div>
    </div> -->
    <el-drawer append-to-body v-if="isSignature" :visible="isSignature" size="100%" :before-close="handleClose"
        class="signdrawer">
        <div class="page sign-page">
            <div style="width: 90%; margin: 0 auto;  display: flex;justify-content: start;">
                <el-button type="danger" size="mini" @click="clearArea()">清除签名</el-button>
                <!-- <el-button type="danger" size="mini" @click="Cancel()">取消签名</el-button> -->
                <el-button type="primary" size="mini" @click="saveSign()">确认签名</el-button>
            </div>
            <div v-if="this.isOrientation" :class="[this.$store.state.isphone ? 'content_sign2' : 'content_sign']">
                <div class="sign-wrap" id="signWrap">
                    <canvas class="sign-center" id="signatureId" width="100%"></canvas>
                </div>
            </div>
            <div v-else :class="[this.$store.state.isphone ? 'content_sign1' : 'content_sign']">
                <div class="sign-wrap" id="signWrap">
                    <canvas class="sign-center" id="signatureId" width="100%"></canvas>
                </div>
            </div>
        </div>
    </el-drawer>
</template>
<script>
export default {
    name: "signature",
    props: ['isSignature'],
    data() {
        return {
            isSignWrap: true,
            isContent: false,
            isOrientation: false,
            image: "",
            mousePressed: false,
            signatureValue: "",
            canvas2D: "",
            lastX: 0,
            lastY: 0,
        };
    },
    watch: {
        isSignature: {
            handler(val) {
                console.log(val);
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.$nextTick(() => {
            // alert((window.orientation === 180 || window.orientation === 0) + '' + (window.orientation === 90 || window.orientation === -90));
            if (window.orientation === 180 || window.orientation === 0) {
                that.isOrientation = false
            }
            if (window.orientation === 90 || window.orientation === -90) {
                that.isOrientation = true
            }
            this.getList()
        })
        let that = this
        window.addEventListener("onorientationchange" in window ? "orientationchange" : "resize", function () {
            if (window.orientation === 180 || window.orientation === 0) {
                that.isOrientation = false
                setTimeout(() => {
                    that.getList()
                }, 500);
            }
            if (window.orientation === 90 || window.orientation === -90) {
                that.isOrientation = true
                setTimeout(() => {
                    that.getList()
                }, 500);
            }
        }, false);

    },
    methods: {
        handleClose(done) {
            // console.log("1111");
            this.$emit("onSignCancel", false)
        },
        getList() {
            // alert('1')
            this.image = "";
            this.mousePressed = false;
            var lastX, lastY;
            this.canvas2D = document.getElementById("signatureId").getContext("2d");
            this.signatureValue = document.getElementById("signatureId");
            var signWrap = document.getElementById("signWrap");
            this.signatureValue.width = signWrap.clientWidth; // 设置宽度
            this.signatureValue.height = signWrap.clientHeight; // 设置高度
            // 监听touchstart事件，touchmove事件，touchend事件等事件
            this.InitThis();
        },
        onSignIcon() {

            this.isContent = !this.isContent
            this.$nextTick(() => {
                this.getList()
            })
        },
        Cancel() {
            this.$emit("onSignCancel", false)
        },
        base64ImgtoFile(dataurl, filename = 'file') {
            let arr = dataurl.split(',')
            let mime = arr[0].match(/:(.*?);/)[1]
            let suffix = mime.split('/')[1]
            let bstr = atob(arr[1])
            let n = bstr.length
            let u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            return new File([u8arr], `${filename}.${suffix}`, {
                type: mime
            })
        },
        InitThis() {
            // 触摸屏
            var that = this;
            this.signatureValue.addEventListener(
                "touchstart",
                function (event) {
                    if (event.targetTouches.length == 1) {
                        event.preventDefault(); // 阻止浏览器默认事件，重要
                        var touch = event.targetTouches[0];
                        this.mousePressed = true;
                        that.Draw(
                            touch.pageX - this.offsetLeft,
                            touch.pageY - this.offsetTop,
                            false
                        );
                    }
                },
                false
            );
            this.signatureValue.addEventListener(
                "touchmove",
                function (event) {
                    if (event.targetTouches.length == 1) {
                        event.preventDefault(); // 阻止浏览器默认事件，重要
                        var touch = event.targetTouches[0];
                        if (this.mousePressed) {
                            that.Draw(
                                touch.pageX - this.offsetLeft,
                                touch.pageY - this.offsetTop,
                                true
                            );
                        }
                    }
                },
                false
            );
            this.signatureValue.addEventListener(
                "touchend",
                function (event) {
                    if (event.targetTouches.length == 1) {
                        event.preventDefault(); // 阻止浏览器默认事件，防止手写的时候拖动屏幕，重要
                        this.mousePressed = false;
                    }
                },
                false
            );
            // 鼠标
            this.signatureValue.onmousedown = function (event) {
                console.log(event.pageX, this.offsetLeft,
                    event.pageY, this.offsetTop,);
                this.mousePressed = true;
                that.Draw(
                    event.pageX - this.offsetLeft,
                    event.pageY - this.offsetTop,
                    false
                );
            };
            this.signatureValue.onmousemove = function (event) {
                if (this.mousePressed) {
                    that.Draw(
                        event.pageX - this.offsetLeft,
                        event.pageY - this.offsetTop,
                        true
                    );
                }
            };
            this.signatureValue.onmouseup = function (event) {
                this.mousePressed = false;
            };
        },
        Draw(x, y, isDown) {
            if (isDown) {
                this.canvas2D.beginPath();
                this.canvas2D.strokeStyle = "#000"; // 颜色
                this.canvas2D.lineWidth = 6; // 线宽
                this.canvas2D.lineJoin = "round";
                this.canvas2D.lineMax = 15; // 设置画笔最大线宽
                this.canvas2D.lineMin = 6; // 设置画笔最小线宽
                this.canvas2D.linePressure = 1.2; // 设置画笔笔触压力
                this.canvas2D.smoothness = 30; // 设置画笔笔触大小变化的平滑度
                this.canvas2D.moveTo(this.lastX, this.lastY);
                this.canvas2D.lineTo(x, y);
                this.canvas2D.closePath();
                this.canvas2D.stroke();
            }
            this.lastX = x;
            this.lastY = y;
        },
        // 清空画板
        clearArea() {
            this.canvas2D.setTransform(1, 0, 0, 1, 0, 0);
            this.canvas2D.clearRect(0, 0, this.canvas2D.canvas.width, this.canvas2D.canvas.height);
        },
        // 提交签名
        saveSign() {
            this.checkEmpty(); // 调用 表单非空验证
        },
        checkEmpty() {
            var c = document.getElementById("signatureId"); // 获取html的canvas对象，我这个id="Signature"
            if (this.isCanvasBlank(c)) {
                alert("请在签名区域签名后再次确认");
                return;
            } else {
                var image = this.signatureValue.toDataURL("image/png"); // 得到生成后的签名base64位  url 地址
                console.log(image); // 打印图片base64 url
                this.image = image
                let file = this.base64ImgtoFile(this.image)
                console.log(file);
                let obj = {
                    url: image,
                    file: file
                }
                this.$emit("onSignature", obj)
            }
        },

        // 验证canvas画布是否为空函数
        isCanvasBlank(canvas) {
            var blank = document.createElement("canvas"); // 系统获取一个空canvas对象
            blank.width = canvas.width;
            blank.height = canvas.height;
            return canvas.toDataURL() == blank.toDataURL(); // 比较值相等则为空
        },
        // downloadBlob(blob, filename = "file") {
        //     const url = URL.createObjectURL(blob);
        //     console.log(url);
        // },
    },
};
</script>
<style lang="less" scoped>
.signdrawer {
    /deep/.el-drawer__header {
        margin-bottom: 0;
    }
}

/deep/.el-dialog__close {
    font-size: 20px !important;
}

.page {

    width: 100%;
}

/deep/.el-drawer__header {
    margin-bottom: 20px;
}

.content {
    width: 600px;
    height: 270px;
    margin: 0 auto;
    background-color: #fff;
    // background: url(../assets/img/photo_qmq.png) no-repeat;
    // background-size: 100% 100%;
    // background-position: center center;

    .sign-wrap {
        width: 100%;
        height: 100%;
    }
}

.sign-center {
    // background: url("../../../../../assets/signature.jpg") no-repeat;
    // background-size: cover;
}

.content_sign {
    margin: 10px auto;
    width: 90%;
    height: 70vh;
    // border-radius: 12px;
    // background-color: #eeee;
    // background: url(../assets/img/photo_qmq.png) no-repeat;
    // background-size: 100% 100%;
    // background-position: center center;

    .sign-wrap {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background-color: #eeee;
    }
}


/*竖屏 css*/
.content_sign1 {
    margin: 10px auto;
    width: 90vw;
    height: 30vh;
    // border-radius: 12px;
    // background-color: blue;
    // background: url(../assets/img/photo_qmq.png) no-repeat;
    // background-size: 100% 100%;
    // background-position: center center;

    .sign-wrap {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background-color: #eeee;
    }
}

/*横屏 css*/
.content_sign2 {
    margin: 10px auto;
    width: 90vw;
    height: 70vh;
    // border-radius: 12px;
    // background-color: red;
    // background: url(../assets/img/photo_qmq.png) no-repeat;
    // background-size: 100% 100%;
    // background-position: center center;

    .sign-wrap {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background-color: #eeee;
    }
}


// .content_sign1 {
//     margin: 10px auto;
//     width: 90%;
//     height: 30vh;
//     // border-radius: 12px;
//     // background-color: #eeee;
//     // background: url(../assets/img/photo_qmq.png) no-repeat;
//     // background-size: 100% 100%;
//     // background-position: center center;

//     .sign-wrap {
//         width: 100%;
//         height: 100%;
//         border-radius: 12px;
//         background-color: #eeee;
//     }
// }



.con-btn {

    width: 100%;
    // display: flex;
    // align-content: center;
    // justify-content: space-between;
    opacity: 0.75;


    span {
        font-size: 0.14rem;
        width: 100%;
        height: 0.48rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // .staging-btn {
    //     color: #4154ff;
    //     background: #fff;
    // }

    // .submit-btn {
    //     color: #fff;
    //     background: #4154ff;
    // }
}
</style>
