<template>
    <div>
        <div v-if="$store.state.isphone">
            <el-dialog class="searchItemCode" :modal="false" v-if="isVisible" top="60px" v-dialogDrags
                :close-on-click-modal="false" @close="close" :show-close="false" :visible.sync="isVisible"
                :fullscreen="isFullscreen" width="100%">
                <div slot="title" style="display: flex;justify-content: space-between;align-items: center;">
                    <div class="title">
                        <span style="padding: 10px;">查询{{ title }}</span>
                    </div>
                    <div>
                        <i v-if="isFullscreen" class="iconfont icon-zuidahua "
                            style="font-size: 16px; cursor: pointer;margin-right: 10px;" @click.stop="onFullscreen"></i>
                        <i v-else class="iconfont icon-chuangkouzuidahua"
                            style="font-size: 16px; cursor: pointer;margin-right: 10px;" @click.stop="onFullscreen"></i>
                        <i class="el-icon-close" style="font-size: 16px; cursor: pointer;" @click.stop="onCancel"></i>
                    </div>
                </div>
                <div class="search_company_name">
                    <el-input clearable v-model="searchValue" size="mini" @keyup.enter.native="onPhoneSearch"
                        :placeholder="searchPlaceholder + title"></el-input>
                    <el-button type="primary" @click.stop="onPhoneSearch" size="mini">查询</el-button>
                </div>
                <div class="selection_box" v-if="!isChoose">
                    <div class="selection_icon" @click.stop="isSelectionValue = !isSelectionValue">
                        <el-button size="mini">查看已选{{ title }}</el-button>
                    </div>
                    <div class="selection_value" v-if="isSelectionValue && selectionValue != null">
                        <h1 class="selection_title">{{ title }} {{ selectionValue.length }}</h1>
                        <div v-for="(item, i) in selectionValue" :key="i">
                            <div class="selection_value_item">
                                <div class="selection_value_item_name">
                                    <p>名称：{{ item.item_name }}</p>
                                    <p>编码：{{ item.item_code }}</p>
                                </div>
                                <div class="selection_value_item_close" @click.stop="DeletesSelection(i)">
                                    <i class="el-icon-delete"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="flag" class="phone_bialog_scroll" v-infinite-scroll="onScroll" infinite-scroll-distance="20"
                    v-loading="isDialogTableLoading">
                    <div v-for="(item, i) in phoneList" :key="i"
                        :class="['phone_bialog_scroll_item', phoneArrId.includes(item.id) ? 'phone_bialog_scroll_item_active' : '']"
                        @click.stop="onPhoneSelect(item)">
                        <div :class="[j == 0 ? 'phone_bialog_scroll_items0' : 'phone_bialog_scroll_items']"
                            v-for="(items, j) in tableList.table_header_data">
                            <span>
                                {{ items.currentUserEditTitle == '' ? items.title.label : items.currentUserEditTitle }}:
                            </span>
                            {{ formatt(item[items.field], items) }}
                        </div>
                        <i v-if="phoneArrId.includes(item.id)" class="el-icon-check phone_bialog_scroll_item_check"></i>
                    </div>
                </div>
                <div v-else class="undialogTable">
                    <div class="undialogTable_icon">
                        <i style="" class="el-icon-search"></i>
                    </div>
                    <div class="undialogTable_text">请在查询后使用。</div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click.stop="onCancel" size="mini">取 消</el-button>
                    <el-button type="primary" @click.stop="onApplied" size="mini">应用</el-button>
                </span>
            </el-dialog>
        </div>
        <div v-else>
            <el-dialog class="searchItemCode" v-if="isVisible" :modal="false" top="60px" v-dialogDrags
                :close-on-click-modal="false" :show-close="false" :visible.sync="isVisible" width="1000px"
                :fullscreen="isFullscreen">
                <div slot="title" style="display: flex;justify-content: space-between;align-items: center;">
                    <div class="title">
                        <span style="padding: 10px;">查询{{ title }}</span>
                    </div>
                    <div>
                        <i v-if="isFullscreen" class="iconfont icon-zuidahua "
                            style="font-size: 16px; cursor: pointer;margin-right: 10px;" @click.stop="onFullscreen"></i>
                        <i v-else class="iconfont icon-chuangkouzuidahua"
                            style="font-size: 16px; cursor: pointer;margin-right: 10px;" @click.stop="onFullscreen"></i>
                        <i class="el-icon-close" style="font-size: 16px; cursor: pointer;" @click.stop="onCancel"></i>
                    </div>
                </div>
                <div class="search_company_name">
                    <el-input clearable v-model="searchValue" size="mini" @keyup.enter.native="onSearch"
                        :placeholder="searchPlaceholder + title"></el-input>
                    <el-button size="mini" type="primary" @click.stop="onSearch">查询</el-button>
                </div>
                <div class="selection_box" v-if="!isChoose">
                    <div class="selection_icon" @click.stop="isSelectionValue = !isSelectionValue">
                        <el-button size="mini">查看已选{{ title }}</el-button>
                    </div>
                    <div class="selection_value" v-if="isSelectionValue && selectionValue != null">
                        <h1 class="selection_title">{{ title }} {{ selectionValue.length }}</h1>
                        <div v-for="(item, i) in selectionValue" :key="i">
                            <div class="selection_value_item">
                                <div class="selection_value_item_name">
                                    <p>名称：{{ item.item_name }}</p>
                                    <p>编码：{{ item.item_code }}</p>
                                </div>
                                <div class="selection_value_item_close" @click.stop="DeletesSelection(i)">
                                    <i class="el-icon-delete"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="flag">
                    <el-table ref="dialogTable" border size="mini" :data="tableList.table_body_data"
                        v-loading="isDialogTableLoading" @select="onSelectionChange" @select-all="getSelectAll"
                        :row-key="getRowKey" @row-click="rowClick" style="border-color: rgb(220, 223, 230); width: 100%;"
                        :cell-style="{ color: 'rgba(95, 93, 93, 1)', borderColor: 'rgb(220, 223, 230)', backgroundColor: 'rgba(255,255,255,0)', textAlign: 'center' }"
                        :header-cell-style="{ color: 'rgba(151, 161, 163, 1)', height: '15px', borderColor: 'rgb(220, 223, 230)', backgroundColor: 'rgba(244,246,250,1)', textAlign: 'center' }">
                        <el-table-column v-if="isChoose" width="50px" fixed="left" label="选择" align="center">
                            <template slot-scope="scope">
                                <el-radio :label="scope.row" v-model="radioValue">&nbsp;</el-radio>
                            </template>
                        </el-table-column>
                        <el-table-column v-else type="selection" :reserve-selection="true"></el-table-column>
                        <template v-for="(item, i) in tableList.table_header_data">
                            <el-table-column show-overflow-tooltip v-if="item.title.label != '关注'"
                                :label="item.currentUserEditTitle == '' ? item.title.label : item.currentUserEditTitle"
                                :width="item.width" :min-width="item.minWidth == '' ? '150px' : item.minWidth"
                                :fixed="item.fixDirection == '' ? false : item.fixDirection" :key="i">
                                <template slot-scope="scope">

                                    <span v-if="item.is_onclick" class="cellClickButton"
                                        @click.stop="cellClick(scope.row, item)">
                                        {{ item.title.label == '关联单据编号' ? '...' : formatt(scope.row[item.field],
                                            item) }}
                                    </span>
                                    <span v-else>
                                        {{ item.title.label == '关联单据编号' ? '...' : formatt(scope.row[item.field],
                                            item) }}
                                    </span>
                                </template>
                            </el-table-column>
                        </template>
                    </el-table>
                </div>
                <div v-else class="undialogTable">
                    <div class="undialogTable_icon">
                        <i style="" class="el-icon-search"></i>
                    </div>
                    <div class="undialogTable_text">请在查询后使用。</div>
                </div>

                <div class="block">
                    <el-pagination @size-change="sizeChange" @current-change="currentChange" :page-sizes="[10, 15, 20, 25]"
                        :page-size="itemPerpage" layout="total, sizes, prev, pager, next, jumper" :total="toTal">
                    </el-pagination>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click.stop="onCancel" size="mini">取 消</el-button>
                    <el-button type="primary" @click.stop="onApplied" size="mini">应用</el-button>
                </span>
            </el-dialog>
            <el-drawer title="详情" class="drawer_jsxPreview" v-if="drawer" :wrapperClosable="false" :append-to-body="true"
                :visible.sync="drawer" :direction="direction" :size="size + '%'">
                <Conent v-if="drawer" :drawer="this.drawer" :isInForm="true" :form_id="this.formId" :flow_id="this.flowId"
                    :approve_status="this.approveStatus" :size="this.size" @onInformConent="onInformConent"
                    @onToPrint="onToPrint">
                </Conent>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import { debounce } from "@/utils/AntiShake"
import tablesetting from "@/components/Tools/SubassemblyTools/TableSetting.vue"
import Conent from "@/components/OA/components/conent.vue";
import { log } from 'util';
export default {
    bind(el, binding) {
        // 获取节点
        let ariaEls = el.querySelectorAll(".el-radio__original");
        ariaEls.forEach((item) => {
            item.removeAttribute("aria-hidden");
        });
    },
    props: ["isVisible", "obj", "isChoose", "searchValue", "title"],
    data() {
        return {
            drawer: false,
            formId: null,
            flowId: null,
            approveStatus: null,
            isFullscreen: false,
            isDialogTableLoading: false,
            // 开关
            isSelectionValue: false,
            searchPlaceholder: "请输入",
            // 单选
            radioValue: null,
            //多选数据
            selectionValue: null,
            // selectionArr: null,
            //
            itemPerpage: null,
            // itemPerpage: null,
            //Deletes数据
            tableList: null,
            phoneList: [],
            phoneArrId: [],
            //总条数
            toTal: null,
            // 
            flag: false,
        }
    },
    computed: {
        size() {
            if (this.$store.state.isphone) {
                return 100;
            } else {
                return 50;
            }
        },
    },
    watch: {
        phoneArrId: {
            handler(val) {
                let arr = []
                if (val.length > 0) {
                    this.phoneList.forEach(items => {
                        val.forEach(item => {
                            if (items.id == item) {
                                arr.push(items)
                            }
                        })
                    })
                }
                if (this.isChoose) {
                    this.radioValue = arr[0]
                } else {
                    this.selectionValue = arr
                }
                console.log(arr);
            },
            deep: true
        }
    },
    components: {
        tablesetting, Conent
    },
    created() {
    },
    mounted() {
        if (this.obj.data) {
            this.phoneList = []
            this.getSpecialPrice(this.obj);
            return
        }
        if (this.obj.searchField === 'search') {
            this.obj.params.search = this.searchValue
        } else {
            this.obj.params.keyword = this.searchValue
        }
        if (this.searchValue !== "" && this.searchValue !== null) {
            if (this.$store.state.isphone) {
                this.onPhoneSearch()
            } else {
                this.getLists(this.obj)
            }
        } else {
            this.getLists(this.obj)
        }
    },
    methods: {
        cellClick(res, item) {
            if (['订单编号', '送样单编号'].includes(item.title.label)) {
                this.formId = res.oa_form.id
                this.flowId = res.oa_form.flow
                this.drawer = true
            }
            console.log(res.oa_form.id, item);
            console.log(res.oa_form.flow, item.title.label);
        },
        onPhoneSelect(val) {
            if (this.isChoose) {
                if (this.phoneArrId.length !== 0) {
                    this.phoneArrId.splice(0, 1, val.id)
                } else {
                    this.phoneArrId.push(val.id)
                }
            } else {
                if (this.phoneArrId.includes(val.id)) {
                    let num = this.phoneArrId.indexOf(val.id)
                    this.phoneArrId.splice(num, 1)
                } else {
                    this.phoneArrId.push(val.id)
                }
            }

        },
        // 移动端搜索
        onPhoneSearch() {
            this.phoneList = []
            if (this.obj.data) {
                return
            } else {
                if (this.obj.searchField === 'search') {
                    this.obj.params.search = this.searchValue
                } else {
                    this.obj.params.keyword = this.searchValue
                }
                this.obj.params.page = 1
                this.getPhoneLists(this.obj)
            }
        },
        // 移动端数据
        async getPhoneLists(val) {
            this.isDialogTableLoading = true
            let obj = val
            if (this.phoneList.length > 0 && this.phoneList.length >= this.toTal) {
                this.obj.params.page -= 1
                this.$message.success("已全部加载")
                this.isDialogTableLoading = false
                return

            }
            const res = await this.$http({
                method: "GET",
                url: obj.url,
                params: obj.params
            })
            if (res.status == 200) {
                this.tableList = res.data.results
                this.toTal = res.data.count
                res.data.results.table_body_data.forEach(item => {
                    this.phoneList.push(item)
                })
                if (res.data.results.table_body_data.length > 0) {
                    this.flag = true
                } else {
                    this.flag = false
                }
            } else {
                this.$message.error(res.data)
            }
            this.isDialogTableLoading = false
        },
        //滚动加载
        onScroll: debounce(function () {
            if (this.obj.data) {
                return
            } else {
                this.obj.params.page += 1
                this.getPhoneLists(this.obj)
            }

        }, 500),
        // 获取特价
        async getSpecialPrice(obj) {
            console.log(obj);
            this.isDialogTableLoading = true
            let res = await this.$http({
                method: 'POST',
                url: obj.url,
                data: obj.data
            })
            console.log(res);
            if (res.status == 200) {
                this.tableList = res.data
                // this.flag = true
                res.data.table_body_data.forEach(item => {
                    this.phoneList.push(item)
                })
                if (res.data.table_body_data.length > 0) {
                    this.flag = true
                } else {
                    this.flag = false
                }
            } else {
                this.flag = false
                this.$message.error(res.data)
            }
            this.isDialogTableLoading = false
        },
        //获取数据
        async getLists(data) {
            this.isDialogTableLoading = true
            let obj = data
            console.log(obj);
            const res = await this.$http({
                method: "GET",
                url: obj.url,
                params: obj.params
            })
            if (res.status == 200) {
                console.log(res);
                this.tableList = res.data.results
                this.toTal = res.data.count
                if (res.data.results.table_body_data.length > 0) {
                    this.flag = true
                } else {
                    this.flag = false
                }
            } else {
                this.flag = true
                this.$message.error(res.data)
            }
            this.isDialogTableLoading = false

        },
        //搜索
        onSearch() {
            // console.log(this.obj);
            if (this.obj.searchField === 'search') {
                this.obj.params.search = this.searchValue
            }
            else {
                this.obj.params.keyword = this.searchValue
            }
            this.obj.params.page = 1
            this.getLists(this.obj)
        },
        // 点击行
        onRowClick() {
            console.log("搜索");
        },
        // 改变条数
        sizeChange(data) {
            // console.log(data);
            // console.log("改变条数");
            this.obj.params.per_page = data
            this.getLists(this.obj)
        },
        // 改变页
        currentChange(data) {
            this.obj.params.page = data
            this.getLists(this.obj)
        },
        // 应用
        onApplied() {
            if (this.isChoose) {
                console.log(this.radioValue);
                this.$emit('onApplied', this.radioValue)
            } else {
                console.log(this.selectionValue);
                this.$emit('onApplied', this.selectionValue)
            }
            this.$emit("onIsVisible", false)
        },
        //关闭对话框
        onCancel() {
            // this.isVisible = false
            this.$emit("onIsVisible", false)
        },
        // 关闭对话框
        close() {
            this.$emit("onIsVisible", false)
        },
        Thousands(item, obj) {
            if (item == 0) {
                if (obj.zero_show_symbol == null || obj.zero_show_symbol == "") {
                    return 0;
                } else {
                    return obj.zero_show_symbol;
                }
            } else {
                if (obj.is_thousand_separator) {
                    // return '前期'
                    if (obj.decimal_places == 0) {
                        return (item * 1).toLocaleString();
                    } else {
                        return (item * 1)
                            .toFixed(obj.decimal_places)
                            .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
                    }
                } else {
                    if (obj.decimal_places == 0) {
                        return item;
                    } else {
                        return (item * 1).toFixed(obj.decimal_places);
                    }
                }
            }
        },
        // 判断字段数据类型
        formatt(item, obj) {
            // console.log(obj);

            if (typeof item == "boolean") {
                if (obj.title.label == "关注") {
                    return;
                } else {
                    if (item == false) {
                        return "否";
                    } else {
                        return "是";
                    }
                }
            } else if (Object.prototype.toString.call(item) == "[object Null]") {
                return obj.null_show_symbol;
            } else if (Object.prototype.toString.call(item) == "[object Object]") {
                let str = "";
                for (const key in item) {
                    str = str + item[key] + ",";
                }
                return str;
            } else if (item === "") {
                if (obj.null_show_symbol === null || obj.null_show_symbol === "") {
                    return "-";
                } else {
                    return obj.null_show_symbol;
                }
            } else {
                if (!isNaN(item)) {
                    if (
                        obj.title.label == "特殊账期" ||
                        obj.title.label == "账期" ||
                        obj.title.label == "收货人电话"
                    ) {
                        return item;
                    } else {
                        return this.Thousands(item, obj);
                    }
                } else {
                    return item;
                }
            }
        },
        Thousands(item, obj) {
            if (item == 0) {
                if (obj.zero_show_symbol == null || obj.zero_show_symbol == "") {
                    return 0;
                } else {
                    return obj.zero_show_symbol;
                }
            } else {
                if (obj.is_thousand_separator) {
                    // return '前期'
                    if (obj.decimal_places == 0) {
                        return (item * 1).toLocaleString();
                    } else {
                        return (item * 1)
                            .toFixed(obj.decimal_places)
                            .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
                    }
                } else {
                    if (obj.decimal_places == 0) {
                        return item;
                    } else {
                        return (item * 1).toFixed(obj.decimal_places);
                    }
                }
            }
        },
        /*     // 判断字段数据类型
            formatt(item, field) {
                if (field == 'price') {
                    return parseFloat(item).toFixed(3)
                }
                if (typeof (item) == "boolean") {
                    if (item == false) {
                        return "否"
                    } else {
                        return "是"
                    }
                }
                else if (Object.prototype.toString.call(item) == "[object Null]") {
                    // console.log("这是一个空");
                    return "-"
                }
                else if (Object.prototype.toString.call(item) == "[object Object]") {
                    // console.log("这是一个对象");
                    let str = ''
                    for (const key in item) {
                        str = str + item[key] + ','
                    }
                    return str
                }
                else if (item == "") {
                    return "-";
                }
                else {
                    return item
                }
            }, */
        //点击行选中数据
        rowClick(row, column, event) {
            // this.$refs.dialogTable.toggleRowSelection(row, true);
            if (this.isChoose) {
                this.radioValue = row
            } else {
                let refsElTable = this.$refs.dialogTable; // 获取表格对象
                let findRow = refsElTable.selection.find(c => c.id == row.id);  //找到选中的行
                if (findRow) {
                    refsElTable.toggleRowSelection(row, false);  //如过重复选中，则取消选中
                } else {
                    refsElTable.toggleRowSelection(row, true); // 实现选中行中选中事件
                }
                this.selectionValue = refsElTable.selection;
                // console.log(this.selectionValue);
            }
        },
        // 选中表格数据
        onSelectionChange(data) {
            this.selectionValue = data
        },
        // 选中数据回显
        getRowKey(row) {
            return row.id
        },
        // 删除选中数据
        DeletesSelection(data) {
            // console.log(data);
            this.selectionValue.splice(data, 1)
            this.setCurrentRow(this.selectionValue)
        },
        setCurrentRow(selectData) {
            this.$nextTick(() => {
                this.$refs.dialogTable.clearSelection()
                if (!selectData || selectData.length === 0) return
                selectData.forEach(item => {
                    const index = this.getIndex(item.id)
                    if (index === -1) return
                    this.$refs.dialogTable.toggleRowSelection(this.selectionValue[index], true);
                })
            })
        },
        // 获取table 数据中的下标
        getIndex(selectDataId) {
            let index = -1
            for (let i = 0; i < this.selectionValue.length; i++) {
                const item = this.selectionValue[i]
                if (item.id === selectDataId) {
                    index = i
                    continue
                }
            }
            return index
        },
        getSelectAll(selection) {
            // console.log(selection);
            this.selectionValue = selection
        },
        onFullscreen() {
            console.log("测试");
            this.isFullscreen = !this.isFullscreen
        }
    },
}
</script>
<style lang="less" scoped>
/deep/.el-dialog {
    border-radius: 12px;
    overflow: hidden;
}

.cellClickButton {
    color: #409eff;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

/deep/.el-dialog__header {
    background-color: rgb(89, 87, 87);
    color: #fff;
    padding: 0 !important;

    .icon-zuidahua {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            color: #898989;
        }
    }

    .icon-chuangkouzuidahua {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            background-color: #898989;
        }
    }

    .el-icon-close {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            background-color: #898989;
        }
    }
}

.phone_bialog_scroll {
    height: 50vh;
    overflow: auto;
    padding: 10px;
    // border: 1px solid #000;

    .phone_bialog_scroll_item {
        border-radius: 12px;
        padding: 10px;
        margin-bottom: 10px;
        background-color: #eaeaed;
        position: relative;

        .phone_bialog_scroll_item_check {
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 25px;
            color: #8fc31f;
        }

        .phone_bialog_scroll_items0 {
            font-weight: 700;
            color: #000;
        }

        .phone_bialog_scroll_items {
            font-size: 14px;
            color: #606266;
        }
    }

    .phone_bialog_scroll_item_active {

        .phone_bialog_scroll_items0,
        .phone_bialog_scroll_items {
            color: #8fc31f !important;
        }


    }
}

.search_company_name {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    .el-input {
        width: 260px;
        margin-right: 20px;
    }

}

.selection_box {
    position: relative;

    .selection_icon {
        cursor: pointer;
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 10px;
    }

    .selection_value {
        position: absolute;
        top: 20px;
        right: 0px;
        width: 300px;
        height: 480px;
        background: #fff;
        border-radius: 10px;
        z-index: 99;
        overflow-y: auto;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px;
        padding: 10px;

        .selection_title {
            width: 280px;
            background: #fff;
            // position: fixed;
            // top: 0px;
        }
    }

    .selection_value_item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 10px;
        // box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px;
        margin-bottom: 10px;
        border-radius: 12px;
        background-color: rgba(0, 0, 0, 0.08);

        // &:hover {}

        .selection_value_item_name {
            width: 220px;
        }

        .selection_value_item_close {
            font-size: 17px;
            cursor: pointer;
        }
    }
}

.undialogTable {
    // width: 300px;
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .undialogTable_icon {
        width: 100px;
        height: 100px;
        border-radius: 100px;
        text-align: center;
        background: #F5F5F5;

        i {
            font-size: 30px;
            font-weight: 600;
            line-height: 100px;
            color: #AAAAAA;
        }
    }

    .undialogTable_text {
        margin-top: 10px;
    }
}
</style>