<template>
    <div class="fc-upload">
        <el-upload v-bind="$props" :on-change="handleChange" :on-remove="handleRemove" :accept="accept"
            :size-unit="sizeUnit" :show-file-list="showFileList" :auto-upload="autoUpload" :list-type="listType"
            :multiple="multiple" :file-list="list">
            <el-button slot="trigger" v-if="listType === 'text'" size="small" type="primary">{{ buttonText
            }}</el-button>
            <i slot="trigger" v-if="listType === 'picture-card'" class="el-icon-plus"></i>
            <div v-if="showTip" slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
            <div slot="file" slot-scope="{file}">
                <div v-if="listType != 'picture-card'" class="uploadFile">
                    <div class="uploadFile_a">
                        <a :href="'https://back.hugtech.cc' + (file.url || file.file)" target="_blank"
                            rel="noopener noreferrer">
                            {{ file.name || file.file_name }}
                        </a>
                    </div>
                    <div class="uploadFile_i">
                        <el-button type="danger" plain :disabled="disabled" size="mini" icon="el-icon-delete"
                            @click="handleRemove(file, list)">删除</el-button>
                        <el-button type="primary" plain size="mini" icon="el-icon-download" class="uploadFile_i_download"
                            :disabled="!disabled" @click="downloadFile(file)">下载</el-button>
                    </div>
                </div>
                <div v-else class="pictureCardDiv">
                    <img :src="'https://back.hugtech.cc' + (file.url || file.file)" style="width: 148px;height: 148px;">
                    <div class="uploadFile_i">
                        <div style="margin-left: 30px;">
                            <el-button type="danger" plain :disabled="disabled" size="mini" icon="el-icon-delete"
                                @click="handleRemove(file, list)"></el-button>
                            <el-button type="primary" plain size="mini" icon="el-icon-download"
                                class="uploadFile_i_download" :disabled="!disabled" @click="downloadFile(file)"></el-button>
                        </div>
                    </div>
                </div>
            </div>
        </el-upload>
    </div>
</template>

<script>
export default {
    model: {
        prop: "value",
        event: "change",
    },
    name: "fc-upload",
    props: [
        "formData",
        "uploadFormId",
        'label',
        'tag',
        'tagIcon',
        'action',
        'defaultValue',
        "value",
        'labelWidth',
        'disabled',
        'required',
        'accept',
        'name',
        'autoUpload',
        'showTip',
        'buttonText',
        'fileSize',
        'sizeUnit',
        'listType',
        'fileList',
        'showFileList',
        'multiple',
        'regList',
        'changeTag',
        'proCondition',
        'formId',
        'asSummary',
        'layout'
    ],
    watch: {
        defaultValue: {
            handler(val) {
                // console.log('defaultValue', val);
                // if (val != undefined) {
                //     val.forEach((item) => {
                //         item.status = "success"
                //         item.name = item.file_name
                //         item.file_name = item.name
                //         item.url = item.fileS
                //     })
                // }
                console.log(val);
                this.list = val
            },
            deep: true,
            immediate: true
        },
        value: {
            handler(val) {
                // console.log("value", val, this.label);
                // this.list = val
                // let IdArr = []
                // val.forEach((item) => {
                //     IdArr.push(item)
                // })
                // this.$emit("change", IdArr)
                // this.list = val
            },
            deep: true,
            immediate: true
        },
        list: {
            handler(val) {
                // console.log(JSON.stringify(val));
                // this.$emit("change", val);
            },
            deep: true,
            immediate: true
        },

    },
    data() {
        return {
            debounceTimer: null,
            list: this.fileList,
            defaultList: null,
        }
    },
    created() {

    },

    methods: {
        async downloadFiles(file_url, file_name) {
            const res = await this.$http({
                method: "POST",
                url: "util_flow/file_download/",
                data: {
                    file_url: file_url,
                },
                responseType: "blob",
            });
            if (res.status == 200) {
                const data = res.data;
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.style.display = "none";
                link.href = url;
                link.setAttribute("download", file_name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                this.$message.error(res.data);
            }
        },
        async delefiles(val, fileList) {
            // console.log(val, fileList);
            let i = null
            if (fileList != null) {
                fileList.forEach((item, index) => {
                    if (item.id == val.id) {
                        i = index
                    }
                })
            }
            let arr = [val.id]
            const res = await this.$http({
                method: "DELETE",
                url: "oa/oa_form_files_bulk_del/",
                data: arr
            })
            if (res.status == 204) {
                fileList.splice(i, 1)
                let IdArr = []
                if (fileList.length > 0) {
                    fileList.forEach((item) => {
                        IdArr.push(item)
                    })
                }
                this.list = IdArr
                this.fileList = IdArr
                this.defaultList = IdArr
                this.defaultValue = IdArr
                this.$emit("change", IdArr)
            } else {
                this.$message.error(res.data)
            }
        },
        async postfiles(formdata, arr, fileList) {
            arr.forEach(item => {
                formdata.append("files", item)
            })
            const res = await this.$http({
                method: "POST",
                url: "oa/oa_form_add_files/",
                headers: {
                    "Content-Type":
                        "multipart/form-data; boundary=--------------------------051005589955791443255030",
                },
                data: formdata,
            });
            this.list = JSON.parse(JSON.stringify(fileList))
            let IdArr = []
            if (res.status == 200) {
                fileList.forEach((item) => {
                    res.data.forEach((items) => {
                        console.log(item.name == items.name);
                        if (item.name == items.name) {
                            item.id = items.id
                            item.url = items.url
                            item.status = 'success'
                        }
                    })
                })
                fileList.forEach((item) => {
                    console.log(item);
                    IdArr.push(item)
                })
                this.defaultValue = IdArr
                this.defaultList = IdArr
                console.log(IdArr);
                this.$emit("change", IdArr)
            } else {
                fileList.forEach(item => {
                    if (item.raw === file) {
                        item.status = 'fail'
                    }
                });
            }
        },
        uploadFiles(file, fileList) {
            if (this.uploadFormId.flow_id != undefined && this.uploadFormId.flow_id != null && this.uploadFormId.flow_id != '' && this.uploadFormId.form_id != undefined && this.uploadFormId.form_id != null && this.uploadFormId.form_id != '') {
                const formdata = new FormData();
                formdata.append("flow_id", (this.uploadFormId.flow_id * 1));
                formdata.append("form_id", (this.uploadFormId.form_id * 1));
                formdata.append("control_union_id", this.formId);
                formdata.append("is_approve", true);
                let arr = []
                fileList.forEach((item) => {
                    if (item.status == "ready") {
                        arr.push(item.raw);
                    }
                })
                this.postfiles(formdata, arr, fileList)
            } else if (this.uploadFormId.flow_id != undefined && this.uploadFormId.flow_id != null && this.uploadFormId.flow_id != '') {
                const formdata = new FormData();
                formdata.append("flow_id", this.uploadFormId.flow_id);
                formdata.append("control_union_id", this.formId);
                formdata.append("is_approve", true);
                let arr = []
                fileList.forEach((item) => {
                    if (item.status == "ready") {
                        arr.push(item.raw);
                    }
                })
                this.postfiles(formdata, arr, fileList)
            }
        },
        handleChange(file, fileList) {
            let self = this;
            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer);
            }
            this.debounceTimer = setTimeout(function () {
                self.uploadFiles(file, fileList);
            }, 300);
        },
        downloadFile(file) {
            // console.log('下载');
            this.downloadFiles(decodeURI(file.file), file.file_name)
        },
        removeFile(file) {
            // console.log('删除');
        },
        handleRemove(file, fileList) {
            // console.log(file, this.fileList, fileList);
            // console.log("移除");
            if (!this.disabled) {
                console.log(file, fileList);
                this.delefiles(file, fileList)
            } else {
                this.$message.error("无权限")
            }
        },
    },
}
</script>

<style lang="less" scoped>
.uploadFile {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

// .el-upload-list__item{
//     &:hover{
//         .el-button{
//             background-color: #F5F7FA;
//         }
//     }
// }

.uploadFile_a {
    a {
        color: #000;
        text-decoration: none;
    }
}

.uploadFile_i {
    display: flex;
    cursor: pointer;

    .el-button {
        border: none;
    }

}

.pictureCardDiv {
    position: relative;

    .uploadFile_i {
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;

        top: 0;
        left: 0;
        line-height: 148px;
        background-color: #00000025;
        // i{
        //     color: #fff;
        //     font-size: 28px;
        //     margin-right: 20px;
        // }
        // .el-icon-delete{
        //     background-color: #fff;
        //     color: red;
        //     &:active {
        //         background-color: red;
        //     color: #fff;
        //     }
        //     &:hover{
        //         color: red;
        //     }
        // }

        .el-icon-download {
            &:active {
                color: #b4f625;
            }

            &:hover {
                color: #b4f625;
            }
        }
    }

    &:hover {
        .uploadFile_i {

            display: block;
        }
    }

    &:active {
        .uploadFile_i {

            display: block;
        }
    }
}
</style>