<template>
    <div>
        <h1 v-if="isSave">送样申请</h1>
        <div class="orders_form">
            <el-form size="mini" label-position="left" :disabled="sendSamplesReadonly" :model="ordersObj"
                label-width="130px" :inline="true" :rules="sendSamplesForm" ref="sendSamplesRef">
                <el-form-item label="客户" prop="customerValue">
                    <el-input v-model="ordersObj.customerValue" placeholder="客户名称" @keyup.enter.native="isCustomer = true"
                        @blur.stop="isCustomer = true" clearable>
                        <el-button size="mini" slot="prepend" icon="el-icon-search" @click="isCustomer = true"></el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="业务员" prop="creator_id">
                    <template>
                        <el-select v-model="ordersObj.creator_id" placeholder="请选择" clearable>
                            <el-option v-for="item in salesmanList" :label="item.label" :value="item.value" clearable
                                :key="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </el-form-item>
                <el-form-item label="下单日期" prop="order_date">
                    <el-date-picker type="date" value-format="yyyy-MM-dd HH:mm:ss" placeholder="下单日期"
                        v-model="ordersObj.order_date" style="width: 100%;" :picker-options="pickerOptions"
                        clearable></el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="是否正式料号" prop="is_official_number">
                    <el-select v-model="ordersObj.is_official_number" placeholder="请选择" clearable>
                        <el-option v-for="item in official_numberObj" :label="item.label" :value="item.value" clearable
                            :key="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
            </el-form>
        </div>
        <div class="orders_table">
            <el-table v-if="!$store.state.isphone" style="width: 1300px;" size="mini" :data="ordersObj.products" border
                :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ textAlign: 'center' }" show-summary
                :summary-method="getSummaries">
                <el-table-column label="序号" type="index" width="50px"></el-table-column>
                <el-table-column label="设置" width="100">

                    <template slot-scope="data">
                        <el-button type="text" @click="onPushOrders">添加</el-button>
                        <el-button type="text" @click="onDeleteOrders(data.$index)">删除</el-button>
                    </template>
                </el-table-column>

                <el-table-column label="品目编码" prop="product_code" width="210px">

                    <template slot-scope="data">
                        <el-input size="mini" :disabled="sendSamplesReadonly" v-model="data.row.product_code"
                            placeholder="品目编码" @keyup.enter.native="onOrdersProductSearch(data.$index)"
                            @blur.stop="onOrdersProductSearch(data.$index)"
                            @change="onOrdersChange(data.row.product_code, data.$index)" clearable>
                            <el-button size="mini" slot="prepend" icon="el-icon-search"
                                @click="onOrdersProductSearch(data.$index)"></el-button>
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column label="品目名称" prop="product_title" width="210px">

                    <template slot-scope="data">
                        <el-input size="mini" :disabled="sendSamplesReadonly" v-model="data.row.product_title"
                            @input="onUnitPrice(data.$index)" placeholder="品目名称">
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column label="品目规格" prop="specifications" width="210px">

                    <template slot-scope="data">
                        <el-input size="mini" :disabled="sendSamplesReadonly" v-model="data.row.specifications"
                            @input="onUnitPrice(data.$index)" placeholder="品目规格">
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column label="数量" prop="quantity" width="130px">

                    <template slot-scope="data">
                        <el-input type="number" :disabled="sendSamplesReadonly" :min="1" clearable size="mini"
                            v-model="data.row.quantity" placeholder="数量">
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column label="参考单价" prop="special_offer_id" width="150px">

                    <template slot-scope="data">
                        <el-input size="mini" :disabled="sendSamplesReadonly" v-model="data.row.special_offer_price"
                            placeholder="参考单价" @keyup.enter.native="onOrdersSpecialSearch(data.$index)"
                            @change="onOrdersSpecialChange(data.row.special_offer_price, data.$index)"
                            @blur.stop="onOrdersSpecialSearch(data.$index)" clearable disabled>
                            <el-button size="mini" slot="prepend" icon="el-icon-search"
                                @click="onOrdersSpecialSearch(data.$index, data)"></el-button>
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column label="成交单价" prop="unit_price" width="130px">

                    <template slot-scope="data">
                        <el-input size="mini" :disabled="sendSamplesReadonly" type="number" v-model="data.row.unit_price"
                            @blur="onUnitPrice(data.$index)" placeholder="成交单价" clearable>
                        </el-input>
                        <!-- <el-input v-if="data.row.special_offer_title!=null" size="mini" type="number" v-model="data.row.unit_price" @blur="onUnitPrice(data.$index)"
                            placeholder="成交单价" clearable>
                        </el-input> -->
                        <!-- <el-input v-else size="mini" type="number" v-model="data.row.unit_price" @blur="onUnitPrice(data.$index)"
                            placeholder="成交单价" clearable disabled>
                        </el-input> -->
                    </template>
                </el-table-column>

                <el-table-column label="成交金额" prop="total_actual_amount" width="130px">

                    <template slot-scope="data">
                        <div>
                            <!-- {{ onTotalActualAmount(data.row.quantity * data.row.unit_price, data.$index) }} -->
                            {{ Thousands(accMul(data.row.quantity, data.row.unit_price, data.$index)) }}
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="产品摘要" prop="abstract" width="210px">

                    <template slot-scope="data">
                        <el-input size="mini" :disabled="sendSamplesReadonly" v-model="data.row.abstract" placeholder="产品摘要"
                            clearable>
                        </el-input>
                    </template>
                </el-table-column>
            </el-table>
            <div v-else style="border: 1px dashed #8fc31f;padding: 10px;border-radius:12px;">
                <div v-if="!sendSamplesReadonly">
                    <template v-for="(item, i) in ordersObj.products">
                        <div :key="i"
                            style="border:1px solid #DCDFE6; padding:10px; margin-bottom:10px;border-radius:12px;">
                            <div style="display: flex;justify-content: space-between;align-items: center;">
                                <p> 产品{{ i + 1 }}</p>
                                <i @click="onDeleteOrders(i)" class="el-icon-close"></i>
                            </div>
                            <el-form :model="item">
                                <el-form-item label="品目编码" prop="product_code">
                                    <el-input size="mini" v-model="item.product_code" placeholder="品目编码"
                                        @keyup.enter.native="onOrdersProductSearch(i)" @blur.stop="onOrdersProductSearch(i)"
                                        @change="onOrdersChange(item.product_code, i)" clearable>
                                        <el-button size="mini" slot="prepend" icon="el-icon-search"
                                            @click="onOrdersProductSearch(i)"></el-button>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="品目名称" prop="product_title">
                                    <el-input size="mini" v-model="item.product_title" @input="onUnitPrice(i)"
                                        placeholder="品目名称">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="品目规格" prop="specifications">
                                    <el-input size="mini" v-model="item.specifications" @input="onUnitPrice(i)"
                                        placeholder="品目规格">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="数量" prop="quantity">
                                    <el-input type="number" :min="1" clearable size="mini" v-model="item.quantity"
                                        placeholder="数量">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="参考单价" prop="special_offer_id">
                                    <el-input size="mini" v-model="item.special_offer_price" placeholder="参考单价"
                                        @keyup.enter.native="onOrdersSpecialSearch(i)" @blur.stop="onOrdersSpecialSearch(i)"
                                        @change="onOrdersSpecialChange(item.special_offer_price, i)" clearable disabled>
                                        <el-button size="mini" slot="prepend" icon="el-icon-search"
                                            @click="onOrdersSpecialSearch(i)"></el-button>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="成交单价" prop="unit_price">
                                    <el-input v-if="item.special_offer_price != null" size="mini" type="number"
                                        v-model="item.unit_price" @blur="onUnitPrice(i)" placeholder="成交单价" clearable>
                                    </el-input>
                                    <el-input v-else size="mini" type="number" v-model="item.unit_price"
                                        @blur="onUnitPrice(i)" placeholder="成交单价" clearable disabled>
                                    </el-input>
                                    <!-- <el-input size="mini" :disabled="sendSamplesReadonly" type="number"
                                    v-model="item.unit_price" @blur="onUnitPrice(i)" placeholder="成交单价" clearable>
                                </el-input> -->
                                </el-form-item>
                                <el-form-item label="成交金额" prop="total_actual_amount">
                                    <div>
                                        {{ Thousands(accMul(item.quantity, item.unit_price, i)) }}
                                        <!-- {{ data.row.total_actual_amount=data.row.quantity * data.row.unit_price }} -->
                                        <!-- <el-input size="mini" v-model="item.quantity * item.unit_price" placeholder=" 请输入内容"
                                            disabled></el-input> -->
                                    </div>
                                </el-form-item>
                                <el-form-item label="产品摘要" prop="abstract">
                                    <el-input size="mini" v-model="item.abstract" placeholder="产品摘要" clearable>
                                    </el-input>
                                </el-form-item>

                            </el-form>
                        </div>
                    </template>
                    <div>
                        <el-button style="width: 100%;" icon="el-icon-plus" type="text"
                            @click="onPushOrders">添加产品</el-button>
                    </div>
                </div>
                <div v-else>
                    <div style="padding: 0 0 10px 10px;">总计：{{ Thousands(onTotal(ordersObj.products)) }}</div>
                    <template v-for="(item, i) in ordersObj.products">
                        <div :key="i" @click="onOrdersObjProducts(item)"
                            style=" padding:10px; margin-bottom:10px;border-radius:12px;background-color: #f8ffed;">
                            <div
                                style="min-width: 300px; font-weight: 600;font-size:24px;color:  rgb(143,195, 31); padding: 5px;">
                                {{ item.product_code }}
                            </div>
                            <div style="font-weight: 600;font-size:16px; padding: 5px;">{{ item.product_title }}</div>
                            <div v-if="ordersObjProductsId.includes(item.product_id)" class="card_item">
                                <div>规：</div>
                                <div>{{ item.specifications }}</div>
                            </div>
                            <div class="card_item">
                                <div>量：</div>
                                <div>{{ Thousands(item.quantity) }}</div>
                            </div>
                            <div class="card_item">
                                <div>价：</div>
                                <div>{{ Thousands(item.unit_price) }}({{ Thousands(item.special_offer_price) }})</div>
                            </div>
                            <div class="card_item">
                                <div>总：</div>
                                <div>{{ Thousands(accMul(item.quantity, item.unit_price, i)) }}</div>
                            </div>
                            <div class="card_item">
                                <div>摘：</div>
                                <div>{{ item.abstract }}</div>
                            </div>
                        </div>
                    </template>

                </div>

            </div>
        </div>
        <div class="orders_form" style="border: 2px dotted #b5e4c2;margin: 10px 0px;border-radius: 12px;padding-top: 15px;">
            <el-form size="mini" label-position="left" :disabled="sendSamplesReadonly" :model="ordersObj"
                label-width="130px" :inline="true" :rules="sendSamplesForm2" ref="sendSamplesRef2">
                <el-form-item label="交易法人" prop="impersonal_entity">
                    <el-select v-model="ordersObj.impersonal_entity" placeholder="请选择" clearable>
                        <el-option v-for="item in impersonal_entityList" :label="item.label" :value="item.label" clearable
                            :key="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="发货仓库" prop="warehouseValue">
                    <el-input @focus="getInputFocus($event)" size="mini" v-model="ordersObj.warehouseValue"
                        placeholder="发货仓库" @keyup.enter.native="isWarehouse = true" @blur.stop="isWarehouse = true"
                        clearable>
                        <el-button slot="prepend" icon="el-icon-search" @click="isWarehouse = true"></el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="货币类型" prop="currency_type_id">
                    <el-select v-model="ordersObj.currency_type_id" placeholder="请选择" clearable>
                        <el-option v-for="item in currencyOptions" :label="item.label" :value="item.value" clearable
                            :key="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="付款方式" prop="payment_method">
                    <el-select v-model="ordersObj.payment_method" placeholder="请选择" clearable>
                        <el-option v-for="item in paymentMethodOption" :label="item.label" :value="item.label" clearable
                            :key="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="快递方式" prop="deliver_method">
                    <el-select v-model="ordersObj.deliver_method" placeholder="请选择" @change="deliverMethodChange" clearable>
                        <el-option v-for="item in deliverMethodOption" :label="item.label" :value="item.label" clearable
                            :key="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="ordersObj.deliver_method !== null && ordersObj.deliver_method !== ''" label="交货地点/承运人"
                    prop="deliver_addr">
                    <el-select size="mini" placeholder="交货地点/承运人" v-model="ordersObj.deliver_addr" allow-create filterable>
                        <el-option v-for="item in receiverInformationArr" :label="item.label" :value="item.label" clearable
                            :key="item.value">
                        </el-option>
                    </el-select>
                    <el-popover placement="top" trigger="hover" v-model="isDeliverAddrVisible">
                        <p>添加地址?</p>
                        <div style="text-align: right; margin: 0">
                            <el-button size="mini" type="text" @click="isDeliverAddrVisible = false">取消</el-button>
                            <el-button type="primary" size="mini" @click="addAddress">确定</el-button>
                        </div>
                        <i slot="reference" style="margin-left: 5px;font-size:14px;padding: 2px;"
                            class="el-icon-circle-plus-outline popovericon"></i>
                    </el-popover>
                    <!-- <template v-if="receiverInformationArr.length == 0">
                        <el-popconfirm title="暂无地址，添加地址?" @confirm="addAddress">
                            <p slot="title">暂无地址，添加地址?</p>
                            <el-select slot="reference" size="mini" placeholder="交货地点/承运人"
                                v-model="ordersObj.deliver_addr">
                                <el-option v-for="item in receiverInformationArr" :label="item.label"
                                    :value="item.label" clearable :key="item.value">
                                </el-option>
                            </el-select>
                        </el-popconfirm>
                    </template>

                    <template v-else>
                        <el-select size="mini" placeholder="交货地点/承运人" v-model="ordersObj.deliver_addr" allow-create
                            filterable>
                            <el-option v-for="item in receiverInformationArr" :label="item.label" :value="item.label"
                                clearable :key="item.value">
                            </el-option>
                        </el-select>
                    </template> -->
                </el-form-item>
                <el-form-item v-if="ordersObj.deliver_method !== null && ordersObj.deliver_method !== ''" label="收货人"
                    prop="consignee_name">
                    <el-select size="mini" placeholder="收货人" v-model="ordersObj.consignee_name" allow-create filterable
                        @change="consigneeNameChange">
                        <el-option v-for="item in contactList" :label="item.contact_name" :value="item.contact_name"
                            clearable :key="item.id">
                        </el-option>
                    </el-select>
                    <el-popover placement="top" trigger="hover" v-model="isConsigneeNameVisible">
                        <p>添加收货人?</p>
                        <div style="text-align: right; margin: 0">
                            <el-button size="mini" type="text" @click="isConsigneeNameVisible = false">取消</el-button>
                            <el-button type="primary" size="mini" @click="addContact">确定</el-button>
                        </div>
                        <i slot="reference" style="margin-left: 5px;font-size:14px;padding: 2px;"
                            class="el-icon-circle-plus-outline popovericon"></i>
                    </el-popover>
                    <!-- <template v-if="contactList.length == 0">
                        <el-popconfirm title="暂无收货人，添加收货人?" @confirm="addContact">
                            <p slot="title">暂无收货人，添加收货人?</p>
                            <el-select slot="reference" size="mini" placeholder="收货人"
                                v-model="ordersObj.consignee_name">
                                <el-option v-for="item in contactList" :label="item.contact_name"
                                    :value="item.contact_name" clearable :key="item.id">
                                </el-option>
                            </el-select>
                        </el-popconfirm>
                    </template>

                    <template v-else>
                        <el-select size="mini" placeholder="收货人" v-model="ordersObj.consignee_name" allow-create
                            filterable @change="consigneeNameChange">
                            <el-option v-for="item in contactList" :label="item.contact_name" :value="item.contact_name"
                                clearable :key="item.id">
                            </el-option>
                        </el-select>
                    </template> -->
                </el-form-item>

                <el-form-item label="单据有效期" prop="order_life_date">
                    <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="单据有效期"
                        v-model="ordersObj.order_life_date" style="width: 100%" :picker-options="pickerOptions"
                        clearable></el-date-picker>
                </el-form-item>
            </el-form>
        </div>
        <el-dialog :modal="false" :append-to-body="false" :close-on-click-modal="false" :show-close="false"
            :visible.sync="isAddAddress" :fullscreen="isFullscreen" v-dialogDrags width="60%" style="overflow: auto;">
            <!-- <div slot="title" v-if="dialogFromObj != null">{{ dialogFromObj.label }}</div> -->
            <div v-if="dialogFromObj != null" slot="title"
                style="display: flex;justify-content: space-between; align-items: center;;">
                <div class="title">
                    <span style="padding: 10px;">{{ dialogFromObj.label }}</span>
                </div>
                <div>
                    <i v-if="isFullscreen" class="iconfont icon-zuidahua " style=""
                        @click.stop="onFullscreen('isFullscreen')"></i>
                    <i v-else class="iconfont icon-chuangkouzuidahua" @click.stop="onFullscreen('isFullscreen')"></i>
                    <i class="el-icon-close" @click="isAddAddress = false"></i>
                </div>
            </div>
            <span v-if="dialogFromObj != null">
                <Preview @getlist="getFormList" :dialogForm="dialogForm" :formal="true" :id="dialogFromObj.value"
                    :specialDialog="specialDialog">
                </Preview>
            </span>
        </el-dialog>
        <div v-if="isSave">
            <el-button size="mini" type="primary" @click="onSaveOrders" style="margin-top: 10px;">保存</el-button>
        </div>
        <inquireDialog v-if="isCustomer" :isVisible="isCustomer" :obj="customerDialogObj" :title="'客户'" :isChoose="true"
            :searchValue="ordersObj.customerValue" @onIsVisible="customerVisible" @onApplied="customerApplied">
        </inquireDialog>
        <inquireDialog v-if="isProduct" :isVisible="isProduct" :obj="productDialogObj" :title="'品目编码'" :isChoose="false"
            :searchValue="productValue" @onIsVisible="productVisible" @onApplied="productApplied">
        </inquireDialog>
        <inquireDialog v-if="isSpecial" :isVisible="isSpecial" :obj="specialDialogObj" :title="'特价'" :isChoose="true"
            :searchValue="specialValue" @onIsVisible="specialVisible" @onApplied="specialApplied">
        </inquireDialog>
        <inquireDialog v-if="isWarehouse" :isVisible="isWarehouse" :obj="warehouseObj" :title="'仓库'" :isChoose="true"
            :searchValue="ordersObj.warehouseValue" @onIsVisible="warehouseVisible" @onApplied="warehouseApplied">
        </inquireDialog>
    </div>
</template>

<script>
import { debounce } from "@/utils/AntiShake";
import Preview from "@/components/OA/components/jsxPreview.vue"
import inquireDialog from "@/components/ProcessFlow/CRM/Forms/components/Dialog.vue";
import data from "@/components/Tools/SubassemblyTools/PrintJs/net-provider1";
import Decimal from 'decimal.js';
export default {
    props: ["isSampleSubmissions", "sampleSubmissionsFormCustomer", "sampleSubmissionsForm", "formTitle", "readonly", "sampleSubmissionsId"],
    data() {
        return {
            isFullscreen: false,
            // 移动端显示产品规格产品id
            ordersObjProductsId: [],
            //是否添加地址
            isDeliverAddrVisible: false,
            //是否添加收货人
            isConsigneeNameVisible: false,
            printList: ['送样申请'],
            // 是否可编辑
            sendSamplesReadonly: this.readonly === true ? true : false,
            // 业务员列表
            salesmanList: [],
            // 是否显示
            isSave: this.isSampleSubmissions == undefined ? true : this.isSampleSubmissions,
            //添加订单数据
            ordersObj: {
                is_official_number: null,//是否正式料号
                impersonal_entity: null, //交易法人 1代表宁波亨博电磁技术有限公司  2代表深圳亨贝智控物联有限公司  3代表宁波亨嘉科技有限公司
                order_life_date: "", //单据有效期
                warehouseValue: null,//仓库名称
                ware_house_id: null,//仓库id
                payment_method: null,//付款方式
                deliver_method: null,//快递方式
                deliver_addr: null,//交货地点/承运人
                consignee_name: null,//收货人
                customerValue: "",//客户名称
                customer_id: "",//客户id
                creator_id: null,//业务员
                currency_type_id: 1,
                contract_order_type: 4, //订单固定传1，标识这是一个订单，2为报价单,3为长期合同，4为送样单
                order_date: "", //报价日期
                products: [
                    {
                        product_id: null, //产品的外键id
                        unit_price: null, //入库单价
                        quantity: null, //产品数量
                        total_actual_amount: null,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
                        special_offer_id: null, //可以为空,也就是可以不关联特价申请单id
                        abstract: null
                    },
                    {
                        product_id: null, //产品的外键id
                        unit_price: null, //入库单价
                        quantity: null, //产品数量
                        total_actual_amount: null,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
                        special_offer_id: null, //可以为空,也就是可以不关联特价申请单id
                        abstract: null
                    },
                    {
                        product_id: null, //产品的外键id
                        unit_price: null, //入库单价
                        quantity: null, //产品数量
                        total_actual_amount: null,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
                        special_offer_id: null, //可以为空,也就是可以不关联特价申请单id
                        abstract: null
                    },
                ]
            },
            specialDialog: '客户',
            // isSpecialProcess: false,
            //OA是否显示
            isAddAddress: false,
            //收货人
            contactList: [],
            // 交货地点/承运人
            receiverInformationArr: [],
            //OA表单参数
            dialogFromObj: null,
            official_numberObj: [
                { value: 1, label: '是', },
                { value: 2, label: '否', },
            ],
            //付款方式
            paymentMethodOption: [
                { value: 1, label: "寄付" },
                { value: 2, label: "到付" }
            ],
            //快递方式
            deliverMethodOption: [
                { value: 1, label: "顺丰" },
                { value: 2, label: "中通" },
                { value: 3, label: "韵达" },
                { value: 4, label: "圆通" },
                { value: 5, label: "申通" },
                { value: 6, label: "极兔" },
                { value: 7, label: "德邦" },
                { value: 8, label: "其他" },
            ],
            // 交易法人列表
            impersonal_entityList: [
                { value: 1, label: '宁波亨博电磁技术有限公司' },
                { value: 2, label: '深圳亨贝智控物联有限公司' },
                { value: 3, label: '宁波亨嘉科技有限公司' },
            ],
            //  发货仓库开关
            isWarehouse: false,
            //仓库参数
            warehouseValueObj: null,
            // 发货仓库Dialog参数
            warehouseObj: {
                searchField: 'keyword',
                url: 'crm/outbound_product_warehouse/',
                params: {
                    per_page: 10,
                    page_number: 1,
                    user_id: localStorage.getItem("user_id"),
                    keyword: ""
                }
            },
            // 客户名称
            // customerValue: "",
            // 客户对象
            customerObj: {},
            // 客户Dialog开关
            isCustomer: false,
            // 客户Dialog参数
            customerDialogObj: {
                searchField: "search",
                url: "crm/customer_bulk/",
                params: {
                    per_page: 10,
                    page: 1,
                    user_id: localStorage.getItem("user_id"),
                    search: ""
                }
            },
            //产品名称
            productValue: "",
            // 产品开关
            isProduct: false,
            //产品数组
            productArr: [],


            //订单的产品表格当前index
            quotationTableIndex: "",
            // 产品Dialog参数
            productDialogObj: {
                searchField: 'search',
                url: 'crm/special_offer/',
                params: {
                    per_page: 10,
                    page: 1,
                    model_name: 'SpecialOfferTable',
                    sys_name: 3,
                    user: localStorage.getItem("user_id"),
                    search: "",
                    is_using: true,
                }
            },
            // 特价开关
            isSpecial: false,
            // 特价名称
            specialValue: "",
            // 订单的产品当前index
            specialTableIndex: "",
            // 特价Dialog参数
            specialDialogObj: {
                url: 'crm/order_special_offer/',
                data: {
                    // per_page: 10,
                    // page_number: 1,
                    // user_id: localStorage.getItem("user_id"),
                    // keyword: ""
                }
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick(picker) {
                            picker.$emit("pick", new Date());
                        },
                    },
                ],
            },
            //送样单验证
            sendSamplesForm: {
                customerValue: [
                    {
                        required: true,
                        message: "请选择客户",
                        trigger: ["change", "blur"],
                    },
                ],
                creator_id: [
                    {
                        required: true,
                        message: "请选择业务员",
                        trigger: ["change", "blur"],
                    },
                ],
                is_official_number: [
                    {
                        required: true,
                        message: "是否正式料号",
                        trigger: ["change", "blur"],
                    },
                ],
                order_date: [
                    {
                        required: true,
                        message: "请选择下单日期",
                        trigger: ["change", "blur"],
                    },
                ],
            },
            //送样单验证
            sendSamplesForm2: {
                impersonal_entity: [
                    {
                        required: true,
                        message: "请选择交易法人",
                        trigger: ["change", "blur"],
                    }
                ],
                warehouseValue: [
                    {
                        required: true,
                        message: "请选择发货仓库",
                        trigger: ["change", "blur"],
                    }
                ],
                currency_type_id: [
                    {
                        required: true,
                        message: "请选择货币类型",
                        trigger: ["blur", "change"],
                    },
                ],
                payment_method: [
                    {
                        required: true,
                        message: "请选择付款方式",
                        trigger: ["change", "blur"],
                    }
                ],
                deliver_method: [
                    {
                        required: true,
                        message: "请选择快递方式",
                        trigger: ["change", "blur"],
                    }
                ],
                deliver_addr: [
                    {
                        required: true,
                        message: "请选择交货地点/承运人",
                        trigger: ["change", "blur"],
                    }
                ],
                consignee_name: [
                    {
                        required: true,
                        message: "请选择收货人",
                        trigger: ["change", "blur"],
                    }
                ],
                order_life_date: [
                    {
                        required: true,
                        message: "请选择单据有效期",
                        trigger: ["change", "blur"],
                    }
                ],
            },
            //货币类型
            currencyOptions: [],
        }
    },
    components: {
        inquireDialog, Preview
    },
    mounted() {
        this.getCurrency();
        this.getSalesManList();
        let today = new Date();
        this.ordersObj.order_date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();
        if (!this.isSave) {
            if (this.formTitle == undefined && this.sampleSubmissionsId == undefined) {
                this.customerObj = this.sampleSubmissionsForm.customer;
                this.ordersObj.customerValue = this.sampleSubmissionsForm.customer.company_name;
                this.ordersObj.customer_id = this.sampleSubmissionsForm.customer.id;
                this.ordersObj.creator_id = this.sampleSubmissionsForm.salesman.value;
            }
        }
        if (this.sampleSubmissionsFormCustomer != undefined) {
            this.customerObj = this.sampleSubmissionsFormCustomer.customer;
            this.ordersObj.customerValue = this.sampleSubmissionsFormCustomer.customer.company_name;
            this.ordersObj.customer_id = this.sampleSubmissionsFormCustomer.customer.id;
            this.ordersObj.creator_id = this.sampleSubmissionsFormCustomer.salesman.value;
            console.log(this.sampleSubmissionsFormCustomer, "送样单", this.ordersObj);
        }
        if (this.$route.query.id != undefined && this.$route.path !== "/oaintoform") {
            this.getList(this.$route.query.id)
        }
        // if (this.sampleSubmissionsId != undefined) {
        //     this.getList(this.sampleSubmissionsId)
        // }
        this.funDate(30)
    },
    watch: {
        isAddAddress: {
            handler(val) {
                if (!val) {
                    this.dialogFromObj = null
                }
            }
        },
        "ordersObj.warehouseValue": {
            handler(val) {
                if (val !== null) {
                    this.ordersObj.ware_house_id = this.warehouseValueObj.id
                    this.ordersObj.ware_house_title = val
                }
            }
        },
        ordersObj: {
            handler(val) {
                if (this.isSampleSubmissions == false && this.sampleSubmissionsId == undefined) {
                    // console.log(this.sampleSubmissionsForm, "55555")
                    this.$emit("onAddOutboundOrder", this.ordersObj)
                    // console.log(val);
                }
            },
            deep: true,
            immediate: true
        },
        sampleSubmissionsForm: {
            handler(val) {
                if (this.formTitle == "送样申请") {
                    if (val != undefined) {
                        // this.getList(val.id)
                        for (let key in val) {
                            this.ordersObj[key] = val[key]
                        }
                        // this.ordersObj.customerValue = val.customerValue
                        // this.ordersObj.is_official_number = val.is_official_number
                        // this.ordersObj.customer_id = val.customer_id
                        // this.ordersObj.creator_id = val.creator_id
                        // this.ordersObj.contract_order_type = val.contract_order_type
                        // this.ordersObj.order_date = val.order_date
                        // this.ordersObj.order_life_date = val.order_life_date
                        // this.ordersObj.send_sample_number = val.send_sample_number
                        // this.ordersObj.creator_name = val.creator_name
                        // this.ordersObj.avatar = val.avatar
                        // this.ordersObj.id = val.id
                        // this.ordersObj.impersonal_entity = val.impersonal_entity
                        // this.ordersObj.warehouseValue = val.warehouseValue
                        // this.ordersObj.ware_house_id = val.ware_house_id
                        // this.ordersObj.payment_method = val.payment_method
                        // this.ordersObj.deliver_method = val.deliver_method
                        // this.ordersObj.deliver_addr = val.deliver_addr
                        // this.ordersObj.consignee_name = val.consignee_name
                        // this.ordersObj.order_life_date = val.order_life_date
                        // this.ordersObj.products = []
                        this.warehouseValueObj = { id: val.ware_house_id }
                        // val.products.forEach(item => {
                        //     this.ordersObj.products.push({
                        //         product_code: item.product_code,
                        //         product_title: item.product_title,
                        //         specifications: item.specifications,
                        //         product_id: item.product_id, //产品的外键id
                        //         unit_price: item.unit_price, //入库单价
                        //         quantity: item.quantity, //产品数量
                        //         special_offer_obj: item.special_offer_obj,
                        //         total_actual_amount: item.total_residue_amount,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
                        //         special_offer_price: item.special_offer_price, //
                        //         special_offer_id: item.special_offer_id, //可以为空,也就是可以不关联特价申请单id
                        //         abstract: item.abstract,
                        //         order_id: item.order_id
                        //     })
                        // })
                    }
                }
            }, deep: true,
            immediate: true,
        },
        readonly: {
            handler(val) {
                console.log(val, "1111");
                if (val === undefined) {
                    this.sendSamplesReadonly = false
                } else {
                    this.sendSamplesReadonly = val
                    console.log();
                }
            },
            deep: true
        }
    },
    methods: {
        consigneeNameChange() {
            console.log(this.ordersObj.consignee_name);
            this.contactList.forEach(item => {
                if (item.contact_name == this.ordersObj.consignee_name) {
                    console.log(item);
                    this.ordersObj.consignee_phone = item.mobile_phone
                }
            })
        },
        //对话框是否全屏
        onFullscreen(val) {
            this[val] = !this[val]
        },
        //获取货币类型
        async getCurrency() {
            const res = await this.$http({
                method: "GET",
                url: "crm/outbound_currency_type/",
            });
            this.currencyOptions = res.data;
        },
        //产品金额总计
        onTotal(val) {
            let num = null
            val.forEach((item, i) => {
                num += (this.accMul(item.quantity, item.unit_price, i)) * 1;
            })
            return num
        },
        // 千分位符保留三位小数
        Thousands(num) {
            let num1 = num * 1
            return num1.toString().replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
        },
        // 提货方式改变
        async deliverMethodChange() {
            this.ordersObj.deliver_addr = null
            if (this.ordersObj.customer_id != '' && this.ordersObj.customer_id != null) {
                console.log(this.ordersObj.deliver_method, this.id);
                let res = await this.$http({
                    method: 'GET',
                    url: 'crm/customer_receiver_information/',
                    params: {
                        customer: this.ordersObj.customer_id
                    }
                });
                if (res.status == 200) {
                    this.receiverInformationArr = res.data
                    this.receiverInformationArr.forEach((item, index) => {
                        if (item.child == true) {
                            this.ordersObj.deliver_addr = item.label
                        }
                    })
                } else {
                    this.$message.error(res.data)
                }


                this.ordersObj.contact_name = null
                this.ordersObj.consignee_name = null
                this.ordersObj.consignee_phone = null
                let res1 = await this.$http({
                    method: 'GET',
                    url: 'crm/contact_bulk/',
                    params: {
                        user: localStorage.getItem("user_id"),
                        customer_id: this.ordersObj.customer_id,
                    }
                });
                if (res1.status == 200) {
                    console.log(res1.data.table_body_data);
                    this.contactList = res1.data.table_body_data
                    this.contactList.forEach((item, index) => {
                        if (item.is_default == true) {
                            this.ordersObj.contact_name = item.contact_name
                            this.ordersObj.consignee_name = item.contact_name
                            this.ordersObj.consignee_phone = item.mobile_phone
                        }
                        console.log(this.ordersObj);
                    })
                } else {
                    this.$message.error(res1.data);
                }
            } else {
                this.$message.warning("请选择客户后再选择运送方式");
            }

        },
        // 添加收货地址
        addAddress() {
            let obj = {
                label: '收货地址',
                value: 173,
            };
            this.onPushOA(obj);
        },
        // 确认添加联系人
        addContact() {
            let obj = {
                label: '联系人',
                value: 92,
            };
            this.onPushOA(obj);
        },
        onPushOA(val) {
            // console.log(val);
            this.dialogForm = {
                customer: {
                    company_name: this.ordersObj.customerValue,
                    id: this.ordersObj.customer_id,
                },
            };
            this.dialogFromObj = val;
            this.isAddAddress = true;
            // console.log(val);
            // this.getformiconfig(val.value);
        },
        getFormList(val) {
            console.log(val);
            if (val.arr.length == 0) {
                console.log(val);
                if (val.data.status == 200) {
                    this.deliverMethodChange()
                    this.$message.success('提交成功，审批流程已发起')
                } else {
                    this.$message.error(val.data.data)
                }
            } else {
                if (val.arr[0].status == 200) {
                    this.deliverMethodChange()
                    this.$message.success('提交成功，审批流程已发起')
                } else {
                    this.$message.error(val.arr[0].data)
                }
            }
            this.isAddAddress = false
            this.dialogFromObj = null
        },
        // 仓库开关
        warehouseVisible(data) {
            this.isWarehouse = data
        },
        // 仓库参数
        warehouseApplied(data) {
            this.warehouseValueObj = data
            this.ordersObj.warehouseValue = data.warehouse_name
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                if (column.property === "total_actual_amount") {
                    const sum = values.reduce((prev, curr, i) => {
                        // console.log(data, i)
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            // if (data[i].product_code == 'Z0040000') {
                            //     return prev
                            // } else {
                            return prev + value;
                            // }
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] = sum.toString().replace(/(\d)(?=(\d{3})+\.)/g, "$1,");// Add thousand separator
                } else {
                    sums[index] = '';
                }
            });
            return sums;
        },
        funDate(aa) {
            let date1 = new Date()
            let time1 = date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate();//time1表示当前时间
            let date2 = new Date(date1);
            date2.setDate(date1.getDate() + aa);
            let time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();
            this.ordersObj.order_life_date = time2
        },
        async getSalesManList() {
            let res = await this.$http({
                method: 'GET',
                url: 'crm/data_source/',
                params: {
                    source: 'salesman',
                }
            })
            if (res.status == 200) {
                this.salesmanList = res.data;
            } else {
                this.$message.error(res.data)
            }

        },
        async getList(val) {
            const res = await this.$http({
                method: "GET",
                url: `crm/order_relationship_table/${val}/`,
            })
            if (res.status == 200) {
                console.log(res.data);
                if (this.formTitle == "送样申请") {
                    this.ordersObj.id = res.data.id
                    this.ordersObj.send_sample_number = res.data.send_sample_number
                }
                this.ordersObj.customerValue = res.data.customer
                // this.customerObj = res.data.customer
                this.ordersObj.customer_id = res.data.customer_id
                // this.ordersObj.order_number = res.data.order_number
                // this.ordersObj.contract_order_type = res.data.contract_order_type
                this.ordersObj.order_date = res.data.order_date
                // this.ordersObj.order_life_date = res.data.order_life_date
                // this.ordersObj.other_terms1 = res.data.other_terms1
                // this.ordersObj.other_terms2 = res.data.other_terms2
                // this.ordersObj.other_terms3 = res.data.other_terms3
                // this.ordersObj.payment_method = res.data.payment_method
                // this.ordersObj.freight_cost_rule = res.data.freight_cost_rule
                // this.ordersObj.payment_days = res.data.payment_days
                // this.ordersObj.AdvancePayment_ratio = res.data.AdvancePayment_ratio
                // this.ordersObj.deliver_method = res.data.deliver_method
                // this.ordersObj.deliver_date = res.data.deliver_date
                // this.ordersObj.deliver_date_type = res.data.deliver_date_type
                this.ordersObj.creator_id = res.data.creator.id
                // this.ordersObj.impersonal_entity = res.data.impersonal_entity
                // this.warehouseValue = res.data.ware_house.warehouse_name
                // this.ordersObj. deliver_addr= res.data.deliver_addr
                this.ordersObj.products = []
                res.data.order_product_table.forEach(item => {
                    console.log(item);
                    this.ordersObj.products.push({
                        product_code: item.product.item_code,
                        product_title: item.product.item_name,
                        specifications: item.product.specifications,
                        product_id: item.product.id, //产品的外键id
                        unit_price: item.unit_price, //入库单价
                        quantity: item.quantity, //产品数量
                        special_offer_obj: item.special_offer,
                        total_actual_amount: item.total_residue_amount,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
                        special_offer_price: item.special_offer_price, //
                        special_offer_id: item.special_offer.id, //可以为空,也就是可以不关联特价申请单id
                        abstract: item.abstract
                    })
                })
            } else {
                this.$message.error(res.data)
            }

            console.log(this.ordersObj);

        },
        // 客户Dialog开关
        customerVisible(data) {
            // console.log(data);
            this.isCustomer = data
        },
        // 客户Dialog数据
        customerApplied(data) {
            this.customerObj = data
            this.ordersObj.customer_id = data.id
            // console.log(data);
            this.ordersObj.customerValue = data.company_name
        },
        // 产品Dialog开关
        productVisible(data) {
            this.isProduct = data
        },
        // 产品Dialog数据
        productApplied(data) {
            console.log(data);
            this.productArr = data
            const arr = []
            this.productArr.forEach(item => {
                arr.push({
                    product_code: item.field0,
                    product_title: item.item_name,
                    specifications: item.specifications,
                    product_id: item.product, //产品的外键id
                    unit_price: null, //入库单价
                    quantity: null, //产品数量
                    special_offer_obj: null,
                    total_actual_amount: null,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
                    special_offer_price: null, //
                    special_offer_id: null, //可以为空,也就是可以不关联特价申请单id
                    abstract: null
                })
            });
            this.ordersObj.products.splice(this.quotationTableIndex, 1, ...arr)
        },
        onOrdersProductSearch(index) {
            this.isProduct = true
            this.quotationTableIndex = index
        },
        onOrdersChange(data, index) {
            console.log(data, index);
            this.productValue = data
            this.quotationTableIndex = index
        },
        // 添加订单的产品
        onPushOrders() {
            this.ordersObj.products.push({
                product_code: null,
                product_title: null,
                specifications: null,
                product_id: null, //产品的外键id
                unit_price: null, //入库单价
                quantity: null, //产品数量
                special_offer_obj: null,
                total_actual_amount: null,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
                special_offer_price: null, //
                special_offer_id: null, //可以为空,也就是可以不关联特价申请单id
                abstract: null
            })
        },
        //删除报价单的产品
        onDeleteOrders(index) {
            if (this.ordersObj.products.length != 1) {
                this.ordersObj.products.splice(index, 1)
            }
        },
        // 特价的搜索
        async onOrdersSpecialSearch(index, data) {
            console.log(this.ordersObj.products[index]);
            console.log(this.ordersObj.products[index].product_id, this.ordersObj.customer_id, this.ordersObj.products[index].quantity);
            if (this.ordersObj.products[index].product_id != null && this.ordersObj.products[index].product_id != ''
                && this.ordersObj.products[index].quantity != null && this.ordersObj.products[index].quantity != ""
                && this.ordersObj.customer_id != null && this.ordersObj.customer_id != "") {
                this.isSpecial = true
                this.specialTableIndex = index
                this.specialDialogObj.url = 'crm/order_special_offer/'
                this.specialDialogObj.data = {
                    product_id: this.ordersObj.products[index].product_id,
                    customer_id: this.ordersObj.customer_id,
                    quantity: Number(this.ordersObj.products[index].quantity),
                    user_id: localStorage.getItem("user_id")
                }
                // let res = await this.$http({
                //     method: 'POST',
                //     url: 'crm/order_special_offer/',
                //     data: {
                //         product_id: this.ordersObj.products[index].product_id,
                //         customer_id: this.ordersObj.customer_id,
                //         quantity: this.ordersObj.products[index].quantity,
                //         user_id:localStorage.getItem("user_id")
                //     }
                // })
                // console.log(res);

            } else {
                this.$message.error("请添加客户、产品或数量")
            }
        },
        onOrdersSpecialChange(title, index) {
            console.log(title);
            this.specialValue = data
            this.specialTableIndex = index
        },
        // 特价Dialog开关
        specialVisible(val) {
            this.isSpecial = false
        },
        // 特价Dialog数据
        specialApplied(val) {
            console.log(val);
            this.ordersObj.products[this.specialTableIndex].special_offer_obj = val
            this.ordersObj.products[this.specialTableIndex].special_offer_price = parseFloat(val.price)
            this.ordersObj.products[this.specialTableIndex].unit_price = parseFloat(val.price)
            this.ordersObj.products[this.specialTableIndex].special_offer_id = val.id
            // console.log(this.ordersObj.products[this.specialTableIndex].unit_price);
            if (this.ordersObj.products[this.specialTableIndex].unit_price != null) {
                if (val.price != undefined) {
                    if (this.ordersObj.products[this.specialTableIndex].unit_price >= parseFloat(val.price)) {
                    } else {
                        this.ordersObj.products[this.specialTableIndex].unit_price = null
                        this.$message.error('价格不允许')
                    }
                } else {
                    if (this.ordersObj.products[this.specialTableIndex].unit_price >= val.min_price && this.ordersObj.products[this.specialTableIndex].unit_price <= val.max_price) {
                    } else {
                        this.ordersObj.products[this.specialTableIndex].unit_price = null
                        this.$message.error('价格不允许')
                    }
                }
            }
        },
        // 判断单价
        onUnitPrice: debounce(function (index) {
            // console.log(index);
            // console.log(this.ordersObj.products[index].special_offer_obj);
            if (this.ordersObj.products[index].special_offer_obj !== null) {
                if (this.ordersObj.products[index].special_offer_obj.price != undefined) {
                    console.log(this.ordersObj.products[index].unit_price, this.ordersObj.products[index].special_offer_obj.price);
                    if (Number(this.ordersObj.products[index].unit_price) >= Number(this.ordersObj.products[index].special_offer_obj.price)) {
                    } else {
                        this.ordersObj.products[index].unit_price = ""
                        this.$message.error('价格不允许')
                    }
                } else {
                    if (this.ordersObj.products[index].unit_price >= this.ordersObj.products[index].special_offer_obj.min_price && this.ordersObj.products[index].unit_price <= this.ordersObj.products[index].special_offer_obj.max_price) {
                    } else {
                        this.ordersObj.products[index].unit_price = ""
                        this.$message.error('价格不允许')
                    }
                }
            }
        }, 500),
        // 保存报价单
        async onSaveOrders() {
            console.log(this.ordersObj);
            let arr = []
            this.ordersObj.products.forEach((item, i) => {
                if (item.product_id != null) {
                    arr.push(item)
                    item.quantity = Number(item.quantity)
                    item.unit_price = Number(item.unit_price)
                }
            })
            if (arr.length > 0) {
                this.ordersObj.products = arr
            }
            if (this.ordersObj.products.length == 0) {
                this.$message.warning("产品表不能为空")
            } else if (this.ordersObj.customerValue == '') {
                this.$message.warning("请选择客户")
            } else {
                let isEmpty = false
                this.ordersObj.products.forEach((item, index) => {
                    console.log(item);
                    if (item.quantity == '' || item.quantity == null) {
                        console.log(item.quantity);
                        isEmpty = true;
                    }
                })
                console.log(isEmpty);
                if (isEmpty) {
                    this.$message.warning("请填写产品数量")
                } else {
                    let obj = this.ordersObj
                    delete obj.deliver_date
                    this.$refs.sendSamplesRef.validate(async (valid) => {
                        if (valid) {
                            if (this.$route.query.id == null) {
                                const res = await this.$http({
                                    url: "crm/order_relationship_table/",
                                    method: "POST",
                                    data: obj
                                })
                                console.log(res);
                                if (res.status === 200) {
                                    this.$message.success('新增成功')
                                } else {
                                    this.$message.error(res.data)
                                }
                            } else {
                                const res = await this.$http({
                                    url: `crm/order_relationship_table/${this.$route.query.id}/`,
                                    method: "PUT",
                                    data: obj
                                })
                                console.log(res);
                                if (res.status === 200) {
                                    this.$message.success('修改成功')
                                } else {
                                    this.$message.error(res.data)
                                }
                            }
                        }
                    });

                }
            }


        },
        accMul(arg1, arg2, index) {
            if (arg1 === null || arg2 === null || arg1 === '' || arg2 === '') {
                return ""
            } else {
                // console.log(arg1,arg2);
                var m = 0,
                    s1 = arg1.toString(),
                    s2 = arg2.toString();
                try {
                    m += s1.split('.')[1].length;
                } catch (e) { }
                try {
                    m += s2.split('.')[1].length;
                } catch (e) { }
                // this.ordersObj.products[index].total_actual_amount = (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m)
                // return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m);
                let decimal1 = new Decimal(s1)
                let decimal2 = new Decimal(s2)
                let decimal3 = decimal1.times(decimal2)
                this.ordersObj.products[index].total_actual_amount = decimal3 * 1
                return decimal3 * 1
            }
        },
        onTotalActualAmount(amount, index) {
            this.ordersObj.products[index].total_actual_amount = amount
            return amount
        },
        onOrdersObjProducts(val) {
            if (this.ordersObjProductsId.includes(val.product_id)) {
                const index = this.ordersObjProductsId.indexOf(val.product_id)
                this.ordersObjProductsId.splice(index, 1)
            } else {
                this.ordersObjProductsId.push(val.product_id)
            }
        },
    }
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__header {
    background-color: rgb(89, 87, 87);
    color: #fff;
    padding: 0 !important;

    .icon-zuidahua {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            color: #898989;
        }
    }

    .icon-chuangkouzuidahua {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            background-color: #898989;
        }
    }

    .el-icon-close {
        font-size: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            background-color: #898989;
        }
    }
}

.popovericon {
    border-radius: 5px;

    &:hover {
        color: #8fc31f;
        box-shadow: rgba(0, 0, 0, 0.425) 0px 2px 4px;
    }
}

.send_samples {
    width: 100%;
    overflow: auto;
}

.orders_form {
    width: 100%;

    .el-form {
        // display: flex;
        flex-wrap: wrap;

        .el-form-item {
            flex: 1;
            margin-top: 0px;
            padding-left: 10px;
        }

        .el-form-item__content {
            width: 210px;
        }

        .el-date-editor {
            width: 210px !important;
        }

        .el-input {
            width: 210px;
        }

        .el-switch {
            width: 210px;
        }

        .el-select {
            width: 210px;
        }
    }
}

.orders_table {
    .card_item {
        display: flex;
        justify-content: flex-start;
    }
}

@media screen and (max-width:1000px) {
    /deep/.el-textarea__inner {
        width: 260px !important;
    }
}

@media screen and (min-width:1000px) {
    /deep/.el-textarea__inner {
        width: 460px !important;
    }
}


// 合计行文本居中
/deep/.el-table__footer-wrapper {
    tr {
        td {
            text-align: center;
        }
    }
}
</style>