<template>
  <div class="bigBox">
    <el-card v-if="isTable" class="elCard" :style="isHaveTop ? classObject(form, 'card') : 'padding:20px'">
      <transition name="el-zoom-in-top">
        <div class="delDialog" v-if="isDelDialog">
          <el-button size="mini" v-if="permissions.is_delete" type="text" @click="batchDelete">批量删除</el-button>
          <el-button size="mini" v-if="permissions.recycle" type="text" @click="batchRecycle">回收</el-button>
          <el-button size="mini" v-if="permissions.is_generate_order" type="text" @click="createReceipts">生成单据</el-button>
          <el-button type="text" v-if="permissions.is_invalid" size="mini" @click="onVoid"
            :style="classObject(form, 'button')">作废</el-button>
          <el-button type="text" v-if="permissions.is_used" size="mini" @click="enable">启用</el-button>
          <el-button type="text" v-if="permissions.is_stopped" size="mini" :style="classObject(form, 'button')"
            @click="Deactivated">停用</el-button>
          <el-button size="mini" type="text" @click="handleSelectionChange()">取消操作</el-button>
        </div>
      </transition>
      <div class="topBox" v-if="isHaveTop">
        <div style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          ">
          <!-- permissions.is_time_search为true就显示，没有则隐藏 -->
          <div class="tableTitleBox">{{ tableTitle }}</div>
          <div>
            <div class="lineTop" style="display: flex; justify-content: flex-end">
              <!-- permissions.is_time_search为true就显示，没有则隐藏 -->
              <!-- <el-form v-if="permissions.is_time_search" :inline="true" :model="searchTimeForm" class="timeSearch">
                                <el-form-item>
                                    <el-date-picker size="mini" v-model="searchTimeForm.startTime" align="right" type="date"
                                        placeholder="日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                                        :picker-options="pickerOptions">
                                    </el-date-picker>
                                    &nbsp;
                                    <el-date-picker v-if="searchTimeForm.endTime != undefined" size="mini"
                                        v-model="searchTimeForm.endTime" align="right" type="date" placeholder="结束日期"
                                        format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item>
                                    <el-button size="mini" @click="reset">重置</el-button>
                                    <el-button size="mini" :style="classObject(form, 'button')" @click="siftTimeData">
                                        筛选
                                    </el-button>
                                </el-form-item>
                            </el-form> -->
              <!-- 按文本筛选 -->
            </div>
            <div class="top">
              <div class="topLeft">
                <el-form :inline="true" @submit.native.prevent>
                  <el-form-item v-if="permissions.is_time_search">
                    <el-date-picker size="mini" v-model="searchTimeForm.date" align="right" type="daterange"
                      range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                      :picker-options="pickerOptions">
                    </el-date-picker>
                  </el-form-item>
                  <template v-if="permissions.is_search">
                    <el-form-item>
                      <el-input placeholder="请输入搜索文本" v-model="searchText" size="mini" clearable
                        @keydown.enter.native="toSearchText">
                      </el-input>
                    </el-form-item>
                    <el-form-item style="margin-right: 0px">
                      <el-button v-if="desc == undefined" size="mini" :style="classObject(form, 'button')"
                        @click="toSearchText">
                        搜索
                      </el-button>
                      <el-tooltip v-else-if="desc.is_desc" class="item" effect="light" :content="desc.content"
                        placement="top">
                        <el-button size="mini" :style="classObject(form, 'button')" @click="toSearchText">
                          搜索
                        </el-button>
                      </el-tooltip>
                    </el-form-item>
                  </template>
                </el-form>
                <el-button size="mini" :class="[is_include_stopped ? '' : 'include_stopped']" @click="onIncludeStopped"
                  v-if="permissions.is_include_stopped"
                  style="position: relative; top: 7px; margin-left: 10px">包括停用</el-button>
                <el-button size="mini" :style="classObject(form, 'button')" @click="exportExcel"
                  v-if="permissions.is_export" style="position: relative; top: 7px; margin-left: 10px">导出</el-button>
                <el-dropdown style="position: relative; top: 7px; margin-left: 10px" size="mini" split-button
                  @click="importExcel" :style="classObject(form, 'button')" v-if="permissions.is_import">
                  导入
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item size="mini" :style="classObject(form, 'button')" v-if="permissions.is_template"
                      style="height: 28px" @click.native="downloadFile">下载模板</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <!-- <el-button size="mini" @click="exportExcel" :style="classObject(form, 'button')"
                                    v-if="permissions.is_export" style="height: 28px;">
                                    导出
                                </el-button> -->
                <!-- <el-button size="mini" :style="classObject(form, 'button')"
                                    v-if="permissions.is_template" style="height: 28px;" @click="downloadFile">下载模板</el-button> -->

                <!-- <el-upload action="#" v-if="permissions.is_import"> -->

                <!-- </el-upload> -->
                <div class="permissions_is_approve" v-if="permissions.is_approve">
                  <div class="is_approve" @click="onApprove" :style="classObject(form, 'button')">
                    审批
                  </div>
                  <el-popover placement="bottom" trigger="hover">
                    <div class="el-popconfirm">
                      <div class="permissions_is_unapprove" @click="onIsUnapproved" :style="classObject(form, 'button')">
                        审核不通过
                      </div>
                    </div>
                    <i slot="reference" style="width: 20px; font-size: 12px; text-align: center"
                      class="el-icon-arrow-down"></i>
                  </el-popover>
                </div>
                <!-- <el-button v-if="permissions.is_approve" size="mini" @click="onApprove">审批</el-button> -->
                <el-button v-if="permissions.is_unapproved" size="mini" @click="onUnapproved"
                  :style="classObject(form, 'button')"
                  style="position: relative; top: 7px; margin-left: 10px">反审批</el-button>
              </div>
              <el-popover v-if="colData1.length != 0 && permissions.is_filter" placement="bottom-start" title="筛选"
                width="800" trigger="click" ref="firstPopover">
                <div @click="closeAll">
                  <div v-for="(item, index) in formInline.siftList">
                    <el-form :inline="true" :model="formInline.siftList[index]" class="demo-form-inline">
                      <el-form-item :label="index == 0 ? '当' : '且'">
                        <el-select v-model="formInline.siftList[index].ziduan">
                          <el-option v-for="items in colData1" :key="items.id" :label="items.title.label"
                            :value="items.title.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item>
                        <el-select v-model="formInline.siftList[index].selectType">
                          <el-option v-for="(items, keys, indexs) in selectSiftList" :key="items" :label="keys"
                            :value="items">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item v-if="formInline.siftList[index].selectType !=
                        'isnull=True' &&
                        formInline.siftList[index].selectType !=
                        'isnull=False'
                        ">
                        <el-input v-model="formInline.siftList[index].text" :placeholder="'请输入'"
                          @keydown.enter.native="sift('true')">
                        </el-input>
                      </el-form-item>
                      <el-form-item>
                        <i class="el-icon-remove-outline" @click="delRow(item)">
                        </i>
                      </el-form-item>
                    </el-form>
                  </div>
                  <div style="display: flex; justify-content: space-between">
                    <el-popover placement="top" width="250" trigger="click" ref="secondPopover">
                      <el-input placeholder="请输入内容" v-model="searchFiled" clearable @input="searchFiledInput">
                      </el-input>
                      <div style="
                          height: 250px;
                          overflow-y: auto;
                          overflow-x: auto;
                        ">
                        <el-row :gutter="20" style="
                            line-height: 30px;
                            margin: 0;
                            padding: 0px 10px 0px 0px;
                          " v-for="(item, index) in searchFiledList" :key="index">
                          <el-col :span="24" @click.native="addSiftList(item)" class="siftCol">
                            <el-button class="btnList" type="text">
                              {{ item.title.label }}
                            </el-button>
                          </el-col>
                        </el-row>
                      </div>
                      <el-button slot="reference" type="text" size="mini" @click.stop="addSift">
                        <i class="el-icon-circle-plus-outline"></i>
                        添加筛选条件
                      </el-button>
                    </el-popover>
                    <div class="sift">
                      <el-button size="mini" @click.stop="clearData">清空值</el-button>
                      <el-button type="success" size="mini" @click.stop="sift('true')"
                        :style="classObject(form, 'button')">筛选</el-button>
                    </div>
                  </div>
                </div>
                <el-button v-if="siftNum != null" slot="reference" size="mini" :style="classObject(form, 'button')"
                  style="position: relative; top: 7px; margin-left: 10px">
                  <i class="el-icon-search"></i>
                  {{ siftNum }}项
                </el-button>
                <el-button v-else slot="reference" size="mini" :style="classObject(form, 'button')"
                  style="position: relative; top: 7px; margin-left: 10px">
                  <i class="el-icon-search"></i>
                  筛选
                </el-button>
              </el-popover>
              <!-- <el-button size="mini" @click="transformCardOrTable('卡片')" :style="classObject(form, 'button')" style="position: relative;top: 7px;margin-left: 10px;">
                                转卡片
                            </el-button> -->

              <el-tooltip effect="light" content="转为卡片" placement="top">
                <i v-if="permissions.convert_card" style="position: relative; top: 8px; margin-left: 10px"
                  @click="transformCardOrTable('卡片')" class="iconCardBox iconfont icon-qiapianxingshi"></i>
              </el-tooltip>
            </div>
          </div>
        </div>


      </div>
      <!-- 表格主体 -->
      <div class="mainTable" v-if="colData1.length != 0">
        <!-- 表格主体 -->
        <el-table :ref="refName" v-if="permissions.is_table_visible" :show-summary="showSummaryNum != 0" :key="itemKey"
          :summary-method="getSummaries" :data="siftServerData" tooltip-effect="light myTool" row-key="serverCode"
          highlight-current-row @selection-change="handleSelectionChange"
          :header-cell-style="classObject(form, 'tableHeader')" :cell-style="classObject(form, 'tableBody')"
          :style="classObject(form, 'outerLayerBorder')" @row-click="rowClick" border :height="tableHeight1"
          @sort-change="changeSort">
          <el-table-column type="selection" width="60" fixed="left" v-if="permissions.is_delete">
          </el-table-column>

          <template v-for="(item, key, index) in colData1">
            <el-table-column v-if="item.isShow"
              :show-overflow-tooltip="siftServerData.length == 0 ? false : Array.isArray(siftServerData[0][item.field]) ? false : true"
              :label="item.currentUserEditTitle == '' ? item.title.label : item.currentUserEditTitle" :prop="item.field"
              :width="(permissions.is_edit || permissions.sign_back || permissions.outbound || permissions.is_money_return) == true ? item.width : colData1.length - 1 == key ? '' : item.width"
              :min-width="item.minWidth == '' ? '150px' : item.minWidth"
              :fixed="item.fixDirection == '' ? false : item.fixDirection" :key="key"
              :sortable="isTableSort ? 'custom' : item.is_ordering ? 'custom' : false"
              :sort-orders="['descending', 'ascending', null]">
              <template slot-scope="scope">
                <span v-if="item.is_onclick" @click.stop="cellClick(scope.row, item)" class="cellClickButton">
                  <span v-if="item.title.label == '关注'">
                    <i v-if="scope.row[item.field]" class="iconfont icon-shixinwujiaoxing"
                      @click.stop="concern(scope.row)"></i>
                    <i v-else class="iconfont icon-kongxinwujiaoxing" @click.stop="concern(scope.row)"></i>
                  </span>
                  <span v-else style="cursor: pointer">
                    <!-- {{ formatt(scope.row[item.field], item) }} -->
                    <!-- {{ Array.isArray(scope.row[item.field]) ? false : true }} -->
                    <span v-if="Array.isArray(scope.row[item.field])">
                      <span v-if="scope.row[item.field].length > 0">
                        <span v-if="item.title.label == '附件'">
                          <template v-for="items in scope.row[item.field]">
                            <div>
                              <el-popover placement="top-start" trigger="hover" :content="items.name">
                                <i slot="reference" @click.stop="onAccessory(items, item)"
                                  class="el-icon-picture-outline popover_picture_outline">
                                </i>
                              </el-popover>
                            </div>
                          </template>
                        </span>
                        <span v-else @click.stop="onAccessory(scope.row[item.field], item)">
                          <i class="iconfont icon-search icon-search_unapprove popover_picture_outline"></i>
                        </span>
                      </span>
                      <span v-else>
                        {{ formatt(scope.row[item.field], item) }}
                      </span>
                    </span>
                    <span v-else-if="item.title.label == '转化明细'">
                      <i class="iconfont icon-search icon-search_unapprove popover_picture_outline"></i>
                    </span>
                    <span v-else>{{
                      formatt(scope.row[item.field], item)
                    }}</span>
                  </span>
                </span>
                <span v-else>
                  <span v-if="item.title.label == '关注'">
                    <i v-if="scope.row[item.field]" class="iconfont icon-shixinwujiaoxing"
                      @click.stop="concern(scope.row)"></i>
                    <i v-else class="iconfont icon-kongxinwujiaoxing" @click.stop="concern(scope.row)"></i>
                  </span>
                  <span v-else style="cursor: pointer">
                    <!-- {{ formatt(scope.row[item.field], item) }} -->
                    <!-- {{ Array.isArray(scope.row[item.field]) ? false : true }} -->
                    <span v-if="Array.isArray(scope.row[item.field])">
                      <span v-if="scope.row[item.field].length > 0">
                        <span v-if="item.title.label == '附件'">
                          <template v-for="items in scope.row[item.field]">
                            <div>
                              <el-popover placement="top-start" trigger="hover" :content="items.name">
                                <i slot="reference" @click.stop="onAccessory(items, item)"
                                  class="el-icon-picture-outline popover_picture_outline">
                                </i>
                              </el-popover>
                            </div>
                          </template>
                        </span>
                        <span v-else @click.stop="onAccessory(scope.row[item.field], item)">
                          <i class="iconfont icon-search icon-search_unapprove popover_picture_outline"></i>
                        </span>
                      </span>
                      <span v-else>
                        {{ formatt(scope.row[item.field], item) }}
                      </span>
                    </span>
                    <span v-else-if="item.title.label == '转化明细'">
                      <i class="iconfont icon-search icon-search_unapprove popover_picture_outline"></i>
                    </span>
                    <span v-else :class="[
                      formatt(scope.row[item.field], item) == '已通过'
                        ? 'status_font_over'
                        : formatt(scope.row[item.field], item) == '已拒绝'
                          ? 'status_font_red'
                          : formatt(scope.row[item.field], item) == '已撤销'
                            ? 'status_font_gray'
                            : '',
                    ]">{{ formatt(scope.row[item.field], item) }}</span>
                  </span>
                </span>
              </template>
            </el-table-column>
          </template>
          <!-- isHaveEditColumn -->
          <!-- :width="isFlagObj.addButton == undefined ? 80 + (permissions.length * 40) : 60 + (permissions.length * 45) + isFlagObj.addButton.length * 55" -->
          <el-table-column label="操作" fixed="right" min-width="120px" v-if="permissions.is_edit ||
            permissions.sign_back ||
            permissions.outbound ||
            permissions.is_money_return
            ">
            <template slot="header" slot-scope="scope">
              <!-- 设置弹出框 -->
              <!-- 超级管理员（有编辑功能的）可以更改样式等 -->
              <p class="iconBox" @click="settting" v-if="permissions.is_edit">
                <el-popover placement="bottom-start" :width="220" trigger="click">
                  <p style="
                      line-height: 30px;
                      display: flex;
                      justify-content: space-around;
                      align-items: center;
                      border-bottom: 1px #eeeeee solid;
                      margin-bottom: 10px;
                    ">
                    <span>拖动调整表头显示顺序</span>
                    <i class="el-icon-s-grid" @click="editTable(scope)"></i>
                    <i class="el-icon-s-tools" @click="settingTable"></i>
                  </p>
                  <div id="switchBox">
                    <ul>
                      <li v-for="(item, index) in list1" :key="item.id">
                        <el-switch v-model="item.isShow"></el-switch>
                        <span>
                          {{
                            item.currentUserEditTitle != ""
                            ? item.currentUserEditTitle
                            : item.title.label
                          }}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div style="margin-top: 10px; text-align: right">
                    <el-button style="border-radius: 10px" @click="submit2" size="mini"
                      :style="classObject(form, 'button')">
                      保存配置
                    </el-button>
                    <el-button style="
                        border-radius: 10px;
                        background-color: #1fddb1;
                        color: white;
                      " size="mini" @click="exportExcel" v-if="permissions.is_export">导出表格
                    </el-button>
                  </div>
                  <i v-if="permissions.is_edit" slot="reference" size="mini" class="el-icon-s-tools"></i>
                </el-popover>
              </p>
              <!-- 无编辑权限显示操作字样 -->
              <span v-else>操作</span>
            </template>

            <template slot-scope="scope">
              <el-button type="text" v-if="permissions.is_edit != undefined && permissions.is_edit" :style="scope.row.is_active == false
                ? 'color:#d0d0d0'
                : 'color:#5F5D5D'
                " :disabled="scope.row.is_active == false" @click.stop="handleEdit(scope.row)"
                style="color: #d0d0d0">编辑
              </el-button>
              <el-button type="text" size="mini"
                v-if="permissions.is_money_return != undefined && permissions.is_money_return"
                @click.stop="buttonClick(scope.row, '回款')" style="font-size: 14px;"
                :disabled="scope.row.return_type == '预付款' ? true : scope.row.is_complete">{{
                  scope.row.is_complete ? '已回款' : '回款'
                }}</el-button>
              <el-button type="text" size="mini" v-if="permissions.sign_back != undefined && permissions.sign_back"
                @click.stop="buttonClick(scope.row, '回签')" style="font-size: 14px">回签</el-button>
              <el-button type="text" size="mini" v-if="permissions.outbound != undefined && permissions.outbound"
                @click.stop="buttonClick(scope.row, '出货')" style="font-size: 14px">出货</el-button>
              <el-button type="text" size="mini"
                v-if="permissions.is_change_history != undefined && permissions.is_change_history"
                @click.stop="buttonClick(scope.row, '变更历史')" style="font-size: 14px">变更历史</el-button>
              <template v-if="isFlagObj.addButton">
                <template v-for="(item, index) in isFlagObj.addButton">
                  <el-button :key="index" v-if="item != '设为默认'" type="text" @click.stop="buttonClick(scope.row, item)">
                    {{ item }}
                  </el-button>
                  <el-button :key="index" v-else-if="scope.row.is_default == false" type="text"
                    @click.stop="changeDefault(scope.row, item)">
                    {{ item }}
                  </el-button>
                </template>
              </template>
              <template v-if="scope.row.is_default">
                <el-button type="text" disabled>默认</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-empty v-else :image-size="200"></el-empty>
      <!-- 分页 -->
      <div class="block" v-if="total && colData1.length != 0 && permissions.is_table_visible">
        <el-pagination @current-change="currentPageChange" :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total" :current-page="currentPage"
          @size-change="handleSizeChange" :page-sizes="[10, 20, 50, 100]">
        </el-pagination>
      </div>
    </el-card>
    <div v-if="!isTable && cardObj != undefined && cardObj.typeName != undefined && cardObj.typeName != '联系人'"
      class="cardBigBox" id="cardBigBox">
      <el-tooltip style="position: fixed; right: 0; top: 15vh" v-if="!$store.state.isphone" effect="light" content="转为表格"
        placement="top">
        <i class="iconfont icon-biaoge" @click="transformCardOrTable('表格')"
          style="margin-bottom: 1vw; font-size: 28px"></i>
      </el-tooltip>
      <!-- <el-button size="mini">转表格</el-button> -->
      <Layout :rows="cardObj.rows" :columns="cardObj.columns" :rowGap="cardObj.rowGap" :columnGap="cardObj.columnGap"
        :borderRadius="cardObj.borderRadius + 'vw'" name="Layout" :mergedCells="cardObj.mergedCells"
        @loadingMore="loadingMore">
        <div v-for="(item, index) in cardList" class="LayoutDiv" :class="[onOrder(item)]" :slot="'Layout' + index"
          :key="item.id" @click="rowClick(item)">
          <div style="
              display: flex;
              flex-direction: column;
              position: relative;
              padding-top: 8vw;
            ">
            <template v-for="(v, i) in tableDataObj.table_header_data">
              <div v-if="v.is_show_in_card">
                <h2 v-if="v.is_card_title" style="
                    width: 100%;
                    box-sizing: border-box;
                    position: absolute;
                    top: 0;
                    left: 0;
                    overflow: hidden;
                  ">
                  {{ item[v.field] }}
                </h2>
                <div class="cardSpan1" :key="v.field" v-if="!v.is_card_title && v.is_show_in_card">
                  <span class="title"> {{ v.title.label }} : </span>
                  <span class="content" :style="v.title.label == '总金额'
                    ? { fontSize: '4vw !important' }
                    : ''
                    ">
                    {{
                      v.title.label == "总金额"
                      ? onAmount(item[v.field])
                      : item[v.field]
                    }}
                  </span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </Layout>
    </div>
    <div v-if="!isTable && cardObj != undefined && cardObj.typeName == '联系人'" class="cardBigBox" id="cardBigBox"
      style="margin-bottom: 30px">
      <p style="
          text-align: right;
          padding: 0vw 0.7vw 0px 0px;
          margin-bottom: 0.5vw;
        ">
        <el-tooltip style="position: relative; right: 0; top: 0" effect="light" content="转为表格" placement="top">
          <i class="iconfont icon-biaoge" @click="transformCardOrTable('表格')"
            style="font-size: 1.5vw; cursor: pointer"></i>
        </el-tooltip>
      </p>
      <Layout :rows="cardObj.rows" :columns="cardObj.columns" :rowsSize="80" :rowGap="cardObj.rowGap"
        :columnGap="cardObj.columnGap" :borderRadius="cardObj.borderRadius + 'vw'" name="Layout"
        :mergedCells="cardObj.mergedCells">
        <div v-for="(item, index) in cardList" class="LayoutDivContact" :slot="'Layout' + index" :key="item.id"
          @click="editContact(item)">
          <div>
            <div style="display: flex;align-items: flex-end;">
              <h2>
                {{
                  item.contact_name == "" || item.contact_name == null
                  ? "无"
                  : item.contact_name
                }}
              </h2>
              <div style="font-size: 1vw">
                <span style="margin: 0 5px;">{{ item.sex }}</span>
                <span>{{ item.duty }}</span>
              </div>
            </div>
            <div class="contactBox" style="position: relative">
              <div class="contactBox-div">
                <a @click.stop :href="'tel:' + item.mobile_phone" style="color:#8fc31f;text-decoration: none;">
                  <p>
                    <span><i class="iconfont icon-31dianhua"> </i> </span>&nbsp;
                    <span style="color:#000;">手机号：</span>
                    <span style="color:#000;">{{ item.mobile_phone }}
                      <!-- <i class="el-icon-phone-outline">
                      </i> -->
                    </span>
                  </p>
                </a>
              </div>
              <div class="contactBox-div">
                <p>
                  <span><i class="iconfont icon-shuju_shangji_jueceren_huaban1">
                    </i> </span>&nbsp;
                  <span>是否关键决策人：</span>
                  <span>{{ item.decision_maker }}</span>
                </p>
                <p>
                  <span><i class="iconfont icon-jiguan"> </i> </span>&nbsp;
                  <span>籍贯：</span>
                  <span>{{ item.native_place }}</span>
                </p>
              </div>
              <div class="contactBox-div">
                <p>
                  <span><i class="iconfont icon-a-44tubiao-102"> </i> </span>&nbsp;
                  <span>年龄：</span>
                  <span>{{ item.age }}</span>
                </p>
              </div>
              <div class="contactBox-div">
                <div class="p" style="display: flex">
                  <span><i class="iconfont icon-tupian"> </i> </span>&nbsp;&nbsp;
                  <span>微信截图：</span>
                  <img style="width: 5vw" :src="$baseurl + item.image_url" alt="" ref="" />
                  <div v-if="item.image_url != null && item.image_url != ''" @click.stop style="margin-left: 10px">
                    <el-popover :ref="'elPopover' + index" placement="bottom" width="600" trigger="click">
                      <div style="
                          width: 100%;
                          height: 100%;
                          max-height: 600px;
                          overflow: auto;
                        ">
                        <p class="openBlankImgCss">
                          <span @click="openBlankImg($baseurl + item.image_url)">
                            <i class="iconfont icon-liulanqidakai"></i>
                          </span>
                          <span @click="concel(index)">
                            <i class="el-icon-close"></i>
                          </span>
                        </p>
                        <img style="width: 100%" :src="$baseurl + item.image_url" alt="" ref="" />
                      </div>
                      <el-button size="mini" slot="reference" type="text" style="padding: 0px">
                        <el-tooltip class="item" effect="dark" content="查看大图" placement="top-start">
                          <i class="el-icon-search" style="font-size: 1.2vw"></i>
                        </el-tooltip>
                      </el-button>
                    </el-popover>
                  </div>
                </div>
              </div>
              <template v-if="item.is_default == true">
                <div style="position: absolute; top: -2.8vw; left: -1.5vw;">
                  <i class="el-icon-circle-check"></i>
                </div>
              </template>
            </div>
          </div>
          <template v-if="isEditContactIndex == item.id">
            <transition name="el-fade-in-linear">
              <div class="editContactBox">
                <div class="editContactBox-div">
                  <el-button size="mini" type="danger" @click.stop="editContactBtn(item, '删除')">删除</el-button>
                  <el-button size="mini" type="primary" @click.stop="editContactBtn(item, '编辑')">编辑</el-button>
                  <el-button size="mini" type="primary" @click.stop="editContactBtn(item, '设为默认')">设为默认</el-button>
                  <el-button size="mini" @click.stop="editContactBtn(item, '取消')">取消</el-button>
                </div>
              </div>
            </transition>
          </template>
        </div>
        <div v-if="isTableSet" @click="pushOA(btnList[0])" class="LayoutDiv1Contact" :slot="'Layout' + cardList.length"
          style="
            text-align: center;
            padding-top: 6vw;
            color: #cccccc;
            box-sizing: border-box;
          ">
          <i class="el-icon-plus"></i>
        </div>
      </Layout>
    </div>
    <el-dialog custom-class="importDialog" :show-close="false" v-if="isImportExcel" :append-to-body="true" :modal="false"
      :visible.sync="isImportExcel" width="30%" v-dialogDrag :fullscreen="isExportExcelFullscreen">
      <div slot="title" style="display: flex;justify-content: space-between;align-items: center;">
        <div class="title">
          <span style="padding: 10px;">导入数据</span>
        </div>
        <div>
          <i v-if="isImportExcelFullscreen" class="iconfont icon-zuidahua "
            style="font-size: 16px; cursor: pointer;margin-right: 10px;"
            @click.stop="onFullscreen('isImportExcelFullscreen')"></i>
          <i v-else class="iconfont icon-chuangkouzuidahua" style="font-size: 16px; cursor: pointer;margin-right: 10px;"
            @click.stop="onFullscreen('isImportExcelFullscreen')"></i>
          <i class="el-icon-close" style="font-size: 16px; cursor: pointer;" @click.stop="onClose('isImportExcel')"></i>
        </div>
      </div>
      <!-- <div slot="title">导入数据</div> -->
      <div style="max-height: 500px">
        <el-upload ref="upload" accept=".xls,.xlsx" action="#" :auto-upload="false" :multiple="false"
          :file-list="fileList" :on-remove="fileRemove" :on-change="fileChange">
          <el-button size="mini" :style="classObject(form, 'button')">选择文件</el-button>
        </el-upload>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isImportExcel = false" size="mini">取消</el-button>
        <el-button @click="importSubmit" size="mini" :style="classObject(form, 'button')">确定
        </el-button>
      </div>
    </el-dialog>
    <!-- 导出表格弹窗 -->
    <el-dialog v-if="isExportExcel" :show-close="false" :visible.sync="isExportExcel" :append-to-body="true"
      :fullscreen="isExportExcelFullscreen" :modal="false" width="1070px" v-dialogDrag>
      <!-- <div slot="title">导出表格数据</div> -->
      <div slot="title" style="display: flex;justify-content: space-between;align-items: center;">
        <div class="title">
          <span style="padding: 10px;">导出表格数据</span>
        </div>
        <div>
          <i v-if="isExportExcelFullscreen" class="iconfont icon-zuidahua "
            style="font-size: 16px; cursor: pointer;margin-right: 10px;"
            @click.stop="onFullscreen('isExportExcelFullscreen')"></i>
          <i v-else class="iconfont icon-chuangkouzuidahua" style="font-size: 16px; cursor: pointer;margin-right: 10px;"
            @click.stop="onFullscreen('isExportExcelFullscreen')"></i>
          <i class="el-icon-close" style="font-size: 16px; cursor: pointer;" @click.stop="onClose('isExportExcel')"></i>
        </div>
      </div>
      <div class="export_dialog">
        <div class="export_dialog_left">
          <el-form :model="exportForm" label-position="top" class="demo-form-inline" label-width="115px">
            <el-form-item label="导出文件名称">
              <el-input size="mini" v-model="exportForm.exportExcelTitle" placeholder="请输入文件名称">
              </el-input>
            </el-form-item>
            <el-form-item label="导出数据数量">
              <el-radio-group v-model="exportForm.radio" @input="exportDataChange">
                <el-radio label="list">根据列表数据</el-radio>
                <el-radio label="selectList">导出选中数据（多选）</el-radio>
                <el-radio label="allList">根据筛选条件导出</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="导出列">
              <div class="exportListBox">
                <template v-for="(item, key, index) in selectHead">
                  <div class="exportList">
                    <el-switch v-model="item.isShow"></el-switch>
                    <span>{{ item.title.label }}</span>
                  </div>
                </template>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="export_dialog_right">
          <p>预览</p>
          <!-- {{ derivedParameter }} -->
          <el-table id="out-table" :data="exportData" tooltip-effect="light myTool" border max-height="530">
            <template v-for="(item, key, index) in selectHead">
              <el-table-column v-if="item.isShow" :show-overflow-tooltip="true" :label="item.currentUserEditTitle == ''
                ? item.title.label
                : item.currentUserEditTitle
                " :prop="item.field" :width="item.width" :min-width="item.minWidth"
                :fixed="item.fixDirection == '' ? false : item.fixDirection" :key="key">
                <template slot-scope="scope">
                  <span>{{ formatt(scope.row[item.field], item) }}</span>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isExportExcel = false" size="mini">取消</el-button>
        <el-button @click="exportSubmit" size="mini" :style="classObject(form, 'button')">确定
        </el-button>
      </div>
    </el-dialog>
    <!-- 设置弹窗 -->
    <el-dialog v-if="dialogTableVisible" :show-close="false" custom-class="elDialog" :append-to-body="true" :modal="false"
      :fullscreen="dialogTableVisibleFullscreen" :visible.sync="dialogTableVisible" v-dialogDrags
      :close-on-click-modal="false">
      <!-- <div slot="title">编辑表格</div> -->
      <div slot="title" style="display: flex;justify-content: space-between;align-items: center;">
        <div class="title">
          <span style="padding: 10px;">编辑表格</span>
        </div>
        <div>
          <i v-if="dialogTableVisibleFullscreen" class="iconfont icon-zuidahua "
            style="font-size: 16px; cursor: pointer;margin-right: 10px;"
            @click.stop="onFullscreen('dialogTableVisibleFullscreen')"></i>
          <i v-else class="iconfont icon-chuangkouzuidahua" style="font-size: 16px; cursor: pointer;margin-right: 10px;"
            @click.stop="onFullscreen('dialogTableVisibleFullscreen')"></i>
          <i class="el-icon-close" style="font-size: 16px; cursor: pointer;"
            @click.stop="onClose('dialogTableVisible')"></i>
        </div>
      </div>
      <el-table width="100%" :data="list1" :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }" row-key="field" height="500">
        <el-table-column property="title.label" label="表头" fixed="left" width="150">
        </el-table-column>
        <el-table-column property="currentUserEditTitle" label="用户表头" width="150">
          <template slot-scope="scope">
            <el-input v-model="scope.row.currentUserEditTitle" :placeholder="scope.row.currentUserEditTitle">
            </el-input>
          </template>
        </el-table-column>
        <el-table-column property="isShow" label="显示" width="150">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.isShow"> </el-switch>
          </template>
        </el-table-column>
        <el-table-column property="aggregation" label="汇总" min-width="180">
          <template slot-scope="scope">
            <el-radio-group v-model="scope.row.aggregation" size="mini">
              <el-radio-button label="">无</el-radio-button>
              <el-radio-button label="sum">累加</el-radio-button>
              <el-radio-button label="count">计数</el-radio-button>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column property="width" label="宽度" width="150">
          <template slot-scope="scope">
            <el-input v-model="scope.row.width"></el-input>
          </template>
        </el-table-column>
        <el-table-column property="minWidth" label="最小宽度" width="150">
          <template slot-scope="scope">
            <el-input v-model="scope.row.minWidth"></el-input>
          </template>
        </el-table-column>
        <el-table-column property="" label="使用当前宽度(值)" width="150">
          <template slot-scope="scope">
            <el-button size="mini" @click="scope.row.width = scope.row.currentWidth">使用({{ scope.row.currentWidth
            }})</el-button>
          </template>
        </el-table-column>
        <el-table-column property="fixDirection" label="固定列" min-width="180">
          <template slot-scope="scope">
            <el-radio-group v-model="scope.row.fixDirection" size="mini">
              <el-radio-button label="">无</el-radio-button>
              <el-radio-button label="left">左侧</el-radio-button>
              <el-radio-button label="right">右侧</el-radio-button>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column property="decimal_places" label="保留几位小数" min-width="180">
          <template slot-scope="scope">
            <el-input v-model="scope.row.decimal_places"></el-input>
          </template>
        </el-table-column>
        <el-table-column property="is_ordering" label="是否排序" min-width="180">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.is_ordering">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column property="is_thousand_separator" label="是否使用千分符" min-width="180">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.is_thousand_separator">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column property="zero_show_symbol" label="0" min-width="180">
          <template slot-scope="scope">
            <el-input v-model="scope.row.zero_show_symbol"></el-input>
          </template>
        </el-table-column>
        <el-table-column property="null_show_symbol" label="null" min-width="180">
          <template slot-scope="scope">
            <el-input v-model="scope.row.null_show_symbol"></el-input>
          </template>
        </el-table-column>

        <el-table-column property="is_show_in_card" label="是否显示在卡片" width="150">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.is_show_in_card"> </el-switch>
          </template>
        </el-table-column>
        <el-table-column property="is_card_title" label="是否为卡片标题" width="150">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.is_card_title"> </el-switch>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" :style="classObject(form, 'button')" @click="dialogTableVisible = false">取消</el-button>
        <el-button size="mini" @click="submit1" :style="classObject(form, 'button')">确定</el-button>
      </div>
    </el-dialog>
    <!-- 设置表格内样式 -->
    <el-dialog v-if="dialogFormVisible" :show-close="false" :visible.sync="dialogFormVisible" width="35%" v-dialogDrags
      :close-on-click-modal="false" :append-to-body="true" :modal="false" :fullscreen="dialogFormVisibleFullscreen">
      <!-- <div slot="title">编辑表格样式</div> -->
      <div slot="title" style="display: flex;justify-content: space-between;align-items: center;">
        <div class="title">
          <span style="padding: 10px;">编辑表格样式</span>
        </div>
        <div>
          <i v-if="dialogFormVisibleFullscreen" class="iconfont icon-zuidahua "
            style="font-size: 16px; cursor: pointer;margin-right: 10px;"
            @click.stop="onFullscreen('dialogFormVisibleFullscreen')"></i>
          <i v-else class="iconfont icon-chuangkouzuidahua" style="font-size: 16px; cursor: pointer;margin-right: 10px;"
            @click.stop="onFullscreen('dialogFormVisibleFullscreen')"></i>
          <i class="el-icon-close" style="font-size: 16px; cursor: pointer;"
            @click.stop="onClose('dialogFormVisible')"></i>
        </div>
      </div>
      <el-form :model="tableForm" label-width="150px">
        <el-form-item label="圆角值">
          <el-input-number v-model="tableForm.borderRadio" :min="1" :max="20" size="mini"></el-input-number>
          <!-- <el-input 
                                v-model="tableForm.borderRadio" 
                                autocomplete="off"
                                clearable
                                >
                            </el-input> -->
        </el-form-item>
        <hr style="margin-bottom: 30px" />
        <el-form-item label="外层表格边框粗细">
          <el-input-number v-model="tableForm.outerLayerBorder.borderThickness" :min="1" :max="20"
            size="mini"></el-input-number>
          <!-- <el-input 
                                v-model="tableForm.outerLayerBorder.borderThickness" 
                                autocomplete="off"
                                clearable
                                >
                            </el-input> -->
        </el-form-item>
        <!-- <el-form-item label="外层表格边框类型">
                            <el-select 
                                v-model="tableForm.outerLayerBorder.borderType" 
                                placeholder="请选择活动区域" 
                                clearable
                                >
                                <el-option 
                                    label="区域一" 
                                    v-for="(item) in tableTypeSearch" 
                                    :label="item.label"
                                    :value="item.value"
                                    >
                                </el-option>
                            </el-select>
                        </el-form-item> -->
        <el-form-item label="外层表格边框颜色">
          <el-color-picker :predefine="predefineColors" show-alpha
            v-model="tableForm.outerLayerBorder.borderColor"></el-color-picker>
        </el-form-item>
        <hr style="margin-bottom: 30px" />
        <el-form-item label="表头字体颜色">
          <el-color-picker :predefine="predefineColors" show-alpha
            v-model="tableForm.tableHeader.color"></el-color-picker>
        </el-form-item>
        <el-form-item label="表头背景颜色">
          <el-color-picker :predefine="predefineColors" show-alpha
            v-model="tableForm.tableHeader.backgroundColor"></el-color-picker>
        </el-form-item>
        <hr style="margin-bottom: 30px" />
        <el-form-item label="表格主体边框粗细">
          <el-input-number v-model="tableForm.tableBody.borderThickness" :min="1" :max="20" size="mini"></el-input-number>
          <!-- <el-input v-model="tableForm.tableBody.borderThickness" autocomplete="off"></el-input> -->
        </el-form-item>
        <!-- <el-form-item label="表格边框类型">
                            <el-select 
                                v-model="tableForm.tableBody.borderType" 
                                placeholder="请选择活动区域" 
                                clearable
                                >
                                <el-option 
                                    label="区域一" 
                                    v-for="(item) in tableTypeSearch" 
                                    :label="item.label"
                                    :value="item.value"
                                    >
                                </el-option>
                            </el-select>
                        </el-form-item> -->
        <el-form-item label="表格主体字体颜色">
          <el-color-picker :predefine="predefineColors" show-alpha v-model="tableForm.tableBody.color"></el-color-picker>
        </el-form-item>
        <el-form-item label="表格边框颜色">
          <el-color-picker :predefine="predefineColors" show-alpha
            v-model="tableForm.tableBody.borderColor"></el-color-picker>
        </el-form-item>
        <el-form-item label="表格主体背景颜色">
          <el-color-picker :predefine="predefineColors" show-alpha
            v-model="tableForm.tableBody.backgroundColor"></el-color-picker>
        </el-form-item>
        <hr style="margin-bottom: 30px" />
        <el-form-item>
          <el-button size="mini" @click="topicDefault('1')" :style="classObject(form, 'button')">预览靛青蓝
          </el-button>
          <el-button size="mini" @click="topicDefault('1', 1)">设定靛青蓝 </el-button>&nbsp;&nbsp;
          <!-- <el-button 
                                size="mini" 
                                @click="topicDefault('2')"
                                :style="classObject(form, 'button')"
                                >预览亮色
                            </el-button>
                            <el-button 
                                size="mini" 
                                @click="topicDefault('2', 1)"
                                >设定亮色
                            </el-button>&nbsp;&nbsp; -->
          <el-button size="mini" @click="topicDefault('3')" :style="classObject(form, 'button')">预览简易主题
          </el-button>
          <el-button size="mini" @click="topicDefault('3', 1)">设定简易主题
          </el-button>
        </el-form-item>
        <hr style="margin-bottom: 30px" />
        <el-form-item label="按钮背景颜色">
          <el-color-picker :predefine="predefineColors" show-alpha
            v-model="tableForm.button.backgroundColor"></el-color-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogFormVisible = false">取 消
        </el-button>
        <el-button size="mini" type="primary" @click="editTableSubmit" :style="classObject(form, 'button')">确定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog v-if="isAccessory" :show-close="false" :visible.sync="isAccessory" :fullscreen="isAccessoryFullscreen"
      v-dialogDrags :append-to-body="true" :modal="false" width="50%">
      <div slot="title" style="display: flex;justify-content: space-between;align-items: center;">
        <div class="title">
          <span style="padding: 10px;">{{ accessoryObj.title.label }}</span>
        </div>
        <div>
          <i v-if="isAccessoryFullscreen" class="iconfont icon-zuidahua "
            style="font-size: 16px; cursor: pointer;margin-right: 10px;"
            @click.stop="onFullscreen('isAccessoryFullscreen')"></i>
          <i v-else class="iconfont icon-chuangkouzuidahua" style="font-size: 16px; cursor: pointer;margin-right: 10px;"
            @click.stop="onFullscreen('isAccessoryFullscreen')"></i>
          <i class="el-icon-close" style="font-size: 16px; cursor: pointer;" @click.stop="onClose('isAccessory')"></i>
        </div>
      </div>
      <!-- <span slot="title">{{ accessoryObj.title.label }}</span> -->
      <div v-if="accessoryObj.title.label === '账信详情'">
        <template v-for="(item, i) in accessoryArr">
          <div :key="item" style="display: flex; flex-wrap: wrap">
            <div class="old_accessory">
              <div style="width: 100%; font-size: 20px">旧</div>
              <div class="new_accessory_item">
                <div class="new_accessory_item_lable">客户名称:</div>
                <div class="new_accessory_item_value">
                  {{ item.company_name.company_name }}
                </div>
              </div>
              <div class="new_accessory_item">
                <div class="new_accessory_item_lable">账信规则:</div>
                <div class="new_accessory_item_value">
                  {{ item.company_name.credit_rule }}
                </div>
              </div>
              <div class="new_accessory_item">
                <div class="new_accessory_item_lable">账期:</div>
                <div class="new_accessory_item_value">
                  {{ item.company_name.payment_days }}
                </div>
              </div>
              <div class="new_accessory_item">
                <div class="new_accessory_item_lable">额度:</div>
                <div class="new_accessory_item_value">
                  {{ item.company_name.credit_limit }}
                </div>
              </div>
              <div class="new_accessory_item">
                <div class="new_accessory_item_lable">对账日:</div>
                <div class="new_accessory_item_value">
                  {{ item.company_name.account_check_date }}
                </div>
              </div>
              <div class="new_accessory_item">
                <div class="new_accessory_item_lable">对账区间:</div>
                <div class="new_accessory_item_value">
                  {{ item.company_name.account_check_date_range.join("至") }}号
                </div>
              </div>
              <div class="new_accessory_item">
                <div class="new_accessory_item_lable">开票日:</div>
                <div class="new_accessory_item_value">
                  {{ item.company_name.invoice_data }}
                </div>
              </div>
              <div class="new_accessory_item">
                <div class="new_accessory_item_lable">付款日:</div>
                <div class="new_accessory_item_value">
                  {{ item.company_name.payment_date }}
                </div>
              </div>
            </div>
            <div class="new_accessory">
              <div style="width: 100%; font-size: 20px">新</div>
              <div class="new_accessory_item">
                <div class="new_accessory_item_lable">客户名称:</div>
                <div class="new_accessory_item_value">
                  {{ item.company_name.company_name }}
                </div>
              </div>
              <div class="new_accessory_item">
                <div class="new_accessory_item_lable">账信规则:</div>
                <div class="new_accessory_item_value">
                  {{
                    item.credit_rule == 1
                    ? "款到发货"
                    : item.credit_rule == 2
                      ? "账期"
                      : "额度"
                  }}
                </div>
              </div>
              <div class="new_accessory_item">
                <div class="new_accessory_item_lable">账期:</div>
                <div class="new_accessory_item_value">
                  {{ item.payment_days }}
                </div>
              </div>
              <div class="new_accessory_item">
                <div class="new_accessory_item_lable">额度:</div>
                <div class="new_accessory_item_value">
                  {{ item.credit_limit }}
                </div>
              </div>
              <div class="new_accessory_item">
                <div class="new_accessory_item_lable">对账日:</div>
                <div class="new_accessory_item_value">
                  {{ item.account_check_date }}
                </div>
              </div>
              <div class="new_accessory_item">
                <div class="new_accessory_item_lable">对账区间:</div>
                <div class="new_accessory_item_value">
                  {{ item.account_check_date_range.join("至") }}号
                </div>
              </div>
              <div class="new_accessory_item">
                <div class="new_accessory_item_lable">开票日:</div>
                <div class="new_accessory_item_value">
                  {{ item.invoice_data }}
                </div>
              </div>
              <div class="new_accessory_item">
                <div class="new_accessory_item_lable">付款日:</div>
                <div class="new_accessory_item_value">
                  {{ item.payment_date }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-else-if="accessoryObj.title.label === '回签文件' || accessoryObj.title.label === '文件'">
        <!-- {{ accessoryArr }} -->
        <div v-if="accessoryArr.length > 0">
          <template v-for="(item, i) in accessoryArr">
            <div :key="i">
              <a style=" text-decoration: none;color: #97a1a3;" :href="'https://back.hugtech.cc' + item.file"
                target="_blank" rel="noopener noreferrer">{{ item.name
                }}</a>
            </div>
          </template>
        </div>
        <div v-else>暂无数据</div>
      </div>
      <div v-else-if="accessoryObj.title.label === '图片' || accessoryObj.title.label === '门头照片'">
        <div>
          <el-carousel :autoplay="false" trigger="click" @change="onCarousel" :initial-index="carouselInitialIndex"
            ref="setCarouselInitialIndexRef">
            <el-carousel-item v-for="( item, i ) in  accessoryArr " :key="i" :name="i" style="width:100%">
              <!-- <h3 class="small">{{ item }}</h3> -->
              <el-image ref="previewImg" :fit="'contain'" @click="checkImage(accessoryArr)"
                :src="'https://back.hugtech.cc' + item.url" :preview-src-list="previewSrcList(accessoryArr)"></el-image>
            </el-carousel-item>
            <el-image style=" opacity: 0;" :fit="'contain'"
              :src="'https://back.hugtech.cc' + accessoryArr[carouselInitialIndex].url"
              :preview-src-list="previewSrcList(accessoryArr)"></el-image>
          </el-carousel>
          <div>
            <el-popover placement="top-start" trigger="hover" class="accessory_popover">
              <div>
                <template v-for="( item, i ) in  accessoryArr ">
                  <div :key="i" @click="onSetCarouselInitialIndex(i)" class="accessory_popover_item">
                    {{ item.name }}
                  </div>
                </template>
              </div>
              <el-button slot="reference" icon="icon-category iconfont" type="text"></el-button>
            </el-popover>
            <el-button style="font-size: 20px;" icon="el-icon-zoom-in" type="text"
              @click="onPreviewPic(carouselInitialIndex)"></el-button>
          </div>
          <!-- <div v-if="accessoryArr.length > 0">
            <template v-for="(item, i) in accessoryArr">
              <div :key="i">
                <el-image :src="'https://back.hugtech.cc' + item.url" :initial-index="i"
                  :preview-src-list="previewSrcList(accessoryArr)"></el-image>
                <a style=" text-decoration: none;color: #97a1a3;" :href="'https://back.hugtech.cc' + item.url"
                  target="_blank" rel="noopener noreferrer">{{ item.name
                  }}</a>
              </div>
            </template>
          </div>
          <div v-else>暂无数据</div> -->
        </div>
      </div>
      <div v-else> {{ accessoryArr }}</div>
      <span slot="footer" class="dialog-footer" v-if="isAccessory">
        <el-button size="mini" @click="isAccessory = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="isAccessory = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { debounce } from "@/utils/AntiShake";
import Sortable from "sortablejs";
import htmlToExcel from "./htmlToExcel";
import Layout from "@/components/Layout/GridLayout.vue";
import {
  selectBatchDelete,
  saveTableStyle,
} from "@/api/deleteApi/selectBatchDelete";
export default {
  components: {
    Layout,
  },
  props: [
    "tableDataObj",
    "total",
    "createDate",
    "endDate",
    "isFlagObj",
    "importUrl",
    "desc",
    "tableTitle",
    "cardObj",
    "isEditContact",
    "page",
    "per_page",
    "model_name",
    "refName",
    "cardList",
    "isIncludeStopped",
    "isTableSet",
    "isSort",
    "derivedParameter",
  ],
  data() {
    return {
      wrapperElem: null,
      isAccessoryFullscreen: false,
      dialogFormVisibleFullscreen: false,
      dialogTableVisibleFullscreen: false,
      isExportExcelFullscreen: false,
      isImportExcelFullscreen: false,
      carouselInitialIndex: 0,
      isTableSort: this.isSort == undefined ? true : this.isSort,
      isAccessory: false,
      accessoryObj: null,
      accessoryArr: null,
      is_include_stopped: this.isIncludeStopped == undefined ? null : this.isIncludeStopped,
      pageSize: 10, //默认每页显示十条数据
      currentPage: 1,
      predefineColors: [
        //颜色预选
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585",
        "rgba(255, 69, 0, 0.68)",
        "rgb(255, 120, 0)",
        "hsv(51, 100, 98)",
        "hsva(120, 40, 94, 0.5)",
        "hsl(181, 100%, 37%)",
        "hsla(209, 100%, 56%, 0.73)",
        "#c7158577",
      ],
      searchTimeForm: {
        //按时间查询
        startTime: "",
        endTime: "",
        date: [],
      },
      searchText: "", //按文本查询
      selectData: [], //表格选中的数据
      exportData: [], //导出的（预览的表格数据）
      selectHead: [],
      isExportExcel: false, //导出弹窗开关
      exportForm: {
        exportExcelTitle: "", //自定义导出excel的文件名
        radio: "list", //自定义到处数量
        checkboxGroup: [], //导出选中的字段
      },
      fixedPosition: null, //固定列位置
      siftText: "", //筛选字段值
      // siftList: [],                                            //选择筛选的字段
      formInline: {
        siftList: [], //筛选的字段列表
        ziduan: "", //字段
        include: "", //判断条件
        text: "", //输入的判断值
      },
      dialogTableVisible: false, //设置弹窗的开关
      dialogFormVisible: false, //修改表格样式弹窗开关
      form: {},
      tableForm: {}, //保存修改表格样式的数据
      serverData1: [], //传递过来的表格数据
      siftServerData: [], //筛选之后得到的数据
      colData1: [],
      rowColData1: [],
      // 多选框的列表，列出表格的每一列
      // checkBoxGroup: [],
      // 当前选中的多选框，代表当前展示的列
      // checkedColumns: [],
      list: [],
      list1: [],
      showSummaryNum: 0, //有几列有合并功能
      itemKey: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "前天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 2);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      addSiftFlag: false,
      searchFiled: "", //筛选字段输入框绑定值
      searchFiledList: [], //筛选时迷糊搜索表头字段
      isDelDialog: false,
      tableHeight: null,
      tableHeight1: null,
      permissions: {},
      selectSiftList: {
        等于: "iexact",
        不等于: "!iexact",
        包含: "icontains",
        不包含: "!icontains",
        为空: "isnull=True",
        不为空: "isnull=False",
      },
      fileList: [], //导入文件列表
      isImportExcel: false,
      uploadDisabled: false,
      siftNum: null, //筛选项计数
      isHaveTop: true,
      isTable: true, //是否是表格形式
      btnList: [],
      isEditContactIndex: null,
      isHaveEditColumn: false,
      user: localStorage.getItem("user_id"),
    };
  },
  methods: {
    onCarousel(carouselInitialIndex) {
      // console.log(carouselInitialIndex)
      this.carouselInitialIndex = carouselInitialIndex
    },
    onPreviewPic(index) {
      // console.log(index, this.$refs.previewImg);
      // return
      this.$refs.previewImg[index].showViewer = true
      this.checkImage(this.accessoryArr)
    },
    checkImage(val) {//这个事件要绑定el-image父级盒子上
      // console.log('点击事件');
      this.$nextTick(() => {
        let wrapper = document.getElementsByClassName('el-image-viewer__actions__inner');
        let downImg = document.createElement('i');
        downImg.setAttribute('class', 'el-icon-download');
        wrapper[0].appendChild(downImg);
        if (wrapper.length > 0) {
          this.wrapperElem = wrapper[0];
          let arr = JSON.parse(JSON.stringify(val))
          this.cusClickHandler(arr);
        }
      });
    },
    cusClickHandler(val) {
      let arr = val
      console.log(arr);
      this.wrapperElem.addEventListener('click', () => {
        const imgUrl = document.getElementsByClassName('el-image-viewer__img')[0].src;
        const ArrCurImg = arr.filter(item => {
          return 'https://back.hugtech.cc' + item.url === imgUrl;
        });
        // console.log(arr, imgUrl, ArrCurImg);
        return
        // downLoadRemoteFile(imgUrl, ArrCurImg[0].fileName);
      });
    },
    onClose(val) {
      this[val] = false
    },
    onFullscreen(val) {
      this[val] = !this[val]
    },
    onSetCarouselInitialIndex(index) {
      this.$refs.setCarouselInitialIndexRef.setActiveItem(index)
    },
    previewSrcList(val) {
      let arr = []
      val.forEach(item => {
        arr.push('https://back.hugtech.cc' + item.url)
      })
      return arr
    },
    onAmount(item) {
      return (item * 1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    },
    throttle(func, delay) {
      let lastCall = 0;
      return function (...args) {
        const now = new Date().getTime();
        if (now - lastCall < delay) {
          return;
        }
        lastCall = now;
        func.apply(this, args);
      };
    },
    addListen() {
      if (this.isTable) {
        return;
      }
      // console.log(this.cardObj);
      if (this.cardObj === undefined) {
        this.isTable = true
        return;
      }
      if (this.cardObj.typeName === undefined) {
        return;
      }
      const that = this;
      var targetDiv;
      // 获取要触底加载的div元素
      if (this.cardObj.typeName == "商务文档") {
        targetDiv = document.getElementById("cardBigBox1");
      } else {
        targetDiv = document.getElementById("cardBigBox");
      }
      // console.log(targetDiv, this.isTable);
      // 给div元素添加滚动事件监听器
      targetDiv.addEventListener(
        "scroll",
        debounce(function () {
          console.log(
            targetDiv.scrollTop + targetDiv.clientHeight >=
            targetDiv.scrollHeigh - 5
          );
          if (
            targetDiv.scrollTop + targetDiv.clientHeight >=
            targetDiv.scrollHeight - 5
          ) {
            // 到达底部，执行加载操作
            // Your code to load more content goes here
            // console.log('到达底部，可以执行加载操作了！');
            that.loadingMore();
            // that.loadingMore();
          }
        }, 500)
      );
    },
    loadingMore(val) {
      this.currentPage += 1;
      this.$emit("getCurrentPage", this.currentPage, "触底加载");
      console.log(this.currentPage);
    },
    changeSort({ column, prop, order }) {
      if (
        [
          "客户列表",
          "回款单",
          "销售订单查询",
          "长期订单查询",
          "销售报价单查询",
          "销售送样单查询",
          "货币类型",
          "刀具损耗管理",
          "采购入库查询",
          "生产领料查询",
          "仓库列表",
          "跟进记录",
          "拜访签到",
          "销售出库单查询",
          "供应商列表",
          "供应商分配",
          "费用代码管理",
          "单价管理",
        ].includes(this.tableTitle)
      ) {
        this.$emit("changeSort", column, prop, order);
      } else {
        // console.log(column, prop, order);
        if (order == "descending") {
          console.log("-" + prop);
          this.$emit("onChangeSort", "-" + prop);
        } else if (order == "ascending") {
          this.$emit("onChangeSort", prop);
        } else {
          this.$emit("onChangeSort", null);
        }
      }
    },
    enable() {
      console.log(this.selectData);
      let arr = [];
      this.selectData.forEach((item, index) => {
        let obj = {
          id: item.id,
          is_invalid: true,
        };
        arr.push(obj);
      });
      this.$emit("enable", arr);
    },
    Deactivated() {
      let arr = [];
      this.selectData.forEach((item, index) => {
        let obj = {
          id: item.id,
          is_invalid: false,
        };
        arr.push(obj);
      });
      this.$emit("Deactivated", arr);
    },
    concel(index) {
      // console.log(index,this.$refs['elPopover'+index]);
      this.$refs["elPopover" + index][0].doClose();
    },
    getTrueOrFalse() {
      let num = 0;
      this.tableDataObj.permissions.forEach((item, index) => {
        if (item.title == "出货" || item.title == "回签") {
          num++;
        }
      });
      if (
        this.isFlagObj.addButton != undefined &&
        this.isFlagObj.addButton.length > 0
      ) {
        num++;
      }
      // console.log(num);
      if (num > 0) {
        this.isHaveEditColumn = true;
      } else {
        this.isHaveEditColumn = false;
      }
      // console.log(this.tableDataObj,this.isFlagObj.addButton);
    },
    editContactBtn(item, val) {
      // console.log(val);
      if (val == "取消") {
        this.isEditContactIndex = null;
        // console.log(this.isEditContactIndex);
      } else if (val == "编辑") {
        this.handleEdit(item);
        this.isEditContactIndex = null;
      } else if (val == "查看") {
        console.log(item);
        let obj = {
          row: {
            OA_form: item.OA_form,
          },
          item: {
            title: { label: "关联OA单" },
          },
        };
        this.$emit("cellClick", obj);
        this.isEditContactIndex = null;
      } else if (val == "设为默认") {
        this.changeDefault(item, val);
        this.isEditContactIndex = null;
      } else if (val == "删除") {
        this.$confirm("确定删除该数据吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          customClass: "winClass",
        })
          .then(async () => {
            let arr = [];
            arr.push(item.id);
            this.$emit("batchDelete", arr);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    editContact(item) {
      this.isEditContactIndex = item.id;
      if (this.$store.state.isphone) {
        setTimeout(() => {
          let allText4 = document.querySelectorAll(
            ".editContactBox .el-button"
          );
          console.log(allText4);
          allText4.forEach((item, index) => {
            item.style.fontSize = "2.1vw";
            item.style.height = "3.2vw";
            item.style.lineHeight = "1.2vw";
          });
        }, 10);
      }
    },
    openBlankImg(imgUrl) {
      window.open(imgUrl, "_blank");
    },
    pushOA(item) {
      // console.log(item);
      this.$emit("onPushOA", item);
    },
    async getOaMenu() {
      this.isTable = false;
      const res = await this.$http({
        method: "GET",
        url: "oa/oa_menu_list/",
        params: {
          keyword: "联系人",
        },
      });
      this.btnList = res.data;
    },
    rowsDispose(num) {
      if (num % 2 == 0) {
        return num + 2;
      } else {
        return num + 1;
      }
    },
    rowsDispose1(num) {
      if (num % 4 == 0) {
        return num / 4 + 2;
      } else {
        return num / 4 + 1;
      }
    },
    customSort(key) {
      // console.log(key,a,b);
      return (a, b) => {
        let valueA = null;
        let valueB = null;
        if (typeof a[key] == "number" && typeof b[key] == "number") {
          valueA = a[key];
          valueB = b[key];
          // console.log(valueA,valueB);
          return valueA > valueB ? 1 : -1;
        } else {
          valueA = a[key] === null ? "" : a[key].toString();
          valueB = b[key] === null ? "" : b[key].toString();

          return valueA.localeCompare(valueB);
        }

        // if(Number(valueA)=='number'){

        // }else{

        // }
        // return a[key].localeCompare(b[key]);
        // return a[key] > b[key] ? 1 : -1;
      };
      // return a.company_name.localeCompare(b.company_name); // 使用字符串的 localeCompare 方法按字母顺序进行排序
    },
    // 卡片表格互换
    transformCardOrTable(val) {
      console.log(this.cardObj);
      if (val == "表格") {
        this.isTable = true;
      } else {
        this.isTable = false;
        setTimeout(() => {
          this.addListen();
        }, 1000);
      }
    },
    // useCurrentWidth(width,currentWidth){
    // width=currentWidth
    // },
    // 设为默认按钮
    async changeDefault(data, type) {
      this.$emit("changeDefault", data, type);
    },
    // 操作列按钮点击事件
    buttonClick(data, type) {
      this.$emit("buttonClick", data, type);
    },
    //作废
    onVoid() {
      // console.log("作废");
      // console.log(this.selectData);
      if (this.selectData.length != 0) {
        this.$emit("onVoid", this.selectData);
      } else {
        this.$message.error("请选择数据");
      }
    },
    // 审批//不同意
    onIsUnapproved() {
      // console.log("不同意");
      // console.log(this.selectData);
      if (this.selectData.length != 0) {
        this.$emit("onIsUnapproved", this.selectData);
      } else {
        this.$message.error("请选择数据");
      }
    },
    // 审批//同意
    onApprove() {
      // console.log("同意");
      if (this.selectData.length != 0) {
        this.$emit("onApprove", this.selectData);
      } else {
        this.$message.error("请选择数据");
      }
    },
    // 反审批
    onUnapproved() {
      // console.log("反审批");
      if (this.selectData.length != 0) {
        this.$emit("onUnapproved", this.selectData);
      } else {
        this.$message.error("请选择数据");
      }
    },
    // 下载模板
    async downloadFile() {
      this.$emit("downLoadFile");
      // console.log(11111);
    },
    async importExcel() {
      this.isImportExcel = true;
    },
    // 自定义上传方法，param是默认参数，可以取得file文件信息，具体信息如下图
    async importSubmit(param) {
      // console.log(this.fileList);
      const formData = new FormData(); // FormData对象，添加参数只能通过append('key', value)的形式添加
      formData.append("file_path", this.fileList[0].raw); // 添加文件对象
      // this.$emit("importExcel",formData)
      // console.log(formData);
      this.isImportExcel = false;
      // 上传地址
      const res = await this.$http({
        method: "POST",
        url: this.importUrl,
        headers: {
          "Content-Type": "multipart/form-data;",
        },
        data: formData,
      });
      if (res.status === 201) {
        param.onError();
        return this.$message.error(res.data);
      } else if (res.status === 202) {
        param.onError();
        return this.$message.error(res.data);
      } else {
        return this.$notify.success({
          title: "提示",
          message: "文件上传成功",
          duration: 0,
          offset: 100,
        });
      }
    },
    // 文件发生改变
    fileChange(file, fileList) {
      this.loading = false;
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; // 展示最后一次选择的文件
      }
    },
    // 移除选择的文件
    fileRemove(file, fileList) {
      if (fileList.length < 1) {
        this.uploadDisabled = true; // 未选择文件则禁用上传按钮
      }
    },
    // 取消
    closeDialog() {
      this.$refs.upload.clearFiles(); // 清除上传文件对象
      this.fileList = []; // 清空选择的文件列表
      // this.$emit('close', false)
    },
    // 表格每行点击事件
    rowClick(row, column, event) {
      console.log(row);
      this.$emit("rowClick", row);
    },
    // 模糊查询筛选字段
    searchFiledInput() {
      let arr = [];
      this.colData1.forEach((item, index) => {
        if (item.title.label.includes(this.searchFiled)) {
          arr.push(item);
        }
      });
      this.searchFiledList = arr;
    },
    // 嵌套的el-popover窗口关闭
    closeAll() {
      this.$refs.secondPopover.doClose();
    },
    // 根据文本搜索
    toSearchText() {
      this.currentPage = 1;
      // this.pageSize = 10; 
      // console.log(this.searchText, this.searchTimeForm.date, this.is_include_stopped);
      // console.log(this.permissions.is_search);
      // console.log(this.permissions.is_time_search);
      // console.log(this.permissions.is_filter);
      let str = "";
      if (this.formInline.siftList.length == 0) {
        this.siftNum = null;
      } else {
        this.siftNum = this.formInline.siftList.length;
        this.formInline.siftList.forEach((item, index) => {
          if (index == this.formInline.siftList.length - 1) {
            if (
              item.selectType == "isnull=True" ||
              item.selectType == "isnull=False"
            ) {
              str += item.ziduan + "_-_" + item.selectType;
            } else {
              if (item.text != "") {
                str += item.ziduan + "_-_" + item.selectType + "=" + item.text;
              }
            }
          } else {
            if (
              item.selectType == "isnull=True" ||
              item.selectType == "isnull=False"
            ) {
              str += item.ziduan + "_-_" + item.selectType + "&";
            } else {
              if (item.text != "") {
                str +=
                  item.ziduan + "_-_" + item.selectType + "=" + item.text + "&";
              }
            }
          }
        });
      }
      // let obj = {
      //   search: this.searchText,
      //   time: this.searchTimeForm.date,
      //   is_using: this.is_include_stopped
      // }
      console.log(str);
      this.$emit("toSearch", this.searchText, this.searchTimeForm.date, this.is_include_stopped);
      // this.$emit("toSearch", obj);
    },
    // 每页显示数量改变
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log(val);
      if (val > 10) {
        this.tableHeight1 = "68vh";
      } else {
        this.tableHeight1 = "";
      }
      this.$emit("getCurrentPageSize", val);
    },
    // 当前页码改变
    currentPageChange(val) {
      this.currentPage = val;
      this.$emit("getCurrentPage", val);
    },
    async concern(item) {
      this.$emit("concern", item);
    },
    Thousands(item, obj) {
      if (item == 0) {
        if (obj.zero_show_symbol == null || obj.zero_show_symbol == "") {
          return 0;
        } else {
          return obj.zero_show_symbol;
        }
      } else {
        if (obj.is_thousand_separator) {
          // return '前期'
          if (obj.decimal_places == 0) {
            return (item * 1).toLocaleString();
          } else {
            return (item * 1)
              .toFixed(obj.decimal_places)
              .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
          }
        } else {
          if (obj.decimal_places == 0) {
            return item;
          } else {
            return (item * 1).toFixed(obj.decimal_places);
          }
        }
      }
    },
    onAccessory(val, obj) {
      if (obj.title.label == "附件") {
        console.log(val);
        // return
        // 获取图片地址
        const imageUrl = this.$baseurl + val.file;
        // 创建一个<a>标签元素
        const linkElement = document.createElement("a");
        linkElement.href = imageUrl;
        linkElement.target = "_blank";
        linkElement.style.display = "none";
        document.body.appendChild(linkElement);
        linkElement.click();
      } else {
        this.accessoryObj = obj;
        this.accessoryArr = val;
        this.isAccessory = true;
        // console.log(
        //   this.cardObj,
        //   this.isAccessory,
        //   this.accessoryObj,
        //   this.accessoryArr,
        //   "555555"
        // );
      }
    },
    // 判断字段数据类型
    formatt(item, obj) {
      if (typeof item == "boolean") {
        if (obj.title.label == "关注") {
          return;
        } else {
          if (item == false) {
            return "否";
          } else {
            return "是";
          }
        }
      } else if (Object.prototype.toString.call(item) == "[object Null]") {
        return obj.null_show_symbol;
      } else if (Object.prototype.toString.call(item) == "[object Object]") {
        let str = "";
        for (const key in item) {
          str = str + item[key] + ",";
        }
        return str;
      } else if (item === "") {
        if (obj.null_show_symbol === null || obj.null_show_symbol === "") {
          return "-";
        } else {
          return obj.null_show_symbol;
        }
      } else {
        if (!isNaN(item)) {
          if (
            obj.title.label == "特殊账期" ||
            obj.title.label == "账期" ||
            obj.title.label == "收货人电话"
          ) {
            return item;
          } else {
            return this.Thousands(item, obj);
          }
        } else {
          return item;
        }
      }
    },
    // 根据时间筛选
    siftTimeData() {
      // console.log(this.searchTimeForm);
      this.$emit("siftObj", this.searchTimeForm);
    },
    // 重置时间筛选
    reset() {
      this.searchTimeForm = {
        startTime: "",
        endTime: "",
      };
    },
    // 取消批量删除
    // cancelDelete() {
    //     this.isDelDialog = false
    //     this.selectData = [];
    // },
    createReceipts() {
      let arr = [];
      this.selectData.forEach((item, index) => {
        arr.push(item.id);
      });
      console.log("onCreateReceipts", arr);
      this.$emit("onCreateReceipts", arr);
    },
    // 批量删除
    batchDelete() {
      this.$confirm("确定要删除所选数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "winClass",
      })
        .then(async () => {
          // console.log(this.selectData);
          let arr = [];
          this.selectData.forEach((item, index) => {
            arr.push(item.id);
          });
          // console.log(arr);
          this.$emit("batchDelete", arr);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    batchRecycle() {
      this.$confirm("确定要回收所选数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "winClass",
      })
        .then(async () => {
          // console.log(this.selectData);
          let arr = [];
          this.selectData.forEach((item, index) => {
            arr.push(item.id);
          });
          // console.log(arr);
          this.$emit("batchRecycle", arr);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 设置列的按钮点击事件
    // 编辑行数据
    handleEdit(row) {
      this.$emit("editRow", row);
    },
    // 根据按钮颜色深浅动态改变按钮的字体颜色
    changeBtnTextColor() {
      let color = this.form.button.backgroundColor;
      let arr = color
        .replace("rgba(", "")
        .replace(")", "")
        .split(",")
        .map(Number);
      arr.pop();
      let $grayLevel = arr[0] * 0.299 + arr[1] * 0.587 + arr[2] * 0.114;
      if ($grayLevel > 191) {
        this.form.button.color = "black";
      } else {
        this.form.button.color = "white";
      }
    },
    // 默认主题色
    topicDefault(index, isSubmit) {
      this.dialogFormVisible = false;
      if (index == "1") {
        this.form = {
          borderRadio: "8",
          outerLayerBorder: {
            borderThickness: "1",
            borderType: "groove",
            borderColor: "rgba(0,94,97,1)",
          },
          tableHeader: {
            color: "rgba(74, 199, 208, 1)",
            backgroundColor: "rgba(3,52,71,1)",
          },
          tableBody: {
            color: "rgba(122, 233, 241, 1)",
            borderThickness: "1",
            borderType: "groove",
            borderColor: "rgba(18,58,81,1)",
            backgroundColor: "rgba(39,66,82,1)",
          },
          button: {
            backgroundColor: "rgba(39,66,82,1)",
            color: "rgba(122, 233, 241, 1)",
          },
        };
        // 判断rgba颜色深浅动态改变字体颜色
        this.changeBtnTextColor();
        // 保存设置到后端
        if (isSubmit) {
          this.tableForm = JSON.parse(JSON.stringify(this.form));
          this.submit1();
        }
      }
      // if (index == "2") {
      //     this.form = {
      //         borderRadio: "8",
      //         outerLayerBorder: {
      //             borderThickness: "1",
      //             borderType: "solid",
      //             borderColor: "rgba(244, 223, 190, 0.66)"
      //         },
      //         tableHeader: {
      //             color: 'rgba(174, 179, 176, 0.71)',
      //             backgroundColor: "rgba(255, 192, 203, 0.77)"
      //         },
      //         tableBody: {
      //             color: 'rgba(117, 119, 114, 0.8)',
      //             borderThickness: "1",
      //             borderType: "solid",
      //             borderColor: "rgba(193, 187, 185, 0.78)",
      //             backgroundColor: "rgba(249, 201, 201, 0.28)"
      //         },
      //         button: {
      //             backgroundColor: "rgba(249, 201, 201, 0.28)",
      //             color:''
      //         }
      //     }
      //     this.changeBtnTextColor();
      //     // 保存设置到后端
      //     if (isSubmit) {
      //         this.tableForm = JSON.parse(JSON.stringify(this.form));
      //        this.submit1()
      //     }
      // }
      if (index == "3") {
        this.form = {
          borderRadio: "8",
          outerLayerBorder: {
            borderThickness: "1",
            borderType: "solid",
            borderColor: "rgba(220, 223, 230, 1)",
          },
          tableHeader: {
            color: "rgba(151, 161, 163, 1)",
            backgroundColor: "rgba(244,246,250,1)",
          },
          tableBody: {
            color: "rgba(95, 93, 93, 1)",
            borderThickness: "1",
            borderType: "solid",
            borderColor: "rgba(220, 223, 230, 1)",
            backgroundColor: "rgba(255,255,255,0)",
          },
          button: {
            backgroundColor: "rgba(255,255,255,0)",
            color: "",
          },
        };
        this.changeBtnTextColor();
        // 保存设置到后端
        if (isSubmit) {
          this.tableForm = JSON.parse(JSON.stringify(this.form));
          this.submit1();
        }
      }
    },
    // 设置表格样式弹窗确定按钮
    editTableSubmit() {
      this.dialogFormVisible = false;
      this.form = JSON.parse(JSON.stringify(this.tableForm));
      this.submit1();
      this.changeBtnTextColor();
    },
    // 设置表格样式按钮被点击
    settingTable() {
      this.dialogFormVisible = true;
    },
    // 获取后台数据，处理
    classObject(styleObj, position) {
      if (position == "tableHeader") {
        return {
          color: styleObj.tableHeader.color,
          height: "15px",
          border:
            styleObj.tableBody.borderThickness +
            "px " +
            styleObj.tableBody.borderColor +
            " " +
            styleObj.tableBody.borderType,
          "background-color": styleObj.tableHeader.backgroundColor,
          "text-align": "center",
        };
      } else if (position == "tableBody") {
        return {
          color: styleObj.tableBody.color,
          border:
            styleObj.tableBody.borderThickness +
            "px " +
            styleObj.tableBody.borderColor +
            " " +
            styleObj.tableBody.borderType,
          "background-color": styleObj.tableBody.backgroundColor,
          "text-align": "center",
        };
      } else if (position == "card") {
        return {
          "border-radius": styleObj.borderRadio + "px",
        };
      } else if (position == "outerLayerBorder") {
        return {
          border:
            styleObj.outerLayerBorder.borderThickness +
            "px " +
            styleObj.outerLayerBorder.borderColor +
            " " +
            styleObj.outerLayerBorder.borderType,
        };
      } else if (position == "button") {
        return {
          "background-color": styleObj.button.backgroundColor,
          color: styleObj.button.color,
        };
      }
    },
    onIncludeStopped() {
      this.is_include_stopped = !this.is_include_stopped;
      // this.$emit("includeStopped", this.searchText, this.searchTimeForm.date, this.is_include_stopped,);
      this.$emit("includeStopped", this.is_include_stopped, this.searchText, this.searchTimeForm.date,);
    },
    // 导出excel
    exportExcel() {
      this.isExportExcel = true;
      if (this.exportForm.radio == "list") {
        this.exportData = this.serverData1; //导出的表格测试数据
      } else {
        this.exportData = this.selectData;
      }
    },
    // 导出数据数量单选按钮改变
    async exportDataChange(val) {
      if (val == "list") {
        this.exportData = this.serverData1; //导出的表格测试数据
      } else if (val == "allList") {
        this.exportData = []
        let obj = this.derivedParameter
        if (obj.data != undefined) {
          delete obj.data.per_page
          delete obj.data.page
        }
        if (obj.params != undefined) {
          delete obj.params.per_page
          delete obj.params.page
        }
        const res = await this.$http(obj)
        console.log(res);
        if (res.status == 200) {
          this.exportData = res.data.table_body_data
        } else {
          this.$message.error(res.data)
        }
        // this.=res.data.table_header_data
      } else {
        this.exportData = this.selectData;
      }
    },
    // 导出弹窗的确定按钮
    exportSubmit() {
      // if (this.exportForm.radio === "allList") {
      //   this.$emit("onExcel", "allList");
      //   this.isExportExcel = false;
      // } else {
      htmlToExcel.getExcel(
        "#out-table",
        this.exportForm.exportExcelTitle == ""
          ? "默认名称"
          : this.exportForm.exportExcelTitle,
        this.colData1
      );
      // }
    },
    //选中数据
    handleSelectionChange(val) {
      // console.log(val);
      if (val) {
        this.selectData = val;
        if (this.selectData.length == 0) {
          this.isDelDialog = false;
        } else {
          this.isDelDialog = true;
          // console.log(this.isDelDialog);
        }
      } else {
        this.isDelDialog = false;
        // this.$refs.multipleTable.clearSelection();
        this.$refs[this.refName].clearSelection();
      }
      this.$emit("handleSelectionChange", val);
    },
    // 表格最后一行合计方法
    getSummaries(a) {
      let sums = [];
      const { columns, data } = a;

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "汇总";
          return;
        }

        this.list.forEach((item, i) => {
          if (item.field == column.property) {
            const values = data.map((item) => Number(item[column.property]));

            if (item.aggregation == "sum") {
              if (!values.every((value) => isNaN(value))) {
                const sum = values.reduce((prev, curr) => prev + curr, 0);
                sums[index] = sum.toLocaleString(); // Add thousand separators
              } else {
                sums[index] = "N/A";
              }
            }
            if (item.aggregation == "count") {
              sums[index] = data.length.toLocaleString(); // Add thousand separators
            }
          }
        });
      });

      return sums;
    },
    // 表头右侧设置按钮
    settting() {
      this.list1 = JSON.parse(JSON.stringify(this.list));
      this.list1.forEach((item, index) => {
        if (item.is_show_in_card == undefined) {
          this.$set(item, "is_show_in_card", false);
        }
        if (item.is_card_title == undefined) {
          this.$set(item, "is_card_title", false);
        }
        // if(item.title.value==null){
        //     item.title.value = ''
        // }
      });
      console.log(this.list1);
      // console.log(this.list1,this.tableDataObj);
      console.log(this.$refs[this.refName].columns);
      let template = this.$refs[this.refName].columns;
      // let template = document.querySelectorAll(".mainTable .el-table__header colgroup col");
      // console.log(template);
      let template1 = [];
      template.forEach((v, i) => {
        if (v.width == undefined) {
          template1.push(v.minWidth);
        } else {
          template1.push(v.width);
        }
      });
      console.log(template1);
      // 如果有删除列则去除掉
      if (this.permissions.is_delete) {
        template1.shift();
      }
      // console.log(template1);
      let arr = [];
      let arr1 = [];
      let arr2 = [];
      this.list1.forEach((item, index) => {
        if (item.fixDirection == "left") {
          arr.push(template1[0]);
          item.currentWidth = template1[0];
          template1.splice(0, 1);
        }
        // else if(item.fixDirection=='right'){
        //     arr1.push(template1[index])
        // }else{
        //     arr2.push(template1[index])
        // }
      });
      this.list1.forEach((item, index) => {
        if (item.fixDirection == "") {
          arr1.push(template1[0]);
          item.currentWidth = template1[0];
          template1.splice(0, 1);
        }
      });
      this.list1.forEach((item, index) => {
        if (item.fixDirection == "right") {
          arr1.push(template1[0]);
          item.currentWidth = template1[0];
          template1.splice(0, 1);
        }
      });
      let template2 = arr.concat(arr2, arr1);
      // console.log(this.list1);
      // console.log(template2);

      this.$nextTick(() => {
        this.drag1();
      });
    },
    // 编辑表格
    editTable(row) {
      // 编辑表格窗弹出前赋值
      this.dialogTableVisible = true;
      console.log(this.dialogTableVisible);
      this.$nextTick(() => {
        this.drag();
      });
    },
    // 清空值
    clearData() {
      this.formInline.siftList.forEach((item, index) => {
        item.text = "";
      });
    },
    // 添加筛选条件去除某一行
    delRow(obj) {
      this.formInline.siftList = this.formInline.siftList.filter(
        (item, index) => item != obj
      );
    },
    // 根据筛选内容进行筛选
    sift(boolean) {
      if (boolean == "true") {
        this.currentPage = 1;
        this.pageSize = 10;
      }
      // console.log(this.formInline.siftList);
      let str = "";
      // this.formInline.siftList = this.formInline.siftList.filter((item)=>item.text!='');
      if (this.formInline.siftList.length == 0) {
        this.siftNum = null;
      } else {
        this.siftNum = this.formInline.siftList.length;
        this.formInline.siftList.forEach((item, index) => {
          if (index == this.formInline.siftList.length - 1) {
            if (
              item.selectType == "isnull=True" ||
              item.selectType == "isnull=False"
            ) {
              str += item.ziduan + "_-_" + item.selectType;
            } else {
              if (item.text != "") {
                str += item.ziduan + "_-_" + item.selectType + "=" + item.text;
              }
            }
          } else {
            if (
              item.selectType == "isnull=True" ||
              item.selectType == "isnull=False"
            ) {
              str += item.ziduan + "_-_" + item.selectType + "&";
            } else {
              if (item.text != "") {
                str +=
                  item.ziduan + "_-_" + item.selectType + "=" + item.text + "&";
              }
            }
          }
        });
      }
      // console.log(this.formInline.siftList);
      // let str = ""
      // this.formInline.siftList.forEach((item, index) => {
      //     if (index == this.formInline.siftList.length - 1) {
      //         if (item.selectType == "isnull=True" || item.selectType == "isnull=False") {
      //             str += item.ziduan + "__" + item.selectType
      //         } else {
      //             str += item.ziduan + "__" + item.selectType + "=" + item.text
      //         }

      //     } else {
      //         if (item.selectType == "isnull=True" || item.selectType == "isnull=False") {
      //             str += item.ziduan + "__" + item.selectType + "&"
      //         } else {
      //             str += item.ziduan + "__" + item.selectType + "=" + item.text + "&"
      //         }
      //     }
      // })
      // console.log(str);
      this.$emit("toSift", str);
      this.$refs.firstPopover.doClose();
      // console.log(this.serverData1);
      // let arr1 = [];
      // // console.log(this.formInline.siftList, this.serverData1);
      // if (this.formInline.siftList.length) {
      //     // 筛选条件
      //     this.formInline.siftList.forEach((item1, index1) => {
      //         let arr = []
      //         // 表格数据
      //         this.serverData1.forEach((v, i) => {
      //             for (const key in v) {
      //                 // 判断是否为相同字段
      //                 if (item1.ziduan == key) {
      //                     // 某一字段包含搜索值
      //                     // console.log(this.serverData1[i][key]);
      //                     // console.log(item1);
      //                     this.serverData1[i][key] = this.serverData1[i][key].toString()
      //                     if (
      //                         (item1.selectType == "包含" && this.serverData1[i][key].includes(item1.text))
      //                         || (item1.selectType == "等于" && this.serverData1[i][key] == item1.text)
      //                         || (item1.selectType == "不等于" && this.serverData1[i][key] != item1.text)) {

      //                         if (arr.includes(this.serverData1[i])) {
      //                             return
      //                         } else {
      //                             // arr.push(this.serverData1[i]);
      //                             arr.push(this.serverData1[i].id);
      //                         }
      //                     }
      //                 }
      //             }
      //         })
      //         arr1.push(arr)
      //     })

      //     let a = arr1.reduce((a, b) => a.filter(c => b.includes(c)))

      //     this.siftServerData = [];
      //     // 如果筛选的结果长度大于0,找到对应数据渲染表格
      //     if (a.length > 0) {
      //         a.forEach((item, index) => {
      //             this.serverData1.forEach((v, i) => {
      //                 if (v.id == item) {
      //                     this.siftServerData.push(v)
      //                 }
      //             })
      //         })
      //     }
      // } else {
      //     this.siftServerData = this.serverData1;
      // }
      // console.log(this.siftServerData);
    },
    // 添加筛选条件点击事件
    addSift() {
      this.addSiftFlag = true;
    },
    // 字段点击选择
    addSiftList(item) {
      if (this.formInline.siftList.includes(item)) {
      } else {
        let obj = {
          ziduan: item.title.value,
          selectType: "",
          text: "",
        };
        this.formInline.siftList.push(obj);
      }
      // console.log(this.formInline.siftList);
      this.formInline.siftList = this.fn1(this.formInline.siftList);
      this.formInline.region = item.title;
    },
    // 筛选数组去重
    fn1(tempArr) {
      for (let i = 0; i < tempArr.length; i++) {
        for (let j = i + 1; j < tempArr.length; j++) {
          if (tempArr[i].ziduan == tempArr[j].ziduan) {
            tempArr.splice(j, 1);
            j--;
          }
        }
      }
      return tempArr;
    },
    async submit2() {
      this.list1.forEach((item, index) => {
        item.width = item.currentWidth;
      });
      this.submit1();
    },
    // 更改用户表头、宽度等设置、保存配置
    async submit1() {
      this.dialogTableVisible = false;
      this.colData1 = JSON.parse(JSON.stringify(this.list1));
      this.list = JSON.parse(JSON.stringify(this.list1));
      this.list.forEach((item, index) => {
        // console.log(index + 1);
        item.order = index + 1;
      });
      this.list.forEach((item, i) => {
        this.$refs[this.refName].columns.forEach((items, i) => {
          console.log(items.label, item.currentUserEditTitle, item, items);
          if (item.currentUserEditTitle == '') {
            if (items.label == item.title.label) {
              item.width = items.width;
            }
          } else {
            if (items.label == item.currentUserEditTitle) {
              item.width = items.width;
            }
          }

        });
      });
      // console.log(this.list);
      // return
      if (this.tableDataObj.model_class_name != undefined) {
        let res = await saveTableStyle(
          { headerTable: this.list, tableStyle: this.form },
          this.tableDataObj,
          { model: this.tableDataObj.model_class_name },
          this.user
        );
        if (res == "添加成功" || res == "更改成功") {
          this.$emit("getSaveRes", true);
          this.$message.success("保存成功");
        } else {
          this.$message.error(res);
        }
      } else {
        this.$emit("changeStyle", {
          headerTable: this.list,
          tableStyle: this.form,
        });
      }
      this.itemKey = Math.random();
    },

    // 编辑表格可以拖拽每行表格
    drag() {
      const el = document.querySelectorAll(".topBox tbody")[0];
      console.log(el);
      if (el == undefined) {
        return
      }
      Sortable.create(el, {
        // disabled: false, // 拖拽不可用? false 启用
        ghostClass: "sortable-ghost", //拖拽样式
        animation: 150, // 拖拽延时，效果更好看
        onEnd: (e) => {
          // 拖拽结束时的触发
          let arr = this.list1; // 获取表数据
          arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]); // 数据处理，获取最新的表格数据
          this.$nextTick(function () {
            this.list1 = arr;
            // console.log(this.list1)
          });
        },
      });
    },
    // 点击表头右侧按钮，可上下拖动表头
    drag1() {
      const el = document.querySelectorAll("#switchBox ul")[2];
      // console.log(el);
      Sortable.create(el, {
        disabled: false, // 拖拽不可用? false 启用
        ghostClass: "sortable-ghost", //拖拽样式
        animation: 150, // 拖拽延时，效果更好看
        onEnd: (e) => {
          // 拖拽结束时的触发
          console.log(e.newIndex);
          let arr = this.list1; // 获取表数据
          arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]); // 数据处理，获取最新的表格数据
          this.$nextTick(function () {
            this.list1 = arr;
          });
          console.log(this.list1);
        },
      });
    },
    // 单元格点击事件
    async cellClick(row, item) {
      // console.log(row,item);
      let obj = {
        row: row,
        item: item,
      };
      console.log("cellClick", obj);
      this.$emit("cellClick", obj);
    },
    async gettableDataObj() {
      // let res = await this.$http({
      // })
    },
    onOrder(val) {
      if (
        val["special_payment_days"] == null ||
        val["special_payment_days"] == ""
      ) {
        return "LayoutDivOrderNull";
      } else {
        return "LayoutDivOrder";
      }
    },
  },
  created() {
    this.gettableDataObj();
    this.$nextTick(() => {
      // console.log(123456);
      this.serverData1 = JSON.parse(
        JSON.stringify(this.tableDataObj.table_body_data)
      );
      console.log(this.serverData1);
      this.siftServerData = this.serverData1; //表格主体绑定的数据

      this.colData1 = JSON.parse(
        JSON.stringify(this.tableDataObj.table_header_data)
      ); //表头数据
      this.colData1.forEach((item, index) => {
        if (item.aggregation != "") {
          this.showSummaryNum += 1;
        }
      });
      this.searchFiledList = this.colData1; //筛选时模糊搜索表头字段
      this.selectHead = JSON.parse(
        JSON.stringify(this.tableDataObj.table_header_data)
      ); //导出excel用的以及预览表格循环表头

      let arr = JSON.stringify(this.tableDataObj.table_header_data);
      this.list = JSON.parse(arr);
    });
    // console.log(this.tableDataObj.permissions);
    if (this.tableDataObj.permissions.length <= 2) {
      if (this.tableDataObj.permissions.length == 1) {
        if (
          this.tableDataObj.permissions[0].field == "is_edit" ||
          this.tableDataObj.permissions[0].field == "is_delete"
        ) {
          this.isHaveTop = false;
        }
      } else if (this.tableDataObj.permissions.length == 2) {
        if (
          this.tableDataObj.permissions[0].field == "is_edit" ||
          this.tableDataObj.permissions[1].field == "is_delete"
        ) {
          this.isHaveTop = false;
        } else if (
          this.tableDataObj.permissions[1].field == "is_edit" ||
          this.tableDataObj.permissions[0].field == "is_delete"
        ) {
          this.isHaveTop = false;
        }
      } else {
        this.isHaveTop = false;
      }
    }
    // 获取用户设定过的样式数据，没定义过则按照默认的
    if (this.tableDataObj.table_color_css == null) {
      this.topicDefault("3");
    } else {
      this.form = this.tableDataObj.table_color_css;
    }
    this.tableForm = JSON.parse(JSON.stringify(this.form));
    this.changeBtnTextColor();
    this.searchTimeForm.startTime = this.createDate; //筛选的起始时间
    this.searchTimeForm.endTime = this.endDate; //筛选的结束时间
    // 操作列按钮个数，以此改变宽度
    this.permissions.length = 0;
    this.tableDataObj.permissions.forEach((item, key, index) => {
      // console.log(item);
      this.permissions[item.field] = item.checked;
      if (item.field == "is_edit" && item.checked == true) {
        this.permissions.length += 1;
      }
      if (item.field == "sign_back" && item.checked == true) {
        this.permissions.length += 1;
      }
      if (item.field == "outbound" && item.checked == true) {
        this.permissions.length += 1;
      }
    });
  },
  mounted() {
    // console.log('111111');
    setTimeout(() => {
      this.addListen();
    }, 1000);
    // console.log('222222');

    // if (this.cardObj != undefined) {
    // if (this.cardObj.typeName != undefined) {
    // if (this.cardObj.typeName == '联系人') {
    //     if (document.documentElement.clientWidth < 1000) {

    //         setTimeout(() => {
    //             console.log("hahhahahahahhahahah");
    //             let allText = document.querySelectorAll(".contactBox-div span");
    //             allText.forEach((item, index) => {
    //                 item.style.fontSize = '2.2vw'
    //             })

    //             let allText1 = document.querySelectorAll(".contactBox-div p");
    //             allText1.forEach((item, index) => {
    //                 item.style.lineHeight = '3.6vw'
    //             })

    //             let allText2 = document.querySelectorAll(".contactBox-div .p");
    //             allText2.forEach((item, index) => {
    //                 item.style.lineHeight = '3.6vw'
    //             })
    //             let allText3 = document.querySelectorAll(".LayoutDiv1 div");
    //             allText3.forEach((item, index) => {
    //                 item.style.marginTop = '8.6vw'
    //             })
    //         }, 100)
    //     }
    // }
    // } else {
    //     this.isTable = true
    // }
    // } else {
    //     this.isTable = true
    // }
    if (this.isTableSet) {
      this.isTable = false;
    } else {
      this.isTable = true;
    }
    if (document.documentElement.clientWidth < 1000) {
      this.isTable = false;
    }
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 380;
      //后面的200：根据需求空出的高度，自行调整
      this.getTrueOrFalse();
    });
    if (
      this.cardObj != undefined &&
      this.cardObj.typeName != undefined &&
      this.cardObj.typeName == "联系人"
    ) {
      this.getOaMenu();
    }
  },
  watch: {
    isExportExcel: {
      handler(val) {
        if (!val) {
          this.exportForm.radio = 'list'
        }
      }
    },
    "tableDataObj.permissions": {
      handler(val) {
        // console.log('111');
        // 操作列按钮个数，以此改变宽度
        this.permissions = {};
        this.permissions.length = 0;
        this.tableDataObj.permissions.forEach((item, key, index) => {
          // console.log(item);
          this.permissions[item.field] = item.checked;
          if (item.field == "is_edit" && item.checked == true) {
            this.permissions.length += 1;
          }
          if (item.field == "sign_back" && item.checked == true) {
            this.permissions.length += 1;
          }
          if (item.field == "outbound" && item.checked == true) {
            this.permissions.length += 1;
          }
        });
      },
    },
    "$store.state.isphone": {
      handler(val) {
        /*  if (val) {
                     console.log("hahhahahahahhahahah");
                     let allText = document.querySelectorAll(".contactBox-div span");
                     allText.forEach((item, index) => {
                         item.style.fontSize = '2.2vw'
                     })
 
                     let allText1 = document.querySelectorAll(".contactBox-div p");
                     allText1.forEach((item, index) => {
                         item.style.lineHeight = '3.6vw'
                     })
 
                     let allText2 = document.querySelectorAll(".contactBox-div .p");
                     allText2.forEach((item, index) => {
                         item.style.lineHeight = '3.6vw'
                     })
                     let allText3 = document.querySelectorAll(".LayoutDiv1 div");
                     allText3.forEach((item, index) => {
                         item.style.marginTop = '8.6vw'
                     })
 
                     let allText4 = document.querySelectorAll(".editContactBox .el-button");
                     console.log(allText4);
                     allText4.forEach((item, index) => {
                         item.style.fontSize = '2.1vw'
                         item.style.height = '3.2vw'
                         item.style.lineHeight = '1.2vw'
                     })
                 } else {
                     console.log("hahhahahahahhahahah");
                     let allText = document.querySelectorAll(".contactBox-div span");
                     allText.forEach((item, index) => {
                         item.style.fontSize = '0.8vw'
                     })
 
                     let allText1 = document.querySelectorAll(".contactBox-div p");
                     allText1.forEach((item, index) => {
                         item.style.lineHeight = '1.5vw'
                     })
 
                     let allText2 = document.querySelectorAll(".contactBox-div .p");
                     allText2.forEach((item, index) => {
                         item.style.lineHeight = '1.5vw'
                     })
 
                     let allText3 = document.querySelectorAll(".LayoutDiv1 div");
                     allText3.forEach((item, index) => {
                         item.style.marginTop = '1.5vw'
                     })
 
                     let allText4 = document.querySelectorAll(".editContactBox .el-button");
                     console.log(allText4);
                     allText4.forEach((item, index) => {
                         item.style.fontSize = '0.7vw'
                         item.style.height = '1.6vw'
                     })
                 } */
      },
    },
    page: {
      handler(val) {
        // console.log(val);
        this.currentPage = val;
      },
    },
    per_page: {
      handler(val) {
        // console.log(val);
        this.pageSize = val;
      },
    },
    isTable: {
      handler(val) {
        this.$emit("getCardOrTable", val);
      },
    },
    isEditContact: {
      // handler(val){
      //     this.isEditContactIndex = null
      // }
    },
    searchText: {
      handler(val) {
        console.log(val);
        if (val === "") {
          this.$emit("toSearch", val, this.searchTimeForm.date, this.is_include_stopped);
        }
      },
    },
    tableDataObj: {
      handler(val) {
        this.serverData1 = val.table_body_data;
        this.siftServerData = this.serverData1;
        this.colData1 = val.table_header_data;
        this.showSummaryNum = 0;
        this.colData1.forEach((item, index) => {
          if (item.aggregation != "") {
            this.showSummaryNum += 1;
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="less">
.myTool {
  max-width: 500px !important;
}

.icon-search_unapprove {
  font-size: 15px;
  text-align: center;
  // padding: 5px 7px;
  cursor: pointer !important;
}

.permissions_is_unapprove {
  font-size: 15px;
  text-align: center;
  padding: 5px 7px;
  cursor: pointer !important;

  &:hover {
    color: rgb(143, 195, 31) !important;
    border-color: #ddedbc !important;
    background-color: #f4f9e9 !important;
  }
}
</style>

<style lang="less" scoped>
/deep/.el-carousel {
  .el-carousel__container {
    height: auto;
  }

  .carousel__item {
    height: 100%;
  }
}

/deep/.el-carousel__button {
  width: 10px;
  background-color: rgb(89, 87, 87);
}

// /deep/.isAccessoryFullscreen_carousel {
//   .el-carousel__container {
//     height: 600px;
//   }
// }

// /deep/.isAccessoryFullscreen_carousel_false {
//   .el-carousel__container {
//     height: 300px;
//   }
// }


/deep/ .el-dialog__body {
  max-height: 700px !important;
}

/deep/.el-dialog__header {
  background-color: rgb(89, 87, 87);
  color: #fff;
  padding: 0 !important;

  .icon-zuidahua {
    font-size: 16px;
    cursor: pointer;
    padding: 10px;

    &:hover {
      color: #898989;
    }
  }

  .icon-chuangkouzuidahua {
    font-size: 16px;
    cursor: pointer;
    padding: 10px;

    &:hover {
      background-color: #898989;
    }
  }

  .el-icon-close {
    font-size: 16px;
    cursor: pointer;
    padding: 10px;

    &:hover {
      background-color: #898989;
    }
  }
}

.accessory_popover {
  /deep/.iconfont {
    font-size: 20px !important;
  }
}

.accessory_popover_item {
  padding: 5px 10px;
  color: #5f5d5d;
  cursor: pointer;

  &:hover {
    color: rgb(143, 195, 31) !important;
  }
}

.include_stopped {
  background-color: rgb(143, 195, 31) !important;
  color: #fff !important;
}

/deep/.cell {
  text-align: center;
}

.el-card {
  box-shadow: none;
  padding: 0px 0px 0px 0px;

  /deep/.el-card__body {
    padding-top: 0px !important;
  }
}

/deep/.current-row {
  background-color: rgb(212, 211, 212) !important;
}

.tableTitleBox {
  font-size: 18px;
}

.LayoutDivOrder {
  background-color: rgb(244, 209, 209) !important;
}

.elCard {
  position: relative;

  .delDialog {
    width: 100%;
    height: 48px;
    background-color: #ffffff;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100;
    border: 1px solid #eeeeee;

    .el-button {
      padding: 5px 15px;
      margin: 10px;

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 4px;
      }

      &:first-child {
        color: #f46c6c;
      }
    }
  }
}

.topBox {
  position: relative;
  margin: 10px 0px;

  .lineTop {}

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    .topLeft {
      display: flex;
      height: 28px;
      top: 7px;
    }

    /deep/.el-upload {
      margin-left: 10px;
    }

    .el-button {
      i {
        margin-right: 5px;
      }
    }
  }
}

.importDialog {
  /deep/.el-dialog__body {
    height: auto;
  }
}

// 表格主体
.mainTable {
  .el-table {
    .el-button {
      padding: 0px;
    }

    .del {
      cursor: pointer;

      &:hover {
        color: red;
      }
    }

    .edit {
      cursor: pointer;

      &:hover {
        color: rgba(190, 190, 188, 0.85);
      }
    }

    /deep/.hover-row {
      background-color: #eeeeee !important;
    }

    /deep/.el-table__header {
      th.el-table__cell {
        border-top: none !important;
        border-left: none !important;

        &:last-child {
          border-right: none !important;
        }
      }
    }

    /deep/.el-table__body {
      td.el-table__cell {
        border-left: none !important;
        border-top: none !important;

        &:last-child {
          border-right: none !important;
        }
      }
    }

    /deep/.el-table__cell {
      text-align: center;
    }
  }

  /deep/ .el-table {
    .el-table__header,
    .el-table__body,
    .el-table__footer {
      width: 100% !important;
      table-layout: fixed !important;
    }

    .el-table__fixed-right {
      height: 100% !important;
    }
  }
}

/deep/.el-table__footer-wrapper tbody td.el-table__cell {
  background-color: #f5f7fa;
}

.siftCol {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/deep/.el-tooltip__popper {
  max-width: 20% !important;
}

// 导出弹窗样式
.export_dialog {
  display: flex;
  justify-content: space-between;

  .export_dialog_left {
    width: 30%;

    .exportListBox {
      height: 400px;
      overflow: auto;

      .exportList {
        padding-left: 30px;

        span {
          margin-left: 10px;
        }
      }
    }
  }

  .export_dialog_right {
    width: 68%;
  }
}

#switchBox {
  ul {
    height: 300px;
    overflow-y: auto;
    cursor: default;

    li {
      list-style: none;
      line-height: 30px;

      span {
        margin-left: 20px;
      }
    }
  }
}

/deep/.el-dialog__body {
  padding: 10px 20px;
  min-height: 150px;
  max-height: 500px;
  overflow: auto;
}

// 添加筛选每行的图标
.el-icon-remove-outline {
  font-size: 20px;
  cursor: pointer;
}

.btnList {
  &:hover {
    color: aqua;
  }
}

.bigBox {
  .iconBox {
    text-align: center;

    /deep/.el-checkbox {
      display: block;
      line-height: 50px;
    }

    .el-icon-s-tools {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .el-icon-s-tools {
    font-size: 20px;
    cursor: pointer;
  }
}

// 表格点击高亮
/deep/ .el-table__body tr.current-row>td {
  background-color: #f3f3f3 !important;
}

/deep/.el-table__cell {
  padding: 9px 0px;
}

.cellClickButton {
  color: #409eff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.permissions_is_approve {
  width: 70px;
  // height: 27px;
  line-height: 27px;
  border: 1px solid #dcdfe6;
  display: flex;
  border-radius: 3px;
  box-sizing: border-box;
  margin-left: 10px;
  color: #606266;
  position: relative;
  top: 7px;

  .is_approve {
    width: 50px;
    text-align: center;
    font-size: 12px;
    border-right: 1px solid #dcdfe6;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      color: rgb(143, 195, 31);
      border-color: #ddedbc;
      background-color: #f4f9e9;
    }
  }
}

.el-icon-circle-check {
  font-size: 28px;
  color: rgb(143, 195, 31);
}

@media screen and (min-width: 1000px) {
  #cardBigBox {
    // height: 80vh;
    overflow-y: auto;
  }

  #cardBigBox1 {
    height: 80vh;
    overflow-y: scroll;
  }

  .contactBox-div {

    p,
    .p {
      line-height: 2.4vw;
      font-size: 1.4vw;

      .iconfont {
        font-size: 1.4vw;
        color: rgb(143, 195, 31);
      }
    }
  }

  .cardBigBox {

    .LayoutDiv,
    .LayoutDiv1 {
      background-color: rgb(234, 234, 237);
      background-repeat: repeat;
      background-size: 100%;
      height: 100%;
      font-size: 0.7vw;
      overflow: auto;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
      cursor: pointer;
      position: relative;

      h2 {
        position: sticky;
        top: 0;
        left: 0;
        font-size: 2vw;
        line-height: 2vw;
        padding: 1vw 0.5vw;
        background-color: rgb(143, 195, 31);
        color: #fff;
        width: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .contactName {
        font-size: 24px;
        line-height: 24px;
      }

      .cardSpan,
      .cardSpan1 {
        padding: 1vw 0.5vw 0 0.5vw;
        display: inline-block;
        width: auto;
        line-height: 1.4vw;

        h2 {
          width: 100%;
        }

        .title,
        .content {
          font-size: 0.8vw;
        }

        .title {
          font-size: 1vw;
        }

        .content {
          color: #727272;
        }
      }
    }

    .LayoutDiv {
      position: relative;

      h2 {
        font-size: 1vw;
        line-height: 1vw;
      }
    }

    .editContactBox {
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgb(89, 87, 87);
      top: 0;
      left: 0;
      margin: auto;
      text-align: center;
      z-index: 1;

      .editContactBox-div {
        width: 40%;
        height: 60%;
        text-align: center;
        position: absolute;
        top: -8%;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        flex-direction: column;
      }

      .el-button {
        font-size: 0.7vw;
        height: 1.6vw;
        display: block;
        margin-left: 0px;
        margin-bottom: 0.5vw;
      }
    }

    .LayoutDiv1 {
      div {
        margin-top: 1.5vw;
        text-align: center;
        color: #cccccc;
      }

      .el-icon-plus {
        // color: rgb(143,195, 31);
        font-size: 7vw;
      }
    }
  }

  .LayoutDiv1Contact {
    div {
      margin-top: 1.5vw;
      text-align: center;
      color: #cccccc;
    }

    .el-icon-plus {
      font-size: 7vw;
    }
  }

  // .editContactBox {
  //     width: 100%;
  //     height: 100%;
  //     position: absolute;
  //     background: rgb(89, 87, 87);
  //     top: 0;
  //     left: 0;
  //     margin: auto;
  //     text-align: center;

  //     .editContactBox-div {
  //         width: 40%;
  //         height: 60%;
  //         text-align: center;
  //         position: absolute;
  //         top: -8%;
  //         left: 0;
  //         right: 0;
  //         bottom: 0;
  //         margin: auto;
  //         display: flex;
  //         flex-direction: column;
  //     }

  //     .el-button {
  //         font-size: 0.7vw;
  //         height: 1.6vw;
  //         display: block;
  //         margin-left: 0px;
  //         margin-bottom: 0.5vw;
  //     }
  // }

  .LayoutDivContact,
  .LayoutDiv1Contact {
    background-color: rgb(234, 234, 237);
    background-repeat: repeat;
    background-size: 100%;
    height: 100%;
    padding: 1.5vw 2vw;
    font-size: 0.7vw;
    overflow: auto;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    cursor: pointer;
    position: relative;

    h2 {
      font-size: 2vw;
      line-height: 2vw;
      // margin-bottom: 1.6vw;
    }

    .contactName {
      font-size: 24px;
      line-height: 24px;
    }

    .cardSpan,
    .cardSpan1 {
      display: inline-block;
      width: 50%;
      line-height: 1.4vw;

      h2 {
        width: 100%;
      }

      .title,
      .content {
        font-size: 0.8vw;
      }

      .title {
        font-size: 1vw;
      }

      .content {
        color: #727272;
      }
    }

    .cardSpan1 {
      width: 100%;
    }
  }

  .LayoutDivBusiness {
    height: 100%;
    overflow: auto;
    background-color: rgb(234, 234, 237);

    .businessBox {
      position: relative;

      h2 {
        background-color: rgb(143, 195, 31);
        color: #fff;
        position: sticky;
        top: 0;
        left: 0;
        padding: 0.5vw 1vw;
        font-size: 2vw !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .business {
        padding: 0.5vw 1vw;
        display: flex;
        font-size: 1vw;

        .businessTitle {
          white-space: nowrap;
        }

        .businessContent {
          color: #727272;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .LayoutDiv1Business {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 5vw;
    color: #cccccc;
    background-color: rgb(234, 234, 237);

    div {
      text-align: center;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1000px) {
  #cardBigBox {
    height: 80vh;
    overflow-y: scroll;
  }

  #cardBigBox1 {
    height: 80vh;
    overflow-y: scroll;
  }

  .icon-biaoge {
    font-size: 4vw !important;
  }

  .contactBox-div {

    p,
    .p {
      line-height: 4.4vw;
      font-size: 2.4vw;

      .iconfont {
        font-size: 3.4vw;
        color: rgb(143, 195, 31);
      }
    }
  }

  .cardBigBox {

    .LayoutDiv,
    .LayoutDiv1 {
      // background-color: red;
      background-color: rgb(234, 234, 237);
      background-repeat: repeat;
      background-size: 100%;
      height: 100%;
      font-size: 0.7vw;
      overflow: auto;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
      cursor: pointer;
      padding: 0px !important;
      position: relative;

      h2 {
        position: sticky;
        top: 0;
        left: 0;
        padding: 2vw 2vw !important;
        font-size: 4vw !important;
        line-height: 4vw !important;
        background-color: rgb(143, 195, 31);
        color: #fff;
        width: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .contactName {
        font-size: 24px;
        line-height: 24px;
      }

      .cardSpan,
      .cardSpan1 {
        padding: 1.5vw 2vw;
        width: auto !important;
        line-height: 4vw !important;
        display: flex;
        align-items: center;

        .title,
        .content {
          font-size: 3vw !important;
        }

        .title {
          color: #727272;
          margin-right: 2vw;
          font-size: 3vw !important;
          white-space: nowrap;
        }

        .content {
          // color: red;
          width: 40vw;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          // overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          color: #000;
        }
      }
    }

    .LayoutDiv {
      position: relative;

      h2 {
        font-size: 1vw;
        line-height: 1vw;
      }
    }

    .editContactBox {
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgb(89, 87, 87);
      top: 0;
      left: 0;
      margin: auto;
      text-align: center;
      z-index: 1;

      .editContactBox-div {
        width: 40%;
        height: 60%;
        text-align: center;
        position: absolute;
        top: -8%;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        flex-direction: column;
      }

      .el-button {
        font-size: 0.7vw;
        height: 1.6vw;
        display: block;
        margin-left: 0px;
        margin-bottom: 0.5vw;
      }
    }

    .LayoutDiv1 {
      div {
        margin-top: 1.5vw;
        text-align: center;
        color: #cccccc;
      }

      .el-icon-plus {
        // color: rgb(143,195, 31);
        font-size: 7vw;
      }
    }
  }

  .LayoutDivBusiness {
    height: 100%;
    overflow: auto;
    background-color: rgb(234, 234, 237);

    .businessBox {
      position: relative;

      h2 {
        background-color: rgb(143, 195, 31);
        color: #fff;
        position: sticky;
        top: 0;
        left: 0;
        padding: 1.5vw 2vw;
        font-size: 4vw !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .business {
        padding: 1.5vw 2vw;
        display: flex;
        font-size: 4vw;

        .businessTitle {
          white-space: nowrap;
        }

        .businessContent {
          color: #727272;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .LayoutDiv1Business {
    height: 100%;
    display: flex;
    align-items: center;

    font-size: 10vw;
    color: #cccccc;
    background-color: rgb(234, 234, 237);

    div {
      text-align: center;
      width: 100%;
    }
  }

  .LayoutDivContact,
  .LayoutDiv1Contact {
    background-color: rgb(234, 234, 237);
    background-repeat: repeat;
    background-size: 100%;
    height: 100%;
    padding: 1.5vw 2vw;
    font-size: 0.7vw;
    overflow: auto;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    cursor: pointer;
    position: relative;

    h2 {
      font-size: 2vw;
      line-height: 2vw;
      margin-bottom: 1.6vw;
    }

    .contactName {
      font-size: 24px;
      line-height: 24px;
    }

    .cardSpan,
    .cardSpan1 {
      display: inline-block;
      width: 50%;
      line-height: 1.4vw;

      h2 {
        width: 100%;
      }

      .title,
      .content {
        font-size: 0.8vw;
      }

      .title {
        font-size: 1vw;
      }

      .content {
        // width: 65%;
        color: #727272;
      }
    }

    .cardSpan1 {
      width: 100%;
    }
  }

  .LayoutDiv1Contact {
    div {
      margin-top: 7.5vw;
      text-align: center;
      color: #cccccc;
    }

    .el-icon-plus {
      font-size: 7vw;
    }
  }

  .winClass {
    width: 300px !important;
  }
}

.icon-qiapianxingshi {
  font-size: 20px;
}

.iconCardBox {
  cursor: pointer;
}

.openBlankImgCss {
  text-align: right;
  position: sticky;
  top: -0.1vw;
  background-color: #fff;

  span {
    cursor: pointer;

    &:hover {
      color: rgb(143, 195, 31);
      text-decoration: solid;
    }

    &:first-child {
      margin-right: 1.4vw;
    }

    &:nth-child(2) {
      margin-right: 0.8vw;
    }
  }
}

.icon-liulanqidakai {
  font-size: 26px;
  color: rgb(143, 195, 31);
}

.icon-shixinwujiaoxing,
.icon-kongxinwujiaoxing {
  color: rgb(216, 213, 23);
  font-size: 26px;
  position: relative;
  top: 2px;
}

.icon-liulanqidakai {
  font-size: 26px;
  color: rgb(143, 195, 31);
}

.el-icon-close {
  font-size: 26px;
}

/deep/.el-dialog {
  overflow: hidden;
  border-radius: 12px !important;

  .el-dialog__header {
    padding: 10px;
    background-color: rgb(89, 87, 87);
    color: #fff;
  }

  .el-dialog__close {
    position: absolute;
    top: -5px;
    right: 0px;
    color: rgb(209, 209, 209);
  }
}

/deep/.el-table__body-wrapper {
  // z-index: 2;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  /* 添加阴影效果 */
}

.icon-biaoge {
  color: #9c9c9c;

  &:hover {
    color: black;
    cursor: pointer;
  }
}

.new_accessory,
.old_accessory {
  width: 50%;
  text-align: center;
}

.new_accessory_item {
  display: flex;
}

.new_accessory_item_lable {
  min-width: 80px;
  text-align: end;
  margin-right: 5px;
}

.new_accessory_item_value {
  white-space: nowrap;
}

.popover_picture_outline {
  &:hover {
    color: rgb(143, 195, 31);
  }
}

.status_font_over {
  color: rgb(143, 195, 31);
}

.status_font_red {
  color: red;
}

.status_font_gray {
  color: rgb(166, 166, 166);
}

.status_font_default {}

// .status_font_over{}
</style>

<style>
.el-table .el-table__fixed {
  pointer-events: none;
}

.el-table .el-table__fixed>* {
  pointer-events: all;
}

.el-table .el-table__fixed-right {
  pointer-events: none;
}

.el-table__fixed-right>* {
  pointer-events: all;
}
</style>
