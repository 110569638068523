<template>
    <div style="position: relative;">
        <el-popover placement="top" trigger="hover">
            <div style="text-align: center;">
                设置表单
            </div>
            <i slot="reference" class="el-icon-setting"
                style="cursor: pointer;position: absolute;top: 0;right:10px;z-index: 1;font-size: 17px;"
                @click="onIsSetting"></i>
        </el-popover>
        <el-form v-if="inline" :ref="refs" :disabled="readonly" label-width="100px" :model="formData" :rules="rules"
            size="mini">
            <template v-for="(item, i) in settingForm.control_setting">
                <el-form-item :label="item.basic_settings.show_name"
                    :class="[item.type == '多文本输入框' || item.type == '表格' ? 'input_textarea' : '']"
                    :prop="item.type == '对象(单选)' ? item.basic_settings.field + '.label' : item.basic_settings.field"
                    :key="i" v-if="onIsRoleId(item.visible_rule.role_limit, role_ids) && item.permissions.show">
                    <template v-if="item.type === '文本输入框'">
                        <el-input style="width: 240px;" v-model="formData[item.basic_settings.field]"
                            :placeholder="item.basic_settings.placeholder" clearable>
                        </el-input>
                    </template>
                    <template v-else-if="item.type === '多文本输入框'">
                        <el-input style="width: 100%;" type="textarea" :autosize="{ minRows: 3, maxRows: 16 }"
                            v-model="formData[item.basic_settings.field]" :placeholder="item.basic_settings.placeholder">
                        </el-input>
                    </template>
                    <template v-else-if="item.type === '数字输入框'">
                        <el-input-number style="width: 240px;" controls-position="right" :min="minValue(item.numeric_settings.max_digits,
                            item.numeric_settings.decimal_places)" :max="maxValue(item.numeric_settings.max_digits,
        item.numeric_settings.decimal_places)" :precision="item.numeric_settings.decimal_places"
                            v-model="formData[item.basic_settings.field]" :placeholder="item.basic_settings.placeholder">
                        </el-input-number>
                    </template>
                    <template v-else-if="item.type === '开关'">
                        <div style="width: 240px;">
                            <el-switch v-model="formData[item.basic_settings.field]" active-color="#13ce66"
                                inactive-color="#ff4949">
                            </el-switch>
                        </div>
                    </template>
                    <template v-else-if="item.type === '下拉选择框(单选)'">
                        <el-select style="width: 240px;" v-model="formData[item.basic_settings.field]"
                            :placeholder="item.basic_settings.placeholder" clearable @focus="onSelectRadio(item)">
                            <el-option v-for="items in item.options" :label="items[item.model_class_field]"
                                :value="items[item.model_class_value]" :key="items[item.model_class_value]">
                            </el-option>
                        </el-select>
                    </template>
                    <template v-else-if="item.type === '下拉选择框(多选)'">
                        <el-select style="width: 240px;" v-model="formData[item.basic_settings.field]" multiple
                            collapse-tags :placeholder="item.basic_settings.placeholder" clearable
                            @focus="onSelectMultiple(item)">
                            <el-option v-for="items in item.options" :label="items[item.model_class_field]"
                                :value="items[item.model_class_value]" :key="items[item.model_class_value]">
                            </el-option>
                        </el-select>
                    </template>
                    <template v-else-if="item.type === '对象(单选)'">
                        <div>
                            <el-input style="width: 240px;" v-model="formData[item.basic_settings.field].label"
                                :placeholder="item.basic_settings.placeholder" clearable
                                @keyup.enter.native="onCustomerRadio(item, formData[item.basic_settings.field].label)"
                                @clear="onCustomerClear(item.basic_settings.field)">
                                <el-button slot="prepend" icon="el-icon-search"
                                    @click="onCustomerRadio(item, formData[item.basic_settings.field].label)"></el-button>
                            </el-input>
                        </div>
                    </template>
                    <template v-else-if="item.type === '对象(多选)'">
                        <div style="box-sizing: border-box; background-color: #fff;border-radius: 4px;overflow: hidden; display: flex;align-items: center;width: 240px;"
                            class="el-input-group--prepend">
                            <div class="el-input-group__prepend">
                                <el-button size="mini" icon="el-icon-search" @click="onCustomerMultiple(item)"></el-button>
                            </div>
                            <div class="el-input__inner tag_el_input"
                                :class="[formData[item.basic_settings.field].length > 0 ? 'border_radius0' : 'border_radius4']"
                                style="min-width: 40px;border-top-left-radius: 0px !important;border-bottom-left-radius: 0px !important;">
                                <el-input v-model="customerValues" placeholder="请输入内容"
                                    @keyup.enter.native="onCustomerMultiple(item)"></el-input>
                            </div>
                            <div v-if="formData[item.basic_settings.field].length > 0" style="min-width: 120px;"
                                class="input_tag" v-horizontal-scroll>
                                <div v-if="formData[item.basic_settings.field].length === 1" class="input_tag_1">
                                    <div style="width: 90px; font-size: 12px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; padding-left: 5px;"
                                        :key="index" v-for="(tag, index) in formData[item.basic_settings.field]">
                                        {{ tag.label }}
                                    </div>
                                    <i class="el-icon-close" @click="onTagClose(item.basic_settings.field, 0)"></i>
                                </div>
                                <el-popover v-else placement="bottom" trigger="click">
                                    <div>
                                        <el-tag style="font-size: 12px;cursor: pointer !important;" :key="index"
                                            v-for="(tag, index) in formData[item.basic_settings.field]" closable
                                            :disable-transitions="false"
                                            @close="onTagClose(item.basic_settings.field, index)">
                                            {{ tag.label }}
                                        </el-tag>
                                    </div>
                                    <div slot="reference"
                                        style="font-size: 12px; display: flex;align-items: baseline;cursor: pointer !important;">
                                        <div
                                            style="width: 40px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; padding-left: 5px;">
                                            {{ formData[item.basic_settings.field][0].label }}
                                        </div>
                                        <div>
                                            外共{{
                                                (formData[item.basic_settings.field].length - 1) }}项
                                        </div>
                                    </div>
                                </el-popover>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="item.type === '日期选择(范围)'">
                        <el-date-picker style="width: 240px;" v-model="formData[item.basic_settings.field]" type="daterange"
                            align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                            format="yyyy/MM/dd" value-format="yyyy/MM/dd" :picker-options="pickerOptions" clearable>
                        </el-date-picker>
                    </template>
                    <template v-else-if="item.type === '日期选择'">
                        <el-date-picker style="width: 240px;" v-model="formData[item.basic_settings.field]" type="date"
                            align="right" :placeholder="item.basic_settings.placeholder" format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd" clearable>
                        </el-date-picker>
                    </template>
                    <template v-else-if="item.type === '组织架构(人员单选)'">
                        <div style="width: 240px;">
                            <OrgSelect @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                :isCheckbox="false" :value="formData[item.basic_settings.field]" :tab="'user'"
                                buttonType="input" :title="item.basic_settings.show_name">
                            </OrgSelect>
                            <el-input style="display: none;" v-model="formData[item.basic_settings.field]"
                                placeholder="请输入内容"></el-input>
                        </div>
                    </template>
                    <template v-else-if="item.type === '组织架构(人员多选)'">
                        <div style="width: 240px;">
                            <OrgSelect @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                :isCheckbox="true" :value="formData[item.basic_settings.field]" :tab="'user'"
                                buttonType="input" :title="item.basic_settings.show_name">
                            </OrgSelect>
                            <el-input style="display: none;" v-model="formData[item.basic_settings.field]"
                                placeholder="请输入内容"></el-input>
                        </div>
                    </template>
                    <template v-else-if="item.type === '组织架构(部门单选)'">
                        <div style="width: 240px;">
                            <OrgSelect @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                :isCheckbox="false" :value="formData[item.basic_settings.field]" :tab="'dep'"
                                buttonType="input" :title="item.basic_settings.show_name">
                            </OrgSelect>
                        </div>
                    </template>
                    <template v-else-if="item.type === '组织架构(部门多选)'">
                        <div style="width: 240px;">
                            <OrgSelect @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                :isCheckbox="true" :value="formData[item.basic_settings.field]" :tab="'dep'"
                                buttonType="input" :title="item.basic_settings.show_name">
                            </OrgSelect>
                        </div>
                    </template>
                    <template v-else-if="item.type === '表格'">
                        <div>
                            <slot name="shortcut_button"></slot>
                        </div>
                        <el-table show-summary :data="formData[item.basic_settings.field]" ref="table_ref" width="1300px"
                            size="mini" border :header-cell-style="{ 'text-align': 'center' }"
                            :cell-style="{ textAlign: 'center' }">
                            <el-table-column label="序号" type="index" width="60px" prop="index">
                            </el-table-column>
                            <el-table-column label="设置" width="100px" prop="setting">
                                <template slot-scope="scope">
                                    <el-button type="text" @click="onPushTable(item)">添加</el-button>
                                    <el-button type="text" @click="onDeleteTable(scope, item)">删除</el-button>
                                </template>
                            </el-table-column>
                            <template v-for="(column, j) in item.particulars">
                                <el-table-column v-if="column.is_show && column.type == '数组'" :label="column.label"
                                    :prop="column.field" width="240px" :key="j">
                                    <template slot-scope="scope">
                                        <el-input v-if="column.is_edit" size="mini" v-model="scope.row[column.field]"
                                            clearable
                                            @keyup.enter.native="onParticulars(scope.$index, column, scope.row[column.field], item.particulars)"
                                            @blur.stop="onParticulars(scope.$index, column, scope.row[column.field], item.particulars)">
                                            <el-button size="mini" slot="prepend" icon="el-icon-search pointer"
                                                @click="onParticulars(scope.$index, column, scope.row[column.field], item.particulars)"></el-button>
                                        </el-input>
                                        <div v-else>
                                            {{ scope.row[column.field] }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column v-if="column.is_show && column.type == '文本'" :label="column.label"
                                    :prop="column.field" width="200px" :key="j">
                                    <template slot-scope="scope">
                                        <el-input v-if="column.is_edit" v-model="scope.row[column.field]"
                                            placeholder="请输入内容" clearable></el-input>
                                        <div v-else>
                                            {{ scope.row[column.field] }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column v-if="column.is_show && column.type == '数字'" :label="column.label"
                                    :prop="column.field" width="200px" :key="j">
                                    <template slot-scope="scope">
                                        <el-input v-if="column.is_edit" size="mini" type="number"
                                            v-model="scope.row[column.field]" clearable>
                                        </el-input>
                                        <div v-else>
                                            {{ scope.row[column.field] }}
                                        </div>
                                    </template>
                                </el-table-column>
                            </template>
                        </el-table>
                    </template>
                </el-form-item>
            </template>
            <el-form-item v-if="isFormBtn">
                <el-button type="primary" @click="onSubmit">提交</el-button>
                <el-button type="danger" @click="onReset">重置</el-button>
            </el-form-item>
        </el-form>
        <el-form v-else :ref="refs" :model="formData" :disabled="readonly" label-width="100px" :rules="rules" size="mini">
            <div v-if="$store.state.isphone">
                <template v-for="(item, i) in settingForm.control_setting">
                    <el-form-item :label="item.basic_settings.show_name"
                        :class="[item.type == '多文本输入框' || item.type == '表格' ? 'input_textarea' : '']"
                        :prop="item.type == '对象(单选)' ? item.basic_settings.field + '.label' : item.basic_settings.field"
                        :key="i" v-if="onIsRoleId(item.visible_rule.role_limit, role_ids) && item.permissions.show">
                        <template v-if="item.type === '文本输入框'">
                            <el-input style="width: 100%;" v-model="formData[item.basic_settings.field]"
                                :placeholder="item.basic_settings.placeholder" clearable>
                            </el-input>
                        </template>
                        <template v-else-if="item.type === '多文本输入框'">
                            <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 16 }"
                                v-model="formData[item.basic_settings.field]"
                                :placeholder="item.basic_settings.placeholder">
                            </el-input>
                        </template>
                        <template v-else-if="item.type === '数字输入框'">
                            <el-input-number style="width: 100%;" controls-position="right" :min="minValue(item.numeric_settings.max_digits,
                                item.numeric_settings.decimal_places)" :max="maxValue(item.numeric_settings.max_digits,
        item.numeric_settings.decimal_places)" :precision="item.numeric_settings.decimal_places"
                                v-model="formData[item.basic_settings.field]"
                                :placeholder="item.basic_settings.placeholder">
                            </el-input-number>
                        </template>
                        <template v-else-if="item.type === '开关'">
                            <div style="width: 100%;">
                                <el-switch v-model="formData[item.basic_settings.field]" active-color="#13ce66"
                                    inactive-color="#ff4949">
                                </el-switch>
                            </div>
                        </template>
                        <template v-else-if="item.type === '下拉选择框(单选)'">
                            <el-select style="width: 100%;" v-model="formData[item.basic_settings.field]"
                                :placeholder="item.basic_settings.placeholder" clearable @focus="onSelectRadio(item)">
                                <el-option v-for="items in item.options" :label="items[item.model_class_field]"
                                    :value="items[item.model_class_value]" :key="items[item.model_class_value]">
                                </el-option>
                            </el-select>
                        </template>
                        <template v-else-if="item.type === '下拉选择框(多选)'">
                            <el-select style="width: 100%;" v-model="formData[item.basic_settings.field]" multiple
                                collapse-tags :placeholder="item.basic_settings.placeholder" clearable
                                @focus="onSelectMultiple(item)">
                                <el-option v-for="items in item.options" :label="items[item.model_class_field]"
                                    :value="items[item.model_class_value]" :key="items[item.model_class_value]">
                                </el-option>
                            </el-select>
                        </template>
                        <template v-else-if="item.type === '对象(单选)'">
                            <div>
                                <el-input style="width: 100%;" v-model="formData[item.basic_settings.field].label"
                                    :placeholder="item.basic_settings.placeholder" clearable
                                    @keyup.enter.native="onCustomerRadio(item, formData[item.basic_settings.field].label)"
                                    @clear="onCustomerClear(item.basic_settings.field)">
                                    <el-button slot="prepend" icon="el-icon-search"
                                        @click="onCustomerRadio(item, formData[item.basic_settings.field].label)"></el-button>
                                </el-input>
                            </div>
                        </template>
                        <template v-else-if="item.type === '对象(多选)'">
                            <div style="box-sizing: border-box; background-color: #fff;border-radius: 4px;overflow: hidden; display: flex;align-items: center;min-width: 240px;"
                                class="el-input-group--prepend">
                                <div class="el-input-group__prepend">
                                    <el-button size="mini" icon="el-icon-search"
                                        @click="onCustomerMultiple(item)"></el-button>
                                </div>
                                <div class="el-input__inner tag_el_input"
                                    :class="[formData[item.basic_settings.field].length > 0 ? 'border_radius0' : 'border_radius4']"
                                    style="min-width: 40px;border-top-left-radius: 0px !important;border-bottom-left-radius: 0px !important;">
                                    <el-input v-model="customerValues" :placeholder="item.basic_settings.placeholder"
                                        @keyup.enter.native="onCustomerMultiple(item.basic_settings.field)"></el-input>
                                </div>
                                <div v-if="formData[item.basic_settings.field].length > 0" style="min-width: 120px;"
                                    class="input_tag" v-horizontal-scroll>
                                    <div v-if="formData[item.basic_settings.field].length === 1" class="input_tag_1">
                                        <div style="width: 90px; font-size: 12px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; padding-left: 5px;"
                                            :key="index" v-for="(tag, index) in formData[item.basic_settings.field]">
                                            {{ tag.label }}
                                        </div>
                                        <i class="el-icon-close" @click="onTagClose(item.basic_settings.field, 0)"></i>
                                    </div>
                                    <el-popover v-else placement="bottom" trigger="click">
                                        <div>
                                            <el-tag style="font-size: 12px;cursor: pointer !important;" :key="index"
                                                v-for="(tag, index) in formData[item.basic_settings.field]" closable
                                                :disable-transitions="false"
                                                @close="onTagClose(item.basic_settings.field, index)">
                                                {{ tag.label }}
                                            </el-tag>
                                        </div>
                                        <div slot="reference"
                                            style="font-size: 12px; display: flex;align-items: baseline;cursor: pointer !important;">
                                            <div
                                                style="width: 40px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; padding-left: 5px;">
                                                {{ formData[item.basic_settings.field][0].label }}
                                            </div>
                                            <div>
                                                外共{{
                                                    (formData[item.basic_settings.field].length - 1) }}项
                                            </div>
                                        </div>
                                    </el-popover>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="item.type === '日期选择(范围)'">
                            <el-date-picker style="width: 100%;" v-model="formData[item.basic_settings.field]"
                                type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
                                end-placeholder="结束日期" format="yyyy/MM/dd" value-format="yyyy/MM/dd"
                                :picker-options="pickerOptions" clearable>
                            </el-date-picker>
                        </template>
                        <template v-else-if="item.type === '日期选择'">
                            <el-date-picker style="width: 100%;" v-model="formData[item.basic_settings.field]" type="date"
                                align="right" :placeholder="item.basic_settings.placeholder" format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd" clearable>
                            </el-date-picker>
                        </template>
                        <template v-else-if="item.type === '组织架构(人员单选)'">
                            <div style="width: 100%;">
                                <OrgSelect @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                    :isCheckbox="false" :value="formData[item.basic_settings.field]" :tab="'user'"
                                    buttonType="input" :title="item.basic_settings.show_name">
                                </OrgSelect>
                                <el-input style="display: none;" v-model="formData[item.basic_settings.field]"
                                    placeholder="请输入内容"></el-input>
                            </div>
                        </template>
                        <template v-else-if="item.type === '组织架构(人员多选)'">
                            <div style="width: 100%;">
                                <OrgSelect @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                    :isCheckbox="true" :value="formData[item.basic_settings.field]" :tab="'user'"
                                    buttonType="input" :title="item.basic_settings.show_name">
                                </OrgSelect>
                                <el-input style="display: none;" v-model="formData[item.basic_settings.field]"
                                    placeholder="请输入内容"></el-input>
                            </div>
                        </template>
                        <template v-else-if="item.type === '组织架构(部门单选)'">
                            <div style="width: 100%;">
                                <OrgSelect @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                    :isCheckbox="false" :value="formData[item.basic_settings.field]" :tab="'dep'"
                                    buttonType="input" :title="item.basic_settings.show_name">
                                </OrgSelect>
                            </div>
                        </template>
                        <template v-else-if="item.type === '组织架构(部门多选)'">
                            <div style="width: 100%;">
                                <OrgSelect @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                    :isCheckbox="true" :value="formData[item.basic_settings.field]" :tab="'dep'"
                                    buttonType="input" :title="item.basic_settings.show_name">
                                </OrgSelect>
                            </div>
                        </template>
                        <template v-else-if="item.type === '表格'">
                            <div>
                                <slot name="shortcut_button"></slot>
                            </div>
                            <el-table show-summary :data="formData[item.basic_settings.field]" ref="table_ref"
                                width="1300px" size="mini" border :header-cell-style="{ 'text-align': 'center' }"
                                :cell-style="{ textAlign: 'center' }">
                                <el-table-column label="序号" type="index" width="60px"></el-table-column>
                                <el-table-column label="设置" width="100px" prop="setting">
                                    <template slot-scope="scope">
                                        <el-button type="text" @click="onPushTable(item)">添加</el-button>
                                        <el-button type="text" @click="onDeleteTable(scope, item)">删除</el-button>
                                    </template>
                                </el-table-column>
                                <template v-for="(column, j) in item.particulars">
                                    <el-table-column v-if="column.is_show && column.type == '数组'" :label="column.label"
                                        :prop="column.field" width="240px" :key="j">
                                        <template slot-scope="scope">
                                            <el-input v-if="column.is_edit" size="mini" v-model="scope.row[column.field]"
                                                clearable
                                                @keyup.enter.native="onParticulars(scope.$index, column, scope.row[column.field], item.particulars)"
                                                @blur.stop="onParticulars(scope.$index, column, scope.row[column.field], item.particulars)">
                                                <el-button size="mini" slot="prepend" icon="el-icon-search pointer"
                                                    @click="onParticulars(scope.$index, column, scope.row[column.field], item.particulars)"></el-button>
                                            </el-input>
                                            <div v-else>
                                                {{ scope.row[column.field] }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column v-if="column.is_show && column.type == '文本'" :label="column.label"
                                        :prop="column.field" :width="column.label == '单位' ? '60px' : '200px'" :key="j">
                                        <template slot-scope="scope">
                                            <el-input v-if="column.is_edit" v-model="scope.row[column.field]"
                                                placeholder="请输入内容" clearable></el-input>
                                            <div v-else>
                                                {{ scope.row[column.field] }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column v-if="column.is_show && column.type == '数字'" :label="column.label"
                                        :prop="column.field" width="200px" :key="j">
                                        <template slot-scope="scope">
                                            <el-input v-if="column.is_edit" size="mini" type="number"
                                                v-model="scope.row[column.field]" clearable>
                                            </el-input>
                                            <div v-else>
                                                <div>
                                                    {{ scope.row[column.field] }}
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </template>
                            </el-table>
                        </template>
                    </el-form-item>
                </template>
            </div>
            <div v-else style="display: flex;flex-wrap: wrap;">
                <templat :style="{ width: item.type === '多文本输入框' || item.type === '表格' ? '100%' : '340px', }"
                    v-for="(item, i) in settingForm.control_setting"
                    v-if="onIsRoleId(item.visible_rule.role_limit, role_ids) && item.permissions.show">
                    <el-form-item :label="item.basic_settings.show_name"
                        :class="[item.type == '多文本输入框' || item.type == '表格' ? 'input_textarea' : '']"
                        :prop="item.type == '对象(单选)' ? item.basic_settings.field + '.label' : item.basic_settings.field"
                        :key="i">
                        <template v-if="item.type === '文本输入框'">
                            <el-input style="width: 220px;" v-model="formData[item.basic_settings.field]"
                                :placeholder="item.basic_settings.placeholder" clearable>
                            </el-input>
                        </template>
                        <template v-else-if="item.type === '多文本输入框'">
                            <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 16 }"
                                v-model="formData[item.basic_settings.field]"
                                :placeholder="item.basic_settings.placeholder">
                            </el-input>
                        </template>
                        <template v-else-if="item.type === '数字输入框'">
                            <el-input-number style="width: 220px;" controls-position="right" :min="minValue(item.numeric_settings.max_digits,
                                item.numeric_settings.decimal_places)" :max="maxValue(item.numeric_settings.max_digits,
        item.numeric_settings.decimal_places)" :precision="item.numeric_settings.decimal_places"
                                v-model="formData[item.basic_settings.field]"
                                :placeholder="item.basic_settings.placeholder">
                            </el-input-number>
                        </template>
                        <template v-else-if="item.type === '开关'">
                            <div style="width: 220px;">
                                <el-switch v-model="formData[item.basic_settings.field]" active-color="#13ce66"
                                    inactive-color="#ff4949">
                                </el-switch>
                            </div>
                        </template>
                        <template v-else-if="item.type === '下拉选择框(单选)'">
                            <el-select style="width: 220px;" v-model="formData[item.basic_settings.field]"
                                :placeholder="item.basic_settings.placeholder" clearable @focus="onSelectRadio(item)">
                                <el-option v-for="items in item.options" :label="items[item.model_class_field]"
                                    :value="items[item.model_class_value]" :key="items[item.model_class_value]">
                                </el-option>
                            </el-select>
                        </template>
                        <template v-else-if="item.type === '下拉选择框(多选)'">
                            <el-select style="width: 220px;" v-model="formData[item.basic_settings.field]" multiple
                                collapse-tags :placeholder="item.basic_settings.placeholder" clearable
                                @focus="onSelectMultiple(item)">
                                <el-option v-for="items in item.options" :label="items[item.model_class_field]"
                                    :value="items[item.model_class_value]" :key="items[item.model_class_value]">
                                </el-option>
                            </el-select>
                        </template>
                        <template v-else-if="item.type === '对象(单选)'">
                            <div>
                                <el-input style="width: 220px;" v-model="formData[item.basic_settings.field].label"
                                    :placeholder="item.basic_settings.placeholder" clearable
                                    @keyup.enter.native="onCustomerRadio(item, formData[item.basic_settings.field].label)"
                                    @clear="onCustomerClear(item.basic_settings.field)">
                                    <el-button slot="prepend" icon="el-icon-search"
                                        @click="onCustomerRadio(item, formData[item.basic_settings.field].label)"></el-button>
                                </el-input>
                            </div>
                        </template>
                        <template v-else-if="item.type === '对象(多选)'">
                            <div style="box-sizing: border-box; background-color: #fff;border-radius: 4px;overflow: hidden; display: flex;align-items: center;min-width: 240px;"
                                class="el-input-group--prepend">
                                <div class="el-input-group__prepend">
                                    <el-button size="mini" icon="el-icon-search"
                                        @click="onCustomerMultiple(item)"></el-button>
                                </div>
                                <div class="el-input__inner tag_el_input"
                                    :class="[formData[item.basic_settings.field].length > 0 ? 'border_radius0' : 'border_radius4']"
                                    style="min-width: 40px;border-top-left-radius: 0px !important;border-bottom-left-radius: 0px !important;">
                                    <el-input v-model="customerValues" :placeholder="item.basic_settings.placeholder"
                                        @keyup.enter.native="onCustomerMultiple(item.basic_settings.field)"></el-input>
                                </div>
                                <div v-if="formData[item.basic_settings.field].length > 0" style="min-width: 120px;"
                                    class="input_tag" v-horizontal-scroll>
                                    <div v-if="formData[item.basic_settings.field].length === 1" class="input_tag_1">
                                        <div style="width: 90px; font-size: 12px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; padding-left: 5px;"
                                            :key="index" v-for="(tag, index) in formData[item.basic_settings.field]">
                                            {{ tag.label }}
                                        </div>
                                        <i class="el-icon-close" @click="onTagClose(item.basic_settings.field, 0)"></i>
                                    </div>
                                    <el-popover v-else placement="bottom" trigger="click">
                                        <div>
                                            <el-tag style="font-size: 12px;cursor: pointer !important;" :key="index"
                                                v-for="(tag, index) in formData[item.basic_settings.field]" closable
                                                :disable-transitions="false"
                                                @close="onTagClose(item.basic_settings.field, index)">
                                                {{ tag.label }}
                                            </el-tag>
                                        </div>
                                        <div slot="reference"
                                            style="font-size: 12px; display: flex;align-items: baseline;cursor: pointer !important;">
                                            <div
                                                style="width: 40px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; padding-left: 5px;">
                                                {{ formData[item.basic_settings.field][0].label }}
                                            </div>
                                            <div>
                                                外共{{
                                                    (formData[item.basic_settings.field].length - 1) }}项
                                            </div>
                                        </div>
                                    </el-popover>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="item.type === '日期选择(范围)'">
                            <el-date-picker style="width: 220px;" v-model="formData[item.basic_settings.field]"
                                type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
                                end-placeholder="结束日期" format="yyyy/MM/dd" value-format="yyyy/MM/dd"
                                :picker-options="pickerOptions" clearable>
                            </el-date-picker>
                        </template>
                        <template v-else-if="item.type === '日期选择'">
                            <el-date-picker style="width: 220px;" v-model="formData[item.basic_settings.field]" type="date"
                                align="right" :placeholder="item.basic_settings.placeholder" format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd" clearable>
                            </el-date-picker>
                        </template>
                        <template v-else-if="item.type === '组织架构(人员单选)'">
                            <div style="width: 220px;">
                                <OrgSelect @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                    :isCheckbox="false" :value="formData[item.basic_settings.field]" :tab="'user'"
                                    buttonType="input" :title="item.basic_settings.show_name">
                                </OrgSelect>
                                <el-input style="display: none;" v-model="formData[item.basic_settings.field]"
                                    placeholder="请输入内容"></el-input>
                            </div>
                        </template>
                        <template v-else-if="item.type === '组织架构(人员多选)'">
                            <div style="width: 220px;">
                                <OrgSelect @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                    :isCheckbox="true" :value="formData[item.basic_settings.field]" :tab="'user'"
                                    buttonType="input" :title="item.basic_settings.show_name">
                                </OrgSelect>
                                <el-input style="display: none;" v-model="formData[item.basic_settings.field]"
                                    placeholder="请输入内容"></el-input>
                            </div>
                        </template>
                        <template v-else-if="item.type === '组织架构(部门单选)'">
                            <div style="width: 220px;">
                                <OrgSelect @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                    :isCheckbox="false" :value="formData[item.basic_settings.field]" :tab="'dep'"
                                    buttonType="input" :title="item.basic_settings.show_name">
                                </OrgSelect>
                            </div>
                        </template>
                        <template v-else-if="item.type === '组织架构(部门多选)'">
                            <div style="width: 220px;">
                                <OrgSelect @input="(val) => { onOrganization(val, item.basic_settings, item.type) }"
                                    :isCheckbox="true" :value="formData[item.basic_settings.field]" :tab="'dep'"
                                    buttonType="input" :title="item.basic_settings.show_name">
                                </OrgSelect>
                            </div>
                        </template>
                        <template v-else-if="item.type === '表格'">
                            <div>
                                <slot name="shortcut_button"></slot>
                            </div>
                            <el-table show-summary :data="formData[item.basic_settings.field]" ref="table_ref"
                                width="1300px" size="mini" border :header-cell-style="{ 'text-align': 'center' }"
                                :cell-style="{ textAlign: 'center' }">
                                <el-table-column label="序号" type="index" width="60px"></el-table-column>
                                <el-table-column label="设置" width="100px" prop="setting">
                                    <template slot-scope="scope">
                                        <el-button type="text" @click="onPushTable(item)">添加</el-button>
                                        <el-button type="text" @click="onDeleteTable(scope, item)">删除</el-button>
                                    </template>
                                </el-table-column>
                                <template v-for="(column, j) in item.particulars">
                                    <el-table-column v-if="column.is_show && column.type == '数组'" :label="column.label"
                                        :prop="column.field" width="240px" :key="j">
                                        <template slot-scope="scope">
                                            <el-input v-if="column.is_edit" size="mini" v-model="scope.row[column.field]"
                                                clearable
                                                @keyup.enter.native="onParticulars(scope.$index, column, scope.row[column.field], item.particulars)"
                                                @blur.stop="onParticulars(scope.$index, column, scope.row[column.field], item.particulars)">
                                                <el-button size="mini" slot="prepend" icon="el-icon-search pointer"
                                                    @click="onParticulars(scope.$index, column, scope.row[column.field], item.particulars)"></el-button>
                                            </el-input>
                                            <div v-else>
                                                {{ scope.row[column.field] }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column v-if="column.is_show && column.type == '文本'" :label="column.label"
                                        :prop="column.field" :width="column.label == '单位' ? '60px' : '200px'" :key="j">
                                        <template slot-scope="scope">
                                            <el-input v-if="column.is_edit" v-model="scope.row[column.field]"
                                                placeholder="请输入内容" clearable></el-input>
                                            <div v-else>
                                                {{ scope.row[column.field] }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column v-if="column.is_show && column.type == '数字'" :label="column.label"
                                        :prop="column.field" width="200px" :key="j">
                                        <template slot-scope="scope">
                                            <el-input v-if="column.is_edit" size="mini" type="number"
                                                v-model="scope.row[column.field]" clearable>
                                            </el-input>
                                            <div v-else>
                                                <div>
                                                    {{ scope.row[column.field] }}
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </template>
                            </el-table>
                        </template>
                    </el-form-item>
                </templat>
            </div>
            <el-form-item v-if="isFormBtn">
                <div style="display: flex;justify-content: flex-end;">
                    <el-button type="primary" @click="onSubmit">提交</el-button>
                    <el-button type="danger" @click="onReset">重置</el-button>
                </div>
            </el-form-item>
        </el-form>
        <inquireDialog v-if="isCustomer" :isVisible="isCustomer" :obj="customerDialogObj" :title="'客户'" :isChoose="true"
            :searchValue="customerValue" @onIsVisible="onIsVisible" @onApplied="onApplied">
        </inquireDialog>
        <inquireDialog v-if="isCustomers" :isVisible="isCustomers" :obj="customerDialogObj" :title="'客户'" :isChoose="false"
            :searchValue="customerValues" @onIsVisible="onIsVisibles" @onApplied="onApplieds">
        </inquireDialog>
        <inquireDialog v-if="isParticulars" :isVisible="isParticulars" :obj="particularsObj" :title="'品目信息'"
            :isChoose="false" :searchValue="particularsValues" @onIsVisible="onIsVisiblesParticulars"
            @onApplied="onAppliedParticulars">
        </inquireDialog>
        <SettingDialog :title="'测试表单'" :isSetting="isSetting" :settingForm="settingForm" @onIsVisible="onSettingIsVisible">
        </SettingDialog>
    </div>
</template>
<script>
import { EventBus } from '@/components/ProcessFlow/CRM/Forms/components/eventBus';
import OrgSelect from "@/components/OA/components/FormControls/OrgSelect/index.vue"
import inquireDialog from "@/components/ProcessFlow/CRM/Forms/components/Dialog.vue"
import SettingDialog from "@/components/ProcessFlow/CRM/Forms/components/SettingDialog.vue";
import {
    yesterdayRange,
    todayRange,
    currentWeekRange,
    lastWeekRange,
    currentMonthRange,
    lastMonthRange,
    currentMoneyMonth,
    lastMoneyMonth
} from "@/utils/FormDate.js";
export default {
    props: {
        settingForm: {
            type: Object,
            default: {},
        },
        refs: {
            type: String,
            default: "",
        },
        inline: {
            type: Boolean,
            default: false,
        },
        isFormBtn: {
            type: Boolean,
            default: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        }

    },
    components: {
        OrgSelect,
        inquireDialog,
        SettingDialog,
    },
    data() {
        return {
            isEditForm: true,
            isSetting: false,
            role_ids: localStorage.getItem('role_ids'),
            formData: {},
            particularsColumn: null,
            particularsIndex: null,
            particularsValues: null,
            isParticulars: false,
            particularsObj: {
                searchField: "search",
                url: "crm/special_offer/",
                params: {
                    per_page: 10,
                    page: 1,
                    model_name: "SpecialOfferTable",
                    sys_name: 3,
                    user: localStorage.getItem("user_id"),
                    search: "",
                    distinct: 1,
                    is_using: true,
                },
            },
            controlArr: [],
            rules: {},

            //客户多选
            isCustomers: false,
            //客户多选
            customerValues: null,
            customerFields: null,
            customerLabels: null,
            customerLabels1: null,

            //客户单选
            customerValue: null,
            customerField: null,
            customerLabel: null,
            customerLabel1: null,
            // 客户单选
            isCustomer: false,

            // 客户Dialog参数
            customerDialogObj: {
                searchField: "search",
                url: null,
                params: {
                    per_page: 10,
                    page: 1,
                    user_id: localStorage.getItem("user_id"),
                    search: ""
                }
            },

            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        // console.log(todayRange);
                        picker.$emit('pick', todayRange.split('-'));
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        picker.$emit('pick', yesterdayRange.split('-'));
                    }
                }, {
                    text: '本周',
                    onClick(picker) {
                        picker.$emit('pick', currentWeekRange.split('-'));
                    }
                }, {
                    text: '上周',
                    onClick(picker) {
                        picker.$emit('pick', lastWeekRange.split('-'));
                    }
                }, {
                    text: '本月',
                    onClick(picker) {
                        picker.$emit('pick', currentMonthRange.split('-'));
                    }
                }, {
                    text: '上月',
                    onClick(picker) {
                        picker.$emit('pick', lastMonthRange.split('-'));
                    }
                }, {
                    text: '本财务月',
                    onClick(picker) {
                        picker.$emit('pick', currentMoneyMonth.split('-'));
                    }
                }, {
                    text: '上财务月',
                    onClick(picker) {
                        picker.$emit('pick', lastMoneyMonth.split('-'));
                    }
                },]
            },
        }
    },
    methods: {
        onPushTable(data) {
            let obj = {}
            data.particulars.forEach(item => {
                obj[item.field] = null
            })
            this.formData[data.basic_settings.field].push(obj)
        },
        onDeleteTable(column, data) {
            // console.log(this.formData[data.basic_settings.field].length)
            if (this.formData[data.basic_settings.field].length > 1) {
                this.formData[data.basic_settings.field].splice(column.$index, 1)
            }
        },
        handleIsEditForm(val) {
            this.isEditForm = val
        },
        onAppliedParticulars(val) {
            // console.log(this.particularsIndex);
            // console.log(this.particularsColumn);
            let arr = []
            let obj1 = {}
            val.forEach(items => {
                let obj = {}
                this.particularsColumn.forEach(item => {
                    if (item.acquire != null && item.acquire != '') {
                        // console.log(items[item.acquire]);
                        obj[item.field] = items[item.acquire]
                    } else {
                        obj[item.field] = null
                    }
                    obj1[item.field] = null
                })
                arr.push(obj)
            })
            console.log(arr, obj1);
            this.formData.details.splice(this.particularsIndex, 1, ...arr, obj1)
        },
        onIsVisiblesParticulars(val) {
            this.isParticulars = val
        },
        onParticulars(index, item, str, particulars) {
            this.particularsObj.url = item.url
            this.particularsIndex = index
            this.particularsValues = str
            this.particularsColumn = particulars
            this.isParticulars = true;
        },
        onIsSetting() {
            this.isSetting = true
        },
        onSettingIsVisible(val) {
            this.isSetting = val
            this.getSettingForm()
        },
        async getSettingForm() {
            const res = await this.$http({
                method: "GET",
                url: "rbac/form_control_setting/",
                params: {
                    form_root: this.settingForm.form_root,
                    classification: this.settingForm.classification,
                }
            })
            if (res.data[0] != null) {
                this.settingForm = JSON.parse(JSON.stringify(res.data[0]))
            }
        },
        onSelectRadio(val) {
            this.getSelect(val)
        },
        onSelectMultiple(val) {
            this.getSelect(val)
        },
        async getSelect(val) {
            this.isEditForm = false
            if (val.url != null && val.url != '') {
                const res = await this.$http({
                    method: "GET",
                    url: val.url,
                })
                if (val.url == 'crm/customer_payment_days_verify/?customer_id=1') {
                    val.options = res.data.credit_rule_choice
                } else {
                    val.options = res.data
                }
            }
            setTimeout(() => {
                this.isEditForm = true
            }, 500);
        },
        onOrganization(val, item, type) {
            console.log(item);
            console.log(type);
            console.log(val);
            if (type === '组织架构(人员单选)') {
                this.formData[item.fields] = val[0].user_sys_id
            } else if (type === '组织架构(人员多选)') {
                let arr = []
                val.forEach(item => {
                    arr.push(item.user_sys_id)
                })
                if (this.formData[item.fields] != null) {

                    this.formData[item.fields] = [...this.formData[item.fields], ...arr]
                } else {
                    this.formData[item.fields] = [...arr]
                }
            } else if (type === '组织架构(部门单选)') {

            } else {

            }
            this.formData[item.field] = val

        },
        onTagClose(str, index) {
            this.formData[str].splice(index, 1)
        },
        onSubmit() {
            this.$refs[this.refs].validate((valid) => {
                if (valid) {
                    this.$emit('onAppliedForm', this.formData)
                } else {
                    return false;
                }
            })
        },
        onReset() {
            this.$refs[this.refs].resetFields()
        },
        onCustomerClear(val) {
            this.formData[val] = {
                label: null,
                value: null,
            }
        },
        onCustomersClear(val) {
            this.formData[val] = null
        },
        onCustomerRadio(item, str) {
            this.customerField = item.model_class_field
            this.customerDialogObj.url = item.url
            this.customerLabel = item.basic_settings.field
            this.customerLabel1 = item.basic_settings.fields
            this.customerValue = str
            // console.log(item.url, '测试');
            this.isCustomer = true
        },
        onCustomerMultiple(item) {
            this.customerFields = item.model_class_field
            this.customerDialogObj.url = item.url
            this.customerLabels = item.basic_settings.field
            this.customerLabels1 = item.basic_settings.fields
            this.isCustomers = true
        },
        // 客户多选
        onIsVisibles(val) {
            this.isCustomers = val
        },
        //客户多选参数
        onApplieds(val) {
            let arr = []
            let idArr = []
            val.forEach(item => {
                let obj = {
                    label: item[this.customerFields],
                    value: item.id,
                }
                arr.push(obj)
                idArr.push(item.id)
            });
            this.formData[this.customerLabels] = [...this.formData[this.customerLabels], ...arr]
            this.formData[this.customerLabels1] = [...this.formData[this.customerLabels1], ...idArr]
        },
        // 客户单选
        onIsVisible(val) {
            this.isCustomer = val
        },
        //客户单选参数
        onApplied(val) {
            let obj = {
                label: val[this.customerField],
                value: val.id,
            }
            this.formData[this.customerLabel] = obj
            this.formData[this.customerLabel1] = val.id

            console.log(this.customerLabel);
        },
        minValue(num1, num2) {
            return ('-' + ('9'.repeat(num1 - num2) + '.' + ('9'.repeat(num2)))) * 1
        },
        maxValue(num1, num2) {
            return ('9'.repeat(num1 - num2) + '.' + ('9'.repeat(num2))) * 1
        },
        onIsRoleId(val, val1) {
            if (val.length > 0) {
                let flag = null
                val.forEach(item => {
                    if (val1.includes(item)) {
                        return flag = true
                    } else {
                        flag = false
                    }
                })
                return flag
            } else {
                return true;
            }
        }
    },
    watch: {
        settingForm: {
            handler(val) {

                if (this.isEditForm) {
                    let obj = {}
                    let rules = {}
                    val.control_setting.forEach(item => {
                        if (item != null) {
                            if (item.type == '对象(单选)') {
                                if (item.default_value == null || item.default_value == "") {
                                    obj[item.basic_settings.field] = {
                                        label: null,
                                        value: null,
                                    }
                                    obj[item.basic_settings.fields] = null
                                } else {
                                    obj[item.basic_settings.field] = item.default_value
                                    obj[item.basic_settings.fields] = item.default_value.value
                                }
                                if (item.permissions.require) {
                                    rules[item.basic_settings.field + '.label'] = [{ required: true, message: item.basic_settings.placeholder, trigger: [], }]
                                }
                            } else if (item.type == '对象(多选)') {
                                obj[item.basic_settings.field] = []
                                obj[item.basic_settings.fields] = null
                                if (item.permissions.require) {
                                    rules[item.basic_settings.field] = [{ required: true, message: item.basic_settings.placeholder, trigger: [], }]
                                }
                            } else if (item.type == '表格') {
                                obj[item.basic_settings.field] = []
                                let obj1 = {}
                                item.particulars.forEach(item => {
                                    obj1[item.field] = null
                                })
                                obj[item.basic_settings.field].push(obj1)
                            } else {
                                if (item.type == '数字输入框') {
                                    obj[item.basic_settings.field] = item.default_value * 1
                                } else if (item.type == '组织架构(人员单选)' || item.type == '组织架构(人员多选)' || item.type == '组织架构(部门单选)' || item.type == '组织架构(部门多选)') {
                                    obj[item.basic_settings.field] = item.default_value
                                    obj[item.basic_settings.fields] = null
                                } else {
                                    obj[item.basic_settings.field] = item.default_value
                                }
                                if (item.permissions.require) {
                                    rules[item.basic_settings.field] = [{ required: true, message: item.basic_settings.placeholder, trigger: [], }]
                                }
                            }
                        }
                    })
                    this.rules = rules
                    this.formData = obj
                    this.$nextTick(() => {
                        this.$refs[this.refs].resetFields()
                    })
                }
                // console.log(this.isEditForm, JSON.stringify(this.formData), this.formData);
            },
            deep: true,
            immediate: true,
        },
        formData: {
            handler(val) {
                if (this.isFormBtn) {
                } else {
                    this.$emit("onAppliedForm", val)
                }
            },
            deep: true,
            immediate: true,
        }
    },
    mounted() {

    },
    created() {
        EventBus.$on('onIsEditForm', this.handleIsEditForm);
    },
    beforeDestroy() {
        EventBus.$off('onIsEditForm', this.handleIsEditForm);
    },
}
</script>
<style lang="less" scoped>
/deep/.el-form {
    .el-form-item__label {
        text-align: start !important;
    }

    .el-form-item__content {
        margin-left: 100px !important;
    }



    .tags {
        .as-input {
            min-height: 28px;
            line-height: 25px;
        }
    }
}

/deep/.input_textarea {
    .el-form-item__content {
        margin-left: 0 !important;
    }
}

.el-tag+.el-tag {
    margin-left: 5px;
}

.input_tag_1 {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        color: #8fc31f;
    }

    .el-icon-close {
        border-radius: 50%;
        text-align: center;
        position: relative;
        cursor: pointer;
        font-size: 12px;
        height: 16px;
        width: 16px;
        line-height: 16px;
        vertical-align: middle;

        &:hover {
            color: #fff;
            background-color: #8fc31f;
        }
    }
}

/deep/.input_tag {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 30px;
    line-height: 28px;
    overflow-x: scroll;
    white-space: nowrap;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &::-webkit-scrollbar {
        display: none;
    }

    .el-tag {

        box-sizing: border-box;
        height: 25px;
        line-height: 25px;
    }
}

/deep/.tag_el_input {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 30px;
    line-height: 28px;
    border: 1px solid #DCDFE6;
    border-radius: 0;

    .el-input {
        display: flex;
    }

    .el-input__inner {
        border-width: 0;
        padding: 0;
        height: 25px;
        border: 0px solid rgb(89, 87, 87);
        line-height: 25px;
    }
}

.border_radius0 {
    border-radius: 0px !important;
}

.border_radius4 {
    border-radius: 4px !important;
}

// 合计行文本居中
/deep/.el-table__footer-wrapper {
    tr {
        td {
            text-align: center;
        }
    }
}
</style>