<template>
  <div class="orders">
    <h1 v-if="isSave">出库申请</h1>
    <div class="orders_form">
      <el-form size="mini" label-position="left" :disabled="ordersReadonly" :model="ordersObj" label-width="130px"
        :rules="ordersObjRules" :inline="true" ref="outboundOrdersObjRef1">
        <el-form-item label="客户" prop="customerValue">
          <el-input @focus="getInputFocus($event)" v-model="ordersObj.customerValue" placeholder="客户名称"
            @keyup.enter.native="isCustomer = true" @blur.stop="isCustomer = true" clearable>
            <el-button size="mini" slot="prepend" icon="el-icon-search" @click="isCustomer = true"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="业务员" prop="salesman_id">

          <template>
            <el-select v-model="ordersObj.salesman_id" placeholder="请选择" clearable>
              <el-option v-for="item in salesmanList" :label="item.label" :value="item.value" clearable :key="item.value">
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="出库日期" prop="outbound_time">
          <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="出库日期" v-model="ordersObj.outbound_time"
            style="width: 100%" :picker-options="pickerOptions" clearable></el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <div class="btnsBox" v-if="!ordersReadonly">
      <el-button size="mini" type="primary" @click="onSalesOrder">带出订单</el-button>
      <el-button size="mini" type="primary" @click="onSampleSubmission">带出送样单</el-button>
      <el-button size="mini" type="primary" @click="onInventory">查看库存</el-button>
      <el-button size="mini" type="primary" @click="onSalesOutInventory">带出库存</el-button>
    </div>
    <div class="orders_table">
      <el-table v-if="!$store.state.isphone" ref="table_ref" width="1300px" size="mini" :data="ordersObj.products" border
        :header-cell-style="headerRowStyle" :cell-style="cellStyle" show-summary :summary-method="getSummaries">
        <el-table-column label="序号" type="index" width="50px"></el-table-column>
        <el-table-column label="设置" width="100">
          <template slot-scope="data">
            <el-button type="text" @click="onPushOrders">添加</el-button>
            <el-button type="text" @click="onDeleteOrders(data.$index)">删除</el-button>
          </template>
        </el-table-column>
        <el-table-column label="品目编码" prop="product_code" width="256px">
          <template slot-scope="data">
            <el-input @focus="getInputFocus($event)" :disabled="ordersReadonly" size="mini"
              v-model="data.row.product_code" placeholder="品目编码" @keyup.enter.native="onOrdersProductSearch(data.$index)"
              @blur.stop="onOrdersProductSearch(data.$index)" @change="onOrdersChange(data.row.product_code, data.$index)"
              clearable>
              <el-button size="mini" slot="prepend" icon="el-icon-search"
                @click="onOrdersProductSearch(data.$index)"></el-button>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="品目名称" prop="product_title" width="150px">

        </el-table-column>
        <el-table-column label="品目规格" prop="specifications" width="210px">
        </el-table-column>
        <el-table-column label="数量" prop="quantity" width="130px">
          <template slot-scope="data">
            <el-input @focus="getInputFocus($event)" type="number" :disabled="ordersReadonly" :min="1" clearable
              size="mini" v-model="data.row.quantity" placeholder="数量">
            </el-input>
          </template>
        </el-table-column>
        <!-- <el-table-column label="参考单价" prop="special_offer" width="150px">
          <template slot-scope="data">
            <el-input @focus="getInputFocus($event)" size="mini" :disabled="ordersReadonly"
              v-model="data.row.special_offer_price" type="number" placeholder="选择品目编码后查询"
              @keyup.enter.native="onOrdersSpecialSearch(data.$index)"
              @change="onOrdersSpecialChange(data.row.special_offer_price, data.$index)" clearable disabled>
              <el-button size="mini" slot="prepend" icon="el-icon-search"
                @click="onOrdersSpecialSearch(data.$index, data)"></el-button>
            </el-input>
          </template>
        </el-table-column> -->
        <el-table-column label="当前库存" v-if="salesOutInventoryList.length > 0">
          <template slot-scope="data">
            <span>
              {{ salesOutInventoryList[data.$index] != undefined ? salesOutInventoryList[data.$index].quantity
                : '' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="占用数量" v-if="salesOutInventoryList.length > 0">
          <template slot-scope="data">
            <span>
              {{ salesOutInventoryList[data.$index] != undefined ?
                salesOutInventoryList[data.$index].rest_amount : '' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="剩余数量" v-if="salesOutInventoryList.length > 0">
          <template slot-scope="data">
            <span>
              {{ salesOutInventoryList[data.$index] != undefined ?
                salesOutInventoryList[data.$index].temporary_use_quantity : '' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="成交单价" prop="unit_price" width="130px">
          <template slot-scope="data">
            <el-input @focus="getInputFocus($event)" :disabled="ordersReadonly"
              v-if="data.row.special_offer_price != null" size="mini" type="number" v-model="data.row.unit_price"
              @blur="onUnitPrice(data.$index)" placeholder="成交单价" clearable>
            </el-input>
            <el-input @focus="getInputFocus($event)" :disabled="ordersReadonly" v-else size="mini" type="number"
              v-model="data.row.unit_price" @blur="onUnitPrice(data.$index)" placeholder="成交单价" clearable>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="成交金额" prop="total_actual_amount" width="130px">
          <template slot-scope="data">
            <div>
              {{ Thousands(accMul(data.row.quantity, data.row.unit_price, data.$index)) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="产品摘要" prop="abstract" width="210px">
          <template slot-scope="data">
            <el-input @focus="getInputFocus($event)" size="mini" :disabled="ordersReadonly" v-model="data.row.abstract"
              placeholder="摘要" clearable>
            </el-input>
          </template>
        </el-table-column>
      </el-table>
      <div v-else style="border:1px dashed #8fc31f;padding: 10px;border-radius:12px;">
        <div v-if="!ordersReadonly">
          <template v-for="(item, i) in ordersObj.products">
            <div :key="i" style="border:1px solid #DCDFE6; padding:10px; margin-bottom:10px;border-radius:12px;">
              <div style="display: flex;justify-content: space-between;align-items: center;">
                <p> 产品{{ i + 1 }}</p>
                <i @click="onDeleteOrders(i)" class="el-icon-close"></i>
              </div>
              <el-form :model="item">
                <el-form-item label="品目编码" prop="product_code">
                  <el-input @focus="getInputFocus($event)" size="mini" v-model="item.product_code" placeholder="品目编码"
                    @keyup.enter.native="onOrdersProductSearch(i)" @blur.stop="onOrdersProductSearch(i)"
                    @change="onOrdersChange(item.product_code, i)" clearable>
                    <el-button size="mini" slot="prepend" icon="el-icon-search"
                      @click="onOrdersProductSearch(i)"></el-button>
                  </el-input>
                </el-form-item>
                <el-form-item label="品目名称" prop="product_title">
                  <el-input size="mini" v-model="item.product_title" disabled placeholder="品目名称"></el-input>
                </el-form-item>
                <el-form-item label="品目规格" prop="specifications">
                  <el-input size="mini" v-model="item.specifications" disabled placeholder="品目规格"></el-input>
                </el-form-item>
                <el-form-item label="数量" prop="product_title">
                  <el-input @focus="getInputFocus($event)" type="number" :min="1" clearable size="mini"
                    v-model="item.quantity" placeholder="数量">
                  </el-input>
                </el-form-item>
                <el-form-item label="参考单价" prop="product_title">
                  <el-input @focus="getInputFocus($event)" size="mini" type="number" v-model="item.special_offer_price"
                    placeholder="选择品目编码后查询" @keyup.enter.native="onOrdersSpecialSearch(i)"
                    @blur.stop="onOrdersSpecialSearch(i)" @change="onOrdersSpecialChange(item.special_offer_price, i)"
                    clearable>
                    <el-button size="mini" slot="prepend" icon="el-icon-search"
                      @click="onOrdersSpecialSearch(i)"></el-button>
                  </el-input>
                </el-form-item>
                <el-form-item label="成交单价" prop="product_title">
                  <el-input @focus="getInputFocus($event)" v-if="item.special_offer_price != null" size="mini"
                    type="number" v-model="item.unit_price" @blur="onUnitPrice(i)" placeholder="成交单价" clearable>
                  </el-input>
                  <el-input @focus="getInputFocus($event)" v-else size="mini" type="number" v-model="item.unit_price"
                    @blur="onUnitPrice(i)" placeholder="成交单价" clearable>
                  </el-input>
                </el-form-item>
                <el-form-item label="成交金额" prop="product_title">
                  <el-input size="mini" v-if="accMul(item.quantity, item.unit_price, i) == ''" v-model="item.quantity"
                    placeholder=" 请输入内容" disabled></el-input>
                  <span v-else>{{ accMul(item.quantity, item.unit_price, i) }}</span>

                </el-form-item>
                <el-form-item label="产品摘要" prop="product_title">
                  <el-input @focus="getInputFocus($event)" size="mini" v-model="item.abstract" placeholder="摘要" clearable>
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
          </template>
          <div>
            <el-button style="width: 100%;" :disabled="ordersReadonly" @click="onPushOrders" icon="el-icon-plus"
              type="text">添加产品</el-button>
          </div>
        </div>
        <div v-else>
          <div style="padding: 0 0 10px 10px;">总计：{{ Thousands(onTotal(ordersObj.products)) }}</div>
          <template v-for="(item, i) in ordersObj.products">
            <div :key="i" @click="onOrdersObjProducts(item)"
              style=" padding:10px;background-color: #f8ffed; margin-bottom:10px;border-radius:12px;">
              <div style="min-width: 300px; font-weight: 600;font-size:24px;color:  rgb(143,195, 31); padding: 5px;">
                {{ item.product_code }}
              </div>
              <div style="font-weight: 600;font-size:16px; padding: 5px;">{{ item.product_title }}</div>
              <div v-if="ordersObjProductsId.includes(item.product_id)" class="card_item">
                <div>规：</div>
                <div>{{ item.specifications }}</div>
              </div>
              <div class="card_item">
                <div>量：</div>
                <div>{{ Thousands(item.quantity) }}</div>
              </div>
              <div class="card_item">
                <div>价：</div>
                <div>{{ Thousands(item.unit_price) }}({{ Thousands(item.special_offer_price) }})</div>
              </div>
              <div class="card_item">
                <div>总：</div>
                <div>{{ Thousands(accMul(item.quantity, item.unit_price, i)) }}</div>
              </div>
              <div class="card_item">
                <div>摘：</div>
                <div>{{ item.abstract }}</div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="orders_form" style="border: 2px dotted #b5e4c2;margin: 10px 0px;border-radius: 12px; padding-top:15px;">
      <el-form size="mini" label-position="left" :disabled="ordersReadonly" :model="ordersObj" label-width="130px"
        :inline="true" ref="outboundOrdersObjRef2" :rules="ordersObjRules">
        <el-form-item label="交易法人" prop="impersonal_entity">
          <el-select v-model="ordersObj.impersonal_entity" placeholder="请选择" clearable>
            <el-option v-for="item in impersonal_entityList" :label="item.label" :value="item.label" clearable
              :key="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发货仓库" prop="warehouseValue">
          <el-input @focus="getInputFocus($event)" size="mini" v-model="ordersObj.warehouseValue" placeholder="发货仓库"
            @keyup.enter.native="isWarehouse = true" @blur.stop="isWarehouse = true" clearable>
            <el-button slot="prepend" icon="el-icon-search" @click="isWarehouse = true"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="货币类型" prop="currency_type_id">
          <el-select v-model="ordersObj.currency_type_id" placeholder="请选择" clearable>
            <el-option v-for="item in currencyOptions" :label="item.label" :value="item.value" clearable
              :key="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款方式" prop="payment_method">
          <el-select v-model="ordersObj.payment_method" :disabled="ordersObj.payment_method == '款到发货' ? true : false"
            placeholder="请选择" clearable>
            <el-option v-for="item in paymentMethodOption" :label="item.label"
              :disabled="(ordersObj.payment_method === '约定账期' && item.label === '额度') ? true : ordersObj.payment_method === '额度' && item.label === '约定账期' ? true : false"
              :value="item.label" clearable :key="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预付款比例(%)" prop="AdvancePayment_ratio" v-if="ordersObj.payment_method == '约定账期'">
          <el-input @focus="getInputFocus($event)" type="number" v-model="ordersObj.AdvancePayment_ratio"
            placeholder="预付款比例" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="运送方式" prop="deliver_method">
          <template>
            <el-select v-model="ordersObj.deliver_method" placeholder="请选择" @change="deliverMethodChange" clearable>
              <el-option v-for="item in deliverMethodOption" :label="item.label" :value="item.label" clearable
                :key="item.value">
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <template v-if="ordersObj.deliver_method == '交货到客户公司' || ordersObj.deliver_method == '交货到客户指定点'">
          <el-form-item label="交货地点/承运人" prop="deliver_addr">
            <el-select size="mini" placeholder="交货地点/承运人" v-model="ordersObj.deliver_addr" allow-create>
              <el-option v-for="item in receiverInformationArr" :label="item.label" :value="item.label" clearable
                :key="item.value">
              </el-option>
            </el-select>
            <el-popover placement="top" trigger="hover" v-model="isDeliverAddrVisible">
              <p>添加地址?</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="isDeliverAddrVisible = false">取消</el-button>
                <el-button type="primary" size="mini" @click="addAddress">确定</el-button>
              </div>
              <i slot="reference" style="margin-left: 5px;font-size:14px;padding: 2px;"
                class="el-icon-circle-plus-outline popovericon"></i>
            </el-popover>
          </el-form-item>
        </template>
        <el-form-item label="交货地点/承运人" v-else-if="ordersObj.deliver_method == '交货给客户指定承运人'" prop="deliver_addr">
          <el-input @focus="getInputFocus($event)" v-model="ordersObj.deliver_addr" placeholder="交货地点/承运人"
            clearable></el-input>
        </el-form-item>
        <template v-if="ordersObj.deliver_method == '交货到客户公司' || ordersObj.deliver_method == '交货到客户指定点'">
          <el-form-item label="收货人" prop="contact_name">
            <el-select size="mini" placeholder="收货人" v-model="ordersObj.consignee_name" allow-create
              @change="consigneeNameChange">
              <el-option v-for="item in contactList" :label="item.contact_name" :value="item.contact_name" clearable
                :key="item.id">
              </el-option>
            </el-select>
            <el-popover placement="top" trigger="hover" v-model="isConsigneeNameVisible">
              <p>添加收货人?</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="isConsigneeNameVisible = false">取消</el-button>
                <el-button type="primary" size="mini" @click="addContact">确定</el-button>
              </div>
              <i slot="reference" style="margin-left: 5px;font-size:14px;padding: 2px;"
                class="el-icon-circle-plus-outline popovericon"></i>
            </el-popover>
          </el-form-item>
        </template>
        <el-form-item label="是否含税" prop="is_tax">
          <el-select @change="onTax" v-model="ordersObj.is_tax" filterable allow-create default-first-option
            placeholder="请选择" clearable>
            <el-option v-for="item in isTaxOption" :key="item.value" :label="item.label" :value="item.value" clearable>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="orders_form" style="border: 2px dotted #bbc3e9; border-radius: 12px;padding-top:15px;">
      <el-form size="mini" :model="ordersObj" label-position="left" :disabled="ordersReadonly" label-width="130px"
        :inline="true" :rules="ordersObjRules" ref="outboundOrdersObjRef3">
        <el-form-item label="账期" prop="payment_days">
          <el-input :disabled="true" @focus="getInputFocus($event)" v-model="ordersObj.payment_days" placeholder="账期"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="有效期" prop="order_life_date">
          <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="有效期" v-model="ordersObj.order_life_date"
            style="width: 100%" :picker-options="pickerOptions" clearable></el-date-picker>
        </el-form-item>
        <el-form-item label="授信额度" prop="credit_limit">
          <el-input :disabled="true" @focus="getInputFocus($event)" v-model="ordersObj.credit_limit" placeholder="授信额度"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="启用特殊账期">
          <el-switch v-model="ordersObj.isSpecialPaymentDays"
            :disabled="ordersObj.payment_method == '约定账期' ? false : true"
            @change="onSpecialPaymentDays(ordersObj.isSpecialPaymentDays)"></el-switch>
        </el-form-item>
        <el-form-item label="特殊账期" prop="special_payment_days" v-if="ordersObj.isSpecialPaymentDays">
          <el-input type="number" @input="handlerInput" :min="0" @focus="getInputFocus($event)"
            v-model="ordersObj.special_payment_days" placeholder="特殊账期" clearable>
          </el-input>
        </el-form-item>
        <br />
        <el-form-item label="其他条款1" prop="other_terms1">
          <el-input @focus="getInputFocus($event)" type="textarea" v-model="ordersObj.other_terms1" placeholder="其他条款1"
            clearable>
          </el-input>
        </el-form-item>
        <br />
        <el-form-item label="其他条款2" prop="other_terms2">
          <el-input @focus="getInputFocus($event)" type="textarea" v-model="ordersObj.other_terms2" placeholder="其他条款2"
            clearable>
          </el-input>
        </el-form-item>
        <br />
        <el-form-item label="其他条款3" prop="other_terms3">
          <el-input @focus="getInputFocus($event)" type="textarea" v-model="ordersObj.other_terms3" placeholder="其他条款3"
            clearable>
          </el-input>
        </el-form-item>
      </el-form>
      <el-dialog :modal="false" :append-to-body="false" :show-close="false" :close-on-click-modal="false"
        :fullscreen="isFullscreen" :visible.sync="isAddAddress" v-dialogDrags width="60%" style="overflow: auto;">
        <!-- <div slot="title" v-if="dialogFromObj != null">{{ dialogFromObj.label }}</div> -->
        <div v-if="dialogFromObj != null" slot="title"
          style="display: flex;justify-content: space-between; align-items: center;;">
          <div class="title">
            <span style="padding: 10px;">{{ dialogFromObj.label }}</span>
          </div>
          <div>
            <i v-if="isFullscreen" class="iconfont icon-zuidahua " style=""
              @click.stop="onFullscreen('isFullscreen')"></i>
            <i v-else class="iconfont icon-chuangkouzuidahua" @click.stop="onFullscreen('isFullscreen')"></i>
            <i class="el-icon-close" @click="isAddAddress = false"></i>
          </div>
        </div>
        <span v-if="dialogFromObj != null">
          <Preview @getlist="getFormList" :dialogForm="dialogForm" :id="dialogFromObj.value"
            :specialDialog="specialDialog">
          </Preview>
        </span>
      </el-dialog>
    </div>
    <inquireDialog v-if="isWarehouse" :isVisible="isWarehouse" :obj="warehouseObj" :title="'仓库'" :isChoose="true"
      :searchValue="ordersObj.warehouseValue" @onIsVisible="warehouseVisible" @onApplied="warehouseApplied">
    </inquireDialog>
    <inquireDialog v-if="isCustomer" :isVisible="isCustomer" :obj="customerDialogObj" :title="'客户'" :isChoose="true"
      :searchValue="ordersObj.customerValue" @onIsVisible="customerVisible" @onApplied="customerApplied">
    </inquireDialog>
    <inquireDialog v-if="isProduct" :isVisible="isProduct" :obj="productDialogObj" :title="'品目编码'" :isChoose="false"
      :searchValue="productValue" @onIsVisible="productVisible" @onApplied="productApplied">
    </inquireDialog>
    <inquireDialog v-if="isSpecial" :isVisible="isSpecial" :obj="specialDialogObj" :title="'特价'" :isChoose="true"
      :searchValue="specialValue" @onIsVisible="specialVisible" @onApplied="specialApplied">
    </inquireDialog>
    <inquireDialog v-if="isSalesOrder" :isVisible="isSalesOrder" :obj="salesOrderObj" :title="'销售订单'" :isChoose="true"
      :searchValue="salesOrderObj.params.search" @onIsVisible="salesOrderVisible" @onApplied="salesOrderApplied">
    </inquireDialog>
    <inquireDialog v-if="isSampleSubmission" :isVisible="isSampleSubmission" :obj="SampleSubmissionObj" :title="'送样单'"
      :isChoose="true" :searchValue="SampleSubmissionObj.params.search" @onIsVisible="SampleSubmissionVisible"
      @onApplied="salesOrderApplied">
    </inquireDialog>
    <div v-if="isSave" class="sales_outbound_orders_footer">
      <div :class="this.$store.state.iscollapse
        ? 'sales_outbound_orders_footer_padding1'
        : 'sales_outbound_orders_footer_padding2'
        "></div>
      <div style="width: 100%; border-top: 1px solid #dbd9d9; padding: 5px 15px">
        <el-button size="mini" type="primary" @click="onSaveOrders" style="margin-top: 10px">保存</el-button>
      </div>
    </div>
    <el-dialog v-if="isInventory" :modal="false" :visible.sync="isInventory" width="50%" :show-close="false" v-dialogDrags
      :close-on-click-modal="false" :fullscreen="isInventoryFullscreen">
      <div slot="title" style="display: flex;justify-content: space-between; align-items: center;;">
        <div class="title">
          <span style="padding: 10px;">库存</span>
        </div>
        <div>
          <i v-if="isInventoryFullscreen" class="iconfont icon-zuidahua " style=""
            @click.stop="onFullscreen('isInventoryFullscreen')"></i>
          <i v-else class="iconfont icon-chuangkouzuidahua" @click.stop="onFullscreen('isInventoryFullscreen')"></i>
          <i class="el-icon-close" @click="isInventory = false"></i>
        </div>
      </div>
      <div style="margin-bottom:10px;display:flex;justify-content:center;">
        <el-input type="text" style="width: 70%;" v-model="inventoryObj.search" @keyup.enter.native="onSearchInventory"
          size="mini" placeholder="请输入内容" clearable>
          <el-button slot="append" type="primary" icon="el-icon-search" @click="onSearchInventory">搜索</el-button>
        </el-input>
      </div>
      <el-table v-loading="isInventoryLoading" class="inventory" :data="tableList[currentPage - 1]" border
        style="border-color: rgb(220, 223, 230);" :cell-style="{
          color: 'rgba(95, 93, 93, 1)', borderColor: 'rgb(220, 223, 230)', backgroundColor: 'rgba(255,255,255,0)',
          textAlign: 'center'
        }" max-height="500px"
        :header-cell-style="{ color: 'rgba(151, 161, 163, 1)', height: '15px', borderColor: 'rgb(220, 223, 230)', backgroundColor: 'rgba(244,246,250,1)', textAlign: 'center' }">
        <el-table-column label="序号" type="index" fixed="left" width="50px"></el-table-column>
        <el-table-column label="品目编码" fixed="left" prop="item_code">
        </el-table-column>
        <el-table-column label="品目名称" prop="item_name">
        </el-table-column>
        <el-table-column label="规格" prop="specifications">
        </el-table-column>
        <el-table-column label="当前库存" prop="quantity" width="120px">
        </el-table-column>
        <el-table-column label="占用数量" prop="temporary_use_quantity" width="120px">
        </el-table-column>
        <el-table-column label="剩余数量" prop="rest_amount" width="120px">
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :page-sizes="[10, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage"
        layout="sizes, prev, pager, next" :total="inventoryList.length">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isInventory = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="isInventory = false" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { debounce } from "@/utils/AntiShake";
import inquireDialog from "@/components/ProcessFlow/CRM/Forms/components/Dialog.vue";
import data from "@/components/Tools/SubassemblyTools/PrintJs/net-provider1";
// oa表单
import Preview from "@/components/OA/components/jsxPreview.vue"
import { tree } from '@/components/OA/components/FormControls/OrgTransfer/tree';
import { log } from 'util';
import Decimal from 'decimal.js';
export default {
  props: ["isSaveAddOutboundOrder", "addOrderForm", "addOrdersCustomer", "formTitle", "readonly", "outboundOrderId"],
  data() {
    return {
      //移动端显示产品规格id
      ordersObjProductsId: [],
      //判断产品成交单价是否低于单价
      is_unit_price: false,

      /* 
      出库单
      */
      //出库是否可编辑
      ordersReadonly: this.readonly == undefined ? false : this.readonly,
      // 传递打印数据
      printData: null,
      // 是否显示
      isSave: this.isSaveAddOutboundOrder === undefined ? true : this.isSaveAddOutboundOrder,
      //出库单参数
      ordersObj: {
        receiver_information_id: null,          //交货地点/承运人下拉选择框选中值
        customerValue: "",
        impersonal_entity: null, //交易法人 1代表宁波亨博电磁技术有限公司  2代表深圳亨贝智控物联有限公司  3代表宁波亨嘉科技有限公司
        impersonalEntityAddress: null, //交易法人地址
        warehouseValue: null,
        ware_house_id: null, //发货仓库 1代表宁波仓  2代表深圳仓
        deliveryDate: null, //交期  1代表订单回签后XX个工作日  2代表预付款后XX个工作日  3代表全款后XX个工作日
        customer_id: "",
        salesman_id: null,
        // order_number: "",
        contract_order_type: 1, //订单固定传1，标识这是一个订单
        outbound_time: "", //出库日期
        order_life_date: "", //订单有效期至
        credit_limit: 0,//信用额度
        isSpecialPaymentDays: false,
        other_terms1: "",
        other_terms2: "",
        other_terms3: "",
        currency_type_id: 1,
        payment_method: "",// 1代表预付款 2代表款到发货，3代表约定账期
        // freight_cost_rule: "",//1代表单笔销售订单金额大于1000，由供方承担，2代表无，3代表需方提供，以订单明细的运费为准
        is_package_recycle: true,//包装是否回收，1代表是，0代表否
        payment_days: null,//账期
        special_payment_days: null,//特殊账期
        AdvancePayment_ratio: null,//预付比例
        deliver_method: "",//交提货方式,1代表工厂交货（客户自提），2代表交货到客户指定点，3代表交货到客户公司，4代表交货给客户指定承运人（运费客户自理）
        deliver_addr: null,//交接地点/承运人
        contact_name: null,       //收货人（默认）（
        consignee_name: null,    //收货人
        consignee_phone: null,   //收货人联系电话
        is_tax: true,
        products: [
          {
            product_code: null,
            product_title: null,
            specifications: null,
            product_id: null, //产品的外键id
            unit_price: null, //入库单价
            quantity: null, //产品数量
            special_offer_obj: null,
            total_actual_amount: null,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
            special_offer_price: null, //
            special_offer_id: null, //可以为空,也就是可以不关联特价申请单id
            abstract: null
          },
          {
            product_code: null,
            product_title: null,
            specifications: null,
            product_id: null, //产品的外键id
            unit_price: null, //入库单价
            quantity: null, //产品数量
            special_offer_obj: null,
            total_actual_amount: null,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
            special_offer_price: null, //
            special_offer_id: null, //可以为空,也就是可以不关联特价申请单id
            abstract: null
          },
        ]
      },
      /*   printList: [
          {
            "title": "客户",
            "vModel": "customerValue"
          },
          {
            "title": "业务员",
            "vModel": "salesman_id"
          },
          {
            "title": "出库日期",
            "vModel": "outbound_time"
          },
          {
            "title": "交易法人",
            "vModel": "impersonal_entity"
          },
          {
            "title": "发货仓库",
            "vModel": "warehouseValue"
          },
          {
            "title": "交货日期类型",
            "vModel": "deliver_date_type"
          },
          {
            "title": "付款方式",
            "vModel": "payment_method"
          },
          {
            "title": "运送方式",
            "vModel": "deliver_method"
          },
          {
            "title": "账期",
            "vModel": "payment_days"
          },
          {
            "title": "订单有效期",
            "vModel": "order_life_date"
          },
          {
            "title": "其他条款1",
            "vModel": "other_terms1"
          },
          {
            "title": "其他条款2",
            "vModel": "other_terms2"
          },
          {
            "title": "其他条款3",
            "vModel": "other_terms3"
          },
          [
            {
              "title": "品目编码",
              "vModel": "product_code"
            },
            {
              "title": "品目名称",
              "vModel": "product_title"
            },
            {
              "title": "品目规格",
              "vModel": "specifications"
            },
            {
              "title": "数量",
              "vModel": "quantity"
            },
            {
              "title": "参考单价",
              "vModel": "special_offer"
            },
            {
              "title": "成交单价",
              "vModel": "unit_price"
            },
            {
              "title": "成交金额",
              "vModel": "total_actual_amount"
            },
            {
              "title": "产品摘要",
              "vModel": "abstract"
            }
          ]
        ], */
      //含税下拉框
      isTaxOption: [{
        label: "是",
        value: true,
      }, {
        label: "否",
        value: false
      }],
      // 业务员列表
      salesmanList: [],
      // 付款方式
      paymentMethodOption: [
        // {
        //     label: "款到发货",
        //     value: 1,
        // },
        {
          label: "款到发货",
          value: 1
        },
        {
          label: "约定账期",
          value: 2
        },
        {
          label: "额度",
          value: 3
        }
      ],
      // 运送方式
      deliverMethodOption: [

        {
          label: "交货到客户公司",
          value: 3,
        },
        {
          label: "交货到客户指定点",
          value: 2,
        },
        {
          label: "客户自提",
          value: 1,
        },
        // {
        //     label: "交货给客户指定承运人",
        //     value: 4,
        // },
      ],
      // 出库日期
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
        ],
      },
      // 交易法人列表
      impersonal_entityList: [
        { value: 1, label: '宁波亨博电磁技术有限公司' },
        { value: 2, label: '深圳亨贝智控物联有限公司' },
        { value: 3, label: '宁波亨嘉科技有限公司' },
      ],
      // shipsWarehouseList: [
      //     { value: 1, label: '宁波仓' },
      //     { value: 2, label: '深圳仓' },
      // ],
      // 交期列表
      deliveryDateList: [
        { value: 1, label: '订单回签后' },
        { value: 2, label: '预付款后' },
        { value: 3, label: '全款后' },
      ],
      //交货地点/承运人
      receiverInformationArr: [],
      // 货币类型
      currencyOptions: [],
      //收货人
      contactList: [],
      // isPrint: false,
      //账期参数
      paymentDaysObj: null,
      //付款方式是否可编辑
      isEditPaymentMethods: true,
      //账期是否禁用可编辑                
      isEditPaymentDays: false,
      //出库单表单验证
      ordersObjRules: {
        customerValue: [
          { required: true, message: "请选择客户", trigger: ["blur", "change"] },
        ],
        salesman_id: [
          { required: true, message: "请选择业务员", trigger: "change" },
        ],
        outbound_time: [
          { required: true, message: "请选择出库日期", trigger: ["blur", "change"] },
        ],
        impersonal_entity: [
          { required: true, message: "请选择交易法人", trigger: ["blur", "change"] },
        ],
        warehouseValue: [
          { required: true, message: "请选择仓库", trigger: ["blur", "change"] },
        ],
        // deliver_date_type: [
        //     { required: true, message: "请选择交货日期类型", trigger: ["blur", "change"] },
        // ],
        currency_type_id: [
          { required: true, message: "请选择货币类型", trigger: ["blur", "change"] },
        ],
        payment_method: [
          { required: true, message: "请选择付款方式", trigger: ["blur", "change"] },
        ],
        AdvancePayment_ratio: [
          { required: true, message: "请输入预付款比例", trigger: ["blur", "change"] },
          {
            min: 0,
            max: 100,
            message: "请输入0 ~ 100",
            trigger: ["blur", "change"],
          },
        ],
        deliver_method: [
          { required: true, message: "请选择提货方式", trigger: ["blur", "change"] },
        ],
        receiver_information_id: [
          { required: true, message: "请选择交货地点/承运人", trigger: ["blur", "change"] },
        ],
        deliver_addr: [
          {
            required: true,
            message: "请输入交货地点/承运人",
            trigger: ["change", "blur"],
          },
        ],
        contact_name: [
          {
            required: true,
            message: "请输入收货人",
            trigger: ["change", "blur"],
          },
        ],
        is_tax: [
          {
            required: true,
            message: "请选择是否含税",
            trigger: ["change", "blur"],
          },
        ],
        payment_days: [
          {
            required: true,
            message: "请输入账期",
            trigger: ["blur", "change"],
          },
        ],
        order_life_date: [
          {
            required: true,
            message: "请选择有效期",
            trigger: ["blur", "change"],
          },
        ],
        special_payment_days: [
          {
            required: true,
            message: "请输入特殊账期",
            trigger: ["blur", "change"],
          },
        ],
      },
      // paymentTransformMethod: '',
      // isForm: false,

      /*
       查询客户
        */
      // 客户名称
      // customerValue: "",
      // 客户对象
      customerObj: {},
      // 客户Dialog开关
      isCustomer: false,
      // 客户Dialog参数
      customerDialogObj: {
        searchField: "search",
        url: "crm/customer_bulk/",
        params: {
          per_page: 10,
          page: 1,
          user_id: localStorage.getItem("user_id"),
          search: ""
        }
      },

      /* 
      查询产品
       */
      //产品名称
      productValue: "",
      // 产品开关
      isProduct: false,
      //产品数组
      productArr: [],
      //订单的产品表格当前index
      quotationTableIndex: "",
      // 产品Dialog参数
      productDialogObj: {
        searchField: 'search',
        url: 'crm/special_offer/',
        params: {
          per_page: 10,
          page: 1,
          model_name: 'SpecialOfferTable',
          sys_name: 3,
          user: localStorage.getItem("user_id"),
          search: "",
          distinct: 1,
          is_using: true
        }
      },

      /* 
      查询发货仓
       */
      //  发货仓库开关
      isWarehouse: false,
      // warehouseValue: null,
      warehouseValueObj: null,
      // 发货仓库Dialog参数
      warehouseObj: {
        searchField: 'keyword',
        url: 'crm/outbound_product_warehouse/',
        params: {
          per_page: 10,
          page_number: 1,
          user_id: localStorage.getItem("user_id"),
          keyword: ""
        }
      },

      /* 
      查询特价 
      */
      // 特价开关
      isSpecial: false,
      // 特价名称
      specialValue: "",
      // 订单的产品当前index
      specialTableIndex: "",
      // 特价Dialog参数
      specialDialogObj: {
        searchField: 'search',
        url: 'crm/order_special_offer/',
        data: {
          // per_page: 10,
          // page_number: 1,
          // user_id: localStorage.getItem("user_id"),
          // keyword: ""
        },
      },

      /*
       带出订单
        */
      //订单开关
      isSalesOrder: false,
      // 订单参数
      salesOrderObj: {
        searchField: 'search',
        url: "crm/order_relationship_table/",
        params: {
          per_page: 10,
          page: 1,
          is_approve: 1,
          is_invalid: 0,
          contract_order_type: 1,
          search: "",
          customer: null,
        },
      },

      /* 
      带出送样单
       */
      // 送样单开关
      isSampleSubmission: false,
      // 送样单参数
      SampleSubmissionObj: {
        searchField: 'search',
        url: "crm/order_relationship_table/",
        params: {
          per_page: 10,
          page: 1,
          customer: null,
          search: "",
          is_approve: 1,
          is_invalid: 0,
          contract_order_type: 4,
        },
      },

      /* 
      查看库存
       */
      //库存数据
      tableList: [],
      //库存条数
      pageSize: 10,
      //库存页码
      currentPage: 1,
      //库存是否全屏
      isInventoryFullscreen: false,
      //库存搜索参数
      inventoryObj: {
        list: true,
        search: "",
      },
      //库存表格数据
      inventoryList: null,
      //是否显示库存
      isInventory: false,
      //库存是否加载
      isInventoryLoading: false,
      //带出库存参数
      salesOutInventoryList: [],

      /* 
      快捷OA表单
       */
      // 是否显示地址提示
      isDeliverAddrVisible: false,
      //是否显示收货人提示
      isConsigneeNameVisible: false,
      //OA表单是否全屏
      isFullscreen: false,
      //OA表单是否显示
      isAddAddress: false,
      // addAddressText: '',
      // 获取OA表单参数
      dialogFromObj: null,
      // formPermission: null,
      // formConfig: null,
      // isSpecialProcess: false,
      // OA表单是否是对话框
      specialDialog: '客户',
      // OA表单客户参数
      dialogForm: null,
    }
  },
  components: {
    inquireDialog,
    Preview,
  },
  mounted() {
    this.getCurrency()
    this.getSalesManList();
    let today = new Date();
    this.ordersObj.outbound_time =
      today.getFullYear() +
      "-" +
      ((today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1)) +
      "-" +
      ((today.getDate()) < 10 ? '0' + (today.getDate()) : (today.getDate()));
    this.ordersObj.order_life_date = this.funDate(180)
    // console.log(this.$route.query.id, this.ordersObj);
    if (this.addOrdersCustomer != undefined) {
      if (this.addOrdersCustomer.customer != undefined) {
        this.customerObj = this.addOrdersCustomer.customer;
        this.ordersObj.customerValue = this.addOrdersCustomer.customer.company_name;
        this.ordersObj.customer_id = this.addOrdersCustomer.customer.id;
      }
      if (this.addOrdersCustomer.salesman != undefined) {
        this.ordersObj.salesman_id = this.addOrdersCustomer.salesman.value;
      }
    }
    if (!this.isSave) {
      console.log(this.isSaveAddOutboundOrder == false && this.outboundOrderId == undefined);
      if (this.formTitle == undefined && this.outboundOrderId == undefined) {
        this.customerObj = this.addOrderForm;
        if (this.addOrderForm.customer != undefined) {
          this.ordersObj.customerValue = this.addOrderForm.customer.company_name;
          this.ordersObj.customer_id = this.addOrderForm.customer.id;
        }
        if (this.addOrderForm.salesman != undefined) {
          this.ordersObj.salesman_id = this.addOrderForm.salesman.value;
        }
        if (this.addOrderForm.quotationId != undefined && this.addOrderForm.quotationId != null && this.addOrderForm.quotationId != '') {
          console.log(this.addOrderForm.quotationId, "addOrderForm.quotationId");

        }
      }
    }

  },
  watch: {
    pageSize: {
      handler(val) {
        this.tableList = []
        // console.log(Math.ceil((this.list.length) / this.pageSize))
        for (var i = 0; i < this.inventoryList.length; i += this.pageSize) {
          this.tableList.push(this.inventoryList.slice(i, i + this.pageSize));
        }
        this.currentPage = 1
      },
      // immediate: true,
      deep: true,
    },
    isAddAddress: {
      handler(val) {
        if (!val) {
          this.dialogFromObj = null
        }
      }
    },
    "ordersObj.customerValue": {
      handler(val) {
        if (this.ordersObj.customer_id == "" || this.ordersObj.customer_id == null || this.ordersObj.payment_method == '款到发货') {

        } else {
          if (!this.readonly) {
            this.getPaymentDays()
          }
        }
      }
    },
    readonly: {
      handler(val) {
        if (val == undefined) {
          this.ordersReadonly = false
        } else {
          this.ordersReadonly = this.readonly
        }
      }, deep: true
    },
    "ordersObj.warehouseValue": {
      handler(val) {
        if (val !== null) {
          this.ordersObj.ware_house_id = this.warehouseValueObj.id
          this.ordersObj.ware_house_title = val
        }
      }
    },
    ordersObj: {
      handler(val) {
        // this.$emit("onAddOutboundOrder", obj)
        if (this.isSaveAddOutboundOrder == false && this.outboundOrderId == undefined) {
          this.$emit("onAddOutboundOrder", this.ordersObj, this.paymentDaysObj)
          console.log(val, this.ordersObj);
        }
      },
      deep: true
    },
    "ordersObj.payment_method": {
      handler(val) {
        if (val == '款到发货') {
          this.ordersObj.payment_days = 0;
          // this.isEditPaymentDays = true               //账期编辑禁用
        } /* else {
          this.paymentTransformMethod = val
          this.isEditPaymentDays = false               //账期编辑启用
          // console.log(this.$route.query.id);
          if (this.$route.query.id == undefined) {
            this.ordersObj.payment_days = this.paymentDaysObj.payment_days;
          }

        } */
        if (val == '约定账期') {
          this.ordersObj.AdvancePayment_ratio = 0;
        } else {
          this.ordersObj.AdvancePayment_ratio = null
        }
      }
    },
    // 特殊账期开关
    'ordersObj.isSpecialPaymentDays': {
      handler(val) {
        if (val) {

        } else {
          this.ordersObj.special_payment_days = null
        }
      }
    },
    "ordersObj.special_payment_days": {
      handler(val) {
        /* if (val == '') {
          this.ordersObj.special_payment_days = null
        }
        // 特殊账期不为空
        if (val != null) {
          // 特殊账期为0
          if (val == 0) {
            this.ordersObj.payment_method = "款到发货"
            this.isEditPaymentMethods = true                    //付款方式禁选
          }
          else if (val > 0) {
            // 特殊账期不为空，不为0
            this.ordersObj.payment_method = this.paymentTransformMethod
            this.isEditPaymentMethods = false
          }
          // 特殊账期小于0  （前端输入框控件已经控制值大于等于0）
          else {

          }
        }
        // 特殊账期为空
        else {
          // 特殊账期为空，账期大于0
          if (this.ordersObj.payment_days > 0) {
            this.ordersObj.payment_method = this.paymentTransformMethod
            this.isEditPaymentMethods = false
          }
          //特殊账期为null，账期小于等于0
          else {
            this.ordersObj.payment_method = "款到发货"
            this.isEditPaymentMethods = true                    //付款方式禁选
          }
        } */
      }
    },
    "ordersObj.payment_days": {
      handler(val) {
        /* console.log(val);
        if (!this.isForm) {
          if (val <= 0) {
            console.log(val);
            this.ordersObj.payment_method = "款到发货"
            if (this.paymentDaysObj.payment_days > 0) {
              this.isEditPaymentMethods = false
            } else {
              this.isEditPaymentMethods = true
            }
          } else {
            this.ordersObj.payment_method = this.paymentTransformMethod
            this.isEditPaymentMethods = false

          }
        } */
      }
    },
    addOrderForm: {
      handler(val) {
        console.log(val);
        console.log(this.formTitle);
        if (this.formTitle == "出库申请(亿看)") {
          if (val != undefined) {
            for (const key in val) {
              this.ordersObj[key] = val[key]
            }
            this.warehouseValueObj = { id: val.ware_house_id }
            if (val.isSpecialPaymentDays != undefined) {
              this.ordersObj.isSpecialPaymentDays = val.isSpecialPaymentDays
            } else {
              this.ordersObj.isSpecialPaymentDays = false
            }
            console.log(val.products, '出库申请(亿看)');

            /*  this.warehouseValueObj = { id: val.ware_house_id }
             this.ordersObj.receiver_information_id = val.receiver_information_id
             this.ordersObj.customerValue = val.customerValue
             this.ordersObj.impersonal_entity = val.impersonal_entity
             this.ordersObj.impersonalEntityAddress = val.impersonalEntityAddress
             this.ordersObj.warehouseValue = val.warehouseValue
             this.ordersObj.ware_house_id = val.ware_house_id
             this.ordersObj.customer_id = val.customer_id
             this.ordersObj.salesman_id = val.salesman_id
             this.ordersObj.contract_order_type = val.contract_order_type
             this.ordersObj.outbound_time = val.outbound_time
             this.ordersObj.order_life_date = val.order_life_date
             this.ordersObj.credit_limit = val.credit_limit
             this.ordersObj.contact_name = val.contact_name
             this.ordersObj.mobile_phone = val.mobile_phone
             this.ordersObj.consignee_name = val.consignee_name
             this.ordersObj.consignee_phone = val.consignee_phone
             this.ordersObj.creator_mobile_phone = val.creator_mobile_phone
             if (val.isSpecialPaymentDays != undefined) {
               this.ordersObj.isSpecialPaymentDays = val.isSpecialPaymentDays
             } else {
               this.ordersObj.isSpecialPaymentDays = false
             }
             this.ordersObj.other_terms1 = val.other_terms1
             this.ordersObj.other_terms2 = val.other_terms2
             this.ordersObj.other_terms3 = val.other_terms3
             this.ordersObj.payment_method = val.payment_method
             this.ordersObj.is_package_recycle = val.is_package_recycle
             this.ordersObj.payment_days = val.payment_days
             this.ordersObj.special_payment_days = val.special_payment_days
             this.ordersObj.AdvancePayment_ratio = val.AdvancePayment_ratio
             this.ordersObj.deliver_method = val.deliver_method
             this.ordersObj.deliver_addr = val.deliver_addr
             // this.ordersObj.deliver_date = val.deliver_date
             // this.ordersObj.deliver_date_type = val.deliver_date_type
             // this.ordersObj.deliver_date_delta = val.deliver_date_delta
             this.ordersObj.ware_house_title = val.ware_house_title
             this.ordersObj.creator_name = val.creator_name
             this.ordersObj.avatar = val.avatar
             this.ordersObj.id = val.id
             this.ordersObj.is_tax = val.is_tax
             this.ordersObj.products = []
             val.products.forEach(item => {
               this.ordersObj.products.push({
                 product_code: item.product_code,
                 product_title: item.product_title,
                 specifications: item.specifications,
                 product_id: item.product_id, //产品的外键id
                 unit_price: parseFloat(item.unit_price).toFixed(3), //入库单价
                 quantity: item.quantity, //产品数量
                 special_offer_obj: item.special_offer_obj,
                 total_actual_amount: item.total_residue_amount,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
                 special_offer_price: parseFloat(item.special_offer_price).toFixed(3), //
                 special_offer_id: item.special_offer, //可以为空,也就是可以不关联特价申请单id
                 abstract: item.abstract,
                 order_id: item.order_id
               })
             }) */
            // this.isForm = true
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "ordersObj.products": {
      handler(val) {
        this.is_unit_price = false
        val.forEach((item, i) => {
          // console.log(val)
          if (item.unit_price != null && item.special_offer_obj != null) {
            if (item.unit_price * 1 < item.special_offer_obj.price * 1) {
              this.is_unit_price = true
            }
          }
        })
        // console.log(this.is_unit_price, "测试")
        this.getBackgroundColor()
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    //获取货币类型
    async getCurrency() {
      const res = await this.$http({
        method: "GET",
        url: "crm/outbound_currency_type/",
      });
      this.currencyOptions = res.data;
    },
    // 库存条数
    handleSizeChange(val) {
      this.pageSize = val
      console.log(`每页 ${val} 条`);
    },
    // 库存页数
    handleCurrentChange(val) {
      this.currentPage = val
      console.log(`当前页: ${val}`);
    },
    // 控制表单背景颜色
    getBackgroundColor() {
      if (this.ordersObj.isSpecialPaymentDays || this.ordersObj.is_tax == false || this.is_unit_price) {
        if (document.querySelectorAll('.card_jsxPreview').length == 0) {
          if (document.querySelectorAll('.dialog_jsxPreview').length == 0) {
            document.querySelectorAll('.drawer_jsxPreview')[0].children[0].children[0].children[1].style.backgroundColor = '#ff00000d'
          } else {
            document.querySelectorAll('.dialog_jsxPreview')[0].children[0].children[1].style.backgroundColor = '#ff00000d'
          }
        } else {
          document.querySelectorAll('.card_jsxPreview')[0].style.backgroundColor = '#ff00000d'
        }
      } else {
        if (document.querySelectorAll('.card_jsxPreview').length == 0) {
          if (document.querySelectorAll('.dialog_jsxPreview').length == 0) {
            document.querySelectorAll('.drawer_jsxPreview')[0].children[0].children[0].children[1].style.backgroundColor = '#fff'
          } else {
            document.querySelectorAll('.dialog_jsxPreview')[0].children[0].children[1].style.backgroundColor = '#fff'
          }
        } else {
          document.querySelectorAll('.card_jsxPreview')[0].style.backgroundColor = '#fff'
        }
      }
    },
    //特殊账期
    onSpecialPaymentDays(val) {
      // console.log(this.ordersObj.isSpecialPaymentDays);
      this.getBackgroundColor()
    },
    // 含税
    onTax() {
      // console.log(this.ordersObj.is_tax)
      this.getBackgroundColor()
    },
    //产品总计
    onTotal(val) {
      let num = null
      val.forEach((item, i) => {
        // num += this.accMul(item.quantity, item.unit_price, i)
        num += (this.accMul(item.quantity, item.unit_price, i)) * 1;
      })
      return num
    },
    // 千分符保留3位小数
    Thousands(num) {
      let num1 = num * 1
      return num1.toString().replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    },
    // 收货人获取电话
    consigneeNameChange(val) {
      console.log(val);
      let arr = this.contactList.filter(v => v.contact_name == val)
      console.log(arr[0]);
      this.ordersObj.consignee_phone = arr[0].mobile_phone
      console.log(this.ordersObj);
    },
    // 产品表格总计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (column.property === "total_actual_amount") {
          const sum = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + value;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = sum.toString().replace(/(\d)(?=(\d{3})+\.)/g, "$1,"); // Add thousand separator
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    //特殊账期
    handlerInput(val) {
      console.log(this.ordersObj);
      console.log(val < 0);
      if (val < 0) {
        this.ordersObj.special_payment_days = 0;
      } else {
        this.ordersObj.special_payment_days = val * 1;
      }
    },
    //选择客户获取账期/收款方式/信用额度
    async getPaymentDays() {
      // console.log(this.ordersObj,this.ordersObj.customer_id,this.ordersObj.customerValue);
      let res = await this.$http.get("crm/customer_payment_days_verify/", { params: { customer_id: this.ordersObj.customer_id } })
      console.log(res.data);
      if (res.status == 200) {
        this.paymentDaysObj = res.data
      } else {
        this.$message.error(res.data)
      }
      this.paymentMethodOption = res.data.credit_rule_choice
      this.ordersObj.payment_days = res.data.payment_days;
      this.ordersObj.payment_method = res.data.credit_rule_display;
      this.ordersObj.credit_limit = res.data.credit_limit;
      /* // 账期为0，付款方式固定为款到发货
      if (res.data.payment_days == 0 || res.data.payment_days < 0) {
        if (!this.isForm) {
          this.ordersObj.payment_method = "款到发货"
        }
        this.isEditPaymentMethods = true            //付款方式禁用
        this.isEditPaymentDays = true               //账期禁用
        // let time = new Date();
        // if(res.data.account_limit_date==null){
        //     console.log("无限制");
        //     this.isEditPaymentDays = false
        // }else if(Date.parse(res.data.account_limit_date)>Date.parse(time)){
        //     console.log("没有过期");
        //     this.isEditPaymentDays = true
        // }else{
        //     console.log("过期喽");
        //     this.isEditPaymentDays = true
        //     this.ordersObj.payment_days = 0
        // }
      }
      // 账期大于0，可选择预付和约定账期
      else if (res.data.payment_days > 0) {
        this.isEditPaymentDays = false
        this.isEditPaymentMethods = false
        // this.paymentMethodOption = [
        //     {
        //         label: "预付",
        //         value: 2,
        //     },
        //     {
        //         label: "约定账期",
        //         value: 3,
        //     },
        // ]
        // let time = new Date();
        // if(res.data.account_limit_date==null){
        //     console.log("无限制");
        //     this.isEditPaymentDays = false
        // }else if(Date.parse(res.data.account_limit_date)>Date.parse(time)){
        //     console.log("没有过期");
        //     this.isEditPaymentDays = false
        // }else{
        //     console.log("过期喽");
        //     this.ordersObj.payment_method = "款到发货"
        //     this.isEditPaymentMethods = true            //付款方式禁用
        //     this.isEditPaymentDays = true               //账期禁用
        //     this.ordersObj.payment_days = 0
        // }
      } */
    },
    async getPaymentInfo(id) {
      let res = await this.$http({
        method: 'GET',
        url: 'crm/customer_payment_days_verify/',
        params: {
          customer_id: id,
        }
      })
      console.log(res);
    },
    // toPrint() {
    //     this.saveTag();
    //     this.isPrint = true
    // },
    // 表单提交
    getInputFocus(event) {
      console.log("event", event)
      event.currentTarget.select();
    },
    //OA表单提交
    getFormList(val) {
      console.log(val);
      if (val.arr.length == 0) {
        console.log(val);
        if (val.data.status == 200) {
          this.deliverMethodChange()
          this.$message.success('提交成功，审批流程已发起')
        } else {
          this.$message.error(val.data.data)
        }
      } else {
        if (val.arr[0].status == 200) {
          this.deliverMethodChange()
          this.$message.success('提交成功，审批流程已发起')
        } else {
          this.$message.error(val.arr[0].data)
        }
      }
      this.isAddAddress = false
      this.dialogFromObj = null
    },
    //  获取生成表单数据
    async getformiconfig(id) {
      const res = await this.$http.get('oa/oa_flow_format/', { params: { flow_id: id } })
      console.log(res)
      this.formPermission = res.data.permission_data
      this.formConfig = res.data.layout_data.formData
      this.formConfig.fields.forEach((item) => {
        console.log(item);
        if (item.label == "客户名称" && item.tag == "fc-autocompletion") {
          item.defaultValue = {
            value: this.dialogForm.customer.id,
            label: this.dialogForm.customer.company_name,
          };
        }
        if (item.label == "客户" && item.tag == "fc-autocompletion") {
          item.defaultValue = {
            value: this.dialogForm.customer.id,
            label: this.dialogForm.customer.company_name,
          };
        }

      });
      this.isSpecialProcess = res.data.is_special_process
    },
    //获取表单
    onPushOA(val) {
      console.log(val);
      this.isAddAddress = true;
      this.dialogForm = {
        customer: {
          company_name: this.ordersObj.customerValue,
          id: this.ordersObj.customer_id,
        },
      };
      this.dialogFromObj = val;
      console.log(val);
      this.getformiconfig(val.value);
    },
    // 添加地址确认请求
    async addAddressSubmit() {
      this.isAddAddress = false
    },
    // 确认添加地址
    addAddress() {
      let obj = {
        label: '收货地址',
        value: 173,
      };
      this.onPushOA(obj);
    },
    // 确认添加地址
    addContact() {
      let obj = {
        label: '联系人',
        value: 92,
      };
      this.onPushOA(obj);
    },
    // 交货日期天数失焦
    dayNumberBlur() {
      // this.ordersObj.deliver_date = this.funDate(Number(this.ordersObj.deliver_date_delta))
      // console.log(this.ordersObj.deliver_date);
    },
    // 提货方式改变
    async deliverMethodChange() {
      this.ordersObj.deliver_addr = null
      if (this.ordersObj.customer_id != '' && this.ordersObj.customer_id != null) {
        if (this.ordersObj.deliver_method == '交货到客户指定点' || this.ordersObj.deliver_method == '交货到客户公司') {
          console.log(this.ordersObj.deliver_method, this.id);
          let res = await this.$http({
            method: 'GET',
            url: 'crm/customer_receiver_information/',
            params: {
              customer: this.ordersObj.customer_id
            }
          });
          if (res.status == 200) {
            this.receiverInformationArr = res.data
            this.receiverInformationArr.forEach((item, index) => {
              if (item.child == true) {
                this.ordersObj.deliver_addr = item.label
              }
            })
          } else {
            this.$message.error(res.data)
          }


          this.ordersObj.contact_name = null
          this.ordersObj.consignee_name = null
          this.ordersObj.consignee_phone = null
          let res1 = await this.$http({
            method: 'GET',
            url: 'crm/contact_bulk/',
            params: {
              user: localStorage.getItem("user_id"),
              customer_id: this.ordersObj.customer_id,
            }
          });
          if (res1.status == 200) {
            console.log(res1.data.table_body_data);
            this.contactList = res1.data.table_body_data
            this.contactList.forEach((item, index) => {
              if (item.is_default == true) {
                this.ordersObj.contact_name = item.contact_name
                this.ordersObj.consignee_name = item.contact_name
                this.ordersObj.consignee_phone = item.mobile_phone
              }
              console.log(this.ordersObj);
            })
          } else {
            this.$message.error(res1.data);
          }
        }
      } else {
        this.$message.warning("请选择客户后再选择运送方式");
      }

    },
    // 仓库开关
    warehouseVisible(data) {
      this.isWarehouse = data
      this.warehouseObj = {
        searchField: 'keyword',
        url: 'crm/outbound_product_warehouse/',
        params: {
          per_page: 10,
          page_number: 1,
          user_id: localStorage.getItem("user_id"),
          keyword: ""
        }
      }
    },
    // 仓库参数
    warehouseApplied(data) {
      this.warehouseValueObj = data
      this.ordersObj.warehouseValue = data.warehouse_name
    },
    //获取单据有效期
    funDate(aa) {
      let date1 = new Date()
      let time1 = date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate();//time1表示当前时间
      let date2 = new Date(date1);
      date2.setDate(date1.getDate() + aa);
      // let time2 = date2.getFullYear()+"-"+((date2.getMonth()+1)<10?"0"+(date2.getMonth()+1):(date2.getMonth()+1))+"-"+date2.getDate()<10?"0"+date2.getDate():date2.getDate();
      let year = date2.getFullYear();
      let month = date2.getMonth() + 1;
      let day = date2.getDate();
      if (month < 10) {
        month = "0" + month
      } if (day < 10) {
        day = "0" + day
      }
      // month<10?'0'+month:month
      // day<10?'0'+day:day
      let time2 = year + "-" + month + "-" + day
      return time2
    },
    //业务员
    async getSalesManList() {
      let res = await this.$http({
        method: 'GET',
        url: 'crm/data_source/',
        params: {
          source: 'salesman',
        }
      })
      if (res.status == 200) {
        this.salesmanList = res.data;
      } else {
        this.$message.error(res.data)
      }

    },

    // 客户Dialog开关
    customerVisible(data) {
      // console.log(data);
      this.isCustomer = data
      this.customerDialogObj = {
        searchField: "search",
        url: "crm/customer_bulk/",
        params: {
          per_page: 10,
          page: 1,
          user_id: localStorage.getItem("user_id"),
          search: ""
        }
      }
    },
    // 客户Dialog数据
    customerApplied(data) {
      this.customerObj = data
      this.ordersObj.customer_id = data.id
      // console.log(data);
      this.ordersObj.customerValue = data.company_name
    },
    // 产品Dialog开关
    productVisible(data) {
      this.isProduct = data
      this.productDialogObj = {
        searchField: 'search',
        url: 'crm/special_offer/',
        params: {
          per_page: 10,
          page: 1,
          model_name: 'SpecialOfferTable',
          sys_name: 3,
          user: localStorage.getItem("user_id"),
          search: "",
          distinct: 1,
          is_using: true
        }
      }
    },
    // 产品Dialog数据
    productApplied(data) {
      console.log(data);
      this.productArr = data
      const arr = []
      this.productArr.forEach(item => {
        arr.push({
          product_code: item.field0,
          product_title: item.item_name,
          specifications: item.specifications,
          product_id: item.product, //产品的外键id
          unit_price: null, //入库单价
          quantity: null, //产品数量
          special_offer_obj: item,
          total_actual_amount: null,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
          special_offer_price: null, //
          special_offer_id: null, //可以为空,也就是可以不关联特价申请单id
          abstract: null
        })
      });
      this.ordersObj.products.splice(this.quotationTableIndex, 1, ...arr)
    },
    //获取当前产名当前下标
    onOrdersProductSearch(index) {
      this.productDialogObj.params.search = this.ordersObj.products[index].product_code
      this.isProduct = true
      this.quotationTableIndex = index
    },
    //品目编码
    onOrdersChange(data, index) {
      console.log(data, index);
      this.productValue = data
      this.quotationTableIndex = index
      this.ordersObj.products[index].product_title = ''
      this.ordersObj.products[index].specifications = ''
      this.ordersObj.products[index].product_id = null
      console.log(this.ordersObj.products[index]);
    },
    // 添加订单的产品
    onPushOrders() {
      this.ordersObj.products.push({
        product_code: null,
        product_title: null,
        specifications: null,
        product_id: null, //产品的外键id
        unit_price: null, //入库单价
        quantity: null, //产品数量
        special_offer_obj: null,
        total_actual_amount: null,//产品总金额，前端计算传到后端，公式：total_actual_amount=unit_price*quantity
        special_offer_price: null, //
        special_offer_id: null, //可以为空,也就是可以不关联特价申请单id
        abstract: null
      })
    },
    //删除报价单的产品
    onDeleteOrders(index) {
      if (this.ordersObj.products.length != 1) {
        this.ordersObj.products.splice(index, 1)
      }
    },
    // 特价的搜索
    async onOrdersSpecialSearch(index, data) {
      console.log(this.ordersObj.products[index].product_id, this.ordersObj.customer_id, this.ordersObj.products[index].quantity);
      if (this.ordersObj.products[index].product_id != null && this.ordersObj.products[index].product_id != ""
        && this.ordersObj.products[index].quantity != null && this.ordersObj.products[index].quantity != ""
        && this.ordersObj.customer_id != null && this.ordersObj.customer_id != '') {
        this.isSpecial = true
        this.specialTableIndex = index
        this.specialDialogObj.url = 'crm/order_special_offer/'
        this.specialDialogObj.data = {
          product_id: this.ordersObj.products[index].product_id,
          customer_id: this.ordersObj.customer_id,
          quantity: Number(this.ordersObj.products[index].quantity),
          user_id: localStorage.getItem("user_id")
        }
        // let res = await this.$http({
        //     method: 'POST',
        //     url: 'crm/order_special_offer/',
        //     data: {
        //         product_id: this.ordersObj.products[index].product_id,
        //         customer_id: this.ordersObj.customer_id,
        //         quantity: this.ordersObj.products[index].quantity,
        //         user_id:localStorage.getItem("user_id")
        //     }
        // })
        // console.log(res);

      } else {
        this.$message.error("请添加产品、数量或客户")
      }
    },
    //参考单价
    onOrdersSpecialChange(title, index) {
      console.log(title, index, "单价");
      this.specialValue = title
      this.specialTableIndex = index
      this.ordersObj.products[index].special_offer_id = null
    },
    // 特价Dialog开关
    specialVisible(val) {
      this.isSpecial = false
      this.specialDialogObj = {
        searchField: 'search',
        url: 'crm/order_special_offer/',
        data: {
          // per_page: 10,
          // page_number: 1,
          // user_id: localStorage.getItem("user_id"),
          // keyword: ""
        },
      }
    },
    // 特价Dialog数据
    specialApplied(val) {
      console.log(val);
      this.ordersObj.products[this.specialTableIndex].special_offer_obj = val
      this.ordersObj.products[this.specialTableIndex].special_offer_price = parseFloat(val.price)
      this.ordersObj.products[this.specialTableIndex].special_offer_id = val.id
      this.ordersObj.products[this.specialTableIndex].unit_price = parseFloat(val.price)
      console.log(this.ordersObj.products[this.specialTableIndex].unit_price);
      if (this.ordersObj.products[this.specialTableIndex].unit_price != null) {
        if (val.price != undefined) {
          if (this.ordersObj.products[this.specialTableIndex].unit_price >= parseFloat(val.price)) {
          } else {
            this.ordersObj.products[this.specialTableIndex].unit_price = null
            this.$message.error('价格不允许')
          }
        } else {
          if (this.ordersObj.products[this.specialTableIndex].unit_price >= val.min_price && this.ordersObj.products[this.specialTableIndex].unit_price <= val.max_price) {
          } else {
            this.ordersObj.products[this.specialTableIndex].unit_price = null
            this.$message.error('价格不允许')
          }
        }
      }
    },
    // 判断单价
    onUnitPrice: debounce(function (index) {

      // console.log(this.ordersObj.products[index].special_offer_obj);
      if (this.ordersObj.products[index].special_offer_obj !== null) {
        console.log(index);
        if (this.ordersObj.products[index].special_offer_obj.price != undefined) {
          console.log(index);
          console.log(this.ordersObj.products[index].unit_price, this.ordersObj.products[index].special_offer_obj.price);
          if (Number(this.ordersObj.products[index].unit_price) >= Number(this.ordersObj.products[index].special_offer_obj.price)) {
          } else {
            if (this.ordersObj.products[index].unit_price != null && this.ordersObj.products[index].unit_price != '') {
              // this.is_unit_price = true
            } else {
              this.ordersObj.products[index].unit_price = ""
              this.$message.error('价格不允许空')
            }
            // this.ordersObj.products[index].unit_price = ""
          }
        } else {
          if (this.ordersObj.products[index].unit_price >= this.ordersObj.products[index].special_offer_obj.min_price && this.ordersObj.products[index].unit_price <= this.ordersObj.products[index].special_offer_obj.max_price) {
          } else {
            console.log("222");
            this.ordersObj.products[index].unit_price = ""
            this.$message.error('价格不允许')
          }
        }
      }
    }, 500),
    // 保存销售订单
    async onSaveOrders() {
      let arr = []
      this.ordersObj.products.forEach((item, i) => {
        if (item.product_id != null) {
          arr.push(item)
          item.quantity = Number(item.quantity)
          item.unit_price = Number(item.unit_price)
        }
      })
      if (arr.length > 0) {
        this.ordersObj.products = arr
      }

      if (this.ordersObj.products[0].product_id == null) {
        this.$message.warning("产品表不能为空")
      } else if (this.ordersObj.customerValue == '') {
        this.$message.warning("请选择客户")
      }
      // else if(this.ordersObj.deliver_method==''){
      //     this.$message.warning("请选择提货方式")
      // }
      // else if(this.ordersObj.freight_cost_rule=''){
      //     this.$message.warning("请选择运费规则")
      // }
      // else if(this.ordersObj.outbound_time=''){
      //     this.$message.warning("请选择下单日期")
      // }
      else {
        let isEmpty = false
        this.ordersObj.products.forEach((item, index) => {
          console.log(item);
          if (item.quantity == '' || item.quantity == null) {
            console.log(item.quantity);
            isEmpty = true;
          }
        })
        console.log(isEmpty);
        if (isEmpty) {
          this.$message.warning("请填写产品数量")
        } else {
          let obj = this.ordersObj
          if (obj.is_package_recycle) {
            obj.is_package_recycle = 1
          } else {
            obj.is_package_recycle = 2
          }
          console.log(this.ordersObj);
          this.ordersObj.payment_days = Number(this.ordersObj.payment_days)
          if (this.ordersObj.special_payment_days != null) {
            this.ordersObj.special_payment_days = Number(this.ordersObj.special_payment_days)
          }
          console.log(this.ordersObj);
          // if(obj.deliver_addr==null||obj.deliver_addr==''){
          //     this.ordersObj.deliver_addr = this.ordersObj.receiver_information_id 
          // }
          let isSpecialIdNull = false
          this.ordersObj.products.forEach((item, i) => {
            if (item.special_offer_id == null || item.special_offer_id == '' || item.unit_price < item.special_offer_price) {
              isSpecialIdNull = true
            }
          })
          // 天数转为数字类型
          // this.ordersObj.deliver_date_delta = Number(this.ordersObj.deliver_date_delta)
          if (isSpecialIdNull == false) {
            this.$refs.ordersObjRef1.validate(async (valid) => {
              if (valid) {
                this.$refs.ordersObjRef2.validate(
                  async (valid) => {
                    if (valid) {
                      this.$refs.ordersObjRef3.validate(
                        async (valid) => {
                          if (valid) {
                            if (obj.products.length > 0) {
                              let address = await this.$http({
                                url: 'crm/customer_bulk/open_sea_list/',
                                method: 'GET',
                                params: {
                                  user: localStorage.getItem('userid'),
                                  job_id: localStorage.getItem('job_id'),
                                  model_name: 'Customer',
                                  sys_name: 3,
                                  query_type: 1,
                                  open_sea_id: 6,
                                  search: obj.impersonal_entity,
                                }
                              })
                              if (address.status == 200) {
                                console.log(address.data.table_body_data);
                                if (address.data.table_body_data.length > 0) {
                                  obj.impersonalEntityAddress = address.data.table_body_data[0].field22
                                }
                                console.log(obj);
                              } else {
                                this.$message.error(res.data)
                                return
                              }
                              if (this.$route.query.id == null) {
                                const res = await this.$http({
                                  url: "crm/order_relationship_table/",
                                  method: "POST",
                                  data: obj
                                })
                                console.log(res);
                                if (res.status === 200) {
                                  this.$message.success('新增成功')
                                } else {
                                  this.$message.error(res.data)
                                }
                              } else {
                                const res = await this.$http({
                                  url: `crm/order_relationship_table/${this.$route.query.id}/`,
                                  method: "PUT",
                                  data: obj
                                })
                                console.log(res);
                                if (res.status === 200) {
                                  this.$message.success('修改成功')
                                } else {
                                  this.$message.error(res.data)
                                }
                              }
                            } else {
                              this.$message.error("请添加产品");
                            }
                          }
                        }
                      );
                    }
                  }
                );
              }
            });
          } else {
            this.$message.warning("请填写参考单价或更改成交单价")
          }
        }
      }

    },
    // onTotalActualAmount(amount, index) {
    //     this.ordersObj.products[index].total_actual_amount = amount
    //     // console.log(amount);
    // },
    // 成交金额
    accMul(arg1, arg2, index) {

      if (arg1 === null || arg2 === null || arg1 === '' || arg2 === '') {
        return ""
      } else {
        // console.log(arg1,arg2);
        var m = 0,
          s1 = arg1.toString(),
          s2 = arg2.toString();
        try {
          m += s1.split('.')[1].length;
        } catch (e) { }
        try {
          m += s2.split('.')[1].length;
        } catch (e) { }
        let decimal1 = new Decimal(s1)
        let decimal2 = new Decimal(s2)
        let decimal3 = decimal1.times(decimal2)
        console.log(decimal3);
        this.ordersObj.products[index].total_actual_amount = decimal3 * 1
        return decimal3 * 1
      }
    },
    //点击显示品目编码
    onOrdersObjProducts(val) {
      if (this.ordersObjProductsId.includes(val.product_id)) {
        const index = this.ordersObjProductsId.indexOf(val.product_id)
        this.ordersObjProductsId.splice(index, 1)
      } else {
        this.ordersObjProductsId.push(val.product_id)
      }
    },
    // 带出订单
    onSalesOrder() {
      this.salesOrderObj.params.search = this.ordersObj.customerValue
      this.salesOrderObj.params.customer = this.ordersObj.customer_id
      console.log(this.ordersObj.customerValue, this.salesOrderObj);
      this.isSalesOrder = true;
    },
    //带出送样单
    onSampleSubmission() {
      this.SampleSubmissionObj.params.search = this.ordersObj.customerValue
      this.SampleSubmissionObj.params.customer = this.ordersObj.customer_id
      this.isSampleSubmission = true
    },
    //销售订单开关
    salesOrderVisible(val) {
      this.isSalesOrder = val;
      if (!val) {
        this.salesOrderObj = {
          searchField: 'search',
          url: "crm/order_relationship_table/",
          params: {
            per_page: 10,
            page: 1,
            is_approve: 1,
            is_invalid: 0,
            contract_order_type: 1,
            search: "",
            customer: null,
          },
        }
      }
    },
    //送样单开关
    SampleSubmissionVisible(val) {
      this.isSampleSubmission = val;
      if (!val) {
        this.SampleSubmissionObj = {
          searchField: 'search',
          url: "crm/order_relationship_table/",
          params: {
            per_page: 10,
            page: 1,
            customer: null,
            search: "",
            is_approve: 1,
            is_invalid: 0,
            contract_order_type: 4,
          },
        }
      }
    },
    //带出出库单
    async salesOrderApplied(val1) {
      let res = await this.$http.get("crm/order_relationship_table/" + val1.id + "");
      let val = null;
      console.log(res);
      if (res.status == 200) {
        res.data.oa_form.content.forEach(item => {
          if (item.control_title == "表单套件") {
            val = JSON.parse(item.value)
          }
        })
        delete val.order_number
        delete val.send_sample_number
        val.sales_order_id = val1.id
      } else {
        this.$message.error(res.data)
        return
        val.order_id = val1.id
      }
      // console.log(val, "出库申请(亿看)出库申请(亿看)");
      for (const key in val) {
        this.ordersObj[key] = val[key]
      }
      this.ordersObj.salesman_id = val.creator_id
      // console.log(this.ordersObj.salesman_id,val.creator_id);
      val.products.forEach((item, index) => {
        if (item.special_offer_id === undefined || item.special_offer_id === null || item.special_offer_id === '') {
          this.ordersObj.products[index].special_offer_id = item.special_offer_obj.id
        }
      })
      if (this.ordersObj.deliver_method == '交货到客户公司' || this.ordersObj.deliver_method == '交货到客户指定点') {
        console.log(this.ordersObj.deliver_method);
        let res = await this.$http({
          method: 'GET',
          url: 'crm/customer_receiver_information/',
          params: {
            customer: this.ordersObj.customer_id
          }
        });
        if (res.status == 200) {
          this.receiverInformationArr = res.data

        } else {
          this.$message.error(res.data)
        }
      }
    },
    // 查看库存
    onInventory() {
      this.postInventory(this.inventoryObj)
      this.isInventory = true
    },
    // 查库存post
    async postInventory(val) {
      this.isInventoryLoading = true
      let obj = JSON.parse(JSON.stringify(val))
      if (val.search != "" && val.search != null) {
        obj.item_code_list = [val.search]
        delete obj.search
      } else {
        delete obj.search
        delete obj.item_code_list
      }
      const res = await this.$http({
        url: "crm/sales_outbound_table/query_ecount_for_output_quantity_enough/",
        method: "POST",
        data: obj
      })
      if (res.status == 200) {
        this.inventoryList = res.data
        this.tableList = []
        for (var i = 0; i < this.inventoryList.length; i += this.pageSize) {
          this.tableList.push(this.inventoryList.slice(i, i + this.pageSize));
        }
        this.isInventoryLoading = false
      } else {
        this.inventoryList = null
        this.$message.error(res.data)
        this.isInventoryLoading = false
      }
      // this.isInventoryLoading = false
    },
    // 搜索库存
    onSearchInventory() {
      this.inventoryObj.search = this.inventoryObj.search.replace(/^\s+|\s+$/g, '')
      this.currentPage = 1
      this.postInventory(this.inventoryObj)
    },
    //带出库存
    async onSalesOutInventory() {
      let arr = []
      this.ordersObj.products.forEach((item, i) => {
        if (item.product_code != null && item.product_code != '') {
          arr.push(item.product_code)
        }
      })
      if (arr.length > 0) {
        console.log(arr)
        let obj = {
          list: true,
          item_code_list: arr
        }
        const res = await this.$http({
          method: "POST",
          url: "crm/sales_outbound_table/query_ecount_for_output_quantity_enough/",
          data: obj
        })
        if (res.status == 200) {
          console.log(res.data);
          this.salesOutInventoryList = res.data
        } else {
          this.$message.error(res.data)
        }

      } else {
        this.$message.error('请添加产品')
      }
    },
    //产品库存表格颜色
    headerRowStyle(row) {
      // console.log(row);
      if (row.column.label == '当前库存' || row.column.label == '占用数量' || row.column.label == '剩余数量') {
        return { textAlign: 'center', backgroundColor: '#8fc31f24' }
      } else {
        return { textAlign: 'center' }
      }
    },
    //产品库存表格颜色
    cellStyle(row) {
      // console.log(row);
      if (row.column.label == '当前库存' || row.column.label == '占用数量' || row.column.label == '剩余数量') {
        return { textAlign: 'center', backgroundColor: '#8fc31f24' }
      } else {
        return { textAlign: 'center' }
      }
    },
    //对话框是否全屏
    onFullscreen(val) {
      this[val] = !this[val]
    }
  }

};
</script>
<style lang="less" scoped>
/deep/.el-dialog__header {
  background-color: rgb(89, 87, 87);
  color: #fff;
  padding: 0 !important;

  .icon-zuidahua {
    font-size: 16px;
    cursor: pointer;
    padding: 10px;

    &:hover {
      color: #898989;
    }
  }

  .icon-chuangkouzuidahua {
    font-size: 16px;
    cursor: pointer;
    padding: 10px;

    &:hover {
      background-color: #898989;
    }
  }

  .el-icon-close {
    font-size: 16px;
    cursor: pointer;
    padding: 10px;

    &:hover {
      background-color: #898989;
    }
  }
}

.inventory {
  /deep/.el-table__cell {
    padding: 5px 0;
  }
}

.btnsBox {
  margin: 0px 0px 5px 0px;
}

.popovericon {
  border-radius: 5px;

  &:hover {
    color: #8fc31f;
    box-shadow: rgba(0, 0, 0, 0.425) 0px 2px 4px;
  }
}

.sales_outbound_orders_footer {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  display: flex;
}

.sales_outbound_orders_footer_padding1 {
  min-width: 64px;
}

.sales_outbound_orders_footer_padding2 {
  min-width: 222px;
}

.orders {
  width: 100%;
  height: 90%;
  overflow: auto;

}

.orders_form {
  // width: 1300px;

  .el-form {
    // display: flex;
    flex-wrap: wrap;

    .el-form-item {
      flex: 1;
      margin-top: 0 !important;
      padding-left: 10px;
    }


    .el-form-item__content {
      width: 210px;
    }

    .el-date-editor {
      width: 210px !important;
    }

    .el-input {
      width: 210px;
    }

    .el-switch {
      width: 210px;
    }

    .el-select {
      width: 210px;
    }
  }
}

@media screen and (max-width:1000px) {
  /deep/.el-textarea__inner {
    width: 260px !important;
  }
}

@media screen and (min-width:1000px) {
  /deep/.el-textarea__inner {
    width: 460px !important;
  }
}

// 合计行文本居中
/deep/.el-table__footer-wrapper {
  tr {
    td {
      text-align: center;
    }
  }
}

.orders_table {

  // width: 1300px;
  .card_item {
    display: flex;
    justify-content: flex-start;
  }
}
</style>