<template>
    <div class="orders">
        <h1 v-if="isSave">采购入库申请</h1>
        <!-- {{isAddOrder}}{{ addOrderForm }}{{ addOrdersCustomer }}{{ formTitle }}{{ readonly }}{{ addOrderId }} -->
        <div class="orders_form">
            <el-form size="mini" label-position="left" :disabled="isReadonly" :model="ordersObj" label-width="130px"
                :rules="ordersObjRules" :inline="true" ref="ordersObjRef1">
                <el-form-item label="供应商" prop="customerValue">
                    <el-input @focus="getInputFocus($event)" v-model="ordersObj.customerValue" placeholder="客户名称"
                        @keyup.enter.native="isCustomer = true" @blur.stop="isCustomer = true" clearable>
                        <el-button size="mini" slot="prepend" icon="el-icon-search" @click="isCustomer = true"></el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="仓库" prop="warehouseValue">
                    <el-input @focus="getInputFocus($event)" size="mini" v-model="ordersObj.warehouseValue" placeholder="仓库"
                        @keyup.enter.native="isWarehouse = true" @blur.stop="isWarehouse = true" clearable>
                        <el-button slot="prepend" icon="el-icon-search" @click="isWarehouse = true"></el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="货币类型" prop="currency_type">
                    <el-select v-model="ordersObj.currency_type" placeholder="请选择" clearable>
                        <el-option v-for="item in currencyOptions" :label="item.label" :value="item.value" clearable
                            :key="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="法人" prop="legal_person">
                    <el-select v-model="ordersObj.legal_person" placeholder="请选择" clearable>
                        <el-option v-for="item in legalPersonOptions" :label="item.label" :value="item.value" clearable
                            :key="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="入库日期" prop="put_date">
                    <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="入库日期" v-model="ordersObj.put_date"
                        style="width: 100%" :picker-options="pickerOptions" clearable></el-date-picker>
                </el-form-item>
            </el-form>
        </div>
        <div class="orders_table">
            <el-table v-if="!$store.state.isphone" ref="table_ref" width="1300px" size="mini" :data="ordersObj.products"
                border :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ textAlign: 'center' }" show-summary
                :summary-method="getSummaries">
                <el-table-column label="序号" type="index" width="50px"></el-table-column>
                <el-table-column label="设置" width="100">
                    <template slot-scope="data">
                        <el-button type="text" @click="onPushOrders">添加</el-button>
                        <el-button type="text" @click="onDeleteOrders(data.$index)">删除</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="品目编码" prop="product_code" width="256px">
                    <template slot-scope="data">
                        <el-input @focus="getInputFocus($event)" :disabled="isReadonly" size="mini"
                            v-model="data.row.product_code" placeholder="品目编码"
                            @keyup.enter.native="onOrdersProductSearch(data.$index)"
                            @blur.stop="onOrdersProductSearch(data.$index)"
                            @change="onOrdersChange(data.row.product_code, data.$index)" clearable>
                            <el-button size="mini" slot="prepend" icon="el-icon-search"
                                @click="onOrdersProductSearch(data.$index)"></el-button>
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column label="品目名称" prop="product_title" width="150px">
                </el-table-column>
                <el-table-column label="品目规格" prop="specifications" width="210px">
                </el-table-column>
                <el-table-column label="数量" prop="quantity" width="130px">
                    <template slot-scope="data">
                        <el-input @focus="getInputFocus($event)" type="number" :disabled="isReadonly" :min="1" clearable
                            size="mini" v-model="data.row.quantity" placeholder="数量">
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column label="单价" prop="unit_price" width="130px">
                    <template slot-scope="data">
                        <el-input @focus="getInputFocus($event)" type="number" :disabled="isReadonly" :min="1" clearable
                            size="mini" v-model="data.row.unit_price" placeholder="单价">
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column label="金额" prop="production_money" width="130px">
                    <template slot-scope="data">
                        <div>
                            {{ data.row.production_money=data.row.quantity * data.row.unit_price }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="批次号" prop="batch_num" width="210px">
                    <template slot-scope="data">
                        <el-input @focus="getInputFocus($event)" :disabled="isReadonly" :min="1" clearable size="mini"
                            v-model="data.row.batch_num" placeholder="批次号">
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column label="产品摘要" prop="abstract" width="210px">
                    <template slot-scope="data">
                        <el-input @focus="getInputFocus($event)" size="mini" :disabled="isReadonly"
                            v-model="data.row.abstract" placeholder="摘要" clearable>
                        </el-input>
                    </template>
                </el-table-column>
            </el-table>
            <div v-else style="border: 1px dashed #8fc31f; padding: 10px; border-radius: 12px">
                <div v-if="!isReadonly">
                    <template v-for="(item, i) in ordersObj.products">
                        <div :key="i"
                            style="border: 1px solid #dcdfe6;padding: 10px;margin-bottom: 10px;border-radius: 12px; ">
                            <div style="display: flex;justify-content: space-between;align-items: center;">
                                <p>产品{{ i + 1 }}</p>
                                <i @click="onDeleteOrders(i)" class="el-icon-close"></i>
                            </div>
                            <el-form :model="item">
                                <el-form-item label="品目编码" prop="product_code">
                                    <el-input @focus="getInputFocus($event)" size="mini" v-model="item.product_code"
                                        placeholder="品目编码" @keyup.enter.native="onOrdersProductSearch(i)"
                                        @blur.stop="onOrdersProductSearch(i)" @change="onOrdersChange(item.product_code, i)"
                                        clearable>
                                        <el-button size="mini" slot="prepend" icon="el-icon-search"
                                            @click="onOrdersProductSearch(i)"></el-button>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="品目名称" prop="product_title">
                                    <el-input size="mini" v-model="item.product_title" disabled
                                        placeholder="品目名称"></el-input>
                                </el-form-item>
                                <el-form-item label="品目规格" prop="specifications">
                                    <el-input size="mini" v-model="item.specifications" disabled
                                        placeholder="品目规格"></el-input>
                                </el-form-item>
                                <el-form-item label="数量" prop="quantity">
                                    <el-input @focus="getInputFocus($event)" type="number" :min="1" clearable size="mini"
                                        v-model="item.quantity" placeholder="数量">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="单价" prop="unit_price">
                                    <el-input @focus="getInputFocus($event)" type="number" :disabled="isReadonly" :min="1"
                                        clearable size="mini" v-model="item.unit_price" placeholder="单价">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="金额" prop="production_money">
                                    {{ item.production_money=item.quantity * item.unit_price }}
                                </el-form-item>
                                <el-form-item label="产品摘要" prop="abstract">
                                    <el-input @focus="getInputFocus($event)" size="mini" v-model="item.abstract"
                                        placeholder="摘要" clearable>
                                    </el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </template>
                    <div>
                        <el-button style="width: 100%" :disabled="isReadonly" @click="onPushOrders" icon="el-icon-plus"
                            type="text">添加产品</el-button>
                    </div>
                </div>
                <div v-else>
                    <div style="padding: 0 0 10px 10px">
                        总计：{{ Thousands(onTotal(ordersObj.products)) }}
                    </div>
                    <template v-for="(item, i) in ordersObj.products">
                        <div :key="i" @click="onOrdersObjProducts(item)"
                            style="padding: 10px;background-color: #f8ffed;margin-bottom: 10px;border-radius: 12px;">
                            <div
                                style=" min-width: 300px;font-weight: 600;font-size: 24px;color: rgb(143, 195, 31);padding: 5px;">
                                {{ item.product_code }}
                            </div>
                            <div style="font-weight: 600; font-size: 16px; padding: 5px">
                                {{ item.product_title }}
                            </div>
                            <div v-if="ordersObjProductsId.includes(item.product)" class="card_item">
                                <div>规：</div>
                                <div>{{ item.specifications }}</div>
                            </div>
                            <div class="card_item">
                                <div>量：</div>
                                <div>{{ Thousands(item.quantity) }}</div>
                            </div>
                            <div class="card_item">
                                <div>价：</div>
                                <div>
                                    {{ Thousands(item.unit_price) }}
                                </div>
                            </div>
                            <div class="card_item">
                                <div>总：</div>
                                <div>
                                    {{ item.production_money=item.quantity * item.unit_price }}
                                    <!-- {{ Thousands(accMul(item.quantity, item.unit_price, i)) }} -->
                                </div>
                            </div>
                            <div class="card_item">
                                <div>摘：</div>
                                <div>{{ item.abstract }}</div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <inquireDialog v-if="isWarehouse" :isVisible="isWarehouse" :obj="warehouseObj" :title="'仓库'" :isChoose="true"
            :searchValue="ordersObj.warehouseValue" @onIsVisible="warehouseVisible" @onApplied="warehouseApplied">
        </inquireDialog>
        <inquireDialog v-if="isCustomer" :isVisible="isCustomer" :obj="customerDialogObj" :title="'客户'" :isChoose="true"
            :searchValue="ordersObj.customerValue" @onIsVisible="customerVisible" @onApplied="customerApplied">
        </inquireDialog>
        <inquireDialog v-if="isProduct" :isVisible="isProduct" :obj="productDialogObj" :title="'品目编码'" :isChoose="false"
            :searchValue="productValue" @onIsVisible="productVisible" @onApplied="productApplied">
        </inquireDialog>
        <!-- <div v-if="isSave" class="sales_outbound_orders_footer">
            <div :class="this.$store.state.iscollapse
                ? 'sales_outbound_orders_footer_padding1'
                : 'sales_outbound_orders_footer_padding2'
                "></div>
            <div style="width: 100%; border-top: 1px solid #dbd9d9; padding: 5px 15px">
                <el-button size="mini" type="primary" @click="onSaveOrders" style="margin-top: 10px">保存</el-button>
            </div>
        </div> -->
    </div>
</template>

<script>
import { debounce } from "@/utils/AntiShake";
import inquireDialog from "@/components/ProcessFlow/CRM/Forms/components/Dialog.vue";
import Preview from "@/components/OA/components/jsxPreview.vue";
import Decimal from 'decimal.js';
export default {
    props: [
        "isAddOrder",
        "addOrderForm",
        "addOrdersCustomer",
        "formTitle",
        "readonly",
        "addOrderId",
    ],
    data() {
        return {
            //产品品目规格id
            ordersObjProductsId: [],
            // 是否显示
            isSave: null,
            //采购入库单是否编辑
            isReadonly: this.readonly == undefined ? false : this.readonly,
            // 采购入库验证
            ordersObjRules: {
                customerValue: [
                    {
                        required: true,
                        message: "请选择供应商",
                        trigger: ["blur", "change"],
                    },
                ],
                warehouseValue: [
                    {
                        required: true,
                        message: "请选择仓库",
                        trigger: ["blur", "change"],
                    },
                ],
                currency_type: [
                    {
                        required: true,
                        message: "请选择货币类型",
                        trigger: ["blur", "change"],
                    },
                ],
                legal_person: [
                    {
                        required: true,
                        message: "请选择货币类型",
                        trigger: ["blur", "change"],
                    },
                ],
                put_date: [
                    {
                        required: true,
                        message: "请选择入库日期",
                        trigger: ["blur", "change"],
                    },
                ],
            },
            //采购入库单参数
            ordersObj: {
                customer: null,
                customerValue: null,
                to_ware_house: null,
                warehouseValue: null,
                // production_plant: null,
                put_date: '',
                // work_order_number: null,
                // sales_order: null,
                currency_type: 1,
                legal_person: 1,
                put_type: 2,
                products: [
                    {
                        product: null,
                        product_code: null,
                        product_title: null,
                        specifications: null,
                        production_money: null,
                        unit_price: null,
                        quantity: null,
                        batch_num: null,
                        abstract: null,
                    }
                ],
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick(picker) {
                            picker.$emit("pick", new Date());
                        },
                    },
                ],
            },
            //是否显示客户
            isCustomer: false,
            // 客户Dialog参数
            customerDialogObj: {
                searchField: "search",
                url: "crm/customer_bulk/",
                params: {
                    per_page: 10,
                    page: 1,
                    user_id: localStorage.getItem("user_id"),
                    search: "",
                },
            },
            //是否显示仓库
            isWarehouse: false,
            // 发货仓库Dialog参数
            warehouseObj: {
                searchField: "keyword",
                url: "crm/outbound_product_warehouse/",
                params: {
                    per_page: 10,
                    page_number: 1,
                    user_id: localStorage.getItem("user_id"),
                    keyword: "",
                },
            },
            isProduct: false,
            //产品下标
            quotationTableIndex: null,
            // 产品Dialog参数
            productDialogObj: {
                searchField: "search",
                url: "crm/special_offer/",
                params: {
                    per_page: 10,
                    page: 1,
                    model_name: "SpecialOfferTable",
                    sys_name: 3,
                    user: localStorage.getItem("user_id"),
                    search: "",
                    distinct: 1,
                    is_using: true,
                },
            },
            //货币
            currencyOptions: [],
            //法人
            legalPersonOptions: [],
        };
    },
    components: {
        inquireDialog,
        Preview,
    },
    mounted() {
        let today = new Date();
        this.ordersObj.put_date = today.getFullYear() + "-" + (today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1) + "-" + (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
        if (this.addOrdersCustomer != undefined) {
            if (this.addOrdersCustomer.to_ware_house != undefined) {
                this.ordersObj.to_ware_house = this.addOrdersCustomer.to_ware_house.id;
                this.ordersObj.warehouseValue = this.addOrdersCustomer.to_ware_house.warehouse_name;
            }
        }
        if (!this.isSave) {
            console.log(this.isAddOrder == false && this.addOrderId == undefined);
            if (this.formTitle == undefined && this.addOrderId == undefined) {
                this.customerObj = this.addOrderForm;
                if (this.addOrderForm.customer != undefined) {
                    this.ordersObj.customer = this.addOrderForm.customer.company_name;
                    this.ordersObj.customerValue = this.addOrderForm.customer.id;
                }
            }
        }
        this.getCurrency()
    },
    watch: {
        readonly: {
            handler(val) {
                if (val == undefined) {
                    this.isReadonly = false;
                } else {
                    this.isReadonly = this.readonly;
                }
            },
            deep: true,
        },
        ordersObj: {
            handler(val) {
                if (this.isAddOrder == false && this.addOrderId == undefined) {
                    console.log(val, this.ordersObj);
                    this.$emit("onAddOutboundOrder", this.ordersObj);
                }
            },
            deep: true,
        },
        isAddOrder: {
            handler(val) {
                if (val == undefined) {
                    this.isSave = true
                } else {
                    this.isSave = val
                }
            },
            deep: true,
            immediate: true,
        },
        addOrderForm: {
            handler(val) {
                console.log(val);
                console.log(this.formTitle);
                if (this.formTitle == "采购入库申请") {
                    if (val != undefined) {
                        for (const key in val) {
                            console.log(key)
                            this.ordersObj[key] = val[key]
                        }
                    }
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        //获取货币类型/法人
        async getCurrency() {
            const res = await this.$http({
                method: "GET",
                url: "crm/outbound_currency_type/",
            });
            const res1 = await this.$http({
                method: "GET",
                url: "app02/label_control/label/",
                params: { url: "TradingLegalEntity/" },
            })
            console.log(res);
            console.log(res1);
            this.currencyOptions = res.data;
            this.legalPersonOptions = res1.data;
        },
        getInputFocus(event) {
            console.log("event", event);
            event.currentTarget.select();
        },
        //客户对话框是否关闭
        customerVisible(val) {
            this.isCustomer = val
        },
        //获取客户对话框数据
        customerApplied(val) {
            // console.log(val);
            this.ordersObj.customer = val.id
            this.ordersObj.customerValue = val.company_name
        },
        //仓库对话框是否关闭
        warehouseVisible(val) {
            this.isWarehouse = val
        },
        //获取仓库对话框数据
        warehouseApplied(val) {
            // console.log(val);
            this.ordersObj.to_ware_house = val.id
            this.ordersObj.warehouseValue = val.warehouse_name
        },

        // 产品Dialog开关
        productVisible(data) {
            this.isProduct = data;
        },
        // 获取当前下标
        onOrdersProductSearch(index) {
            this.isProduct = true;
            this.quotationTableIndex = index;
        },
        // 产品Dialog数据
        productApplied(data) {
            console.log(data);
            let productArr = data;
            const arr = [];
            productArr.forEach((item) => {
                arr.push({
                    product: item.product, //产品的外键id
                    product_code: item.field0,//品目编码
                    product_title: item.item_name,//品目名称
                    specifications: item.specifications,
                    production_money: null, //产品数量
                    unit_price: item.price, //入库单价
                    quantity: null, //产品数量
                    batch_num: null,
                    abstract: null,
                });
            });
            this.ordersObj.products.splice(this.quotationTableIndex, 1, ...arr);
        },
        //添加产品数据
        onPushOrders() {
            this.ordersObj.products.push({
                product: null,
                product_code: null,
                product_title: null,
                specifications: null,
                production_money: null,
                unit_price: null,
                quantity: null,
                batch_num: null,
                abstract: null,
            });
        },
        //删除产品数据
        onDeleteOrders(index) {
            if (this.ordersObj.products.length != 1) {
                this.ordersObj.products.splice(index, 1);
            }
        },
        //产品表格总计
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                if (column.property === "production_money") {
                    const sum = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + value;
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] = sum.toLocaleString(); // Add thousand separator
                } else {
                    sums[index] = "";
                }
            });
            return sums;
        },
        //千分符保留3位小数
        Thousands(num) {
            return (num * 1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
        },
        //产品金额总计
        onTotal(val) {
            let num = null;
            val.forEach((item, i) => {
                // num += this.accMul(item.quantity, item.unit_price, i);
                num += (this.accMul(item.quantity, item.unit_price, i)) * 1;
            });
            return num;
        },
        //金额总计
        accMul(arg1, arg2, index) {
            if (arg1 === null || arg2 === null || arg1 === '' || arg2 === '') {
                return "";
            } else {
                // console.log(arg1,arg2);
                var m = 0,
                    s1 = arg1.toString(),
                    s2 = arg2.toString();
                try {
                    m += s1.split(".")[1].length;
                } catch (e) { }
                try {
                    m += s2.split(".")[1].length;
                } catch (e) { }
                // this.ordersObj.products[index].total_actual_amount =
                //     (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
                //     Math.pow(10, m);
                // return (
                //     (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
                //     Math.pow(10, m)
                // );
                let decimal1 = new Decimal(s1)
                let decimal2 = new Decimal(s2)
                let decimal3 = decimal1.times(decimal2)
                this.ordersObj.products[index].total_actual_amount = decimal3 * 1
                return decimal3 * 1
            }
        },
        //点击产品显示规则
        onOrdersObjProducts(val) {
            if (this.ordersObjProductsId.includes(val.product)) {
                const index = this.ordersObjProductsId.indexOf(val.product);
                this.ordersObjProductsId.splice(index, 1);
            } else {
                this.ordersObjProductsId.push(val.product);
            }
        },
    },
};
</script>
<style lang="less" scoped>
.orders_form {
    // width: 1300px;

    .el-form {
        // display: flex;
        flex-wrap: wrap;

        .el-form-item {
            flex: 1;
            margin-top: 0;
            padding-left: 10px;
        }

        .el-form-item__content {
            width: 210px;
        }

        .el-date-editor {
            width: 210px !important;
        }

        .el-input {
            width: 210px;
        }

        .el-switch {
            width: 210px;
        }

        .el-select {
            width: 210px;
        }
    }
}

@media screen and (max-width:1000px) {
    /deep/.el-textarea__inner {
        width: 260px !important;
    }
}

@media screen and (min-width:1000px) {
    /deep/.el-textarea__inner {
        width: 460px !important;
    }
}

/deep/.el-table__footer-wrapper {
    tr {
        td {
            text-align: center;
        }
    }
}

.orders_table {

    // width: 1300px;
    .card_item {
        display: flex;
        justify-content: flex-start;
    }
}
</style>