<template>
  <div class="fc-autocompletion">

    <!-- {{ formData }}
    {{ options }} -->
    <!-- {{ state }} -->
    <!-- {{ defaultValue }} -->
    <el-autocomplete v-model="state" v-bind="$props" style="width: 100%" popper-class="myclass"
      :fetch-suggestions="querySearch" @focus="querySearchFocus" @select="handleSelect" :placeholder="this.placeholder"
      clearable>
      <template slot-scope="{ item }">
        <div class="name" style="width: 100%;"><span :class="[item.label == state ? 'autocompletion_active' : '']">{{
          item.label
        }}</span></div>
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: [
    "formData",
    "tagIcon",
    "label",
    "placeholder",
    "source",
    "defaultValue",
    "disabled",
    "readonly",
    "options",
    "regList",
    "value",
    "cmpType",
    "tag",
    "precision",
    "labelWidth",
    "step-strictly",
    "controls",
    "controls-position",
    "changeTag",
    "proCondition",
    "showChinese",
    "formId",
    "renderKey",
    "layout",
  ],
  name: "fc-autocompletion",
  watch: {
    source: {
      handler(val) {
        if (val.indexOf("*") == -1) {
        } else {
          this.getlist();
        }
      },
    },
    defaultValue: {
      handler(val) {
        if (val !== undefined) {
          console.log(typeof val == 'object');
          if (typeof val == 'object') {
            this.state = val.label
          } else {
            this.state = JSON.parse(val).label
          }
        } else {
          this.state = null
        }
      },
      // deep: true,
      // immediate: true,
    },
    value: {
      handler(val) {
        console.log(val);
        if (val !== undefined) {
          if (typeof val == 'object') {
            this.state = val.label
          } else {
            this.state = JSON.parse(val).label
          }
        }

      },
      immediate: true,
    }
  },
  data() {
    return {
      state: null,
      restaurants: [],
      // sourcedata: this.source.split("*")[0],
    };
  },
  components: {},
  created() {
    this.getlist();
  },
  methods: {
    querySearchFocus() {
      this.getlist()
    },
    async getlist() {
      // console.log(this.source);
      // this.source = this.source.split("*")[0];
      if (!this.source == "") {
        let obj = {}
        console.log(this.options);
        if (this.options !== undefined) {
          if (this.options.length > 0) {
            this.options.forEach(item => {
              if (item.label != "") {
                console.log(this.formData[item.data]);
                if (this.formData[item.data] != undefined && this.formData[item.data].constructor === Object) {
                  if (this.formData[item.data].value != undefined) {
                    obj[item.label] = this.formData[item.data].value
                  }
                } else {
                  obj[item.label] = this.formData[item.data]
                }
              }
            });
          }
        }
        console.log(obj);
        // return
        const res = await this.$http({
          method: "GET",
          url: this.source.split("*")[0],
          params: obj
        });
        this.restaurants = []
        if (res.data != "") {
          res.data.forEach(item => {
            this.restaurants.push(item)
          });
        }
        // this.restaurants = res.data;

        // console.log(this.defaultValue, "1111");
      }
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      this.arr = results
      console.log(results.length,restaurants.length);
      if (results.length == restaurants.length) {
      } else {
        restaurants.forEach((item,i)=>{
          if(results.includes(item)){
            restaurants.splice(i,1)
          }
        })
        // console.log(restaurants);
        // console.log(results)
        restaurants.unshift(...results)
      }
      cb(restaurants);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.label.toLowerCase().indexOf(queryString.toLowerCase()) !== -1);
      };
    },
    handleSelect(item) {
      console.log(item);
      this.state = item.label;
      console.log(item);
      this.defaultValue = item
      this.$emit("change", item);
      // console.log(item);
    },
    async getval(item) {
      // console.log(this.arr);
      if (this.arr.length == 0) {
        console.log(`${this.source.split("*")[0]} + &keyword=${item.target.value}`);
        const res = await this.$http({
          method: "GET",
          url: `${this.source.split("*")[0]}&keyword=${item.target.value}`,
        });
        console.log(res);
      }
      this.$emit("change", this.state);
    }
  },
};
</script>
<style lang="less">
.myclass {
  width: auto !important;
}

.autocompletion_active {
  color: #8fc31f !important;
}
</style>