<template>
    <div class="container" :style="gridStyle">
        <div v-for="(item, index) in gridItems" :key="index" :style="[item.style, { 'border-radius': borderRadius }]"
            :class="['grid-item', 'grid_' + index]">
            <slot :name="name + index"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DynamicGrid',
    props: {
        cellSize: {
            type: Number,
            default: 100
        },
        rowsSize: {
            type: Number,
            default: 100
        },
        rows: {
            type: Number,
            default: 3
        },
        columns: {
            type: Number,
            default: 3
        },
        rowGap: {
            type: Number,
            default: 1
        },
        columnGap: {
            type: String,
            default: '1%'
        },
        mergedCells: {
            type: Array,
            default: () => []
        },
        borderRadius: {
            type: String,
            default: '0px' // 默认为直角
        },
        name: {
            type: String,
            default: 'name' // 默认为直角
        }
    },
    computed: {

        gridItems() {
            let items = [];
            for (let i = 0; i < this.rows * this.columns; i++) {
                items.push({
                    content: i + 1,
                    style: this.getMergedCellStyle(i)
                });
            }
            console.log(items.length);
            console.log(this.mergedGridItemsCount());
            return items.splice(0, (items.length - this.mergedGridItemsCount()));
        },
        gridStyle() {
            // const cellSize = 100 / this.columns; // 计算每个格子的尺寸（以百分比表示）
            // const columnGapPercentage = this.columnGap || '1%'; // 默认为 1%（如果未指定的话）
            // console.log(this.columnGap);
            // console.log(cellSize);
            // console.log(parseFloat(columnGapPercentage) * (this.columns - 1));
            // console.log(parseFloat(columnGapPercentage) * (this.columns - 1) / this.columns);
            // const adjustedCellSize = cellSize - parseFloat(columnGapPercentage) * (this.columns - 1) / this.columns
            // return {
            //     display: 'grid',
            //     gridTemplateColumns: `repeat(${this.columns}, ${adjustedCellSize}%)`,
            //     gridTemplateRows: `repeat(${this.rows}, ${adjustedCellSize}%)`,
            //     gap: `${columnGapPercentage}`,
            //     aspectRatio: '1 / 1' // 设置每格为正方形
            // }
            const cellSize = this.cellSize / this.columns; // 计算每个格子的尺寸（以百分比表示）
            const rowsSize = this.rowsSize / this.columns; // 计算每个格子的尺寸（以百分比表示）
            const columnGapPercentage = this.columnGap || '20px'; // 默认为 20px（如果未指定的话）
            const gapSize = `${parseFloat(columnGapPercentage)}vw`;
            const adjustedCellSize = cellSize - parseFloat(columnGapPercentage) * (this.columns - 1) / this.columns;
            const adjustedRowsSize = rowsSize - parseFloat(columnGapPercentage) * (this.columns - 1) / this.columns;
            let rowHeight = null
            if (this.$store.state.isphone) {
                rowHeight = `${adjustedRowsSize - 1}vw`;
            } else {
                rowHeight = `${adjustedRowsSize - 1}%`;
            }

            return {
                display: 'grid',
                gridTemplateColumns: `repeat(${this.columns}, ${adjustedCellSize - 1}%)`,
                gridTemplateRows: `repeat(${this.rows}, ${rowHeight})`,
                gap: gapSize,
                aspectRatio: '1 / 1' // 设置每格为正方形
            }
        },


    },
    methods: {
        mergedGridItemsCount() {
            let count = 0;
            this.mergedCells.forEach(cell => {
                count += cell.rowSpan * cell.colSpan - 1;
            });
            return count;
        },
        getMergedCellStyle(index) {
            const mergedCell = this.mergedCells.find(cell => cell.index === index);
            if (mergedCell) {
                return {
                    gridColumn: `span ${mergedCell.colSpan}`,
                    gridRow: `span ${mergedCell.rowSpan}`
                };
            } else {
                return {};
            }
        }
    },
};
</script>

<style scoped lang="less">
.container {
    width: 100%;
    display: grid;
    justify-content: center;

}

@media screen and (max-width: 1000px) {
    .container {}

    .followupTableButton {
        .btn_brawer_list {
            .layout0 {
                i {
                    font-size: 8vw !important
                }
            }
        }
    }
}

.grid-item {
    overflow: hidden;
}
</style>