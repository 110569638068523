<template>
    <div>
        <!-- {{ value }}
        {{ defaultValue }}
        {{ readonly }} -->
        <el-input class="select_box_top_inp" :disabled="true" v-model="stateObj.address" clearable placeholder="请输入地址">
            <el-button size="mini" slot="append" icon="iconfont icon-dizhiguanli" style="color: #8fc31f !important;"
                :disabled="readonly" @click="isMap = true"></el-button>
        </el-input>
        <el-dialog :visible.sync="isMap" fullscreen :modal="false" :append-to-body="false">
            <div slot="title">
                地图
            </div>
            <div>
                <div class="select_box">
                    <div class="select_box_top">
                        <el-input class="select_box_top_inp" v-model="searchStr" placeholder="请输入地址"
                            @blur="addressAutoComplete"></el-input>
                        <el-button class="select_box_top_btn" type="primary" size="mini"
                            @click="addressSearch">搜索</el-button>
                    </div>
                    <ul class="select_box_bottom" v-if="autoCompleteList != null">
                        <li style=" padding: 5px;" v-for="(item, i) in autoCompleteList" :key="i"
                            @click.stop="selectAutoComplete(item, i)">
                            <i class="el-icon-search"></i>
                            <div>
                                <div>{{ item.name }}</div>
                                <div style="color: #9a9ca2;font-size: 12px;">{{ item.district }}{{ item.address }}</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div id="map_container" class="map_container"></div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="isMap = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="isMap = false">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';

export default {
    name: 'fc_map',
    model: {
        prop: 'value',
        event: 'change'
    },
    props: [
        'radius',
        'defaultValue',
        'style',
        'disabled',
        'readonly'
    ],
    data() {
        return {
            isMap: false,
            stateObj: {
                lnglat: null,
                address: null
            },
            map: null,//地图容器
            address: null,//详细地址
            geocoder: null,//地理坐标（经纬度） 转换成 地址描述信息
            Position: [],//经纬度
            currentPosition: [],//当前位置
            circle: null,//范围
            geolocation: null,//
            signInPosition: [],//
            searchStr: '',//搜索
            PlaceSearch: null,
            AutoComplete: null,
            autoCompleteList: null,//
        }
    },
    watch: {
        stateObj: {
            handler(val) {
                console.log(val);
                this.$emit('change', val)
            },
            deep: true
        },
        isMap: {
            handler(val) {
                if (val) {
                    this.$nextTick(() => {
                        this.initMap()
                    })
                }
            }
        },
        defaultValue: {
            handler(val) {
                console.log(val);
                if (val != undefined) {
                    this.stateObj = JSON.parse(val)
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {

    },
    methods: {
        // 初始化地图
        initMap() {
            let that = this
            AMapLoader.load({
                key: "5310b9352e1f51b88b73227fcf95182e",
                version: "2.0",
                plugins: [
                    "AMap.Scale",//比例尺
                    "AMap.Geocoder",//经纬度转详细地址
                    "AMap.Circle",//范围
                    "AMap.Geolocation",//当前位置
                    "AMap.PlaceSearch",//关键字
                    "AMap.AutoComplete",//关键字
                ],

            }).then((AMap) => {
                console.log(that.readonly);
                if (that.readonly) {
                    console.log(that.defaultValue);
                    that.stateObj = JSON.parse(that.defaultValue)
                    console.log(that.stateObj);
                    that.map = new AMap.Map("map_container", {//设置地图容器
                        // mapStyle: 'amap://styles/light',//设置地图样式
                        viewMode: "3D",//是否为3D地图模式
                        zoom: 17, //初始化地图级别
                        resizeEnable: true//是否当前位置
                    })

                    let marker = new AMap.Marker({
                        position: new AMap.LngLat(that.stateObj.lnglat[0], that.stateObj.lnglat[1]),
                        label: {
                            content: "<div class='address'>" + that.stateObj.address + "</div>",
                        }
                    })
                    that.map.clearMap()// 清除所有覆盖物（点标志）
                    that.map.setCenter([that.stateObj.lnglat[0], that.stateObj.lnglat[1]]);
                    that.circle = new AMap.Circle({
                        center: [that.stateObj.lnglat[0], that.stateObj.lnglat[1]],
                        radius: that.radius,
                        fillColor: "rgba(103.182.255,0.5)"
                    })
                    that.map.setZoom(13)
                    that.map.add(marker)// 添加点标志
                    that.map.add(that.circle)// 添加点标志
                } else {
                    that.map = new AMap.Map("map_container", {//设置地图容器
                        // mapStyle: 'amap://styles/light',//设置地图样式
                        viewMode: "3D",//是否为3D地图模式
                        zoom: 17, //初始化地图级别
                        resizeEnable: true//是否当前位置
                    })
                    that.map.addControl(new AMap.Scale())//添加比例尺
                    that.mapClick(AMap)//地图选点
                    that.geocoder = new AMap.Geocoder()//地理坐标（经纬度） 转换成 地址描述信息，
                    that.getCurrentLocation(AMap)//获取当前定位
                    that.search(AMap)
                }

            }).catch(err => {
                console.log(err);
            })
        },
        //获取当前定位
        getCurrentLocation(AMap) {
            const that = this;
            that.geolocation = new AMap.Geolocation({
                timeout: 10000,          //超过10秒后停止定位，默认：5s
                enableHighAccuracy: true,
                convert: false,
                showCircle: false,
                panToLocation: true,
                offset: [20, 90],
                showMarker: false,
                zoomToAccuracy: true,   //定位成功后是否自动调整地图视野到定位点
            });
            that.map.addControl(that.geolocation);
            that.geolocation.getCurrentPosition(function (status, result) {
                //备注：getCurrentPosition方法会调用超时或失败：
                //Get geolocation time out：浏览器定位超时，包括原生的超时，可以适当增加超时属性的设定值以减少这一现象。
                //另外还有个别浏览器（如google Chrome浏览器等）本身的定位接口是黑洞，通过其请求定位完全没有回应，也会超时返回失败。
                //Get geolocation failed：定位失败，Chrome、火狐以及部分套壳浏览器接入的定位服务在国外，有较大限制，失败率高。
                console.log(status, result);

                if (status == 'complete') {
                    // this.handleClick(AMap)
                    // that.onComplete(result, AMap)
                    console.log(result);
                    const markerss = new AMap.Marker({
                        position: new AMap.LngLat(result.position.lng, result.position.lat)
                    })
                    that.currentPosition = [result.position.lng, result.position.lat]
                    that.map.clearMap()// 清除所有覆盖物（点标志）
                    this.map.add(markerss)
                    markerss.on('click', (e) => {
                        // console.log(data.lnglat.lng);
                        // console.log(data.lnglat.lat);
                        let lng = e.lnglat.lng
                        let lat = e.lnglat.lat
                        let lnglat = [lng, lat]
                        that.geocoder.getAddress(lnglat, function (status, result) {
                            if (status === 'complete' && result.regeocode) {
                                that.address = result.regeocode.formattedAddress;
                                // that.showInfoWindow(marker);//自定义信息窗体
                                let thisPosition = {
                                    address: that.address,
                                    lng: lng,
                                    lat: lat
                                };
                                // that.$emit("select", thisPosition) //返回给父组件
                                // that.position = thisPosition;
                                let thisPosition1 = [thisPosition.lng, thisPosition.lat]
                                that.signInPosition = thisPosition1
                                that.map.setCenter(thisPosition1);
                                let marker = new AMap.Marker({
                                    position: new AMap.LngLat(thisPosition.lng, thisPosition.lat),
                                    label: {
                                        content: "<div class='address'>" + that.address + "</div>",
                                    }
                                })
                                that.map.clearMap()// 清除所有覆盖物（点标志）
                                that.circle = new AMap.Circle({
                                    center: [thisPosition.lng, thisPosition.lat],
                                    radius: that.radius,
                                    fillColor: "rgba(103.182.255,0.5)"
                                })
                                that.map.add(marker)// 添加点标志
                                that.map.add(that.circle)// 添加点标志
                                // console.log(that.address);
                                that.stateObj = {
                                    lnglat: thisPosition1,
                                    address: that.address
                                }
                            } else {
                                that.$message.error('根据经纬度查询地址失败')
                            }
                        })

                    })
                    that.map.setCenter(that.currentPosition);

                } else {
                    // that.onError(result) //失败后可使用getCityInfo获取非精准定位（具体到省市）
                    console.log(result);
                }
            });
        },
        // 
        mapClick(AMap) {
            let that = this
            that.map.on('click', (e) => {
                let lng = e.lnglat.lng
                let lat = e.lnglat.lat
                let lnglat = [lng, lat]
                that.geocoder.getAddress(lnglat, function (status, result) {
                    if (status === 'complete' && result.regeocode) {
                        that.address = result.regeocode.formattedAddress;
                        // that.showInfoWindow(marker);//自定义信息窗体
                        let thisPosition = {
                            address: that.address,
                            lng: lng,
                            lat: lat
                        };
                        // that.$emit("select", thisPosition) //返回给父组件
                        // that.position = thisPosition;
                        let thisPosition1 = [thisPosition.lng, thisPosition.lat]
                        that.signInPosition = thisPosition1
                        that.map.setCenter(thisPosition1);
                        let marker = new AMap.Marker({
                            position: new AMap.LngLat(thisPosition.lng, thisPosition.lat),
                            label: {
                                content: "<div class='address'>" + that.address + "</div>",
                            }
                        })
                        that.map.clearMap()// 清除所有覆盖物（点标志）
                        that.circle = new AMap.Circle({
                            center: [thisPosition.lng, thisPosition.lat],
                            radius: that.radius,
                            fillColor: "rgba(103.182.255,0.5)"
                        })
                        that.map.add(marker)// 添加点标志
                        that.map.add(that.circle)// 添加点标志
                        // console.log(that.address);
                        that.stateObj = {
                            lnglat: thisPosition1,
                            address: that.address
                        }
                    } else {
                        that.$message.error('根据经纬度查询地址失败')
                    }
                })
            })
        },
        //
        addressAutoComplete() {
            // console.log('11111');
            let that = this
            if (that.searchStr == '') {
                return
                // return this.$message.error("请输入地址")
            }
            that.AutoComplete.search(that.searchStr, (status, result) => {
                console.log(status, result);
                if (status == 'complete' && result.info === 'OK') {
                    that.autoCompleteList = result.tips
                }
            })
        },
        //
        addressSearch() {
            let that = this
            if (that.searchStr == '') {
                return
                // return this.$message.error("请输入地址")
            }
            that.PlaceSearch.search(that.searchStr, (status, result) => {
                if (status === 'complete' && result.info === 'OK') {
                    // 获取第一个POI对象
                    // var poi = result.poiList.pois[0];
                    // 选中该POI
                    // placeSearch.setSelected(poi);
                    // console.log(result.poiList.pois);
                    // placeSearch.markerClick()
                    let arr = result.poiList.pois
                    for (let i = 0; i < arr.length; i++) {
                        that.marker(window.AMap, arr[i], 'searchMarker' + i, i)
                    }
                }

            })
            that.PlaceSearch.setZoom(11)
        },
        //
        search(AMap) {
            let that = this
            that.PlaceSearch = new AMap.PlaceSearch({
                city: '全国'
            })
            that.AutoComplete = new AMap.AutoComplete({
                city: '全国'
            })
        },
        // 添加marker
        marker(AMap, arr, searchMarker, i) {
            let that = this
            let item = []
            if (Array.isArray(arr.location)) {
                item = item.location
            } else {
                item = [arr.location.lng, arr.location.lat]
            }
            that.address = arr.name + arr.address
            var searchMarker
            searchMarker = new AMap.Marker({
                position: new AMap.LngLat(item[0], item[1]),
                label: {
                    content: "<div class='address'>" + arr.name + arr.address + "</div>",
                }
            })
            this.map.add(searchMarker)// 添加点标志
            searchMarker.on('click', (e) => {
                that.stateObj = {
                    lnglat: item,
                    address: that.address
                }
                // console.log(e);
                that.circle = new AMap.Circle({
                    center: item,
                    radius: that.radius,
                    fillColor: "rgba(103.182.255,0.5)"
                })
                // that.map.clearMap()// 清除所有覆盖物（点标志）
                that.map.add(that.circle)// 添加点标志
            });
        },
        // 添加marker
        markers(AMap, item, searchMarkers, i) {
            let that = this
            let arr = []
            if (Array.isArray(item.location)) {
                arr = item.location
            } else {
                arr = [item.location.lng, item.location.lat]
            }
            // console.log(arr)
            var searchMarkers
            that.address = item.district + item.name
            searchMarkers = new AMap.Marker({
                position: new AMap.LngLat(arr[0], arr[1]),
                label: {
                    content: "<div class='address'>" + that.address + "</div>",
                }
            })
            this.map.setCenter([arr[0], arr[1]]);
            this.map.add(searchMarkers)// 添加点标志
            searchMarkers.on('click', (e) => {
                that.stateObj = {
                    lnglat: arr,
                    address: that.address
                }
                // console.log(e);
                that.circle = new AMap.Circle({
                    center: arr,
                    radius: that.radius,
                    fillColor: "rgba(103.182.255,0.5)"
                })
                // that.map.clearMap()// 清除所有覆盖物（点标志）
                that.map.add(that.circle)// 添加点标志
            });
        },
        //点击
        selectAutoComplete(item, i) {

            this.map.clearMap()// 清除所有覆盖物（点标志）
            this.stateObj.lnglat = [item.location.lng, item.location.lat]
            this.stateObj.address = item.name + item.address
            this.markers(window.AMap, item, 'searchMarkers' + i, i)
            this.autoCompleteList = null
        }
    }

}
</script>

<style lang="less" scoped>
/deep/.iconfont {
    font-size: 16px !important;
}

.map_container {
    width: 100%;
    height: 60vh;
    margin-top: 20px;
}

.address {
    color: #000;
}

.select_box {
    position: relative;

    .select_box_top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .select_box_top_inp {
            width: 70%;
        }

        .select_box_top_btn {
            // width: 20%;
        }
    }

    .select_box_bottom {
        width: 70%;
        height: 50vh;
        overflow: auto;
        position: absolute;
        top: 40px;
        // border-radius: 12px;
        z-index: 999999;
        list-style: none;

        li {
            line-height: 20px;
            padding: 15px;
            background: #fff;
            cursor: pointer;
            display: flex;
            align-items: center;

            i {
                font-size: 16px;
                font-weight: 700;
            }

            &:first-child {
                margin-top: 0;
            }

            &:hover {
                background-color: #EBEBEB;
            }
        }
    }
}
</style>