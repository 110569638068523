<template>
  <div class="fc-org-select">

    <div class="tags">
      <el-button v-if="buttonType === 'button'" size="small" type="primary" icon="el-icon-plus" @click="show = true"
        style="margin-bottom: 6px;">
        {{ title }}
      </el-button>
      <div class="input-box" :class="{ 'as-input': buttonType === 'input' }" @click="show = true">
        <el-tag v-bind="tagConfig" class="org-tag" v-for="(item, index) in innerValue" :key="index"
          @close="onClose(index)">
          {{ item.label }}
        </el-tag>
      </div>

    </div>
    <fc-org-transfer ref="transfer" :value="innerValue" :title="title" :searchable="searchable" :maxNum="maxNum"
      :tab="tab" :isCheckbox="isCheckbox" :show.sync="show" @confirm="onConfirm" />
  </div>
</template>
<script>
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  name: 'fc-org-select',
  props: {
    value: {
      required: true
    },
    tab: {
      default: () => ('all')
    },
    isCheckbox: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '组织机构'
    },
    buttonType: {
      type: String,
      default: 'input'
    }, // or input
    searchable: {
      type: Boolean,
      default: true
    },
    maxNum: {
      type: Number,
      default: 99
    },
    tagConfig: {
      type: Object,
      default: () => ({
        type: 'info',
        closable: true,
        'disable-transitions': false,
        hit: false,
        color: undefined,
        size: 'small',
        effect: 'light'
      })
    }
  },
  data() {
    return {
      tabKeys: [],
      show: false,
      innerValue: [],
      selectedData: []
    }
  },
  created() {

  },
  watch: {
    value: {
      handler: function (val) {
        if (!val) return
        this.innerValue = val
        this.$emit('updt')
        // this.$refs.transfer.up(val)
        // this.$refs.transfer.up(this.innerValue)
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
  },
  methods: {

    onClose(index) {
      this.innerValue.splice(index, 1)
      this.$refs.transfer.up(this.innerValue)
      // console.log(index);
      // console.log(this.innerValue);
      // console.log(this.$refs.transfer);
      this.$emit('input', this.innerValue)
    },

    onConfirm(data) {
      this.innerValue = data
      console.log(data)
      this.$emit('input', this.innerValue)
      // this.$emit('updt', this.innerValue)
    },
    selectedLabels() {
      return 'yyyyyyyy'
    }
  }
}
</script>
<style lang="stylus" scoped>
.tags {
  .input-box.as-input{
    border: 1px solid #DCDFE6;
    padding-left: 6px;
    font-size: 12px;
    min-height: 32px;
    line-height: 30px;
    border-radius: 4px;
    background: white;
    color #606266
    cursor pointer
  }
  .org-tag{
    margin-right: 6px;
    max-width: 6rem;
    overflow hidden
    text-overflow ellipsis
    position relative
    padding-right 1rem
    vertical-align middle

    >>> .el-tag__close{
      position: absolute;
      right: 2px;
      top: 50%;
      margin-top: -7px;

    }
  }
}
</style>
