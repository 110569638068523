<template>
    <div>
        <el-dialog :visible.sync="isSettingTable" :modal="false" :append-to-body="false" :close-on-click-modal="false"
            :show-close="false" v-dialogDrags :min-width="$store.state.isphone ? '90%' : '50%'"
            :fullscreen="isSettingTableFullscreen" style="overflow: auto;">
            <div slot="title" style="display: flex;justify-content: space-between; align-items: center;;">
                <div class="title">
                    <span style="padding: 10px;">{{ settingTitle }}</span>
                </div>
                <div>
                    <i v-if="isSettingTableFullscreen" class="iconfont icon-zuidahua " style=""
                        @click="onFullscreen('isSettingTableFullscreen')"></i>
                    <i v-else class="iconfont icon-chuangkouzuidahua" @click="onFullscreen('isSettingTableFullscreen')"></i>
                    <i class="el-icon-close" @click="onClose('isSettingTable')"></i>
                </div>
            </div>
            <div>
                <div style="margin-bottom: 10px;">
                    <el-button size="mini" type="warning" @click="onDeletSetting()">恢复默认状态</el-button>
                </div>
                <el-table :data="settingTable" size="mini" border style="border-color: rgb(220, 223, 230); width: 100%; "
                    :cell-style="{ color: 'rgba(95, 93, 93, 1)', borderColor: 'rgb(220, 223, 230)', backgroundColor: 'rgba(255,255,255,0)', textAlign: 'center' }"
                    :header-cell-style="{ color: 'rgba(151, 161, 163, 1)', height: '15px', borderColor: 'rgb(220, 223, 230)', backgroundColor: 'rgba(244,246,250,1)', textAlign: 'center' }"
                    @row-click="rowClick">
                    <el-table-column label="名称" width="180" prop="title">
                    </el-table-column>
                    <el-table-column label="字段名" prop="temp_key">
                    </el-table-column>
                </el-table>

            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="onClose('isSettingTable')">取 消</el-button>
                <el-button size="mini" type="primary" @click="onTable()">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog v-if="isForm" :visible.sync="isForm" :modal="false" :append-to-body="false" :close-on-click-modal="false"
            :show-close="false" v-dialogDrags :width="$store.state.isphone ? '90%' : '40%'" :fullscreen="isFormFullscreen"
            style="overflow: auto;">
            <div slot="title" style="display: flex;justify-content: space-between; align-items: center;;">
                <div class="title" v-if="formObj != null">
                    <span style="padding: 10px;">{{ formObj.title }}</span>
                </div>
                <div>
                    <i v-if="isFormFullscreen" class="iconfont icon-zuidahua " style=""
                        @click="onFullscreen('isFormFullscreen')"></i>
                    <i v-else class="iconfont icon-chuangkouzuidahua" @click="onFullscreen('isFormFullscreen')"></i>
                    <i class="el-icon-close" @click="onClose('isForm')"></i>
                </div>
            </div>
            <div v-if="formObj != null">
                <el-form size="mini">
                    <el-form-item label="默认值" prop="title">
                        <el-input v-model="settingForm.control_setting[formObj.temp_key].default_value" placeholder="默认值"
                            clearable></el-input>
                    </el-form-item>
                    <el-form-item label="显示名称" prop="title">
                        <el-input v-model="settingForm.control_setting[formObj.temp_key].basic_settings.show_name"
                            placeholder="显示名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="提示内容" prop="title">
                        <el-input v-model="settingForm.control_setting[formObj.temp_key].basic_settings.placeholder"
                            placeholder="提示内容" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="是否必填" prop="title">
                        <el-switch v-model="settingForm.control_setting[formObj.temp_key].permissions.require"
                            active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                    </el-form-item>
                    <el-form-item label="是否可修改" prop="title">
                        <el-switch v-model="settingForm.control_setting[formObj.temp_key].permissions.update"
                            active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                    </el-form-item>
                    <el-form-item label="是否可编辑" prop="title">
                        <el-switch v-model="settingForm.control_setting[formObj.temp_key].permissions.edit"
                            active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                    </el-form-item>
                    <el-form-item label="小数位数" prop="title">
                        <el-input-number style="width: 100%;"
                            v-model="settingForm.control_setting[formObj.temp_key].numeric_settings.decimal_places"
                            controls-position="right"></el-input-number>
                    </el-form-item>
                    <el-form-item label="最大位数" prop="title">
                        <el-input-number style="width: 100%;"
                            v-model="settingForm.control_setting[formObj.temp_key].numeric_settings.max_digits"
                            controls-position="right"></el-input-number>
                    </el-form-item>
                    <el-form-item label="角色可见称" prop="title">
                        <el-select v-model="settingForm.control_setting[formObj.temp_key].visible_rule.role_limit" multiple
                            collapse-tags style="width: 100%;" placeholder="请选择">
                            <el-option v-for="item in roleOptions" :key="item.id" :label="item.role" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="onClose('isForm')">取 消</el-button>
                <el-button size="mini" type="primary" @click="onForm()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: {
        isSettingTable: {
            type: Boolean,
            default: false
        },
        settingForm: {
            type: Object,
            default: {}
        },
        settingTable: {
            type: Array,
            default: []
        },
        settingTitle: {
            type: String,
            default: "表单"
        }
    },
    components: {},
    data() {
        return {
            formObj: null,
            isForm: false,
            isFormFullscreen: false,
            isSettingTableFullscreen: false,
            roleOptions: [],
        }
    },
    methods: {
        rowClick(row) {
            console.log(row);
            this.formObj = row
            this.isForm = true
        },
        async getRoleOptions() {
            const res = await this.$http({
                method: "GET",
                url: "rbac/role/",
            })
            console.log(res.data);
            this.roleOptions = res.data.role_data
        },
        async onDeletSetting() {
            const res = await this.$http({
                method: "DELETE",
                url: `rbac/form_control_setting/${this.settingForm.id}/`,
            })
            this.onClose('isSettingTable')
        },
        async onForm() {
            if (this.settingForm.id == undefined) {
                const res = await this.$http({
                    method: "POST",
                    url: "rbac/form_control_setting/",
                    data: this.settingForm
                })
                this.settingForm = res.data
            } else {
                const res = await this.$http({
                    method: "PUT",
                    url: `rbac/form_control_setting/${this.settingForm.id}/`,
                    data: this.settingForm
                })
                this.settingForm = res.data
            }
            this.settingTable.forEach((item, i) => {
                if (this.settingForm.control_setting[item.temp_key].basic_settings.show_name != '' && this.settingForm.control_setting[item.temp_key].basic_settings.show_name != null) {
                    item.title = this.settingForm.control_setting[item.temp_key].basic_settings.show_name
                }
            })
            this.onClose('isForm')
        },
        async onTable() {
            if (this.settingForm.id == undefined) {
                const res = await this.$http({
                    method: "POST",
                    url: "rbac/form_control_setting/",
                    data: this.settingForm
                })
                this.settingForm = res.data
            } else {
                const res = await this.$http({
                    method: "PUT",
                    url: `rbac/form_control_setting/${this.settingForm.id}/`,
                    data: this.settingForm
                })
                this.settingForm = res.data
            }
            this.settingTable.forEach((item, i) => {
                if (this.settingForm.control_setting[item.temp_key].basic_settings.show_name != '' && this.settingForm.control_setting[item.temp_key].basic_settings.show_name != null) {
                    item.title = this.settingForm.control_setting[item.temp_key].basic_settings.show_name
                }
            })
            // console.log(this.settingForm, "Table");
            this.onClose('isSettingTable')
            // const data = this.$refs.FromRef.submitForm();
            // console.log(data);
        },
        onFullscreen(val) {
            this[val] = !this[val]
        },
        onClose(val) {
            if (val == 'isSettingTable') {
                this.onIsSettingTable(false)
            }else{
                this[val] = false
            }
        },
        onIsSettingTable(val) {
            this.$emit('onIsSetting', val)
        },
        onSettingForm(val) {
            this.$emit('onSetting', val)
        }
    },
    mounted() {
    },
    created() {
        this.getRoleOptions()
    }
}
</script>